import { Component, ViewEncapsulation, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter, OnInit } from '@angular/core';
import { ConsoleLoggerService } from '../../_services/console-logger.service';
import { OrganizationDto } from 'src/app/_models/organization.model';
import { SubscriptionDto } from 'src/app/_models/subscription.model';
import { Title } from '@angular/platform-browser';

declare var $: JQueryStatic;

@Component({
  selector: 'app-popover-subject-tree',
  templateUrl: './popover-subject-tree.component.html',
  styleUrls: ['./popover-subject-tree.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PopoverSubjectTreeComponent implements OnChanges {
  @Input() organizations: OrganizationDto[];
  @Input() subscriptionToSelect: SubscriptionDto;
  @Input() organizationToSelect: OrganizationDto;
  @Input() deselectAll: boolean;
  @Output() subscriptionChanged = new EventEmitter<SubscriptionDto>();
  @Output() organizationChanged = new EventEmitter<OrganizationDto>();
  @Output() managePopoverClick = new EventEmitter();

  private readonly loggerFrom: string = 'TopBar-Popover';

  public activeOrganization: OrganizationDto;
  public activeSubscription: SubscriptionDto;

  public stopPropagation: boolean;

  constructor(private consoleLogger: ConsoleLoggerService, private titleService: Title) {}

  ngOnChanges(changes: SimpleChanges): void {
    // const organizationsChange: SimpleChange = changes.organizations;
    const organizationChange: SimpleChange = changes.organizationToSelect;
    const subscriptionChange: SimpleChange = changes.subscriptionToSelect;
    const deselectAllChange: SimpleChange = changes.deselectAll;

    if (organizationChange !== undefined && organizationChange.currentValue !== undefined) {
      this.activeOrganization = organizationChange.currentValue;
    }

    if (subscriptionChange !== undefined && subscriptionChange.currentValue !== undefined) {
      this.activeSubscription = subscriptionChange.currentValue;
    }

    if (this.deselectAll) {
      this.activeOrganization = undefined;
      this.activeSubscription = undefined;
    }
  }

  selectSubscription(organization: OrganizationDto, subscription: SubscriptionDto) {
    this.consoleLogger.success('Active Organization:', this.loggerFrom, organization);
    this.consoleLogger.success('Active Subscription:', this.loggerFrom, subscription);

    this.activeOrganization = organization;
    this.activeSubscription = subscription;

    this.titleService.setTitle('Hexa - ' + this.activeSubscription.name);

    this.subscriptionChanged.emit(this.activeSubscription);
    this.organizationChanged.emit(this.activeOrganization);

    this.managePopoverClick.emit();
  }

  cancelPropagation(e: any) {
    // Prevent the popup to close
    this.stopPropagation = true;
  }

  // Created because of CT that has a default name with [guid]
  public getName(name: string): string {
    if (name && name.indexOf('[guid]') > -1) {
      return '[Enregistrement incomplet]';
    }

    return name;
  }
}
