import 'src/app/_helpers/user-helper';
import 'jquery';

import { Route, Router, NavigationEnd } from '@angular/router';
import { Component, AfterContentInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, NgZone, AfterViewInit, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthIds4Service } from './_services/authids4.service';
import { User } from 'oidc-client';
import { ConsoleLoggerService } from './_services/console-logger.service';
import { PortalEvents } from './_events/portal.events';
import { PortalService } from './_services/portal.service';
import { ConfigService } from './_services/config.service';
import { PortalTranslatorService } from './_services/portal-translation.service';
import { Meta } from '@angular/platform-browser';
import { version } from 'process';

declare var $: JQueryStatic;

// declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit {
  title = 'Portal';
  existingRoutes$: Observable<Route[]>;
  isLogged: boolean;
  user: User;

  showRightPanel: boolean;
  fullScreen: boolean;
  rightPanelComponent: any;
  showLeftPanel: boolean;
  assemblyVersion: string;

  showAuthorizationMessage: boolean;

  public displayNavigation = false;

  // Necessary to load the right panel component dynamically
  @ViewChild('entry', { read: ViewContainerRef }) entry: ViewContainerRef;

  public showSpinner: boolean;

  constructor(
    private authIds4Service: AuthIds4Service,
    private logger: ConsoleLoggerService,
    private portalEvents: PortalEvents,
    private portalService: PortalService,
    private resolver: ComponentFactoryResolver,
    private zone: NgZone,
    public translator: PortalTranslatorService,
    private configService: ConfigService,
    private router: Router,
    private meta: Meta
  ) {
    this.settingViewportAndOrientation();

    this.isLogged = false;
    this.showLeftPanel = false;
    this.showRightPanel = false;
    this.fullScreen = true;
    this.changeShowLeftPanel(!this.fullScreen);
    this.showAuthorizationMessage = false;

    // Controlling the show of the global spinner
    this.portalEvents.showSpinner.subscribe((show) => (this.showSpinner = show));

    this.authIds4Service.loggedUser.subscribe((user) => {
      if (user) {
        this.isLogged = true;
        this.user = user;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-148231055-1', { page_path: event.urlAfterRedirects });
      }
    });
  }

  settingViewportAndOrientation() {
    this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover' }, `name='viewport'`);

    if (this.portalEvents.isMobile) {
      // Causes crash on iOS
      // window.screen.orientation.lock('portrait');
    }
  }

  ngOnInit() {
    this.portalService.getAssemblyVersion().subscribe((data) => {
      this.assemblyVersion = data;
      this.meta.addTag({ name: 'version', content: data });
    });
  }

  ngAfterContentInit() {
    this.portalEvents.onAlertEmailAssignmentChangeParams.subscribe((ev) => {});

    this.portalEvents.onAlertEmailAssignmentClose.subscribe((ev) => {});

    this.portalEvents.onStmDashboardLoaded.subscribe((ev) => {
      this.logger.info('onStmDashboardLoaded', 'AppComponent', ev);
      this.fullScreen = true;
    });

    this.portalEvents.onStmDashboardUnloaded.subscribe((ev) => {
      this.logger.info('onStmDashboardUnloaded', 'AppComponent', ev);
      this.fullScreen = true; // TODO: Need to be changed
    });

    this.portalEvents.onAuthorizationFailed.subscribe((ev) => {
      this.showAuthorizationMessage = true;
    });
  }

  getUiOrganization(): string {
    let value = 10;

    if (this.fullScreen) {
      value = 12;
    }

    if (this.showRightPanel) {
      value = value - 2;
    }

    this.changeShowLeftPanel(!this.fullScreen);

    return `col-${value}`;
  }

  changeShowLeftPanel(value: boolean): void {
    // will not managed by asyncTestZone
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.showLeftPanel = value;
      }, 100);
    });
  }

  displayNavigationHandler($event: any) {
    this.displayNavigation = $event;
  }
}
