import { SubscriptionSharingDto } from './subscriptionSharing';
import { AddressDto } from './address';
import { SubscriptionDto } from './subscription.model';
import { OrganizationDomain } from '../portal-api.g';

export class OrganizationDto {
  id: string;
  name: string;
  normalizedName: string;
  subscriptionSharings: SubscriptionSharingDto[];
  subscriptions: SubscriptionDto[];
  address: AddressDto;
  expended: boolean;
  isSaas: boolean;
  cultures: OrganizationCultureDto[];
  organizationDomains: OrganizationDomain[];
}

export class OrganizationCultureDto {
  constructor(state: string, organizationId: string) {
    this.state = state;
    this.organizationId = organizationId;
  }

  id: string;
  organizationId: string;
  name: string;
  normalizedName: string;
  displayName: string;
  cultureCode: string;
  state: string;
}
