import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { CardInfo, MultiCardWidget } from '../../../_models/dashboard-widget';

@Component({
  selector: 'app-multicard-widget',
  templateUrl: './multicard-widget.component.html',
  styleUrls: ['./multicard-widget.component.scss'],
})
export class MulticardWidgetComponent implements OnInit, OnChanges {
  @Input() config: MultiCardWidget;

  public description = '';
  public cards: CardInfo[] = [];

  constructor(private translator: PortalTranslatorService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const configChange = changes.config;

    if (configChange && configChange.currentValue) {
      const configValue: MultiCardWidget = configChange.currentValue;

      if (configValue.description !== '') {
        this.translator.translate(configValue.description).then((t) => {
          this.description = t;
        });
      }

      this.cards = configValue.cards;

      configValue.cards.forEach((card) => {
        this.translator.translate(card.subtitle).then((translation) => {
          if (card.subtitle === 'abandonned_surveys_summary') {
            if (configValue.abandonnedPercentual > 0) {
              card.subtitle = translation.replace('{0}', configValue.abandonnedPercentual.toString()).replace('{1}', configValue.abandonnedPercentualRange.toString());
            } else {
              card.subtitle = '';
            }
          } else {
            card.subtitle = translation;
          }
        });
      });
    }
  }

  ngOnInit() {}
}
