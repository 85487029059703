import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { AlertsSummaryWidget, AlertWidgetWrapper } from '../../../_models/dashboard-widget';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-alerts-summary-widget',
  templateUrl: './alerts-summary-widget.component.html',
  styleUrls: ['./alerts-summary-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AlertsSummaryWidgetComponent implements OnInit {
  @Input() config: AlertsSummaryWidget;
  wrapper: AlertWidgetWrapper;

  constructor(public translator: PortalTranslatorService) {
  }

  ngOnInit() {
    this.wrapper = new AlertWidgetWrapper();
    this.wrapper.widget = this.config;
  }
}
