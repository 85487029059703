import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SortDirection } from './_models/sort-direction-enum';
import { SortEvent } from './_models/sort-event.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() headerText: string;
  @Input() index: number;
  @Output() Sort = new EventEmitter<SortEvent>();

  constructor() { }

  ngOnInit() {
  }

  // private upClicked(event: any) {
  //   let sortEvent: SortEvent = {
  //     direction: SortDirection.Ascending,
  //     index: this.index
  //   }
  //   this.Sort.emit(sortEvent);
  // }

  // private downClicked(event: any) {
  //   let sortEvent: SortEvent = {
  //     direction: SortDirection.Descending,
  //     index: this.index
  //   }
  //   this.Sort.emit(sortEvent);
  // }
}
