<div class="dropdown" [ngClass]="{ showBullet: showBullet, disabled: disabled, 'cancel-min-width': cancelMinWidth, 'auto-width': autoWidth }">
  <div class="dropdown-bullet" *ngIf="showBullet" [style.borderColor]="bulletColor"></div>

  <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" [ngClass]="{ disabled: disabled }">
    <div class="dropdown-content">
      <span class="text">{{ translator.translate(selectedItem?.value || '') | async }}</span>

      <img class="dropdown-chevron" src="/assets/images/icons/iconmonstr-arrow-65-dark.svg" />
    </div>
  </a>

  <div class="dropdown-menu">
    <a class="dropdown-item" href="javascript:void(0)" *ngFor="let item of items" (click)="selectItem(item)" [ngClass]="{ active: selectedItem == item }">
      <span class="text">{{ translator.translate(item?.value || '') | async }}</span>
    </a>
  </div>
</div>
