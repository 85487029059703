import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChange, SimpleChanges, NgZone, OnDestroy } from '@angular/core';
import { CompetitorsWidget } from '../../../_models/dashboard-widget';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ConsoleLoggerService } from '../../../../../_services/console-logger.service';
import { CompetitorInfo } from '../../../_models/competitors';
import { BarValue } from '../../../_models/chart-serie';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-competitors-widget',
  templateUrl: './competitors-widget.component.html',
  styleUrls: ['./competitors-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CompetitorsWidgetComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() config: CompetitorsWidget;

  private readonly loggerFrom: string = 'CompetitorsWidget';

  private charts: am4charts.XYChart[];
  private categoryAxis: am4charts.CategoryAxis<am4charts.AxisRenderer>;
  private valueAxis: am4charts.ValueAxis<am4charts.AxisRenderer>;
  private series: am4charts.ColumnSeries;

  // Default colors
  private chartColorClasses: am4core.Color[] = [am4core.color('#ef6852'), am4core.color('#edbb6b'), am4core.color('#80d07b')];

  constructor(private zone: NgZone, private logger: ConsoleLoggerService, public translator: PortalTranslatorService) {
    this.charts = [];
  }

  ngAfterViewInit(): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.config.competitors.length; i++) {
      const element = this.config.competitors[i];

      if (element && element.colors) {
        this.chartColorClasses = element.colors.map(c => am4core.color(c));
      }

      this.zone.runOutsideAngular(() => {
        this.configChart(i, element);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const configChange: SimpleChange = changes.config;

    if (configChange.currentValue && !configChange.firstChange) {
      // if (this.config.colors && configChange.currentValue.colors) {
      //   this.chartColorClasses = configChange.currentValue.colors.map(c => am4core.color(c));
      // }

      // this.zone.runOutsideAngular(() => {
      //   this.configChart();
      // });
      for (let i = 0; i < this.config.competitors.length; i++) {
        const element = this.config.competitors[i];

        if (element && element.colors) {
          this.chartColorClasses = element.colors.map(c => am4core.color(c));
        }

        this.zone.runOutsideAngular(() => {
          this.configChart(i, element);
        });
      }
    }
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.charts.forEach(chart => {
        if (chart) {
          chart.dispose();
        }
      });
    });
  }

  configChart(chartIndex: number, chartInfo: CompetitorInfo) {
    // this.disposeChart()
    const chart = am4core.create(`chart-${chartIndex}`, am4charts.XYChart);
    chart.colors.list = this.chartColorClasses;
    chart.align = 'center';

    chart.data = chartInfo.values;

    this.categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    this.categoryAxis.dataFields.category = 'label';
    this.categoryAxis.renderer.labels.template.visible = false;
    this.categoryAxis.renderer.grid.template.disabled = true;
    this.categoryAxis.renderer.grid.template.location = 0;
    this.categoryAxis.renderer.inversed = false;
    this.categoryAxis.renderer.minGridDistance = 1;
    this.categoryAxis.renderer.cellStartLocation = 0;
    this.categoryAxis.renderer.cellEndLocation = 1;
    this.categoryAxis.fontFamily = 'OpenSansSemiBold';
    this.categoryAxis.fontSize = '14';

    this.valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    this.valueAxis.id = 'valueAxis';
    this.valueAxis.renderer.opposite = true;
    this.valueAxis.max = 100;
    this.valueAxis.min = 0;

    this.valueAxis.renderer.grid.template.disabled = true;
    this.valueAxis.renderer.labels.template.disabled = true;

    this.series = chart.series.push(new am4charts.ColumnSeries());
    this.series.dataFields.valueY = 'value';
    this.series.dataFields.categoryX = 'label';
    this.series.sequencedInterpolation = true;
    this.series.strokeWidth = 0;
    this.series.columnsContainer.width = am4core.percent(80);

    const categoryLabel = this.series.bullets.push(new am4charts.LabelBullet());
    categoryLabel.label.text = '{value}%';
    categoryLabel.label.fontFamily = 'PoppinsSemiBold';
    categoryLabel.label.fontSize = '14';
    categoryLabel.label.horizontalCenter = 'middle';
    categoryLabel.label.verticalCenter = 'bottom';
    categoryLabel.label.fill = am4core.color('#000');
    categoryLabel.label.hideOversized = false;
    categoryLabel.label.truncate = false;

    this.series.columns.template.adapter.add('fill', (fill, target) => {
      return chart.colors.getIndex(target.dataItem.index);
    });

    this.charts.push(chart);
  }

  getMax(values: BarValue[]): { index: number; value: number } {
    const maxValue = Math.max(
      ...values.map((v, i) => {
        return v.value;
      })
    );

    const returnVal = {
      index: values.findIndex(item => item.value === maxValue),
      value: maxValue
    };

    return returnVal;
  }
}
