import { VocService } from './../../modules/voc/_services/voc.service';
import { ConfigService } from './../../_services/config.service';
import { SubscriptionMenu } from './../../modules/voc/_models/navigation-menu';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationMenu, ProjectMenu, SubscriptionServiceMenu } from 'src/app/modules/voc/_models/navigation-menu';
import { TopMenuBreadCrumb } from 'src/app/modules/voc/_models/top-menu-bread-crumb';
import { PortalEvents } from 'src/app/_events/portal.events';
import { NavigationOption } from 'src/app/_models/navigationOption';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { SubscriptionServiceShortNameEnum } from 'src/app/_services/config.service';
import { VocEvents } from 'src/app/modules/voc/_events/voc.events';
import { Subject } from 'rxjs';
import { OrganizationDto } from 'src/app/portal-api.g';
import { AuthIds4Service } from 'src/app/_services/authids4.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit {
  @Input() navigationMenus: NavigationMenu[];
  @Input() organizations: OrganizationDto[];
  @Output() topMenuBreadCrumbChanged = new EventEmitter<TopMenuBreadCrumb>();
  @Output() toggleNavigationMenu = new EventEmitter<boolean>();

  SubscriptionServiceShortNameEnum = SubscriptionServiceShortNameEnum;
  public activeProject: ProjectMenu = new ProjectMenu();
  public activeSubscriptionService: SubscriptionServiceMenu = new SubscriptionServiceMenu();
  public activeOrganization: NavigationMenu = new NavigationMenu();
  public activeSubscription: SubscriptionMenu = new SubscriptionMenu();
  public numberOfSubscriptionServices = 0;
  public activeOrArchiveProjects = '';
  public displaySubscriptionMenu = false;
  public searchValue = '';
  public searching = false;
  public searchResults = {};
  private topMenuBreadCrumb: TopMenuBreadCrumb = new TopMenuBreadCrumb();
  private projectCancellationToken = new Subject();
  private subscriptionCancellationToken = new Subject();
  protected default = false;
  public showSpinner = false;

  constructor(
    public portalEvents: PortalEvents,
    protected vocEvents: VocEvents,
    protected configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    public translator: PortalTranslatorService,
    protected vocService: VocService,
    protected authIds4Service: AuthIds4Service
  ) { }

  ngOnInit(): void {
    this.topMenuBreadCrumb.organizationName = '';
    this.topMenuBreadCrumb.projectTypeName = '';
    this.topMenuBreadCrumb.subscriptionName = '';
    this.topMenuBreadCrumb.projectName = '';
    this.topMenuBreadCrumb.subscriptionServiceType = '';

    this.portalEvents.organization.listen((organization) => {
      this.topMenuBreadCrumb.organizationName = organization.displayName;
      this.topMenuBreadCrumbChanged.emit(this.topMenuBreadCrumb);
    }, this.subscriptionCancellationToken);

    this.portalEvents.subscription.listen((subscription) => {
      this.topMenuBreadCrumb.subscriptionName = subscription.name;
      this.topMenuBreadCrumbChanged.emit(this.topMenuBreadCrumb);
    }, this.subscriptionCancellationToken);

    this.portalEvents.subscriptionService.listen((value) => {
      this.renewToken(this.projectCancellationToken);

      // if (this.topMenuBreadCrumb.organizationName === '') {
      switch (value.serviceId) {
        case this.configService.serviceIds.voiceOfCustomer:
          this.topMenuBreadCrumb.subscriptionServiceType = this.portalEvents.subscriptionService.value.displayName;

          this.vocEvents.project.listen((project) => {
            if (project) {
              this.topMenuBreadCrumb.projectName = project.name;

              if (this.navigationMenus) {
                this.setActiveBreadCrumbFromRefresh(project.id);
              }
            }
          }, this.projectCancellationToken);

          break;
        default:
          this.topMenuBreadCrumb.subscriptionServiceType = '';
          this.topMenuBreadCrumb.projectName = this.portalEvents.subscriptionService.value.displayName;

          this.setActiveBreadCrumbFromRefresh(null);
          break;
      }

      this.topMenuBreadCrumbChanged.emit(this.topMenuBreadCrumb);

      //}
    }, this.subscriptionCancellationToken);
  }

  renewToken(token: Subject<unknown>) {
    switch (token) {
      case this.projectCancellationToken:
        this.projectCancellationToken.next();
        this.projectCancellationToken.complete();
        this.projectCancellationToken = new Subject();
        break;
      case this.subscriptionCancellationToken:
        this.subscriptionCancellationToken.next();
        this.subscriptionCancellationToken.complete();
        this.subscriptionCancellationToken = new Subject();
        break;
    }
  }

  ngOnDestroy() {
    this.projectCancellationToken.next();
    this.projectCancellationToken.complete();

    this.subscriptionCancellationToken.next();
    this.subscriptionCancellationToken.complete();
  }

  manageOrganizationSelect(organizationId: string) {
    if (this.activeOrganization === this.navigationMenus.find((q) => q.organizationId === organizationId)) {
      this.activeOrganization = new NavigationMenu();
    } else {
      this.activeOrganization = this.navigationMenus.find((q) => q.organizationId === organizationId);
    }
  }

  manageSubscriptionSelect(searchResult: any): void {
    this.activeOrganization = searchResult.organization;
    this.activeSubscription = searchResult.subscription;
    this.populateProjects(null);
    this.displaySubscriptionMenu = true;
    this.clearSearch();
  }

  setOrganizationStyle(navigationMenu: NavigationMenu): any {
    return {
      color: this.activeOrganization === navigationMenu ? '#3ab7cf' : '#327e9e',
    };
  }

  setOrgChevronStyle(navigationMenu: NavigationMenu): any {
    return {
      color: this.activeOrganization === navigationMenu ? '#3ab7cf' : '#191c1d',
      transform: this.activeOrganization === navigationMenu ? 'rotate(180deg)' : 'rotate(0deg)',
    };
  }

  setSubChevronStyle(subscriptionServiceMenu: SubscriptionServiceMenu): any {
    return {
      color: this.activeSubscriptionService === subscriptionServiceMenu ? '#3ab7cf' : '#191c1d',
      transform: this.activeSubscriptionService === subscriptionServiceMenu ? 'rotate(180deg)' : 'rotate(0deg)',
    };
  }

  backFromSubscriptionMenu(elementId: string): void {
    this.displaySubscriptionMenu = false;

    let subscriptionListContainer = document.getElementById(elementId);
    subscriptionListContainer.classList.add('show');

    window.setTimeout(function () {
      subscriptionListContainer.scrollIntoView({ behavior: 'smooth' });
    }, 150);
  }

  selectActiveOrArchive(activeOrArchive: string): void {
    const chevron = document.querySelector('#' + activeOrArchive + '-projects .fas');

    if (this.activeOrArchiveProjects === activeOrArchive) {
      this.activeOrArchiveProjects = '';
      chevron.classList.remove('rotate-arrow');
    } else {
      this.activeOrArchiveProjects = activeOrArchive;
      chevron.classList.add('rotate-arrow');
    }
  }

  hasMultipleSubscriptionServices(): boolean {
    if (this.activeOrganization && this.activeSubscription && this.activeSubscription.subscriptionServices) {
      return this.activeSubscription.subscriptionServices.length > 1;
    }

    return false;
  }

  getSubscriptionServices(): any {
    if (this.activeOrganization && this.activeSubscription) {
      if (this.activeSubscription.subscriptionServices) {
        this.numberOfSubscriptionServices = this.activeSubscription.subscriptionServices.length;
      }

      return this.activeSubscription.subscriptionServices;
    }

    return [];
  }

  manageTextInput(value: string) {
    this.searchValue = value;
    this.searching = value && value !== '';

    if (this.searching) {
      this.searchResults = {};

      this.navigationMenus.forEach((organization) => {
        let subscriptions = organization.subscriptions;

        if (subscriptions.length > 0) {
          var searchValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

          var searchOrganizationValue = organization.organizationName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
          if (searchOrganizationValue.includes(searchValue)) {
            this.searchResults[organization.organizationName] = {
              org: organization,
              subs: subscriptions,
            };
          } else {
            this.searchResults[organization.organizationName] = {
              org: organization,
              subs: [],
            };

            subscriptions.forEach((subscription) => {
              var searchSubscriptionValue = subscription.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

              if (searchSubscriptionValue.includes(searchValue)) {
                this.searchResults[organization.organizationName].subs.push(subscription);
              }
            });
          }
        }
      });
    }
  }

  searchContainsSubscriptions(subscriptions: SubscriptionMenu[]): boolean {
    return subscriptions.filter((e) => e.name.toLowerCase().includes(this.searchValue.toLowerCase())).length > 0;
  }

  searchContainsOrganizations(organization: NavigationMenu): boolean {
    return !organization.organizationName.toLowerCase().includes(this.searchValue.toLowerCase());
  }

  clearSearch() {
    this.searching = false;
    this.searchValue = '';
  }

  subscriptionServiceChange(subscriptionServiceMenu: SubscriptionServiceMenu, event: any) {
    if (this.activeSubscriptionService === subscriptionServiceMenu) {
      this.activeSubscriptionService = new SubscriptionServiceMenu();
    } else {
      if ((subscriptionServiceMenu.projects && subscriptionServiceMenu.projects.length === 0) || !subscriptionServiceMenu.projects) {
        this.toggleNavigationMenu.emit(event);
      }

      this.activeSubscriptionService = subscriptionServiceMenu;

      if (subscriptionServiceMenu.projects === null || subscriptionServiceMenu.projects === undefined || subscriptionServiceMenu.projects.length === 0) {
        this.navigateToService();
      }

      this.default = false;
    }
  }

  selectedProject(project: ProjectMenu) {
    // set active project
    this.activeProject = project;

    this.authIds4Service.loggedUser.subscribe((user) => {
      if (!user.getUserDto().hasRole(environment.roleDeveloper)) {
        window.location.href = `${this.configService.config.portal.v2Url}/${this.portalEvents.organization.value.id}/subscription/${this.portalEvents.subscription.value.id}/voc/${this.activeSubscriptionService.id}/project/${project.id}/overview`;
      }
    });

    // set active subscription service
    if (this.activeSubscriptionService === null || this.activeSubscriptionService === undefined || !this.activeSubscriptionService?.projects.includes(project)) {
      this.activeSubscriptionService = this.activeSubscription.subscriptionServices.find((q) => q.projects.find((i) => i.id === project.id));
    }

    if (this.setPortalEventsSelection()) {
      // #3 is the project id in params
      const selectedPopoverMenu = this.portalEvents.popoverMenu.value.find((q) => q.params[3] === project.id);

      this.portalEvents.selectedPopoverMenu.value = selectedPopoverMenu;
      this.portalEvents.refreshSubscriptionService();

      if (this.vocEvents.projects.value) {
        this.vocEvents.project.value = this.vocEvents.projects.value.find((q) => q.id === project.id);
        if (this.vocEvents.project.value != null) {
          this.vocEvents.projectOptions.value = this.vocEvents.project.value.options;
        }
      }

      this.setBreadCrumb();
      this.router.navigate(
        selectedPopoverMenu.prependParams ? selectedPopoverMenu.prependParams.concat(selectedPopoverMenu.params) : selectedPopoverMenu.params,
        selectedPopoverMenu.options
      );
    }
  }

  setPortalEventsSelection(): boolean {
    if (this.activeOrganization && this.activeSubscription && this.activeSubscriptionService) {
      const selectedOrganization = this.organizations.find((q) => q.id === this.activeOrganization.organizationId);
      this.portalEvents.organization.value = selectedOrganization;

      const selectedSubscription = selectedOrganization.subscriptions.find((q) => q.id === this.activeSubscription.id);
      this.portalEvents.subscription.value = selectedSubscription;

      this.portalEvents.subscriptionService.value = selectedSubscription.subscriptionServices.find((q) => q.id === this.activeSubscriptionService.id);
      return true;
    }

    return false;
  }

  navigateToService() {
    this.activeProject = null;
    this.portalEvents.refreshSubscriptionService();
    this.setPortalEventsSelection();

    let navigationOption: NavigationOption = {
      text: 'home',
      params: ['home'],
      options: { relativeTo: this.route },
    };

    switch (this.activeSubscriptionService.serviceType) {
      case 'stm':
        navigationOption = {
          text: 'stm',
          params: ['stm'],
        };
        break;

      case 'voc':
        navigationOption = {
          text: 'voc',
          params: ['voc', this.portalEvents.subscriptionService.value.id],
        };

        console.log('here')
        this.authIds4Service.loggedUser.subscribe((user) => {
          if (!user.getUserDto().hasRole(environment.roleDeveloper)) {
            window.location.href = `${this.configService.config.portal.v2Url}/${this.portalEvents.organization.value.id}/subscription/${this.portalEvents.subscription.value.id}/voc/${this.activeSubscriptionService.id}`;
          }
        });

        break;

      case 'ms':
        if (this.portalEvents.subscriptionService.value.serviceId === '00000000-0000-0000-0000-100000000004') {
          navigationOption = {
            text: 'mysteryshopping',
            params: ['mystery-shopping', this.portalEvents.subscriptionService.value.id, 'dashboard'],
          };

          this.authIds4Service.loggedUser.subscribe((user) => {
            if (!user.getUserDto().hasRole(environment.roleDeveloper)) {
              window.location.href = `${this.configService.config.portal.v2Url}/${this.portalEvents.organization.value.id}/subscription/${this.portalEvents.subscription.value.id}/mysteryshopping/${this.activeSubscriptionService.id}`;
            }
          });
        } else {
          navigationOption = {
            text: 'mysteryshopper',
            params: ['mysteryshopper', this.portalEvents.subscriptionService.value.id, 'report'],
          };
        }

        break;

      case 'sl':
        this.portalEvents.subscriptionServiceId.value = this.portalEvents.subscriptionService.value.id;
        navigationOption = {
          text: 'sociallistening',
          params: ['sociallistening', this.portalEvents.subscriptionService.value.id, 'dashboard'],
        };
        break;

      case 'ct':
        navigationOption = {
          text: 'contacttracing',
          params: ['contacttracing', this.portalEvents.subscriptionService.value.id, 'patrons'],
        };
        break;
    }

    this.setBreadCrumb();
    this.router.navigate(navigationOption.prependParams ? navigationOption.prependParams.concat(navigationOption.params) : navigationOption.params, navigationOption.options);
  }

  setBreadCrumb() {
    this.topMenuBreadCrumb.organizationName = this.activeOrganization.organizationName;
    this.topMenuBreadCrumb.subscriptionName = this.activeSubscription.name;
    this.topMenuBreadCrumb.projectTypeName = 'active projects';
    this.topMenuBreadCrumb.subscriptionServiceType = this.activeSubscriptionService.name;
    this.topMenuBreadCrumb.projectName = this.activeProject ? this.activeProject.name : this.activeSubscriptionService.name;

    this.topMenuBreadCrumbChanged.emit(this.topMenuBreadCrumb);
  }

  clearBreadCrumb() {
    this.topMenuBreadCrumb.organizationName = '';
    this.topMenuBreadCrumb.subscriptionName = '';
    this.topMenuBreadCrumb.subscriptionServiceType = '';
    this.topMenuBreadCrumb.projectName = '';
  }

  goToOrganizationAdmin(event: any, id: string) {
    event.preventDefault();
    event.stopPropagation();

    const selectedOrg = this.organizations.find((o) => o.id === id);

    this.portalEvents.organization.value = selectedOrg;

    this.clearBreadCrumb();
    this.topMenuBreadCrumb.organizationName = selectedOrg.displayName;
    this.topMenuBreadCrumbChanged.emit(this.topMenuBreadCrumb);

    this.router.navigate(['admin', id, 'users']);
  }

  goToSubscriptionAdmin(event: any, id: string) {
    event.preventDefault();
    event.stopPropagation();

    const selectedOrg = this.organizations.find((o) => o.subscriptions.find((s) => s.id === id));

    this.portalEvents.organization.value = selectedOrg;
    this.portalEvents.subscription.value = selectedOrg.subscriptions.find((s) => s.id === id);

    this.clearBreadCrumb();
    this.topMenuBreadCrumb.organizationName = selectedOrg.displayName;
    this.topMenuBreadCrumb.subscriptionName = this.portalEvents.subscription.value.name;
    this.topMenuBreadCrumbChanged.emit(this.topMenuBreadCrumb);

    this.router.navigate(['admin', selectedOrg.id, 'subscriptions', id, 'users']);
  }

  setActiveBreadCrumbFromRefresh(projectId: any) {
    const organization = this.navigationMenus.find((q) => q.organizationId === this.portalEvents.organization.value.id);

    if (organization) {
      this.activeOrganization = organization;
      this.activeSubscription = this.activeOrganization.subscriptions.find((q) => q.id === this.portalEvents.subscription.value.id);
      const activeSub = this.activeSubscription.subscriptionServices.find((q) => q.id === this.portalEvents.subscriptionService.value.id);

      if (activeSub) {
        this.activeSubscriptionService = activeSub;
      } else {
        this.activeSubscriptionService = new SubscriptionServiceMenu();
        this.activeSubscriptionService.id = this.portalEvents.subscriptionService.value.id;
      }

      this.populateProjects(projectId);

      this.displaySubscriptionMenu = true;
      this.default = true;
    }
  }

  populateProjects(defaultSelecedProjectId: string) {
    const vocSubscriptionServices = this.activeSubscription.subscriptionServices.filter((q) => q.serviceType === 'voc');

    if (vocSubscriptionServices && vocSubscriptionServices.length > 0) {
      this.showSpinner = true;
      const list: { id: string; menu: NavigationOption }[] = [];

      vocSubscriptionServices.forEach((subscriptionService) => {
        this.vocService.getProjects(subscriptionService.id, this.portalEvents.language.value).then((projects) => {
          let projectMenu: ProjectMenu[] = [];
          projects.forEach((project) => {
            projectMenu.push({
              id: project.id,
              name: project.name,
              projectType: 'active projects',
            });

            const menu = { text: project.name, params: ['/voc', subscriptionService.id, 'project', project.id, 'results'] };
            list.push({ id: project.id, menu });
            this.portalEvents.identifiedPopoverMenu = list;
          });

          subscriptionService.projects = projectMenu;
          if (defaultSelecedProjectId) {
            this.activeProject = this.activeSubscriptionService.projects.find((q) => q.id === defaultSelecedProjectId);
          }
          this.showSpinner = false;
        });
      });
    }
  }
}
