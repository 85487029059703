<div class="input-switch" [ngClass]="{ active: active }">
  <div class="label" *ngIf="!noLabel && labelPosition === 'left'">{{ translator.translate(labelTranslationKey) | async }}</div>

  <div class="switch" [style]="setSwitchStyle()" (click)="toggleState()">
    <div class="pointer"></div>
  </div>

  <div class="label" *ngIf="!noLabel && labelPosition === 'right'">{{ translator.translate(labelTranslationKey) | async }}</div>

  <input type="radio" name="radioInput" id="radioInput" [defaultChecked]="active" />
</div>
