import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-unsaved-modal',
  templateUrl: './unsaved-modal.component.html',
  styleUrls: ['./unsaved-modal.component.scss']
})
export class UnsavedModalComponent implements OnInit {
  @Input() openUnsavedModal: boolean;
  @Output() isLeaveConfirmed = new EventEmitter<boolean>();

  public cancelLabel = '';
  public leaveLabel = '';

  constructor(public translator: PortalTranslatorService) { }

  ngOnInit() {
    this.translator.translate('cancel').then(result => {
      this.cancelLabel = result;
    });

    this.translator.translate('leave').then(result => {
      this.leaveLabel = result;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const openUnsavedModal: SimpleChange = changes.openUnsavedModal;

    if (openUnsavedModal) {
      var modal = document.getElementById("unsaved");

      if (openUnsavedModal.currentValue) {
        modal.style.display = "block";

        // When the user clicks anywhere outside of the modal, close it
        window.addEventListener("click", (event) => {
          if (event.target == modal) {
            modal.style.display = "none";
            this.isLeaveConfirmed.emit(false);
          }
        });
      } else {
        modal.style.display = "none";
      }
    }
  }

  closeModal(leave: boolean) {
    var modal = document.getElementById("unsaved");
    modal.style.display = "none";
    this.isLeaveConfirmed.emit(leave);
  }
}
