import { AddressDto } from './address';
import { FullscreenOverlayContainer } from '@angular/cdk/overlay';

export class BranchDto {
  id: string;
  subjectPath: string;
  name: string;
  code: string;
  addressId: string;
  subscriptionId: string;
  address: AddressDto;
  cultures?: BranchCultureDto[];
  enabled: boolean;
}

export class BranchCultureDto {
  branchId: string;
  name: string;
  cultureCode: string;
  imageBlobStorageId?: string;
}

export class BranchCulturePayload {
  name: string;
  cultureCode: string;
  logo: File;
}

export class BranchSocialMedia {
  id?: string;
  url: string;
  type: SocialMedia;
  branchId: string;
}

export enum SocialMedia {
  None = 0,
  GoogleReviews = 1,
  Facebook = 2,
  TripAdvisor = 3,
  BingReviews = 4,
  FourSquare = 5,
  Yelp = 6
}