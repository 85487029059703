import { Component, OnInit } from '@angular/core';
import { AuthIds4Service } from '../_services/authids4.service';
import { Router } from '@angular/router';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { UserAlertNotificationSettings } from '../_models/user-alert-notification-settings';
import { ConfigService } from '../_services/config.service';
import { PortalService } from '../_services/portal.service';
@Component({
  selector: 'app-callback',
  template: '',
})
export class CallbackComponent implements OnInit {
  constructor(
    private authIds4Service: AuthIds4Service,
    private router: Router,
    private logger: ConsoleLoggerService,
    private configService: ConfigService,
    private portalService: PortalService
  ) {}

  ngOnInit() {
    // Receives the callback from Ids4 to complete the login handshake
    // Url define at 'environment.ids4RedirectUrl'
    this.authIds4Service
      .loginCallback()
      .then((user) => {
        this.addUserAlertNotification(user.profile.sub);

        const redirectUrl = localStorage.getItem('redirectUrl');
        this.logger.info('RedirectUrl', 'CallbackComponent', redirectUrl);

        if (redirectUrl) {
          localStorage.removeItem('redirectUrl');
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate(['/']);
        }
      })
      .catch((reason) => {
        this.logger.error(reason, 'CallbackComponent');
        this.router.navigate(['/login']);
      });
  }

  private addUserAlertNotification(userId: string) {
    // TODO: Review this logic.
    const notificationSubscription = new UserAlertNotificationSettings();
    notificationSubscription.serviceId = this.configService.serviceIds.voiceOfCustomer;
    notificationSubscription.userId = userId;
    notificationSubscription.email = true;

    this.portalService.addNotificationSubscription(notificationSubscription).then((x) => {});
  }
}
