import { ConfigService } from 'src/app/_services/config.service';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';
import { Injectable } from '@angular/core';
import { UrlService } from './url.service';

export class ApiServiceBase {
  private baseUrl: string;

  constructor(protected urlService: UrlService, protected configService: ConfigService, protected consoleLogger: ConsoleLoggerService, private modulePath: string) {
    this.baseUrl = `${this.configService.config.api.baseUrl}/api${modulePath}`;
  }

  getUrl(...endpoints: string[]): string {
    const list: string[] = [];
    endpoints.forEach(el => {
      if (el) {
        list.push(el.toString().replace('/', ''));
      }

      if (!el || !list[list.length - 1]) {
        this.consoleLogger.error(endpoints, 'Url Service', 'Endpoint cannot be null or empty');
      }
    });

    this.urlService.requestedUrl = `${this.baseUrl}/${list.join('/')}`;

    return this.urlService.requestedUrl;
  }
}
