import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/_services/config.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { PortalEvents } from '../_events/portal.events';
import * as moment from 'moment';
import { ValueObserver } from '../_base/value.observer';

@Injectable({
  providedIn: 'root',
})
export class PortalTranslatorService {
  private readonly loggerFrom: string;

  public useSubscription: Subscription;
  public translations: Promise<string>[];
  public readonly activeLanguage: ValueObserver<string> = new ValueObserver<string>();

  constructor(private logger: ConsoleLoggerService, private translateService: TranslateService, private configService: ConfigService, private portalEvents: PortalEvents) {
    this.translations = [];

    let initialLang = configService.config.portal.defaultLang;

    if (localStorage.getItem('lanla-portal-lang')) {
      initialLang = localStorage.getItem('lanla-portal-lang');
    } else {
      initialLang = this.configService.config.portal.defaultLang;
    }

    this.changeLanguage(initialLang);
  }

  public translate(key: string): Promise<string> {
    const keyLang = `${key}_${this.activeLanguage.value}`;

    if (this.translations[keyLang]) {
      return this.translations[keyLang];
    }

    if (key === null || key === '') {
      return new Promise((resolve) => resolve);
    }

    return this.translateService
      .get(key)
      .toPromise()
      .then((result) => {
        this.translations[keyLang] = Promise.resolve(result);
        return this.translations[keyLang];
      });
  }

  public translateAll(keys: string[]): Observable<string | string[]> {
    return this.translateService.get(keys);
  }

  public setDefaultLanguage(langCode: string) {
    this.translateService.setDefaultLang(langCode);
  }

  public changeLanguage(langCode: string) {
    if (!langCode) {
      if (this.configService.config.portal.defaultLang) {
        langCode = this.configService.config.portal.defaultLang;
      } else {
        langCode = 'fr';
      }
    }

    if (this.useSubscription) {
      this.useSubscription.unsubscribe();
    }

    this.useSubscription = this.translateService.use(langCode).subscribe((lang) => {
      this.translateService.currentLang = langCode;
      this.activeLanguage.value = langCode;

      this.logger.info('Selected language:', this.loggerFrom, langCode);
      localStorage.setItem('lanla-portal-lang', langCode);
      if (langCode === 'fr') {
        moment.locale('fr-ca');
      } else {
        moment.locale(langCode);
      }

      this.portalEvents.language.value = langCode;
    });
  }
}
