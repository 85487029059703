import { Component, OnInit } from '@angular/core';
import { PosInfo } from '../modules/portal/ui/card/card-content-pos/posInfo';
import { UserInfo } from '../modules/portal/ui/card/card-content-user/userInfo';
@Component({
  selector: 'app-elements-sheet',
  templateUrl: './elements-sheet.component.html',
  styleUrls: ['./elements-sheet.component.scss']
})
export class ElementsSheetComponent implements OnInit {
  public userInfo: UserInfo = new UserInfo();
  public posInfo: PosInfo = new PosInfo();
  public posOtherInfo: PosInfo = new PosInfo();

  constructor() {
  }

  ngOnInit(): void {
    this.userInfo.groups = ["Potato Inc."];
    this.userInfo.permission = true;

    this.posInfo.groups = [
      {
        id: "A1",
        name: "Group 1"
      },
      {
        id: "A2",
        name: "Group 2"
      },
      {
        id: "A3",
        name: "Group 3"
      },
      {
        id: "A4",
        name: "Group 4"
      }
    ]

    this.posOtherInfo.groups = [
      {
        id: "A1",
        name: "Group 1"
      },
      {
        id: "A2",
        name: "Group 2"
      },
      {
        id: "A7",
        name: "Group 7"
      },
      {
        id: "A8",
        name: "Group 8"
      }
    ]
  }

  toggleSwitch(status: boolean): void {
    console.log('switch status: ', status);
  }

  inputTextChange(text: string): void {
    console.log('input text: ', text);
  }

  btnClick(): void {
    console.log('button clicked');
  }

  deleteCard(id: string): void {
    console.log('delete ' + id);
  }

  duplicateCard(id: string): void {
    console.log('duplicate ' + id);
  }

  editCard(id: string): void {
    console.log('edit ' + id);
  }

  impersonateCard(id: string): void {
    console.log('impersonate ' + id);
  }

  titleClick(id: string): void {
    console.log('title');
  }

  groupClick(id: string): void {
    console.log(id);
  }
}
