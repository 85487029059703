import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { TableColumns, TableValueTypeEnum, TableValue, TableRow, Alignment} from 'src/app/_models/table.model';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardFilterService } from '../../voc/_services/dashboard.filter.service';
import { AlertStatusType } from '../../voc/_models/alert-detail-model';
import { AlertTypeEnum } from '../../voc/_models/alert-detail-model';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import * as moment from 'moment';
import { PortalEvents } from 'src/app/_events/portal.events';
import { DatePipe } from '@angular/common';
import { string } from '@amcharts/amcharts4/core';
import { Subject } from 'rxjs/internal/Subject';
import { take } from 'rxjs/internal/operators/take';
import { UrlService } from 'src/app/_services/url.service';
import { SortEvent } from './header/_models/sort-event.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  TableValueTypeEnum = TableValueTypeEnum;
  Alignment = Alignment;

  @Input() tableColumns: TableColumns[];
  @Input() tableRows: TableRow[];
  @Input() stickyTableHeader = false;
  @Input() headerTop = false;
  @Output() alertClicked = new EventEmitter<string>();
  @Output() pdfClicked = new EventEmitter<any>();
  @Output() buttonClicked = new EventEmitter<any>();
  @Output() pdfCustomClicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter<string>();
  @Output() sort = new EventEmitter<SortEvent>();

  public alertStatusType = AlertStatusType;
  public alerttype = AlertTypeEnum;
  public currentLang: string;
  public disableReports = false;

  constructor(
    private filtering: DashboardFilterService,
    public translator: PortalTranslatorService,
    protected portalEvents: PortalEvents,
    protected datePipe: DatePipe,
    protected urlService: UrlService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.disableReports = JSON.parse(localStorage.getItem('disableReports'));
    this.portalEvents.language.listen((lang) => {
      if (lang === 'fr') {
        this.currentLang = 'fr-ca';
      } else {
        this.currentLang = lang;
      }
    });

    this.translator.activeLanguage.listen((lang) => {
      if (lang === 'fr') {
        this.currentLang = 'fr-ca';
      } else {
        this.currentLang = lang;
      }
    });

    this.portalEvents.onPdfDownloaded.subscribe((id) => {
      this.togglePdfSpinner(id, false);
    });
  }

  ngAfterViewInit() {
    const menuSticky = document.getElementById('subMenuSticky');
    if (menuSticky && this.stickyTableHeader) {
      const tableHeaderSticky = document.querySelectorAll('.sticky-header');
      tableHeaderSticky.forEach((th) => th.setAttribute('style', 'top:' + menuSticky.clientHeight + 'px'));
    }
  }

  private isString(val) {
    return typeof val === 'string';
  }

  alertClick(id: string) {
    this.alertClicked.emit(id);
  }

  buttonClick(object: any) {
    this.buttonClicked.emit({ object });
  }

  deleteClick(id: string) {
    this.deleteClicked.emit(id);
  }

  pdfCustomClick(id: number) {
    this.togglePdfSpinner(id, true);
    this.pdfCustomClicked.emit(id);
  }

  togglePdfSpinner(id: number, showSpinner: boolean) {
    const spinnerContainer = document.getElementById(id.toString());
    const pdfButton = document.getElementById('p-' + id.toString());

    if (showSpinner) {
      spinnerContainer.classList.add('show');
      pdfButton.classList.add('hide');
    } else {
      spinnerContainer.classList.remove('show');
      pdfButton.classList.remove('hide');
    }
  }

  navigateToAnswer(id: string) {
    this.router.navigate(['/dev-admin', this.portalEvents.subscription.value.id, 'survey-answers', id]);
  }

  navigateToLocale(params: any) {
    this.filtering.setSubLocaleWithNoLocale(params.id, params.locationType, true);
  }

  getGrade(grade: number): string {
    if (grade < 2) {
      return 'grade-0';
    } else if (grade < 4) {
      return 'grade-1';
    } else {
      return 'grade-2';
    }
  }

  getStatusText(value: any): string {
    const status = value.state as AlertStatusType;
    const type = value.type as AlertTypeEnum;

    switch (status) {
      case AlertStatusType.Cancelled: {
        return 'alert_cancelled';
      }
      case AlertStatusType.Forwarded: {
        return 'alert_forwarded';
      }
      case AlertStatusType.OnHold: {
        return 'alert_on_hold';
      }
      case AlertStatusType.Opened: {
        return 'alert_opened';
      }
      case AlertStatusType.Resolved: {
        return 'alert_solved';
      }
      case AlertStatusType.Unopened: {
        return type === AlertTypeEnum.ToBeRead ? 'alert_to_read' : 'alert_to_solve';
      }
      case AlertStatusType.Read: {
        return 'alert_was_read';
      }
      case AlertStatusType.Closed: {
        return 'alert_closed';
      }
    }
  }

  expand(id: string) {
    const rows = document.querySelectorAll(`.collapsed-${id}`);
    const img = document.getElementById(id);

    if (rows[0].classList.contains('visible')) {
      img.classList.remove('rotate-accordion');
      rows.forEach((row) => row.classList.remove('visible'));
    } else {
      img.classList.add('rotate-accordion');
      rows.forEach((row) => row.classList.add('visible'));
    }
  }

  sortClicked(event: SortEvent) {
    this.sort.emit(event);
  }
}
