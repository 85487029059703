import { Component, OnInit, OnDestroy, NgZone, Input, OnChanges, SimpleChanges, SimpleChange, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Color } from '@amcharts/amcharts4/core';
import { HorizontalBarsWidget } from '../../../_models/dashboard-widget';
import { Template } from '../../../../voc/_models/template';
import { ConsoleLoggerService } from '../../../../../_services/console-logger.service';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-hbarschart-widget',
  templateUrl: './hbarschart-widget.component.html',
  styleUrls: ['./hbarschart-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HBarsChartWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() config: HorizontalBarsWidget;
  @ViewChild('chart', { static: true }) chartElement: ElementRef;

  private readonly loggerFrom: string = 'HBarsWidget';

  private chart: am4charts.XYChart;
  private categoryAxis: am4charts.CategoryAxis<am4charts.AxisRenderer>;
  private valueAxis: am4charts.ValueAxis<am4charts.AxisRenderer>;
  private series: am4charts.ColumnSeries;

  public id: string = Math.floor(Math.random() * Math.floor(10000)).toString();

  // Default colors
  private chartColorClasses: am4core.Color[] = [
    am4core.color('#76e1f2'),
    am4core.color('#6f81f3'),
    am4core.color('#aa73f1'),
    am4core.color('#dc74f1'),
    am4core.color('#f174ae'),
    am4core.color('#f17974'),
    am4core.color('#f1b874'),
    am4core.color('#ecf174')
  ];

  constructor(private zone: NgZone, private logger: ConsoleLoggerService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const seriesChange: SimpleChange = changes.config;

    if (seriesChange.currentValue) {
      if (this.config.colors && seriesChange.currentValue.colors) {
        this.chartColorClasses = seriesChange.currentValue.colors.map(c => am4core.color(c));
      }

      this.zone.runOutsideAngular(() => {
        this.configChart();
      });
    }
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  configChart() {
    this.disposeChart();
    this.chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
    this.chart.colors.list = this.chartColorClasses;
    this.chart.padding(20, 0, 20, 0);
    this.chart.height = am4core.percent(90);
    this.chart.align = 'center';

    this.chart.data = this.config.series;

    this.categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
    this.categoryAxis.dataFields.category = 'label';
    this.categoryAxis.renderer.grid.template.disabled = true;
    this.categoryAxis.renderer.grid.template.location = 0;
    this.categoryAxis.renderer.inversed = true;
    this.categoryAxis.renderer.minGridDistance = 1;
    this.categoryAxis.renderer.cellStartLocation = 0;
    this.categoryAxis.renderer.cellEndLocation = 1;
    this.categoryAxis.renderer.fontFamily = 'Nunito';
    this.categoryAxis.fontSize = '14';

    this.valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
    this.valueAxis.id = 'valueAxis';
    this.valueAxis.renderer.opposite = true;
    this.valueAxis.fontSize = '10';
    this.valueAxis.max = 140;
    this.valueAxis.min = 0;
    this.valueAxis.strictMinMax = true;

    this.valueAxis.renderer.grid.template.disabled = true;
    this.valueAxis.renderer.labels.template.disabled = true;

    this.series = this.chart.series.push(new am4charts.ColumnSeries());
    this.series.dataFields.valueX = 'value';
    this.series.dataFields.categoryY = 'label';
    this.series.columns.template.tooltipText = 'variance: [bold]{variance}[/]';
    this.series.sequencedInterpolation = true;
    this.series.strokeWidth = 0;
    this.series.paddingRight = 24;

    this.series.columns.template.height = am4core.percent(70);
    this.series.columns.template.adapter.add('fill', (fill, target) => {
      return this.chart.colors.getIndex(target.dataItem.index);
    });

    const valueLabel = this.series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = '{valueX}%';
    valueLabel.label.horizontalCenter = 'left';
    valueLabel.label.dx = 10;
    valueLabel.label.fontSize = '14';
    valueLabel.label.fontFamily = 'Poppins';
    valueLabel.label.hideOversized = false;
    valueLabel.label.truncate = false;

    const categoryLabel = this.series.bullets.push(new am4charts.LabelBullet());
    categoryLabel.label.text = '{variance}';
    categoryLabel.label.fontFamily = 'Nunito';
    categoryLabel.label.fontSize = '14';
    categoryLabel.label.horizontalCenter = 'right';
    categoryLabel.label.dx = -10;
    categoryLabel.label.fill = am4core.color('#fff');
    categoryLabel.label.hideOversized = false;
    categoryLabel.label.truncate = false;

    this.chart.responsive.enabled = true;
    this.chart.responsive.useDefault = false;

    this.series.columns.template.events.on("ready", function (ev) {
      var dataItem: any = ev.target.dataItem;

      if (dataItem && dataItem.bullets && dataItem.valueX < 0) {
        var dictionary = dataItem.bullets._dictionary;
        var keys = Object.keys(dictionary);

        for (var i = 0; i < keys.length; i++) {
          var b = dictionary[keys[i]];

          if (b && b.label.text == '{valueX}%') {
            b.label.text = 'N/A';
          }
        }
      }
    });

    this.chart.responsive.rules.push({
      relevant: target => {
        if (target.pixelWidth <= 280) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4charts.XYChart) {
          const state = target.states.create(stateId);
          state.properties.paddingLeft = 40;
          return state;
        }

        if (target instanceof am4charts.AxisLabel) {
          const state = target.states.create(stateId);
          state.properties.fill = am4core.color('#fff');
          state.properties.hideOversized = false;
          state.properties.visible = true;
          state.properties.truncate = true;
          state.properties.inside = true;

          return state;
        }

        if (target instanceof am4charts.LabelBullet) {
          const state = target.states.create(stateId);

          if ((target.label.fill as am4core.Color).hex === '#ffffff') {
            state.properties.disabled = true;
          }

          return state;
        }
      }
    });
  }
}
