import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'node_modules/ng2-file-upload';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() public url: string;
  @Output() public fileDropped = new EventEmitter();

  public uploader: FileUploader = new FileUploader({ url: null });
  public hasBaseDropZoneOver = false;

  constructor() { }

  ngOnInit() {
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileDrop(e: any): void {
    this.fileDropped.emit(e);
  }
}
