import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, COMPILER_OPTIONS, CompilerFactory, Compiler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { appRoutes } from './routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CopyClipboardModule } from 'src/app/_helpers/CopyClipboard.module';
import { environment } from 'src/environments/environment';
import { DpDatePickerModule } from 'ng2-date-picker';
import { AuthGuard } from './_guards/auth.guard';
import { AppComponent } from './app.component';
import { CallbackComponent } from './callback/callback.component';
import { HomeComponent } from './home/home.component';
import { LoggedoutComponent } from './loggedout/loggedout.component';
import { NotificationService } from './_services/notification.service';
import { AuthIds4Service } from './_services/authids4.service';
import { ConsoleLoggerService } from './_services/console-logger.service';
import { QuickFormService } from './_services/quickForm.service';
import { ConfigService } from './_services/config.service';
import { PortalEvents } from './_events/portal.events';
import { ActivateAssignmentComponent } from './activate-assignment/activate-assignment.component';
import { PortalModule } from './modules/portal/portal.module';
import { EntryPageComponent } from './entry-page/entry-page.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { PopoverSubjectTreeComponent } from './top-bar/popover-subject-tree/popover-subject-tree.component';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomMissingTranslationHelper } from './modules/portal/missing-translation-handler';
import { PortalTranslatorService } from './_services/portal-translation.service';
import { GoogleAnalyticsService } from './_services/google-analytics.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MomentModule } from 'ngx-moment';
import { UrlService } from './_services/url.service';
import { AuthenticationInterceptor } from './_base/authentication.interceptor';
import { ForbiddenInterceptor } from './_base/forbidden.interceptor';
import { NoCacheHeadersInterceptor } from './_base/cache.interceptor';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { IdTokenHeadersInterceptor } from './_base/token.interceptor';
import {
  AdminBranchClient,
  BranchClient,
  CategoriesClient,
  DashboardBranchClient,
  DetailsClient,
  ExportClient,
  FiltersClient,
  SignUpClient,
  MySurveysClient,
  TutorialClient,
  CustomImportProjectClient,
  CustomImportAdminClient,
  QrCodeClient,
  ExternalApiClient,
  SurveyAnswerClient,
  CustomImportClient,
  VoCProjectsClient,
  OrganizationClient,
  SubscriptionClient,
  PermissionClient,
  ProjectSearchClient,
  SubscriptionUserGroupClient,
  OrganizationUserGroupClient,
  OrganizationUserAssignmentClient,
  SubscriptionUserAssignmentClient,
  SystemClient,
  SlUserBookmarkClient,
  PlatformsClient,
  UserClient,
  SubscriptionServicesClient,
  BranchGroupClient,
  ConfigurationClient,
  ProviderClient,
  PipelineLogsClient,
  CategoryClient,
  SocialListeningDetailsClient,
  PointOfSalesClient,
  DashboardClient,
  TagsClient,
  //FilterClient,
  FormsClient,
  ValidationsClient,
  IndicatorsClient,
  SectionsClient,
  MysteryShoppingAutomationProcessClient,
  SurveyAnswerCustomImportFieldJobClient,
  AnalystReportsClient,
  SubscriptionManagerClient
} from './portal-api.g';
import { ClipboardModule } from 'ngx-clipboard';
import { NavigationMenuComponent } from './top-bar/navigation-menu/navigation-menu.component';
import { TextBoxComponent } from './modules/shared/text-box/text-box.component';
import { ImageCropperModule } from 'ngx-image-cropper';

import { UpgradeWidgetComponent } from './top-bar/upgrade-widget/upgrade-widget.component';
import { LoginGoogleComponent } from './login-google/login-google.component';
export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

export function initialize(config: ConfigService): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      config.init.then(() => {
        resolve(true);
      });
    });
  };
}

export function jwtOptionsFactory(urlService: UrlService) {
  return {
    tokenGetter: () => urlService.tokenGetter(),
    whitelistedDomains: [
      'portal.projectmarvel.io',
      'portalv1.projectmarvel.io',
      'api.projectmarvel.io',
      'sit-portalui-rcztgzrdht3mq.azurewebsites.net',
      'portalui-gffmwtvozsufm.azurewebsites.net',
      'portal.hulk.cool',
      'portalv1.hulk.cool',
      'api.hulk.cool',
      'portal.ironman.cool',
      'portalv1.ironman.cool',
      'api.ironman.cool',
      'portal.hexa.app',
      'portalv1.hexa.app',
      'api.hexa.app',
      'portal.hexia.app',
      'portalv1.hexia.app',
      'api.hexia.app',
    ],
    blacklistedRoutes: [
      'ids4.projectmarvel.io',
      'sit-identityuiweb-xrjoyqcaii5nu.azurewebsites.net',
      'identityuiweb-puscbe3bc4dmu.azurewebsites.net',
      'id.hulk.cool',
      'id.ironman.cool',
      'id.hexa.app',
      'id.hexia.app'
    ],
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    // SidePanelComponent,
    HomeComponent,
    EntryPageComponent,
    LoggedoutComponent,
    ActivateAssignmentComponent,
    TopBarComponent,
    UpgradeWidgetComponent,
    PopoverSubjectTreeComponent,
    PageNotFoundComponent,
    NavigationMenuComponent,
    TextBoxComponent,
    LoginGoogleComponent,
  ],
  imports: [
    FormsModule,
    MomentModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', enableTracing: false }),
    CopyClipboardModule,
    ClipboardModule,
    PortalModule,
    DpDatePickerModule,
    ImageCropperModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [UrlService],
      },
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHelper },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForbiddenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdTokenHeadersInterceptor,
      multi: true,
    },
    SocialListeningDetailsClient,
    ExportClient,
    DashboardClient,
    PointOfSalesClient,
    AdminBranchClient,
    BranchClient,
    DashboardBranchClient,
    BranchGroupClient,
    CategoriesClient,
    DashboardClient,
    SystemClient,
    MySurveysClient,
    DetailsClient,
    SubscriptionUserGroupClient,
    OrganizationUserGroupClient,
    CustomImportProjectClient,
    QrCodeClient,
    ExternalApiClient,
    CustomImportClient,
    ConfigurationClient,
    PipelineLogsClient,
    CategoryClient,
    CustomImportAdminClient,
    OrganizationClient,
    ProviderClient,
    SubscriptionClient,
    SubscriptionServicesClient,
    FiltersClient,
    VoCProjectsClient,
    TutorialClient,
    SignUpClient,
    SurveyAnswerClient,
    PermissionClient,
    OrganizationUserAssignmentClient,
    SubscriptionUserAssignmentClient,
    UserClient,
    ProjectSearchClient,
    ConfigService,
    UrlService,
    SlUserBookmarkClient,
    PlatformsClient,
    NotificationService,
    AuthIds4Service,
    AuthGuard,
    ConsoleLoggerService,
    QuickFormService,
    PortalEvents,
    PortalTranslatorService,
    GoogleAnalyticsService,
    PointOfSalesClient,
    TagsClient,
    //FilterClient,
    FormsClient,
    ValidationsClient,
    IndicatorsClient,
    SectionsClient,
    MysteryShoppingAutomationProcessClient,
    SurveyAnswerCustomImportFieldJobClient,
    AnalystReportsClient,
    SubscriptionManagerClient,
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    {
      provide: CompilerFactory,
      useClass: JitCompilerFactory,
      deps: [COMPILER_OPTIONS],
    },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
    {
      provide: 'HostConfiguration',
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
