<div class="menu-header">
  <span>{{ translator.translate('menu') | async }}</span>
</div>

<div class="navigation-menu-container">
  <div class="search-box-section" [hidden]="displaySubscriptionMenu">
    <div class="search-box-container">
      <div class="search-input">
        <input type="text" placeholder="{{ translator.translate('search') | async }}" [ngModel]="searchValue" (ngModelChange)="manageTextInput($event)" />
      </div>

      <div class="search-icon">
        <img src="assets/images/navigation/iconmonstr-magnifier-6.svg" *ngIf="!searching" />
        <img src="assets/images/navigation/remove-circle.svg" *ngIf="searching" (click)="clearSearch()" />
      </div>
    </div>
  </div>

  <div class="scrollable">
    <div class="card borderBottom" *ngFor="let navigationMenu of navigationMenus" [hidden]="displaySubscriptionMenu || searching">
      <div
        class="card-header"
        data-toggle="collapse"
        [attr.data-target]="'#collapse' + navigationMenu.organizationId"
        [attr.aria-controls]="'collapse' + navigationMenu.organizationId"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
        (click)="manageOrganizationSelect(navigationMenu.organizationId)"
      >
        <span class="organization-name" [style]="setOrganizationStyle(navigationMenu)">{{ navigationMenu.organizationName }}</span>
        <i class="fas fa-cog fixed" (click)="goToOrganizationAdmin($event, navigationMenu.organizationId)" *ngIf="navigationMenu.adminPermission"></i>
        <i class="fas fa-chevron-down" [style]="setOrgChevronStyle(navigationMenu)"></i>
      </div>

      <div class="collapse" [attr.id]="'collapse' + navigationMenu.organizationId">
        <div class="card-body">
          <ul class="subscriptions-list">
            <li
              class="subscription"
              *ngFor="let subscription of navigationMenu.subscriptions"
              [ngClass]="{ active: activeSubscription && subscription.id === activeSubscription.id, removeBorder: navigationMenu.subscriptions[navigationMenu.subscriptions.length - 1] === subscription }"
              (click)="manageSubscriptionSelect({ organization: navigationMenu, subscription: subscription })"
            >
              <span class="subscription-name"> {{ subscription.name }}</span>
              <i class="fas fa-cog fixed" (click)="goToSubscriptionAdmin($event, subscription.id)" *ngIf="navigationMenu.adminPermission"></i>
              <i class="fas fa-chevron-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="subscription-menu" [hidden]="!displaySubscriptionMenu || searching">
      <div class="btn-back" (click)="backFromSubscriptionMenu('collapse' + activeOrganization.organizationId)">
        <i class="fas fa-chevron-left"></i>

        <span class="btn-text">{{ translator.translate('back') | async }}</span>
      </div>

      <div class="subscription-header">
        <p class="sub-header-label" *ngIf="activeSubscription">{{ activeSubscription.name }}</p>
        <p class="projects-label">{{ translator.translate('projects') | async }}</p>
      </div>

      <div class="menu-spinner" [hidden]="!this.showSpinner">
        <app-loading-spinner [show-spinner]="true"></app-loading-spinner>
      </div>

      <div class="subscription-services-list" [hidden]="this.showSpinner">
        <div class="subscription-service card" *ngFor="let subscriptionService of getSubscriptionServices()" [ngClass]="{borderBottom: hasMultipleSubscriptionServices(), active: (subscriptionService.id === activeSubscriptionService.id) || numberOfSubscriptionServices === 1 }" (click)="subscriptionServiceChange(subscriptionService, $event)">
          <div
            class="card-header"
            data-toggle="collapse"
            [attr.data-target]="'#collapse' + subscriptionService.id"
            [attr.aria-controls]="'collapse' + subscriptionService.id"
            role="button"
            [attr.aria-expanded]="numberOfSubscriptionServices === 1 ? 'true' : 'false'"
            aria-controls="collapseExample"
          >
            <div class="square" *ngIf="SubscriptionServiceShortNameEnum.voc === subscriptionService.serviceType || SubscriptionServiceShortNameEnum.stm === subscriptionService.serviceType">
              <img src="assets/images/navigation/nav-voc.svg" class="nav-voc-black-icon" [ngClass]="{ hide: subscriptionService.id === activeSubscriptionService.id }"/>
              <img src="assets/images/navigation/nav-voc-aqua.svg" class="nav-voc-aqua-icon" [ngClass]="{ hide: subscriptionService.id !== activeSubscriptionService.id }"/>
            </div>
            <div class="square" *ngIf="SubscriptionServiceShortNameEnum.ms === subscriptionService.serviceType">
              <img src="assets/images/navigation/nav-ms.svg" class="nav-voc-black-icon" [ngClass]="{ hide: subscriptionService.id === activeSubscriptionService.id }"/>
              <img src="assets/images/navigation/nav-ms-aqua.svg" class="nav-voc-aqua-icon" [ngClass]="{ hide: subscriptionService.id !== activeSubscriptionService.id }"/>
            </div>
            <div class="square" *ngIf="SubscriptionServiceShortNameEnum.ct === subscriptionService.serviceType">
              <img src="assets/images/navigation/nav-ct.svg" class="nav-voc-black-icon" [ngClass]="{ hide: subscriptionService.id === activeSubscriptionService.id }"/>
              <img src="assets/images/navigation/nav-ct-aqua.svg" class="nav-voc-aqua-icon" [ngClass]="{ hide: subscriptionService.id !== activeSubscriptionService.id }"/>
            </div>
            <div class="square" *ngIf="SubscriptionServiceShortNameEnum.sl === subscriptionService.serviceType">
              <img src="assets/images/navigation/nav-sl.svg" class="nav-voc-black-icon" [ngClass]="{ hide: subscriptionService.id === activeSubscriptionService.id }"/>
              <img src="assets/images/navigation/nav-sl-aqua.svg" class="nav-voc-aqua-icon" [ngClass]="{ hide: subscriptionService.id !== activeSubscriptionService.id }"/>
            </div>

            <div class="subscription-service-name">
              {{ subscriptionService.name }}
              <span *ngIf="subscriptionService.projects.length > 0">({{ subscriptionService.projects.length }})</span>
            </div>

            <i class="fas fa-chevron-down" *ngIf="subscriptionService.projects.length > 0" [style]="setSubChevronStyle(subscriptionService)"></i>
          </div>

          <div class="collapse" [ngClass]="{ show: numberOfSubscriptionServices === 1 || default }" [attr.id]="'collapse' + subscriptionService.id">
            <div class="card-body project-list">
              <ul class="subscriptions-list">
                <li
                  class="project"
                  *ngFor="let project of subscriptionService.projects"
                  [ngClass]="{ active: project === activeProject }"
                  (click)="selectedProject(project);toggleNavigationMenu.emit($event);"
                >
                  <span class="project-name">{{ project.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-results" [hidden]="!searching || displaySubscriptionMenu">
      <div [ngClass]="{ 'result-org' : organization.value.subs.length > 0 }" *ngFor="let organization of searchResults | keyvalue">
        <div [ngClass]="{ card: organization.value.subs.length > 0 }" [hidden]="organization.value.subs.length === 0">
          <div
            class="card-header"
            data-toggle="collapse"
            [attr.data-target]="'#collapseSearch' + organization.value.org.organizationId"
            [attr.aria-controls]="'collapseSearch' + organization.value.org.organizationId"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <span class="organization-name" [style]="setOrganizationStyle(organization)">
              {{ organization.value.org.organizationName }}
            </span>
            <i class="fas fa-cog fixed" (click)="goToOrganizationAdmin($event, organization.value.org.organizationId)" *ngIf="organization.value.org.adminPermission"></i>
            <i class="fas fa-chevron-down"></i>
          </div>

          <div class="collapse" [attr.id]="'collapseSearch' + organization.value.org.organizationId"
            [ngClass]="{ 'show' : searchContainsSubscriptions(organization.value.subs) || searchContainsOrganizations(organization.value.org)}">
            <div class="card-body">
              <ul class="subscriptions-list">
                <li
                  class="subscription"
                  *ngFor="let subscription of organization.value.subs"
                  [ngClass]="{ active: subscription.id === activeSubscription.id, removeBorder: organization.value.subs[organization.value.subs.length - 1] === subscription  }"
                  (click)="manageSubscriptionSelect({ organization: organization.value.org, subscription: subscription })"
                >
                  <span class="subscription-name">{{ subscription.name }}</span>
                  <i class="fas fa-cog fixed" (click)="goToSubscriptionAdmin($event, subscription.id)" *ngIf="organization.value.org.adminPermission"></i>
                  <i class="fas fa-chevron-right"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="upgrade-widget">
    <app-upgrade-widget></app-upgrade-widget>
  </div>
</div>
