import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() {}

  // public showSpinnerBehavior = new BehaviorSubject<boolean>(false);
  // public showSpinner: Observable<boolean> = this.showSpinnerBehavior.asObservable();

  // public setShowSpinner(value: boolean): void {
  //   this.showSpinnerBehavior.next(value);
  // }

  // public getShowSpinner(): boolean {
  //   return this.showSpinnerBehavior.value;
  // }

  confirm(message: string, okCallback: () => any) {
    alertify.confirm(message, (e: any) => {
      if (e) {
        okCallback();
      }
    });
  }

  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
