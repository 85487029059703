<div id="unsaved" class="modal">
  <div class="modal-content">
    <span class="title">{{ translator.translate('unsaved-changes') | async }}</span>
    <span class="content">{{ translator.translate('unsaved-changes-warning') | async }}</span>

    <div class="button-container">
      <div class="close-container">
        <app-input-button [buttonLabel]="this.cancelLabel" [secondary]="true" (manageClick)="closeModal(false)"></app-input-button>
      </div>
      <app-input-button [buttonLabel]="this.leaveLabel" (manageClick)="closeModal(true)"></app-input-button>
    </div>
  </div>
</div>
