<div class="date-input" [ngClass]="{ disabled: disabled }" onclick="javascript:$(this).next('input').focus();">
  <img src="/assets/images/icons/iconmonstr-calendar-4.svg" onclick="javascript:$(this).next('input').focus();" />
  <input
    #dateDirectivePicker="dpDayPicker"
    type="text"
    name="filter"
    class="form-control"
    autocomplete="off"
    (keyup)="cleanText($event)"
    (ngModelChange)="emitDateChange($event)"
    [(ngModel)]="dateModel"
    [dpDayPicker]="datePickerConfig"
    [theme]="'date-picker-lanla'"
    [mode]="calendarFormat"
    [ngClass]="{ dayCalendar: calendarFormat == 'day', dateError: dateEmpty }"
    [disabled]="disabled"
    (focusout)="verifyDate()"
  />
</div>
