import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { PortalEvents } from 'src/app/_events/portal.events';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { OversightWidget, OversightWidgetTabContentDto, OversightWidgetTabDto } from '../../../_models/dashboard-widget';

@Component({
  selector: 'app-oversight-widget',
  templateUrl: './oversight-widget.component.html',
  styleUrls: ['./oversight-widget.component.scss'],
})
export class OversightWidgetComponent implements OnInit {
  @Input() public config: OversightWidget;
  public currentTab: number;
  public mockData: OversightWidgetTabContentDto[];

  constructor(public portalEvents: PortalEvents, public translator: PortalTranslatorService) {
    this.createMockData();
  }

  ngOnInit() {
    this.currentTab = 0;
  }

  selectFilter(tab: number, event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.currentTab = tab;
  }

  public displayMockData(tab: OversightWidgetTabDto) : boolean {
    return tab.content?.length === 0 || !this.config.isEnabled;
  }

  public getTabContentOrMockData(tab: OversightWidgetTabDto): OversightWidgetTabContentDto[] {
    return this.displayMockData(tab)
      ? this.mockData
      : tab.content;
  }

  public getOverlayText(tab: OversightWidgetTabDto): Promise<string> {
    if (!this.config.isEnabled) {
      return this.translator.translate('oversight_industry_not_supported');
    }

    return tab.hasData
      ? this.translator.translate('oversight_all_good')
      : this.translator.translate('oversight_not_enough_data');
  }

  private createMockData(): void {
    this.mockData = [];

    this.mockData.push({
      icon: 'target',
      title: 'Lorem',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      value: 22,
      valueSuffix: '%'
    });

    this.mockData.push({
      icon: 'delivery',
      title: 'Morbi',
      text: 'Morbi imperdiet sapien ligula, vitae convallis mauris euismod eu',
      value: 22,
      valueSuffix: '%'
    });

    this.mockData.push({
      icon: 'greeting',
      title: 'Suscipit',
      text: 'Morbi suscipit turpis erat, nec consequat lacus fringilla sit amet',
      value: 22,
      valueSuffix: '%'
    });

    this.mockData.push({
      icon: 'food',
      title: 'Vivamus',
      text: 'Vivamus interdum nec augue ut rutrum',
      value: 22,
      valueSuffix: '%'
    });

    this.mockData.push({
      icon: 'clean',
      title: 'Maecenas a accumsan',
      text: 'Vivamus posuere justo ac malesuada elementum. Maecenas a accumsan purus',
      value: 22,
      valueSuffix: '%'
    });
  }
}
