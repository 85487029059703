<div class="table-container">
  <table cellspacing="0">
    <tr [hidden]="tableRows?.length <= 0">
      <ng-container *ngFor="let column of tableColumns">
        <th *ngIf="column.value.type !== TableValueTypeEnum.Nps; else npsTh" [attr.rowspan]="2" [ngClass]="{ 'sticky-header': stickyTableHeader, 'header-top': headerTop }">
          <app-header [headerText]="translator.translate(column.value.value || '') | async" [index]="i" (sort)="sortClicked($event)"></app-header>
        </th>
        <ng-template #npsTh>
          <th [attr.rowspan]="1" [attr.colspan]="2" class="no-row-span {{ getGrade(column.value.grade) }}">
            <span>{{ translator.translate(column.value.value) | async }}</span>
          </th>
        </ng-template>
      </ng-container>
    </tr>
    <tr class="breakdown-row" [hidden]="tableRows?.length <= 0">
      <ng-container *ngFor="let column of tableColumns">
        <ng-container *ngIf="column.value.type === TableValueTypeEnum.Nps">
          <th class="breakdown-col">
            <span>Num.</span>
          </th>
          <th class="breakdown-col">
            <span>%</span>
          </th>
        </ng-container>
      </ng-container>
    </tr>
    <tr [hidden]="tableRows?.length > 0">
      <th></th>
    </tr>
    <tr [hidden]="tableRows?.length > 0">
      <td class="empty-row">
        <img src="/assets/images/icons/graph-nodata.svg" alt="data-empty" />
        <h1>{{ translator.translate('dashboard_no_data') | async }}</h1>
      </td>
    </tr>
    <tr *ngFor="let tableValue of tableRows" [hidden]="tableRows?.length <= 0" [ngClass]="tableValue.options?.class">
      <ng-container *ngFor="let cell of tableValue.cells">
        <ng-container *ngIf="cell.type == TableValueTypeEnum.Text; else elseBlock">
          <td
            align="left"
            [ngClass]="{
              centered: cell.options !== undefined && cell.options.align === Alignment.center,
              leftAlign: cell.options !== undefined && cell.options.align === Alignment.left,
              rightAlign: cell.options !== undefined && cell.options.align === Alignment.right,
              boldLabel: cell.options !== undefined && cell.options.boldLabel,
              boldNumber: cell.options !== undefined && cell.options.boldNumber,
              white: cell.options !== undefined && cell.options.white,
              babyBlueOne: cell.options !== undefined && cell.options.babyBlueOne,
              alternateRowColor: cell.options !== undefined && cell.options.alternateRowColor,
              upperCase: cell.options !== undefined && cell.options.upperCase,
              naColor: cell.options !== undefined && cell.options.naTextColor
            }"
          >
            <span class="align-left" [ngClass]="{ cellDisplayFlex: cell.options !== undefined && cell.options.cellDisplayFlex }">
              <i class="fas fa-sort-up up-indicator" *ngIf="cell.options !== undefined && cell.options.isUpIndicator"></i>
              <i class="fas fa-sort-down down-indicator" *ngIf="cell.options !== undefined && cell.options.isUpIndicator !== undefined && !cell.options.isUpIndicator"></i>
              <div class="detail-first-col" [ngClass]="{ cellMarginLeft: cell.options !== undefined && cell.options.cellMarginLeft }">
                <span [innerHTML]="cell.value"></span>
              </div>
            </span>
          </td>
        </ng-container>
        <ng-template #elseBlock>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Nested; else elseBlock2">
            <td *ngFor="let cellChild of cell.value">
              <span>{{ cellChild.value }}</span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock2>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.GradedText; else elseBlock3">
            <td
              class="grade {{ getGrade(cell.value.grade) }}"
              [ngClass]="{
                alternateRowColor: cell.options !== undefined && cell.options.alternateRowColor,
                rightAlign: cell.options !== undefined && cell.options.align === Alignment.right
              }"
            >
              <span>{{ cell.value.value }}</span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock3>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Link; else elseBlock4">
            <td align="left" class="link">
              <a [routerLink]="cell.value.url" [queryParams]="cell.value.params">{{ cell.value.text }}</a>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock4>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Alert; else elseBlock5">
            <td class="alert">
              <div class="alert-container">
                <div class="symbol">
                  <img src="../../../../assets/images/icons/alert/book.svg" *ngIf="cell.value.state === alertStatusType.Read" />
                  <img src="../../../../assets/images/icons/alert/hourglass.svg" *ngIf="cell.value.state === alertStatusType.OnHold" />
                  <img src="../../../../assets/images/icons/alert/checkmark.svg" *ngIf="cell.value.state === alertStatusType.Resolved" />
                  <img src="../../../../assets/images/icons/alert/mail.svg" *ngIf="cell.value.state === alertStatusType.Forwarded" />
                  <img src="../../../../assets/images/icons/alert/open-mail.svg" *ngIf="cell.value.state === alertStatusType.Opened" />
                  <img src="../../../../assets/images/icons/alert/times-circle-regular.svg" *ngIf="cell.value.state === alertStatusType.Closed" />
                  <img src="../../../../assets/images/icons/alert/mail.svg" *ngIf="cell.value.state === alertStatusType.Unopened && cell.value.type === alerttype.ToBeRead" />
                  <img
                    src="../../../../assets/images/icons/alert/light-beacon.svg"
                    *ngIf="cell.value.state === alertStatusType.Unopened && cell.value.type === alerttype.ToBeSolved"
                  />
                  <img src="" *ngIf="cell.value.state === alertStatusType.Cancelled" />
                </div>
                <div class="alert-text">
                  <span>{{ translator.translate(this.getStatusText(cell.value)) | async }} (</span>
                  <a href="javascript:void(0)" (click)="alertClick(cell.value.surveyAnswerId)">{{ translator.translate('see') | async }}</a>
                  <span>)</span>
                </div>
              </div>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock5>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Pdf && !this.disableReports; else elseBlock6">
            <td class="pdf" align="center">
              <!-- <form ngNoForm action="{{cell.value.url}}" method="post" target="_blank">
                <input type="hidden" name="X-id-token" value="{{this.urlService.tokenGetter(true)}}"> -->
              <button type="submit" (click)="openPdf(cell.value.surveyAnswerId, cell.value.projectId, cell.value.fileName)"><img src="/assets/images/icons/icon-pdf-red.svg" /></button>
              <!-- </form> -->
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock6>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Average; else elseBlock7">
            <td
              align="right"
              [ngClass]="{
                centered: cell.options !== undefined && cell.options.align === Alignment.center,
                upperCase: cell.options !== undefined && cell.options.upperCase,
                naColor: cell.options !== undefined && cell.options.naTextColor
              }"
            >
              <span [ngClass]="{ cellDisplayFlex: cell.options !== undefined && cell.options.cellDisplayFlex }">
                <i class="fas fa-sort-up up-indicator" *ngIf="cell.options !== undefined && cell.options.isUpIndicator"></i>
                <i class="fas fa-sort-down down-indicator" *ngIf="cell.options !== undefined && cell.options.isUpIndicator !== undefined && !cell.options.isUpIndicator"></i>
                <div
                  [ngClass]="{
                    cellMarginLeft: cell.options !== undefined && cell.options.cellMarginLeft,
                    boldLabel: cell.options !== undefined && cell.options.boldLabel,
                    boldNumber: cell.options !== undefined && cell.options.boldNumber
                  }"
                >
                  {{ cell.value }}
                </div>
              </span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock7>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.NotAvailable; else elseBlock8">
            <td align="left" class="not-available">
              <span>N/A</span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock8>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Nps; else elseBlock9">
            <td align="right">
              <span>{{ cell.value.detractors }}</span>
            </td>
            <td align="right">
              <span>{{ cell.value.passiveActors }}</span>
            </td>
            <td align="right">
              <span>{{ cell.value.promoters }}</span>
            </td>
            <td align="right" class="grade" [ngClass]="getGrade(cell.value.score.grade)">
              <span>{{ cell.value.score.value }}</span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock9>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.LocaleNavigation; else elseBlock10">
            <td align="left" class="link" *ngIf="cell.value.text !== 'N/A'; else NALINK">
              <a href="javascript:void(0)" (click)="navigateToLocale(cell.value?.params)">{{ cell.value?.text }}</a>
            </td>
            <ng-template #NALINK>
              <td>N/A</td>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock10>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Button; else elseBlock11">
            <td
              align="center"
              class="image-links"
              [ngClass]="{
                alternateRowColor: cell.options !== undefined && cell.options.alternateRowColor
              }"
            >
              <a style="cursor: pointer" (click)="buttonClick(cell.value)" target="_blank" *ngIf="cell.options !== undefined && cell.options.button">
                <img src="/assets/images/icons/eye-open-black.svg" />
              </a>
              <a style="cursor: pointer" id="p-{{ cell.value }}" (click)="pdfCustomClick(cell.value)" target="_blank" *ngIf="cell.options !== undefined && cell.options.pdf">
                <img src="/assets/images/icons/icon-pdf-red.svg" />
              </a>
              <a style="cursor: pointer" (click)="deleteClick(cell.value)" target="_blank" *ngIf="cell.options !== undefined && cell.options.deleteButton">
                <i class="fa fa-trash buttonIcon"></i>
              </a>
              <div id="{{ cell.value }}" class="hide">
                <app-loading-spinner [hostWidth]="30" [widthInput]="129" [show-spinner]="true"></app-loading-spinner>
              </div>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock11>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Accordion; else elseBlock12">
            <td
              align="center"
              [ngClass]="{
                leftAlign: cell.options !== undefined && cell.options.align === Alignment.left,
                alternateRowColor: cell.options !== undefined && cell.options.alternateRowColor
              }"
            >
              <a
                style="cursor: pointer"
                target="_blank"
                (click)="expand(cell.options.id)"
                [href]="'#' + cell.options.id"
                data-toggle="collapse"
                role="button"
                aria-expanded="false"
              >
                <div class="cellDisplayFlex">
                  <span class="boldLabel remove-uppercase">{{ cell.value }}</span>
                  <img id="{{ cell.options.id }}" class="cellMarginLeft" src="/assets/images/icons/iconmonstr-arrow-65-dark.svg" />
                </div>
              </a>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock12>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Date; else elseBlock13">
            <td align="right">
              <span>{{ Date.parse(cell.value) ? datePipe.transform(cell.value, translator.translate('short_dateFormat_csharp') | async) : 'N/A' }}</span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock13>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Datetime; else elseBlock14">
            <td align="right">
              <span>{{ datePipe.transform(cell.value, translator.translate('datetimeFormat_angular_pipe') | async) }}</span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlock14>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Number; else elseBlockDev">
            <td align="right">
              <span>{{ cell.value }}</span>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #elseBlockDev>
          <ng-container *ngIf="cell.type == TableValueTypeEnum.Dev; else elseBlock6">
            <td class="pdf" align="center">
              <button type="button" (click)="navigateToAnswer(cell.value.surveyAnswerId)"><img src="/assets/images/icons/cogwheel.svg" /></button>
            </td>
          </ng-container>
        </ng-template>
      </ng-container>
    </tr>
  </table>
</div>
