<div class="text-box-container">
  <div class="input-field-container" [ngClass]="{'isLowerCase' : this.isLowerCase}" [style]="setFieldStyle()">
    <input
      class="input-field"
      [type]="setInputType()"
      [placeholder]="placeholder"
      [(ngModel)]="text"
      (ngModelChange)="textChanged()"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [maxlength]="maxLength"
      [style]="{ cursor: disabled ? 'not-allowed' : 'auto' }"
      [autocomplete]="autocomplete"
    />

    <img class="icon" [src]="getIcon()" *ngIf="search || password" (click)="manageIconClick()" [style]="{ cursor: password ? 'pointer' : 'auto' }" />
  </div>

  <div class="error-message" *ngIf="error">
    <span>{{ errorMessage }}</span>
  </div>
</div>
