import { Component, OnInit, Input, Output, EventEmitter, NgZone, AfterViewInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { EnhancedDropdownItem } from '../../_models/enhanced-dropdown';
import { ConsoleLoggerService } from '../../../../_services/console-logger.service';
import { DropdownInputComponent } from '../dropdown-input/dropdown-input.component';

@Component({
  selector: 'app-enhanced-dropdown',
  templateUrl: './enhanced-dropdown.component.html',
  styleUrls: ['./enhanced-dropdown.component.scss'],
})
export class EnhancedDropdownComponent implements OnInit, OnChanges {
  @Input() items: EnhancedDropdownItem[];
  @Input() selectedItems: EnhancedDropdownItem[];
  @Input() allText: string | Promise<string>;
  @Input() chooseText: string | Promise<string>;
  @Input() multipleSelectedText: string | Promise<string>;
  @Input() disabled: boolean;
  @Input() dontReinitalize: boolean;
  @Output() selectionChanged: EventEmitter<EnhancedDropdownItem[]> = new EventEmitter<EnhancedDropdownItem[]>();

  private readonly loggerFrom: string = 'EnhancedDropdown';
  public readonly refNumber = Math.floor(Math.random() * 10).toString();
  private expanded = true;
  public selectionLabel: string;
  public allTextLabel: string;
  public chkAllSelected: boolean = false;

  constructor(private logger: ConsoleLoggerService, private zone: NgZone) {}

  ngOnInit() {
    if (!this.dontReinitalize) {
      if (!this.selectedItems) {
        this.selectedItems = [];
      }
    } else {
      this.updateSelectionLabel();
    }

    this.setAllText();

    this.chkAllSelected = this.isAllSelected(this.items);
  }

  setAllText() {
    if (this.allText) {
      switch (typeof this.allText) {
        case 'string':
          this.allTextLabel = this.allText;
          break;
        default:
          this.allText.then((result) => {
            this.allTextLabel = result;
          });
          break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedItems) {
      this.uncheckAllItems(this.items);
      this.selectedItems.forEach((item) => {
        this.checkItem(this.items, item);
      });
      this.updateSelectionLabel();
    }
  }

  uncheckAllItems(items: EnhancedDropdownItem[]) {
    if (items) {
      items.forEach((item) => {
        item.checked = false;

        if (item.subItems) {
          this.uncheckAllItems(item.subItems);
        }
      });
    }
  }

  checkItem(items: EnhancedDropdownItem[], selectedItem: EnhancedDropdownItem) {
    if (selectedItem) {
      items.forEach((i) => {
        if (i) {
          if (i.key === selectedItem.key) {
            i.checked = true;
            selectedItem.value = i.value;
          }

          if (i.subItems) {
            this.checkItem(i.subItems, selectedItem);

            if (!i.checked && i.subItems.length > 0 && i.subItems.filter(ii => ii.checked).length === i.subItems.length) {
              i.checked = true;
              selectedItem.value = i.value;
            }
          }
        }
      });
    }
  }

  expand() {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  selectItem(item: EnhancedDropdownItem, isSelected: boolean, notifyChange: boolean = true) {
    let parent: EnhancedDropdownItem = null;
    if (item.parentKey) {
      let candidates = this.items.filter((i) => i.key == item.parentKey);

      if (candidates.length === 1) {
        parent = candidates[0];
      }
    }

    item.checked = isSelected;

    if (item.selectable && item.checked) {
      if (this.selectedItems.findIndex((i) => i.key === item.key) === -1) {
        this.selectedItems.push(item);
      }
    } else {
      const indexToDelete = this.selectedItems.findIndex((s) => s.key === item.key);

      if (indexToDelete > -1) {
        this.selectedItems.splice(indexToDelete, 1);

        // Deselecting parent
        if (parent && parent.checked) {
          const parentIndexToDelete = this.selectedItems.findIndex((s) => s.key === parent.key);
          if (parentIndexToDelete > -1) {
            this.selectedItems[parentIndexToDelete].checked = false;
            this.selectedItems.splice(parentIndexToDelete, 1);
          }
        }
        /*
        if (item.parent && item.parent.checked !== undefined && item.parent.checked === true) {
          this.selectItem(item.parent, false, false);
        }*/
      }
    }

    // Select children
    if (item.subItems) {
      item.subItems.forEach((s) => this.selectItem(s, isSelected, false));
    }

    // Select parent if all children are selected
    if (parent && parent.checked !== true) {
      const children = parent.subItems.filter((q) => q.checked);
      if (children.length === parent.subItems.length) {
        this.selectItem(parent, true, true);
      }
    }

    this.updateSelectionLabel();
    
    this.chkAllSelected = this.isAllSelected(this.items);

    // Notify the change to parents components
    if (notifyChange) {
      this.logger.info('Selection Changed: ', this.loggerFrom, this.selectedItems);
      this.selectionChanged.emit(this.selectedItems);
    }
  }

  subSelectionChange(event: any) {
    this.selectItem(event.item, event.isSelected, event.notifyChange);
  }

  updateSelectionLabel(): void {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.selectedItems.length === 1) {
        this.selectionLabel = this.selectedItems[0].value;
      } else {
        if (this.multipleSelectedText instanceof Promise) {
          this.multipleSelectedText.then((text) => {
            this.selectionLabel = text;
          });
        } else {
          this.selectionLabel = this.multipleSelectedText;
        }
      }
    } else {
      if (this.chooseText instanceof Promise) {
        this.chooseText.then((text) => {
          this.selectionLabel = text;
        });
      } else {
        this.selectionLabel = this.chooseText;
      }
    }
  }

  allSelected(ev: any) {
    this.logger.info('All Selected Changed: ', this.loggerFrom, ev);

    this.selectAllMainItems(ev.target.checked);

    this.logger.info('Selection Changed: ', this.loggerFrom, this.selectedItems);
  }

  selectAllMainItems(selectAll: boolean) {
    this.items.forEach((item) => {
      if (!selectAll || !item.checked) {
        this.selectItem(item, selectAll, false);
      }
    });

    this.selectionChanged.emit(this.selectedItems);
  }

  subSelectionCount(item: EnhancedDropdownItem): number {
    if (item.subItems) {
      return item.subItems.filter((s) => s.checked).length;
    }

    return 0;
  }

  isAllSelected(items: EnhancedDropdownItem[]): boolean {
    let allSelected = false;

    if (items) {
      allSelected = true;
      for (var i = 0; i < items.length; i++) {
        var current = items[i];

        if (current.subItems && current.subItems.length > 0) {
          allSelected = allSelected && this.isAllSelected(current.subItems);
        } else {
          if (current.selectable) {
            allSelected = allSelected && current.checked !== undefined && current.checked;
          }
        }
      }
    }

    return allSelected;
  }

  labelClick(event: any) {
    event.stopPropagation();
  }

  stopPropagation(e: any) {
    e.stopPropagation();
  }
}
