import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-input-text-box',
  templateUrl: './input-text-box.component.html',
  styleUrls: ['./input-text-box.component.scss']
})
export class InputTextBoxComponent implements OnInit {
  @Input() disabled = false;
  @Input() error = false;
  @Input() errorMessage: string;
  @Input() password: boolean;
  @Input() placeholder = '';
  @Input() readOnly = false;
  @Input() search: boolean;
  @Input() text = '';
  @Input() debounceTime: number;
  @Input() maxLength: number;
  @Input() isLowerCase = false;
  @Input() autocomplete = '';

  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();

  private internalTextChange: EventEmitter<string> = new EventEmitter<string>();
  public passwordVisible = false;

  constructor() { }

  ngOnInit(): void {
    if (this.hasDebounce()) {
      this.internalTextChange.pipe(debounceTime(this.debounceTime))
        .subscribe(text => this.emitExternalTextChanged(text));
    }
  }

  setFieldStyle(): any {
    if (this.readOnly) {
      return {
        backgroundColor: 'transparent',
        border: 0
      };
    } else {
      return {
        backgroundColor: this.disabled ? '#ebeef0' : '#fff',
        border: '1px solid #ebeef0'
      };
    }
  }

  setInputType(): string {
    return this.password && !this.passwordVisible ? 'password' : 'text';
  }

  getIcon(): string {
    if (this.search) {
      return this.text ? '/assets/images/icons/remove-circle.svg' : '/assets/images/icons/iconmonstr-magnifier-black.svg';
    } else if (this.password) {
      return this.passwordVisible ? '/assets/images/icons/eye-open-black.svg' : '/assets/images/icons/eye-close-black.svg';
    }
  }

  manageIconClick(): void {
    if (this.search && this.text !== '') {
      this.text = '';
      // ngModelChange is not triggered when the value of the variable is changed. Fire the method manually.
      // Bypass debounce because if we clear the field we want to trigger immediately.
      this.textChanged(true);
    } else if (this.password) {
      this.passwordVisible = !this.passwordVisible;
    }
  }

  textChanged(bypassDebounce: boolean = false) {
    if (this.hasDebounce() && !bypassDebounce) {
      this.emitInternalTextChanged(this.text);
    } else {
      this.emitExternalTextChanged(this.text);
    }
  }

  private emitInternalTextChanged(text: string) {
    this.internalTextChange.emit(text);
  }

  private emitExternalTextChanged(text: string) {
    this.textChange.emit(text);
  }

  private hasDebounce(): boolean {
    return this.debounceTime && this.debounceTime > 0;
  }
}
