<div class="container">
  <div class="first-row">
    <div class="item">
      <app-card-component [info]="emailsSummary?.sendInProgessCard"></app-card-component>
    </div>
    <div class="item">
      <app-card-component [info]="emailsSummary?.sentEmailsCard"></app-card-component>
    </div>
  </div>
  <div class="second-row">
    <div class="gauge">
      <div class="title">{{ translator.translate('delivered') | async }}</div>
      <app-percentage-gauge-widget [config]="this.deliveredChart"></app-percentage-gauge-widget>
    </div>
    <div class="gauge">
      <div class="title">{{ translator.translate('opened_emails') | async }}</div>
      <app-percentage-gauge-widget [config]="this.openedChart"></app-percentage-gauge-widget>
    </div>
    <div class="gauge">
      <div class="title">{{ translator.translate('clicked_emails_count') | async }}</div>
      <app-percentage-gauge-widget [config]="this.clickedChart"></app-percentage-gauge-widget>
    </div>
    <div class="remaining-info">
      <div class="group">
        <div class="item">
          <div>{{ translator.translate('bounce') | async }}</div>
          <div class="numbers">
            <div class="percentage">{{ emailsSummary?.bounceShare | number:'1.1-1' }}%</div>
            <div class="count">{{ emailsSummary?.bounceCount }}</div>
          </div>
        </div>
        <!-- Hidden for now, until decide how to get the number -->
        <div class="item">
          <div>{{ translator.translate('spam') | async }}</div>
          <div class="numbers">
            <div class="percentage">{{ emailsSummary?.spamShare | number:'1.1-3' }}%</div>
            <div class="count">{{ emailsSummary?.spamCount }}</div>
          </div>
        </div>
        <div class="item last">
          <div>{{ translator.translate('other') | async }}</div>
          <div class="numbers">
            <div class="percentage">{{ emailsSummary?.otherShare | number:'1.1-1' }}%</div>
            <div class="count">{{ emailsSummary?.otherCount }}</div>
          </div>
        </div>
      </div>
      <div class="subtitle">{{ translator.translate('emails_stats_warning') | async }}</div>
    </div>
  </div>
</div>
