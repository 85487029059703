import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataPoint3, PercentageGaugeWidget } from 'src/app/portal-api.g';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { EmailsSummaryWidget } from '../../../_models/dashboard-widget';

@Component({
  selector: 'app-emails-summary-widget',
  templateUrl: './emails-summary-widget.component.html',
  styleUrls: ['./emails-summary-widget.component.scss'],
})
export class EmailsSummaryWidgetComponent implements OnInit, OnChanges {
  @Input() config: EmailsSummaryWidget;

  constructor(public translator: PortalTranslatorService) {}

  public emailsSummary: EmailsSummaryWidget;
  public deliveredChart: PercentageGaugeWidget;
  public openedChart: PercentageGaugeWidget;
  public clickedChart: PercentageGaugeWidget;

  ngOnChanges(changes: SimpleChanges): void {
    const configChange = changes.config;

    if (configChange && configChange.currentValue) {
      this.emailsSummary = configChange.currentValue;

      if (this.emailsSummary.bounceShare.toString() === Number.NaN.toString()) {
        this.emailsSummary.bounceShare = 0;
      }

      if (this.emailsSummary.deliveredShare.toString() === Number.NaN.toString()) {
        this.emailsSummary.deliveredShare = 0;
      }

      if (this.emailsSummary.unsubscribeShare.toString() === Number.NaN.toString()) {
        this.emailsSummary.unsubscribeShare = 0;
      }

      if (this.emailsSummary.spamShare.toString() === Number.NaN.toString()) {
        this.emailsSummary.spamShare = 0;
      }

      this.generateDeliveredChart();
      this.generateOpenedChart();
      this.generateClickedChart();
    }
  }

  ngOnInit() {}

  private generateDeliveredChart(): void {
    this.deliveredChart = new PercentageGaugeWidget();

    this.deliveredChart.serie = new DataPoint3({ key: 'share', value: this.emailsSummary.deliveredShare });
    this.deliveredChart.middleValue = this.emailsSummary.deliveredShare.toFixed(1);
    this.deliveredChart.middleValueUnit = '%';
    this.deliveredChart.midValueFontSize = 60;
    this.deliveredChart.varianceLabel = 'compared_previous_period';
    this.deliveredChart.middleAbsoluteValue = this.emailsSummary.deliveredCount.toString();

    if (this.emailsSummary.deliveredVariation.toString() === Number.NaN.toString() || this.emailsSummary.deliveredVariation.toString() === 'Infinity') {
      this.deliveredChart.variance = 'N/A';
    } else {
      this.deliveredChart.variance = `${this.emailsSummary.deliveredVariation > 0 ? '+' : ''}${this.emailsSummary.deliveredVariation.toFixed(1)}%`;
    }
  }

  private generateOpenedChart(): void {
    this.openedChart = new PercentageGaugeWidget();

    this.openedChart.serie = new DataPoint3({ key: 'share', value: this.emailsSummary.openedShare });
    this.openedChart.middleValue = this.emailsSummary.openedShare.toFixed(1);
    this.openedChart.middleValueUnit = '%';
    this.openedChart.midValueFontSize = 60;
    this.openedChart.varianceLabel = 'compared_previous_period';
    this.openedChart.middleAbsoluteValue = this.emailsSummary.openedCount.toString();

    if (this.emailsSummary.openedVariation.toString() === Number.NaN.toString() || this.emailsSummary.openedVariation.toString() === 'Infinity') {
      this.openedChart.variance = 'N/A';
    } else {
      this.openedChart.variance = `${this.emailsSummary.openedVariation > 0 ? '+' : ''}${this.emailsSummary.openedVariation.toFixed(1)}%`;
    }
  }

  private generateClickedChart(): void {
    this.clickedChart = new PercentageGaugeWidget();

    this.clickedChart.serie = new DataPoint3({ key: 'share', value: this.emailsSummary.clickedShare });
    this.clickedChart.middleValue = this.emailsSummary.clickedShare.toFixed(1);
    this.clickedChart.middleValueUnit = '%';
    this.clickedChart.midValueFontSize = 60;
    this.clickedChart.varianceLabel = 'compared_previous_period';
    this.clickedChart.middleAbsoluteValue = this.emailsSummary.clickedCount.toString();

    if (this.emailsSummary.clickedVariation.toString() === Number.NaN.toString() || this.emailsSummary.clickedVariation.toString() === 'Infinity') {
      this.clickedChart.variance = 'N/A';
    } else {
      this.clickedChart.variance = `${this.emailsSummary.clickedVariation > 0 ? '+' : ''}${this.emailsSummary.clickedVariation.toFixed(1)}%`;
    }
  }
}
