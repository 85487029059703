import { clone } from '../../../../../_helpers/clone';
import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { DailyBreakdownWidget } from '../../../_models/dashboard-widget';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-survey-daily-breakdown-widget',
  templateUrl: './survey-daily-breakdown-widget.component.html',
  styleUrls: ['./survey-daily-breakdown-widget.component.scss'],
})
export class SurveyDailyBreakdownWidgetComponent implements OnInit, OnChanges {
  @Input() config: DailyBreakdownWidget;

  constructor(public translator: PortalTranslatorService) {}

  public widgetData: any;
  private dailyBreakdown: DailyBreakdownWidget;

  ngOnChanges(changes: SimpleChanges): void {
    const configChange: SimpleChange = changes.config;

    if (configChange && configChange.currentValue) {
      this.dailyBreakdown = configChange.currentValue;

      this.widgetData = this.dailyBreakdown.dailyBreakdown;
      this.widgetData.groupType = 1;
    }
  }

  ngOnInit() {}

  public changeGroupType(option: number) {
    this.widgetData.groupType = option;

    this.widgetData = clone(this.widgetData);
  }
}
