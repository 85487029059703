<div id="saveModal" class="modal">
  <div class="modal-content">
    <span class="title">{{ this.title }}</span>
    <span class="content">{{ this.content }}</span>

    <div class="button-container">
      <div class="close-container">
        <app-input-button [buttonLabel]="this.closeButtonLabel" [secondary]="true" (manageClick)="closeModal()"></app-input-button>
      </div>
      <app-input-button [buttonLabel]="this.saveButtonLabel" (manageClick)="save()"></app-input-button>
    </div>
  </div>
</div>
