export enum ObjectType {
  Organization = 0,
  Subscription = 1,
  Service = 2,
  Branch = 3,
  Address = 4,
  SubscriptionService = 5,
  Project = 6,
  BranchGroup = 7,
  BranchService = 8
}
