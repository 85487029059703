import { Component, OnInit } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-entry-page',
  templateUrl: './entry-page.component.html',
  styleUrls: ['./entry-page.component.scss']
})
export class EntryPageComponent implements OnInit {

  constructor(
    public translator: PortalTranslatorService
    // private authIds4Service: AuthIds4Service
  ) { }

  ngOnInit() {
    console.log('I am here.');
  }

  login() {
    // this.authIds4Service.loginIds4();
  }
}
