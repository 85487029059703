<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="inline">
        <button type="button" class="btn btn-primary" (click)="login()">{{ translator.translate('login') | async }}</button>
      </li>
    </ul>
  </div>
</nav>
<div class="container-fluid" id="main-container">
  <div class="row">
    <div class="col-12">
      {{ translator.translate('entry_page_description') | async }}
    </div>
  </div>
</div>