import { Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { AuthGuard } from './_guards/auth.guard';
import { QuickFormComponent } from './modules/portal/quick-form/quick-form.component';
import { HomeComponent } from './home/home.component';
import { LoggedoutComponent } from './loggedout/loggedout.component';
import { ActivateAssignmentComponent } from './activate-assignment/activate-assignment.component';
import { ErrorComponent } from './modules/portal/error/error.component';
import { RoleGuard } from './_guards/role.guard';
import { environment } from 'src/environments/environment';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ElementsSheetComponent } from './elements-sheet/elements-sheet.component';
import { LoginGoogleComponent } from './login-google/login-google.component';

export const appRoutes: Routes = [
  {
    path: 'elements',
    component: ElementsSheetComponent
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'loggedout',
    component: LoggedoutComponent
  },
  {
    path: 'login',
    component: HomeComponent
  },
  {
    path: 'login-google',
    component: LoginGoogleComponent
  },
  {
    path: 'enable_ct',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    component: HomeComponent
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    component: HomeComponent
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'activateassignment',
        component: ActivateAssignmentComponent
      },
      {
        path: 'quickform',
        component: QuickFormComponent,
        canActivate: [RoleGuard],
        data: { roles: [environment.roleDeveloper] }
      },
      {
        path: 'admin/:organizationId/subscriptions/:subscriptionId',
        loadChildren: () => import('./modules/admin-subscription/admin-subscription.module').then((mod) => mod.AdminSubscriptionModule),
      },
      {
        path: 'admin', // TODO: Review routes as some conflict with admin/:organizationId
        loadChildren: () => import('./modules/admin/admin.module').then((mod) => mod.AdminModule)
      },
      {
        path: 'admin/:organizationId',
        loadChildren: () => import('./modules/admin-organization/admin-organization.module').then((mod) => mod.AdminOrganizationModule),
      },
      {
        path: 'voc/:vocSubscriptionServiceId',
        loadChildren: () => import('./modules/voc/voc.module').then((mod) => mod.VocModule)
      },
      {
        path: 'dev-admin/:subscriptionId',
        loadChildren: () => import('./modules/dev-admin/dev-admin.module').then((mod) => mod.DevAdminModule)
      },
      {
        path: 'mysteryshopper/:subscriptionServiceId',
        loadChildren: () => import('./modules/mystery-shopper/mystery-shopper.module').then((mod) => mod.MysteryShopperModule)
      },
      {
        path: 'sociallistening/:subscriptionServiceId',
        loadChildren: () => import('./modules/social-listening/social-listening.module').then((mod) => mod.SocialListeningModule)
      },
      {
        path: 'stm',
        loadChildren: () => import('./modules/stm/stm.module').then((mod) => mod.StmModule)
      },
      {
        path: 'contacttracing/:subscriptionServiceId',
        loadChildren: () => import('./modules/contact-tracing/contact-tracing.module').then((mod) => mod.ContactTracingModule)
      },
      {
        path: 'results',
        loadChildren: () => import('./modules/results/results.module').then((mod) => mod.ResultsModule)
      },
    ],
  },
  {
    path: 'error/:status',
    component: ErrorComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
