import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationClient } from '../portal-api.g';
import { ConfigService } from '../_services/config.service';

@Component({
  selector: 'app-login-google',
  templateUrl: './login-google.component.html',
  styleUrls: ['./login-google.component.scss'],
})
export class LoginGoogleComponent implements OnInit {
  constructor(private configurationClient: ConfigurationClient, private router: Router, private route: ActivatedRoute, private configService: ConfigService) {}

  ngOnInit() {
    this.route.queryParams.subscribe((observer) => {
      var state: string = observer.state;
      var guidMatch = state.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/);

      if (guidMatch) {
        const id = guidMatch[0];
        const code = decodeURIComponent(observer.code);
        
        this.configurationClient.saveGoogleToken(id, `${this.configService.config.portal.baseUrl}/login-google`, code).subscribe(result => {
          this.router.navigateByUrl(state);
        });
      }
    });
  }
}
