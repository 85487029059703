import { VocEvents } from './../../../voc/_events/voc.events';
import { Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges, SimpleChange, HostBinding, NgZone, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import {
  LineChartWidget,
  DistributionGaugeWidget,
  PercentageGaugeWidget,
  DashboardWidgetConfig,
  HorizontalBarsWidget,
  AlertsSummaryWidget,
  WidgetType,
  AlertWidgetWrapper,
  CompetitorsWidget,
  OversightWidget,
  MultiCardWidget,
  EmailsSummaryWidget,
  DailyBreakdownWidget,
  HourlyBreakdownWidget,
  SocialMediaClickRankWidget,
  SocialMediaPageStatsWidget,
} from '../../_models/dashboard-widget';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsoleLoggerService } from '../../../../_services/console-logger.service';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { PortalEvents } from 'src/app/_events/portal.events';
import { config } from 'rxjs';
declare var $: JQueryStatic;

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardWidgetComponent implements OnInit, OnChanges {
  public WidgetType = WidgetType;
  @Input() widgetConfig: DashboardWidgetConfig;
  @Input() elements: (
    | LineChartWidget
    | DistributionGaugeWidget
    | PercentageGaugeWidget
    | HorizontalBarsWidget
    | AlertsSummaryWidget
    | CompetitorsWidget
    | OversightWidget
    | MultiCardWidget
    | EmailsSummaryWidget
    | DailyBreakdownWidget
    | HourlyBreakdownWidget
    | SocialMediaClickRankWidget
    | SocialMediaPageStatsWidget
  )[];
  @Output() public detailClick: EventEmitter<any> = new EventEmitter();

  public elementConfigs: {
    type: WidgetType;
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget;
  }[];

  public hasHelp: boolean;
  public showHelp: boolean;
  public hasData: boolean;
  public loading: boolean;

  @HostBinding('class.alerts-board') public hasAlertsBoard: boolean;
  @HostBinding('class.competitors-board') public hasCompetitorsBoard: boolean;
  @HostBinding('class.emails-board') public hasEmailsBoard: boolean;
  public hasTrendsBoard: boolean;
  public hasDetailsLink: boolean;

  constructor(
    public translator: PortalTranslatorService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: ConsoleLoggerService,
    private portalEvents: PortalEvents,
    private vocEvents: VocEvents,
    private changeDetec: ChangeDetectorRef
  ) {
    this.loading = true;
    this.showHelp = false;
    this.hasHelp = false;
    this.hasAlertsBoard = false;
    this.hasCompetitorsBoard = false;
    this.hasTrendsBoard = false;
    this.hasDetailsLink = false;
    this.hasEmailsBoard = false;
    this.elementConfigs = [];
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const elementsChange: SimpleChange = changes.elements;

    if (elementsChange && elementsChange.currentValue) {
      this.elementConfigs = [];
      elementsChange.currentValue.forEach((element) => {
        if (element) {
          this.setComponentToShow(element);
        }
      });

      this.loading = false;
    }
  }

  setComponentToShow(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    this.hasAlertsBoard = false;
    this.hasCompetitorsBoard = false;
    this.hasEmailsBoard = false;
    this.hasTrendsBoard = false;
    this.hasDetailsLink = this.widgetConfig.showDetailsLink;

    if (this.isLineChart(config)) {
      this.hasData = this.hasData || this.hasItLineSeries(config as LineChartWidget);

      this.elementConfigs.push({
        type: WidgetType.LineChart,
        config,
      });
      this.hasTrendsBoard = true;
    } else if (this.isDistributionGauge(config)) {
      this.hasData = this.hasData || this.hasItDistributionData(config as DistributionGaugeWidget);
      this.elementConfigs.push({
        type: WidgetType.DistributionGauge,
        config,
      });

      if (this.elementConfigs.length == 2) {
        if ((this.elementConfigs[0].config as DistributionGaugeWidget).surveyCount == 0) {
          this.hasData = false;
        }
      }
    } else if (this.isPercentageGauge(config)) {
      const percentageGaugeWidget = config as PercentageGaugeWidget;
      this.hasData =
        this.hasData ||
        (percentageGaugeWidget.serie && percentageGaugeWidget.serie.value !== null && percentageGaugeWidget.serie.value !== undefined && percentageGaugeWidget.serie.value >= 0);
      this.elementConfigs.push({
        type: WidgetType.PercentageGauge,
        config,
      });
    } else if (this.isHorizontalBars(config)) {
      const horizontalBarsWidget = config as HorizontalBarsWidget;
      this.hasData =
        this.hasData ||
        (horizontalBarsWidget.series !== null &&
          horizontalBarsWidget.series !== undefined &&
          horizontalBarsWidget.series.some((bar) => bar.value !== null && bar.value !== undefined && bar.value >= 0));
      this.elementConfigs.push({
        type: WidgetType.HBars,
        config,
      });
    } else if (this.isAlertsSummary(config)) {
      const alertSummary = config as AlertsSummaryWidget;
      const wrapper = new AlertWidgetWrapper();
      wrapper.widget = alertSummary;
      this.hasData = this.hasData || wrapper.hasData();

      this.hasAlertsBoard = true;
      this.elementConfigs.push({
        type: WidgetType.AlertsSummary,
        config,
      });
    } else if (this.isCompetitors(config)) {
      const competitorsWidget = config as CompetitorsWidget;
      this.hasData = this.hasData || competitorsWidget.competitors.length > 0;
      this.hasCompetitorsBoard = true;

      this.elementConfigs.push({
        type: WidgetType.Competitors,
        config,
      });
    } else if (this.isOversight(config)) {
      this.hasData = true;
      this.elementConfigs.push({
        type: WidgetType.Oversight,
        config,
      });
    } else if (this.isMulticard(config)) {
      this.hasData = true;
      this.elementConfigs.push({
        type: WidgetType.Multicard,
        config,
      });
    } else if (this.isEmailsSummary(config)) {
      this.hasData = true;
      this.hasEmailsBoard = true;
      this.elementConfigs.push({
        type: WidgetType.EmailsSummary,
        config,
      });
    } else if (this.isSurveyDailyBreakdown(config)) {
      this.hasData = true;
      this.elementConfigs.push({
        type: WidgetType.StatisticsDailyBreakdown,
        config,
      });
    } else if (this.isSurveyHourlyBreakdown(config)) {
      this.hasData = true;
      this.elementConfigs.push({
        type: WidgetType.StatisticsHourlyBreakdown,
        config,
      });
    } else if (this.isSocialMediaClickRank(config)) {
      this.hasData = true;
      this.elementConfigs.push({
        type: WidgetType.StatisticsSocialMediaRanking,
        config,
      });
    } else if (this.isSocialMediaPageStats(config)) {
      this.hasData = true;
      this.elementConfigs.push({
        type: WidgetType.StatisticsSocialMediaPage,
        config,
      });
    }

    this.loading = false;
  }

  showDetailsLink(): boolean {
    return this.hasDetailsLink;
  }

  isAlertsWidget(): boolean {
    return this.hasAlertsBoard;
  }

  isCompetitorsWidget(): boolean {
    return this.hasCompetitorsBoard;
  }

  isEmailsWidget(): boolean {
    return this.hasEmailsBoard;
  }

  isTrendsWidget(): boolean {
    return this.hasTrendsBoard;
  }

  isLineChart(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('LineChartWidget')) {
      return true;
    }

    return false;
  }

  isDistributionGauge(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('DistributionGaugeWidget')) {
      return true;
    }

    return false;
  }

  isPercentageGauge(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('PercentageGaugeWidget')) {
      return true;
    }

    return false;
  }

  isHorizontalBars(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('HorizontalBarsWidget')) {
      return true;
    }

    return false;
  }

  isAlertsSummary(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('AlertsSummaryWidget')) {
      return true;
    }

    return false;
  }

  isCompetitors(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('CompetitorsWidget')) {
      return true;
    }

    return false;
  }

  isOversight(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('OversightWidget')) {
      return true;
    }

    return false;
  }

  isMulticard(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('MultiCardWidget')) {
      return true;
    }

    return false;
  }

  isEmailsSummary(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('EmailsSummaryWidget')) {
      return true;
    }

    return false;
  }

  isSurveyDailyBreakdown(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('DailyBreakdownWidget')) {
      return true;
    }

    return false;
  }

  isSurveyHourlyBreakdown(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('HourlyBreakdownWidget')) {
      return true;
    }

    return false;
  }

  isSocialMediaClickRank(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('SocialMediaClickRankWidget')) {
      return true;
    }

    return false;
  }

  isSocialMediaPageStats(
    config:
      | LineChartWidget
      | DistributionGaugeWidget
      | PercentageGaugeWidget
      | HorizontalBarsWidget
      | AlertsSummaryWidget
      | CompetitorsWidget
      | OversightWidget
      | MultiCardWidget
      | EmailsSummaryWidget
      | DailyBreakdownWidget
      | HourlyBreakdownWidget
      | SocialMediaClickRankWidget
      | SocialMediaPageStatsWidget
  ) {
    if (config.$type && config.$type.includes('SocialMediaPageStatsWidget')) {
      return true;
    }

    return false;
  }

  hasItLineSeries(config: LineChartWidget) {
    let returnVal = false;

    if (config.series !== undefined && config.series.length > 0) {
      config.series.forEach((item) => {
        returnVal = returnVal || item.values.length > 0;
      });
    }

    return returnVal;
  }

  hasItDistributionData(config: DistributionGaugeWidget) {
    let returnVal = false;

    if (config.series !== undefined && config.series.length > 0) {
      config.series.forEach((item) => {
        returnVal = returnVal || item.value > 0;
      });
    }

    return returnVal;
  }

  goToDetails() {
    this.detailClick.emit();
  }

  goToResultAlerts() {
    this.detailClick.emit();
  }

  goToAlerts() {
    this.router.navigate(['project', this.vocEvents.project.value.id, 'alerts'], { relativeTo: this.route.parent });
  }

  goToTrends() {
    this.router.navigate(['project', this.vocEvents.project.value.id, 'trends'], { relativeTo: this.route.parent });
  }

  goToCompetitors() {
    this.router.navigate(['project', this.vocEvents.project.value.id, 'competitor-results'], { relativeTo: this.route.parent });
  }
}
