<div class="card-container">
  <div class="body">
    <div class="icon"><img [src]="iconSrc"></div>
    <div class="info">
      <div class="number">{{ info?.number }}</div>
      <div class="description">{{ translator.translate(info?.label) | async }}</div>
    </div>
  </div>
  <div class="subtitle">{{ translator.translate(info?.subtitle) | async }}</div>
</div>
