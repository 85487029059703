import { Component, OnInit, Input, HostListener, Output } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss']
})

export class InfoPopoverComponent implements OnInit {
  @Input() helpHTML: string;
  @Input() title: string;
  @Input() showHelp: boolean;
  @Input() hasHelp: boolean;
  @Input() size: number;

  public isComponentClicked: boolean;
  public isFocusInsideComponent: boolean;

  constructor(public translator: PortalTranslatorService) {
     this.isComponentClicked = false;
     this.isFocusInsideComponent = false;
   }

  ngOnInit(): void {
  }

  @HostListener('click')
  clickInside() {
      this.isFocusInsideComponent = true;
      this.isComponentClicked = true;
      this.showHelp = true;
  }

  @HostListener('document:click')
  clickout() {
      if (!this.isFocusInsideComponent && this.isComponentClicked) {
          // do the heavy process
          this.isComponentClicked = false;
      }
      this.isFocusInsideComponent = false;
      this.showHelp = false;
  }

  toggleHelpPopover(e: any): void {
    // Preventing the window.click event
    e.stopPropagation();
    e.preventDefault();

    this.showHelp = !this.showHelp;
  }
}
