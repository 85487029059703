<app-top-bar (displayNavigationMenu)="displayNavigationHandler($event)"></app-top-bar>

<div class="main-container container-fluid" [ngClass]="{ 'prevent-scroll': displayNavigation }">
  <div class="row" *ngIf="!showAuthorizationMessage">
    <div class="{{ getUiOrganization() }}">
      <router-outlet></router-outlet>
      <app-loading-spinner [show-spinner]="this.showSpinner"></app-loading-spinner>
    </div>
    <div class="col-2" [hidden]="!showRightPanel">
      <template #entry></template>
    </div>
  </div>
  <div class="row authorization-message" *ngIf="showAuthorizationMessage">
    <h1>{{ translator.translate('no_authorization') | async }}</h1>
  </div>
</div>
