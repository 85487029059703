import { Component, OnInit, OnDestroy, NgZone, Input, OnChanges, SimpleChanges, SimpleChange, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { MultiBarsWidget } from '../../../_models/dashboard-widget';
import { ConsoleLoggerService } from '../../../../../_services/console-logger.service';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { PortalEvents } from 'src/app/_events/portal.events';
import { capitalize } from 'src/app/_helpers/capitalize';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-breakdown-barchart-widget',
  templateUrl: './breakdown-barchart-widget.component.html',
  styleUrls: ['./breakdown-barchart-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BreakdownBarchartWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() config: any;
  @Input() hideSeriesLabels: boolean;
  @Output() chartLoaded = new EventEmitter<boolean>();
  @ViewChild('chart', { static: true }) chartElement: ElementRef;

  private readonly loggerFrom: string = 'BarChartWidget';
  private chart: am4charts.XYChart;
  private xAxis: am4charts.CategoryAxis<am4charts.AxisRenderer>;
  public id: string = Math.floor(Math.random() * Math.floor(10000)).toString();

  public isLoaded: boolean;

  // Default colors
  private chartColorClasses: am4core.Color[] = [am4core.color('#0082a4'), am4core.color('#8878b2'), am4core.color('#82cad1'), am4core.color('#97be0d'), am4core.color('#e4368a')];

  constructor(private zone: NgZone, private translatorService: PortalTranslatorService, private portalEvents: PortalEvents, private logger: ConsoleLoggerService) {}

  ngOnInit() {
    // this.isLoaded = true;

    this.portalEvents.language.listen((x) => {
      if (this.chart) {
        if (x === 'fr') {
          this.chart.language.locale = am4lang_fr_FR;
        } else {
          this.chart.language.locale = am4lang_en_US;
        }
      }
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const seriesChange: SimpleChange = changes.config;

    if (seriesChange.currentValue) {
      if (this.config.colors && seriesChange.currentValue.colors) {
        this.chartColorClasses = seriesChange.currentValue.colors.map((c) => am4core.color(c));
      }

      if (this.config.series && this.config.series !== undefined) {
        this.zone.runOutsideAngular(() => {
          this.configChart();
        });
      }
    }
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
      this.isLoaded = false;
      this.chartLoaded.emit(false);
    }
  }

  configChart() {
    this.disposeChart();

    this.chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
    this.chart.colors.list = this.chartColorClasses;
    this.chart.data = this.config.series;
    this.chart.numberFormatter.numberFormat = '#,###.#';

    this.chart.legend = new am4charts.Legend();
    this.chart.legend.parent = this.chart.topAxesContainer;
    this.chart.legend.fontSize = '11';
    this.chart.legend.paddingBottom = 16;
    this.chart.legend.contentAlign = 'right';

    this.translatorService.activeLanguage.listen((x) => {
      if (x === 'fr') {
        this.chart.language.locale = am4lang_fr_FR;
      } else {
        this.chart.language.locale = am4lang_en_US;
      }
    });

    this.chart.events.on('appeared', () => {
      this.isLoaded = true;
      this.chartLoaded.emit(this.isLoaded);
    });

    const xAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = 'category';
    xAxis.renderer.minGridDistance = 10;
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.labels.template.fontSize = 10;
    xAxis.renderer.labels.template.maxWidth = 50;

    xAxis.events.on('sizechanged', function (ev) {
      let axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = 90;
        axis.renderer.labels.template.horizontalCenter = 'left';
        axis.renderer.labels.template.verticalCenter = 'middle';
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = 'left';
        axis.renderer.labels.template.verticalCenter = 'top';
      }
    });

    const yAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.extraMax = 0.1;

    Promise.all([this.translatorService.translate('completed_surveys'), this.translatorService.translate('emails_clicked')]).then((translations) => {
      if (this.config.groupType === 1) {
        this.createSeries('count', capitalize(translations[1]));
        this.createSeries('completedCount', capitalize(translations[0]));
      } else {
        this.createSeries('averageCount', capitalize(translations[1].toString()));
        this.createSeries('averageCompletedCount', capitalize(translations[0]));
      }
    });
  }

  public createSeries(value, name): void {
    const series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = value;
    series.dataFields.categoryX = 'category';
    series.name = name;

    const bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.fontSize = '10';
    bullet.dy = -8;
    bullet.label.text = '{valueY}';
    bullet.label.fill = am4core.color('#000000');
  }

  public exportChart(): void {
    if (this.chart.language.locale == am4lang_fr_FR) {
      this.chart.exporting.filePrefix = this.portalEvents.subscription.value.name + '_tendances';
    } else {
      this.chart.exporting.filePrefix = this.portalEvents.subscription.value.name + '_trends';
    }

    this.chart.exporting.export('png');
  }
}

