import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { PosInfo } from './posInfo';

@Component({
  selector: 'app-card-content-pos',
  templateUrl: './card-content-pos.component.html',
  styleUrls: ['./card-content-pos.component.scss']
})
export class CardContentPosComponent implements OnInit {
  @Input() posInfo: PosInfo;
  @Output() groupEvent: EventEmitter<string> = new EventEmitter<string>();
  
  constructor(public translator: PortalTranslatorService) { }

  ngOnInit(): void {
  }

  public onClickGroup(id: string): void {
    this.groupEvent.emit(id);
  }
}
