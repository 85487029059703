import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-input-button',
  templateUrl: './input-button.component.html',
  styleUrls: ['./input-button.component.scss']
})
export class InputButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() icon = '';
  @Input() buttonLabel = '';
  @Input() secondary = false;
  @Input() isProcessing = false;

  @Output() manageClick = new EventEmitter<any>();

  public hover = false;

  constructor(public translator: PortalTranslatorService) { }

  ngOnInit(): void { }

  setButtonStyle(): any {
    const styleObj = {
      backgroundColor: this.secondary ? 'transparent' : '#3ab7cf',
      color: this.secondary ? '#3ab7cf' : '#fff',
      cursor: this.disabled ? 'not-allowed' : 'pointer',
      opacity: this.disabled ? '.5' : '1'
    };

    if (this.hover && !this.disabled) {
      styleObj.backgroundColor = this.secondary ? 'rgba(50, 126, 158, 0.15)' : 'rgba(50, 126, 158, 0.5)';
    }

    return styleObj;
  }

  handleClick(): void {
    if (!this.disabled) {
      this.manageClick.emit();
    }
  }
}
