<div class="elements-sheet-header">Global UI Elements Sheet</div>

<hr />

<div class="element-container">
  <div class="element-title">Switch</div>

  <div class="element-examples">
    <div class="element-example">
      <div class="example-title">Default:</div>

      <app-input-switch (activeChange)="toggleSwitch($event)"></app-input-switch>
    </div>

    <div class="element-example">
      <div class="example-title">Initialized On:</div>

      <app-input-switch [active]="true"></app-input-switch>
    </div>

    <div class="element-example">
      <div class="example-title">Custom Labels:</div>

      <app-input-switch [labelOn]="'switch_assigned'" [labelOff]="'switch_not_assigned'"></app-input-switch>
    </div>

    <div class="element-example">
      <div class="example-title">Alternate Label Position:</div>

      <app-input-switch [labelPosition]="'right'"></app-input-switch>
    </div>

    <div class="element-example">
      <div class="example-title">No Label:</div>

      <app-input-switch [noLabel]="true"></app-input-switch>
    </div>
  </div>
</div>

<hr />

<div class="element-container">
  <div class="element-title">Textbox Input</div>

  <div class="element-examples">
    <div class="element-example">
      <div class="example-title">w/ Error Message:</div>

      <app-input-text-box [error]="true" [errorMessage]="'This is an error message'" (textChange)="inputTextChange($event)"></app-input-text-box>
    </div>

    <div class="element-example">
      <div class="example-title">w/ Preloaded Text:</div>

      <app-input-text-box [text]="'Preloaded text'" (textChange)="inputTextChange($event)"></app-input-text-box>
    </div>

    <div class="element-example">
      <div class="example-title">Disabled:</div>

      <app-input-text-box [disabled]="true" (textChange)="inputTextChange($event)"></app-input-text-box>
    </div>

    <div class="element-example">
      <div class="example-title">Search Field:</div>

      <app-input-text-box [placeholder]="'search here ...'" [search]="true" (textChange)="inputTextChange($event)"></app-input-text-box>
    </div>

    <div class="element-example">
      <div class="example-title">Search Field with debounce:</div>

      <app-input-text-box [placeholder]="'search here ...'" [search]="true" [debounceTime]="1000" (textChange)="inputTextChange($event)"></app-input-text-box>
    </div>

    <div class="element-example">
      <div class="example-title">Password Field:</div>

      <app-input-text-box [password]="true" (textChange)="inputTextChange($event)"></app-input-text-box>
    </div>

    <div class="element-example">
      <div class="example-title">Read-Only:</div>

      <app-input-text-box [readOnly]="true" [text]="'This is read-only'" (textChange)="inputTextChange($event)"></app-input-text-box>
    </div>
  </div>
</div>

<hr />

<div class="element-container">
  <div class="element-title">Button</div>

  <div class="element-examples">
    <div class="element-example">
      <div class="example-title">Primary:</div>

      <app-input-button (manageClick)="btnClick()"></app-input-button>
    </div>

    <div class="element-example">
      <div class="example-title">Secondary:</div>

      <app-input-button [secondary]="true" (manageClick)="btnClick()"></app-input-button>
    </div>

    <div class="element-example">
      <div class="example-title">Disabled (Primary):</div>

      <app-input-button [disabled]="true" (manageClick)="btnClick()"></app-input-button>
    </div>

    <div class="element-example">
      <div class="example-title">Disabled (Secondary):</div>

      <app-input-button [secondary]="true" [disabled]="true" (manageClick)="btnClick()"></app-input-button>
    </div>

    <div class="element-example">
      <div class="example-title">Primary w/ Icon:</div>

      <app-input-button [icon]="'assets/images/icons/xlsx-icon.svg'" (manageClick)="btnClick()"></app-input-button>
    </div>

    <div class="element-example">
      <div class="example-title">Secondary w/ Icon:</div>

      <app-input-button [secondary]="true" [icon]="'assets/images/icons/xlsx-icon_secondary.svg'" (manageClick)="btnClick()"></app-input-button>
    </div>
  </div>
</div>

<hr />

<div class="element-container">
  <div class="element-title">User Card</div>

  <div class="element-examples">
    <div class="element-example">
      <div class="example-title">User Dashboard:</div>

      <app-card
      [title]="'Abdul Sadiq'"
      [secondaryTitle]="'abdul.sadiq@circle6.com'"
      [identifier]="'AS'"
      [data]="userInfo"
      (deleteEvent)="deleteCard($event)"
      (duplicateEvent)="duplicateCard($event)"
      (editEvent)="editCard($event)"
      (actionEvent)="impersonateCard('0e61d40e-5188-45a2-85fc-03a6a0c97f5e')"
      (titleEvent)="titleClick($event)"
      ></app-card>
    </div>

    <div class="element-example">
      <div class="example-title">Points of Service:</div>

      <app-card
      [identifier]="'POS'"
      [displayDuplicateBtn]="true"
      [secondaryTitle]="'1640 - Nicolet'"
      [data]="posInfo"
      (deleteEvent)="deleteCard($event)"
      (duplicateEvent)="duplicateCard($event)"
      (titleEvent)="titleClick($event)"
      (actionEvent)="groupClick($event)"
      (editEvent)="editCard($event)"
      ></app-card>

      <app-card
      [identifier]="'POS'"
      [displayDuplicateBtn]="true"
      [title]="'1640 - Nicolet'"
      [data]="posInfo"
      (deleteEvent)="deleteCard($event)"
      (duplicateEvent)="duplicateCard($event)"
      (titleEvent)="titleClick($event)"
      (actionEvent)="groupClick($event)"
      (editEvent)="editCard($event)"
      ></app-card>
    </div>
  </div>
</div>
