export class TableColumns {
  value: TableValue;
}

export class TableRow {
  cells: TableValue[];
  options?: TableRowOptions;
}

export class TableValue {
  name: string;
  value: any;
  otherValue?: any;
  type: TableValueTypeEnum;
  options?: TableValueOptions;
  grade?: any;
}

export class Table {
  title: string;
  tableColumns: TableColumns[];
  tableRow: TableRow[];
}

export enum TableValueTypeEnum {
  Text = 0,
  Link = 1,
  ImageButton = 2,
  GradedText = 3,
  Nested = 4,
  Alert = 5,
  Pdf = 6,
  Average = 7,
  NotAvailable = 8,
  Nps = 9,
  LocaleNavigation = 10,
  Button = 11,
  Accordion = 12,
  Date = 13,
  Platform = 14,
  Comment = 15,
  Evaluation = 16,
  Opinion = 17,
  Accuracy = 18,
  Dev = 19,
  Datetime = 20,
  Number = 21
}

export enum Alignment {
  left = 0,
  center = 1,
  right = 2
}

export class TableRowOptions {
  class: string;
}

export class TableValueOptions {
  boldLabel: boolean;
  boldNumber: boolean;
  align: Alignment;
  babyBlueOne: boolean;
  alternateRowColor: boolean;
  white: boolean;
  button: boolean;
  pdf: boolean;
  class: string;
  id: string;
  isUpIndicator?: boolean;
  deleteButton: boolean;
  cellMarginLeft: boolean;
  cellDisplayFlex: boolean;
  upperCase: boolean;
  naTextColor: boolean;
  showPdfSpinner: boolean;
  whiteSpaceNoWrap: boolean;

  public constructor(init?: Partial<TableValueOptions>) {
    Object.assign(this, init);
  }
}
