import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { ConfigService } from './config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserClient } from '../portal-api.g';

import * as Oidc from 'oidc-client';
import { getOidcConfig } from '../_helpers/oidcConfig';
import { PortalService } from './portal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthIds4Service {
  // Service responsible to controlling the communication with Ids4

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private consoleLogger: ConsoleLoggerService,
    private portalService: PortalService,
    private userClient: UserClient
  ) {
    Oidc.Log.logger = console;
    Oidc.Log.level = environment.oidcLogLevel;

    this.initialize = new Promise((resolve, reject) => {
      this.init(consoleLogger)
        .then(resolve)
        .then(() => this.start())
        .catch(reject);
    });
  }

  readonly loggerFrom: string = 'AuthIds4Service';

  public initialize: Promise<void>;
  private authorityConfig: UserManagerSettings;
  private loggedUserBehavior = new BehaviorSubject<User>(null);

  public userLoadedEvent: EventEmitter<User> = new EventEmitter<User>();
  public userUnloadedEvent: EventEmitter<string> = new EventEmitter<string>();
  public loggedIn: boolean;
  public loggedUser: Observable<User> = this.loggedUserBehavior.asObservable();
  public userManager: UserManager;
  public userName = '';

  private init(consoleLogger: ConsoleLoggerService): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authorityConfig = getOidcConfig(this.configService.config);

      this.consoleLogger.info('AuthorityConfig', this.loggerFrom, this.authorityConfig);

      if (!this.userManager) {
        this.consoleLogger.info('Empty User', this.loggerFrom);
        this.userManager = new UserManager(this.authorityConfig);
      }

      resolve();
    });
  }

  private start() {
    this.userManager
      .getUser()
      .then((user) => {
        if (user) {
          this.loggedIn = true;
          this.setLoggedUser(user);
        } else {
          this.loggedIn = false;
        }
      })
      .catch((err) => {
        this.loggedIn = false;
      });

    // this.userManager.events.addAccessTokenExpiring(() => {
    //   this.consoleLogger.info('Token Expiring', this.loggerFrom, 'Renew started');
    //   this.userManager.signinSilent();
    // });

    this.consoleLogger.info('Registering Events', this.loggerFrom);

    this.userManager.events.addUserLoaded((user) => {
      this.userClient.saveUserId().subscribe(() => {});
      this.portalService.activateRoleAssignment().then((result) => {
        this.setLoggedUser(user);

        if (this.loggedUserBehavior) {
          this.loggedUserBehavior.next(user);
        }

        this.loggedIn = !(user === undefined);
        // this.consoleLogger.info('authService addUserLoaded', this.loggerFrom, user);
      });
    });

    this.userManager.events.addUserSignedOut(() => {
      this.loginIds4();
    });

    this.userManager.events.addUserUnloaded(() => {
      this.consoleLogger.info('authService addUserUnloaded', this.loggerFrom);
      this.setLoggedUser(null);
    });
  }

  // Using this method will send notification to everyone that subscribe to loggedUser
  public setLoggedUser(user: User, triggerEvent: boolean = true) {
    if (user != null) {
      this.loggedIn = true;
      if (triggerEvent) {
        this.userLoadedEvent.emit(user);
      }
    } else {
      this.loggedIn = false;

      if (triggerEvent) {
        this.userUnloadedEvent.emit('User unloaded');
      }
    }
  }

  public getLoggedUserInfo(): Promise<User> {
    return this.initialize.then(() => this.userManager.getUser());
  }

  public getLoggedUserFromCache() {
    return this.initialize.then(() => this.renewToken()).then(() => this.loggedUserBehavior.value);
  }

  public loginIds4(): Promise<void> {
    localStorage.setItem('signin-method', 'redirect');
    return this.initialize.then(() => this.userManager.signinRedirect());
  }

  public loginCallback(): Promise<User> {
    switch (localStorage.getItem('signin-method')) {
      case 'redirect':
        return this.initialize
          .then(() => this.userManager.signinRedirectCallback())
          .then((user) => {
            this.setLoggedUser(user, true);
            return Promise.resolve(user);
          });
      default:
        return this.initialize
          .then(() => this.userManager.signinSilentCallback())
          .then((user) => {
            this.setLoggedUser(user, true);
            return Promise.resolve(user);
          });
    }
  }

  public signInSilent(): Promise<User> {
    localStorage.setItem('signin-method', 'silent');
    return this.initialize.then(() => this.userManager.signinSilent());
  }

  private renewToken(): Promise<User> {
    return new Promise((resolve, reject) => {
      this.userManager.getUser().then((user) => {
        if (user !== null) {
          if (user.expired) {
            this.consoleLogger.info('Renew token started', this.loggerFrom);
            this.signInSilent()
              .then((user) => {
                this.setLoggedUser(user, false);
                resolve(user);
                return Promise.resolve();
              })
              .catch((err) => {
                this.loginIds4();
              });
          } else {
            if (user !== this.loggedUserBehavior.value) {
              this.loggedUserBehavior.next(user);
            }

            resolve(user);
          }
        } else {
          resolve(null);
        }
      });
    });
  }

  public logoutIds4(): Promise<void> {
    return this.initialize.then(() => {
      return this.userManager.signoutRedirect();
    });
  }

  public logoutCallback(): Promise<any> {
    return this.initialize
      .then(() => this.userManager.clearStaleState())
      .then(() => this.userManager.removeUser())
      .then(() => this.userManager.signoutRedirectCallback());
  }

  public revokeAccess(): Promise<void> {
    this.consoleLogger.warning('Revoking access token', this.loggerFrom);
    return this.initialize.then(() => this.userManager.revokeAccessToken());
  }
}
