export class NavigationMenu {
  organizationName: string;
  organizationId: string;
  subscriptions: SubscriptionMenu[];
  adminPermission: boolean;
}

export class SubscriptionMenu {
  id: string;
  name: string;
  subscriptionServices: SubscriptionServiceMenu[];
  adminPermission: boolean;
}

export class SubscriptionServiceMenu {
  id: string;
  name: string;
  serviceType: string;
  projects: ProjectMenu[];
}

export class ProjectMenu {
  id: string;
  name: string;
  projectType: string;
}
