import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { trigger } from '@angular/animations';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';
import { PortalEvents } from 'src/app/_events/portal.events';

@Component({
  selector: 'app-right-panel-commander',
  templateUrl: './right-panel-commander.component.html',
  styleUrls: ['./right-panel-commander.component.scss']
})
export class RightPanelCommanderComponent implements OnChanges, OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('event-name') eventName: string;
  // tslint:disable-next-line: no-input-rename
  @Input('button-innerHtml') btnInnerHtml: string;
  // tslint:disable-next-line: no-input-rename
  @Input('button-cssClass') btnCssClass: string;
  @Input() params: any;

  constructor(private logger: ConsoleLoggerService, private eventListener: PortalEvents) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const paramChange: SimpleChange = changes.params;

    if (paramChange != null && !paramChange.firstChange) {
      this.logger.info('RoleAssignmentParams', 'QuickFormComponent', paramChange);

      this.eventListener.onRoleAssignmentsChangeParams.emit(this.params);
    }
  }

  triggerEvent() {
    this.logger.info(`Event Name: ${this.eventName}`, 'RightPanelCommander', this.params);

    this.eventListener[this.eventName].emit(this.params);
  }
}
