<div class="text-box-container">
  <div [attr.id]="refNumber" class="float-container">
    <label [attr.id]="'text' + refNumber + 1" [attr.for]="'text' + refNumber" [ngClass]="{ hintError: error}"> {{ placeholder }} </label>
    <input [type]="password ? 'password' : 'string'" [attr.id]="'text' + refNumber" name="text" [disabled]="disabled" [(ngModel)]="text" class="form-control" [ngClass]="{ error: error}" (ngModelChange)="emitTextChanged($event)">
    <div *ngIf="password"  class="preview-password off"></div>
  </div>
  <div *ngIf="error" >
    <p class="error-message">
      <span>
        {{ errorMessage }}
      </span>
    </p>
  </div>
</div>
