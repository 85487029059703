<div class="sub-item-container">
  <label class="question-label" *ngIf="subItems && subItems.length > 0 && subItemTitle">{{subItemTitle}}</label>
  <div id="{{(subItem.labelOverride || subItem.tag)}}" *ngFor="let subItem of subItems">
    <div class="enhanced-sub-card-item">
      <input class="form-check-input" [attr.id]="'chk_' + (subItem.labelOverride || subItem.tag)" (change)="selectItem(subItem, $event.target.checked)" type="checkbox" [(ngModel)]="subItem.checked"
        [hidden]="!subItem.selectable">
      <label class="form-check-label sub-item-label" [attr.for]="'chk_' + (subItem.labelOverride || subItem.tag)" [ngClass]="{selected: subItem.checked}" (click)="labelClick($event)">{{subItem.value}}</label>
    </div>
    <app-sub-item [subItems]="subItem.subItems" [selectedItems]="selectedItems" [subItemTitle]="subItem.subItemTitle" (subSelectionChanged)="subSelectionChange($event)"></app-sub-item>
  </div>
</div>
