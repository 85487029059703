import { Component, OnInit, OnDestroy, NgZone, Input, OnChanges, SimpleChanges, SimpleChange, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { LineChartWidget, LabelsAlignment } from '../../../_models/dashboard-widget';
import { ConsoleLoggerService } from '../../../../../_services/console-logger.service';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { PortalEvents } from 'src/app/_events/portal.events';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-linechart-widget',
  templateUrl: './linechart-widget.component.html',
  styleUrls: ['./linechart-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LinechartWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() config: LineChartWidget;
  @Input() hideSeriesLabels: boolean;
  @Output() chartLoaded = new EventEmitter<boolean>();
  @ViewChild('chart', { static: true }) chartElement: ElementRef;

  private readonly loggerFrom: string = 'LineChartWidget';
  private lineChart: am4charts.XYChart;
  public id: string = Math.floor(Math.random() * Math.floor(10000)).toString();

  public isLoaded: boolean;

  // Default colors
  private chartColorClasses: am4core.Color[] = [am4core.color('#0082a4'), am4core.color('#8878b2'), am4core.color('#82cad1'), am4core.color('#97be0d'), am4core.color('#e4368a')];

  constructor(private zone: NgZone, private translatorService: PortalTranslatorService, private portalEvents: PortalEvents, private logger: ConsoleLoggerService) {}

  ngOnInit() {
    // this.isLoaded = true;

    this.portalEvents.language.listen((x) => {
      if (this.lineChart) {
        if (x === 'fr') {
          this.lineChart.language.locale = am4lang_fr_FR;
        } else {
          this.lineChart.language.locale = am4lang_en_US;
        }
      }
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const seriesChange: SimpleChange = changes.config;

    if (seriesChange.currentValue) {
      if (this.config.colors && seriesChange.currentValue.colors) {
        this.chartColorClasses = seriesChange.currentValue.colors.map((c) => am4core.color(c));
      }

      if (this.config.series && this.config.series !== undefined) {
        this.zone.runOutsideAngular(() => {
          this.configChart();
        });
      }
    }
  }

  private disposeChart() {
    if (this.lineChart) {
      this.lineChart.dispose();
      this.isLoaded = false;
      this.chartLoaded.emit(false);
    }
  }

  configChart() {
    this.disposeChart();

    this.lineChart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
    this.lineChart.colors.list = this.chartColorClasses;
    // this.lineChart.numberFormatter.numberFormat = '#.';

    this.translatorService.activeLanguage.listen((x) => {
      if (x === 'fr') {
        this.lineChart.language.locale = am4lang_fr_FR;
      } else {
        this.lineChart.language.locale = am4lang_en_US;
      }
    });

    this.lineChart.events.on('appeared', () => {
      this.isLoaded = true;
      this.chartLoaded.emit(this.isLoaded);
    });

    const dateAxis = this.lineChart.xAxes.push(new am4charts.DateAxis());
    dateAxis.fontSize = '10';
    // dateAxis.baseInterval = { timeUnit: 'day', count: 1 };
    dateAxis.dateFormats.setKey('day', 'MMM dd, yyyy');
    dateAxis.dateFormats.setKey('month', 'MMM yyyy');
    // dateAxis.renderer.minGridDistance = 30;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.startLocation = 0.3;
    dateAxis.endLocation = 0.7;
    dateAxis.dataFields.date = 'date';

    const valueAxis = this.lineChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.fontSize = '10';

    if (this.config.minValue) {
      valueAxis.min = this.config.minValue;
    }

    if (this.config.maxValue) {
      valueAxis.max = this.config.maxValue;
    }

    if (this.config.strictMinMax) {
      valueAxis.strictMinMax = this.config.strictMinMax;
    }

    if (this.config.numberFormat) {
      valueAxis.numberFormatter.numberFormat = this.config.numberFormat;
    }

    if (this.hideSeriesLabels) {
      valueAxis.renderer.disabled = true;
    }

    valueAxis.renderer.labels.template.adapter.add('text', (text, target) => {
      if (!text) {
        return '';
      }

      return text.match(/\./) ? '' : text;
    });

    this.config.series.forEach((serie) => {
      // this.logger.warning('Pie Chart Serie: ', this.loggerFrom, serie);

      if (serie !== undefined) {
        const series = this.lineChart.series.push(new am4charts.LineSeries());
        series.data = serie.values;
        series.dataFields.dateX = 'date';
        series.dataFields.valueY = 'value';
        series.strokeWidth = 3;
        series.minBulletDistance = 15;
        series.name = serie.name;

        series.tooltipText = '{value}';
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = 'vertical';
        series.tooltip.label.minWidth = 20;
        series.tooltip.label.minHeight = 20;
        series.tooltip.label.fontSize = '13';
        series.tooltip.label.textAlign = 'middle';

        // Make bullets grow on hover
        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 3;
        bullet.circle.radius = 6;
        bullet.circle.fill = am4core.color('#fff');
      }
    });

    // Add chart cursor
    this.lineChart.cursor = new am4charts.XYCursor();
    this.lineChart.cursor.behavior = 'zoomX';

    this.lineChart.scrollbarX = new am4core.Scrollbar();
    this.lineChart.scrollbarX.id = 'scrollX';
    this.lineChart.scrollbarX.parent = this.lineChart.bottomAxesContainer;

    // Create vertical scrollbar and place it before the value axis
    this.lineChart.scrollbarY = new am4core.Scrollbar();
    this.lineChart.scrollbarY.id = 'scrollY';
    this.lineChart.scrollbarY.parent = this.lineChart.leftAxesContainer;
    this.lineChart.scrollbarY.toBack();

    this.lineChart.chartAndLegendContainer.id = 'Container';

    if (!this.hideSeriesLabels) {
      this.lineChart.legend = new am4charts.Legend();
      this.lineChart.legend.parent = this.lineChart.topAxesContainer;
      this.lineChart.legend.fontSize = '11';
      this.lineChart.legend.paddingBottom = 16;
      this.lineChart.legend.contentAlign = this.config.labelsAlignment === LabelsAlignment.left ? 'left' : 'right';
    }

    this.lineChart.responsive.enabled = true;
    this.lineChart.responsive.useDefault = false;

    this.lineChart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 528) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Scrollbar && target.id === 'scrollY') {
          const state = target.states.create(stateId);
          state.properties.disabled = true;

          return state;
        }

        if (target instanceof am4core.Scrollbar && target.id === 'scrollX') {
          const state = target.states.create(stateId);
          state.properties.disabled = true;

          return state;
        }

        if (target instanceof am4core.Container && target.id === 'Container') {
          const state = target.states.create(stateId);
          state.properties.paddingLeft = 0;
          state.properties.paddingRight = 0;

          return state;
        }
      },
    });
  }

  public exportChart(): void {
    if (this.lineChart.language.locale == am4lang_fr_FR) {
      this.lineChart.exporting.filePrefix = this.portalEvents.subscription.value.name + '_tendances';
    } else {
      this.lineChart.exporting.filePrefix = this.portalEvents.subscription.value.name + '_trends';
    }

    this.lineChart.exporting.export('png');
  }
}
