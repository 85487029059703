import { SubscriptionService } from './subscription-services.model';
import { BranchDto } from './branch';

export class SubscriptionDto {
  id: string;
  name: string;
  normalizedName: string;
  organizationId: string;
  cultureCode: string;
  expended: boolean;
  subjectPath: string;
  subscriptionServices: SubscriptionService[];
  branches: BranchDto[];
  cultures: SubscriptionCultureDto[];
  hasConfidentialAccess: boolean;
  state: string;
}

export class SubscriptionCultureDto {
  constructor(state: string, subscriptionId: string) {
    this.state = state;
    this.subscriptionId = subscriptionId;
  }

  id: string;
  subscriptionId: string;
  name: string;
  normalizedName: string;
  cultureCode: string;
  state: string;
}
