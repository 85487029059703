import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { UserInfo } from './userInfo';

@Component({
  selector: 'app-card-content-user',
  templateUrl: './card-content-user.component.html',
  styleUrls: ['./card-content-user.component.scss']
})
export class CardContentUserComponent implements OnInit {
  @Input() userInfo: UserInfo;
  @Output() impersonateEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(public translator: PortalTranslatorService) { }

  ngOnInit(): void {
  }

  public onImpersonateClick(): void {
    this.impersonateEvent.emit();
  }
}
