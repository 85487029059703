import { Injectable } from '@angular/core';
import { FormModel } from '../_models/form.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddressDto } from '../_models/address';

@Injectable({
  providedIn: 'root'
})
export class QuickFormService {
  private formModelBehavior: BehaviorSubject<FormModel> = new BehaviorSubject<FormModel>(null);
  public formModel: Observable<FormModel> = this.formModelBehavior.asObservable();

  private addressesBehavior: BehaviorSubject<AddressDto[]> = new BehaviorSubject<AddressDto[]>(null);
  public addresses: Observable<AddressDto[]> = this.addressesBehavior.asObservable();

  private reloadBehavior: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public reload: Observable<boolean> = this.reloadBehavior.asObservable();

  constructor() {}

  public setFormModel(formModel: FormModel) {
    this.formModelBehavior.next(formModel);
  }

  public setAddresses(addresses: AddressDto[]) {
    this.addressesBehavior.next(addresses);
  }

  public reloadData() {
    this.reloadBehavior.next(true);
  }
}
