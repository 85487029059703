import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card } from '../_models/card';
import { SubscriptionUserAssignmentClient, ExportTableType, OrganizationUserAssignmentClient } from 'src/app/portal-api.g';
import { PortalEvents } from 'src/app/_events/portal.events';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent implements OnInit {
  @Input() public cards: Card[];
  @Input() public title: string;
  @Input() public searchPlaceholder: string;
  @Input() public addLabel: string;
  @Input() public checkBoxFilterLabel: string;
  @Input() public isOrganizationLevel = false;
  @Input() public showSpinner = false;

  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() duplicateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() impersonateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() titleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() groupEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() addEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchFieldEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() checkboxFilterEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public searchText = '';
  public showExportSpinner = false;
  private checked = false;

  public translationTexts = {
    exportFileName: '',
    exportCsv: '',
    exportXlsx: '',
  };

  constructor(
    private portalEvents: PortalEvents,
    private translator: PortalTranslatorService,
    private subscriptionUserAssignmentClient: SubscriptionUserAssignmentClient,
    private organizationUserAssignmentClient: OrganizationUserAssignmentClient
  ) {}

  ngOnInit(): void {
    this.loadtranslations();
  }

  public addClick() {
    this.addEvent.emit();
  }

  public toggleCheckBox() {
    this.checked = !this.checked;
    this.checkboxFilterEvent.emit(this.checked);
  }

  public searchFieldChange(event: any) {
    this.searchText = event;
    this.searchFieldEvent.emit(event);
  }

  private loadtranslations(): Promise<any> {
    return Promise.all([
      this.translator.translate('users').then((translation) => {
        this.translationTexts.exportFileName = translation;
      }),
      this.translator.translate('export_csv').then((translation) => {
        this.translationTexts.exportCsv = translation;
      }),
      this.translator.translate('export_xlsx').then((translation) => {
        this.translationTexts.exportXlsx = translation;
      }),
    ]);
  }

  private export(exportExcel: boolean) {
    let exportType: ExportTableType = ExportTableType.Csv;
    let fileExtension = 'csv';

    if (exportExcel) {
      exportType = ExportTableType.Xlsx;
      fileExtension = 'xlsx';
    }

    this.showExportSpinner = true;

    if (this.isOrganizationLevel) {
      this.organizationUserAssignmentClient.export(this.portalEvents.organization.value.id, this.checked, exportType, undefined, undefined, this.searchText).subscribe(
        (payload) => {
          const blob = new Blob([payload.data], { type: `application/${fileExtension}` });
          this.processExport(blob, fileExtension);
        },
        (error) => {
          this.showExportSpinner = false;
        }
      );
    } else {
      this.subscriptionUserAssignmentClient
        .export(this.portalEvents.organization.value.id, this.portalEvents.subscription.value.id, this.checked, exportType, undefined, undefined, this.searchText)
        .subscribe(
          (payload) => {
            const blob = new Blob([payload.data], { type: `application/${fileExtension}` });
            this.processExport(blob, fileExtension);
          },
          (error) => {
            this.showExportSpinner = false;
          }
        );
    }
  }

  processExport(blob: Blob, fileExtension: string) {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = data;

    link.download = `${this.translationTexts.exportFileName}.${fileExtension}`;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

    this.showExportSpinner = false;
  }

  public exportXlsxClick() {
    this.export(true);
  }

  public exportCsvClick() {
    this.export(false);
  }

  public onDeleteClick(event: any): void {
    this.deleteEvent.emit(event);
  }

  public onEditClick(event: any): void {
    this.editEvent.emit(event);
  }

  public onDuplicateClick(event: any): void {
    this.duplicateEvent.emit(event);
  }

  public onImpersonateClick(event: any): void {
    this.impersonateEvent.emit(event);
  }

  public onTitleClick(event: any): void {
    this.titleEvent.emit(event);
  }

  public onClickGroup(id: string): void {
    this.groupEvent.emit(id);
  }
}
