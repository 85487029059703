import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, RouterModule, UrlTree } from '@angular/router';
import { NotificationService } from '../_services/notification.service';
import { AuthIds4Service } from '../_services/authids4.service';
import { User } from 'oidc-client';
import { UserDto } from 'src/app/_models/user';
import { Observable, of, from } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { ConsoleLoggerService } from '../_services/console-logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private consoleLogger: ConsoleLoggerService, private authIds4Service: AuthIds4Service, private router: Router, private notification: NotificationService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean | UrlTree {
    localStorage.setItem('redirectUrl', state.url);

    return this.authIds4Service
      .getLoggedUserFromCache()
      .then((user) => {
        if (user != null) {
          return true;
        } else {
          return this.authIds4Service
            .getLoggedUserInfo()
            .then((obj) => {
              if (obj) {
                return true;
              } else {
                return this.authFailed(state);
              }
            })
            .catch(() => {
              return this.authFailed(state);
            });
        }
      })
      .catch(() => {
        return this.authFailed(state);
      });
  }

  private authFailed(route: RouterStateSnapshot): UrlTree {
    this.consoleLogger.warning('Login required', 'AuthGuard', route);
    localStorage.setItem('redirectUrl', route.url);

    return this.router.createUrlTree(['/login']);
  }
}
