import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnChanges {
  @Input() switchState = true;
  @Input() title: string;
  @Input() secondaryTitle: string;
  @Input() identifier: string;
  @Input() tag: any;
  @Input() data: any;

  @Input() displayToggleSwtich = false;
  @Input() displayEditBtn = true;
  @Input() displayDuplicateBtn = true;
  @Input() displayDeleteBtn = true;

  @Output() switchStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() duplicateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() impersonateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() titleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionEvent: EventEmitter<string> = new EventEmitter<string>();

  public dataTypeName = '';

  constructor(public translator: PortalTranslatorService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // Optimization to avoid recomputing the type every time the change tracking runs.
      this.dataTypeName = this.dataType();
    }
  }

  private dataType(): string {
    return this.data.constructor.name;
  }

  public onDeleteClick(): void {
    this.deleteEvent.emit(this.tag);
  }

  public onEditClick(): void {
    this.editEvent.emit(this.tag);
  }

  public onDuplicateClick(): void {
    this.duplicateEvent.emit(this.tag);
  }

  public onImpersonateClick(): void {
    this.impersonateEvent.emit(this.tag);
  }

  public onTitleClick(): void {
    this.titleEvent.emit(this.tag);
  }

  public onActionClick(data: string): void {
    this.actionEvent.emit(data);
  }

  public onActiveChange(state: boolean): void {
    this.switchStateChange.emit(state);
  }
}
