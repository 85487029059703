import {
  Component,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  Input,
  Type,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  SimpleChange
} from '@angular/core';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';
import { OverlayBaseComponent } from './overlay-base-component';
import { PortalEvents } from 'src/app/_events/portal.events';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnChanges {
  private readonly loggerFrom = 'OverlayComponent';

  @Input() componentToLoad: Type<OverlayBaseComponent>;
  @Input() showOverlay: boolean;

  @Output() componentInstance: EventEmitter<OverlayBaseComponent> = new EventEmitter<OverlayBaseComponent>();
  @Output() overlayClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() overlayClosing: EventEmitter<void> = new EventEmitter<void>();

  // Necessary to load the right panel component dynamically
  @ViewChild('component', { read: ViewContainerRef }) componentBody: ViewContainerRef;

  public topOffset: number;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private logger: ConsoleLoggerService, private changeDetector: ChangeDetectorRef, private portalEvents: PortalEvents) {
    this.showOverlay = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const showOverlayChange: SimpleChange = changes.showOverlay;
    const componentToLoadChange: SimpleChange = changes.componentToLoad;

    if (showOverlayChange && showOverlayChange.currentValue) {
      this.showOverlay = showOverlayChange.currentValue;
    }

    if (componentToLoadChange && componentToLoadChange.currentValue) {
      this.loadComponent();
    }
  }

  loadComponent() {
    this.toggleDisableTouch();

    let componentFactory = null;

    componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentToLoad);

    const componentRef = this.componentBody.createComponent<OverlayBaseComponent>(componentFactory);

    this.showOverlay = true;

    componentRef.instance.afterViewInit().subscribe(() => {
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      }
      this.onResize();

      this.componentInstance.emit(componentRef.instance);
    });

    componentRef.instance.closeComponent().subscribe(instance => {
      this.toggleDisableTouch();
      this.overlayClosing.emit(instance);
      this.componentToLoad = null;
      componentRef.destroy();
      this.showOverlay = false;
      this.overlayClosed.emit();
    });
  }

  toggleDisableTouch() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('disabletouchscroll');
  }

  onResize() {
    const bodyElement = document.getElementById('overlay-body');
    const pos = window.scrollY;

    this.topOffset = (window.innerHeight - bodyElement.clientHeight / 2) / 2;
    this.topOffset = this.topOffset + pos;

    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }
}
