import { NgModule } from "@angular/core";
import { CopyClipboardDirective } from "./CopyClipboard.directive";

@NgModule({
  imports: [],
  exports: [CopyClipboardDirective],
  declarations: [CopyClipboardDirective],
  providers: []
})
export class CopyClipboardModule {}
