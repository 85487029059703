import { ReportSetting, ExtendedReportSetting } from '../modules/voc/_models/report-setting';

export enum LogLevel {
  none = 0,
  info = 1,
  warning = 2,
  verbose = 3
}

export interface Config {
  readonly Environment: string; // Need to be capitalized for deployment purposes
  readonly rootDomain: string;
  readonly portal: PortalConfig;
  readonly ids4: Ids4Config;
  readonly api: ApiConfig;
  readonly signup: SignupConfig;
  readonly facebookApp: FacebookAppConfig;
}

export interface SignupConfig {
  readonly baseUrl: string;
}

export interface PortalConfig {
  readonly baseUrl: string;
  readonly v2Url: string;
  readonly defaultLang: string;
  readonly iosLink: string;
  readonly androidLink: string;
  readonly logging: LoggingConfig;
  readonly roles: RolesConfig;
  readonly voc: VocConfig;
}

export interface FacebookAppConfig {
  readonly appId: string;
  readonly appSecret: string;
}

export interface LoggingConfig {
  readonly level: LogLevel;
}

export interface VocConfig {
  readonly customReports: ExtendedReportSetting[];
}

export interface RolesConfig {
  readonly developer: string;
  readonly owner: string;
  readonly contributor: string;
  readonly reader: string;
  readonly access: string;
}

export interface Ids4Config {
  readonly authorityUrl: string;
  readonly clientId: string;
  readonly clientSecret: string;
  readonly scope: string;
  readonly blacklistedRoutes: string;
  readonly whitelistedDomains: string;
}

export interface ApiConfig {
  readonly baseUrl: string;
  readonly modules: ModulesConfig;
  readonly exceptionToken: ExceptionTokenConfig;
  readonly customExport: CustomExportConfig;
}

export interface ModulesConfig {
  readonly core: CoreModuleConfig;
  readonly voc: VocModuleConfig;
  readonly mysteryShopperLegacy: MysteryShopperLegacyModuleConfig;
  readonly stm: StmModuleConfig;
  readonly socialListening: SocialListeningModuleConfig;
  readonly contactTracing: ContactTracingModuleConfig;
}

export interface ExceptionTokenConfig {
  readonly core: CoreModuleExceptionsConfig;
}

export interface ModuleConfigBase {
  readonly path: string;
}

export interface CustomExportConfig {
  readonly baseUrl: string;
  readonly executeConfigEndPoint: string;
}

export interface ContactTracingBaseUrl {
  readonly fr: string;
  readonly en: string;
}

export interface CoreModuleConfig extends ModuleConfigBase { }

export interface VocModuleConfig extends ModuleConfigBase { }

export interface StmModuleConfig extends ModuleConfigBase { }

export interface MysteryShopperLegacyModuleConfig extends ModuleConfigBase { }

export interface ContactTracingModuleConfig extends ModuleConfigBase {
  readonly termsUrlEn: string;
  readonly baseUrl: ContactTracingBaseUrl;
  readonly termsUrlFr: string;
}

export interface SocialListeningModuleConfig extends ModuleConfigBase { }

export interface CoreModuleExceptionsConfig {
  readonly paths: string[];
}
