<div class="subject-tree" id="accordion">
  <app-loading-spinner [show-spinner]=""></app-loading-spinner>
  <ng-template [ngIf]="this.organizations.length > 0">
    <div class="card" *ngFor="let organization of this.organizations; let i = index;">
      <div class="card-header keep-popover" [ngClass]="{active: organization === activeOrganization, collapsed: organization !== activeOrganization}" [attr.id]="'heading' + organization.id" data-toggle="collapse"
        [attr.data-target]="'#collapse' + organization.id" [attr.aria-expanded]="organization==activeOrganization" [attr.aria-controls]="'collapse' + organization.id"
        (click)="cancelPropagation($event)">
        <h2 class="mb-0">
          {{ organization.name }}
        </h2>
        <i class="fas fa-chevron-down"></i>
      </div>
      <div [attr.id]="'collapse' + organization.id" [ngClass]="{show: organization == activeOrganization, collapse: organization !== activeOrganization }"
        [attr.aria-labelledby]="'heading' + organization.id">
        <div class="card-body">
          <ul>
            <li *ngFor="let item of organization.subscriptions; let i = index;" [ngClass]="{active: item==activeSubscription}" (click)="selectSubscription(organization, item)">
              <div class="bullet" [hidden]="item!=activeSubscription"></div>
              {{ this.getName(item.name) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
</div>
