<div *ngIf="title" class="title d-flex">
  <h1 *ngIf="size == 1">{{ translator.translate(title) | async }}</h1>
  <h2 *ngIf="size == 2">{{ translator.translate(title) | async }}</h2>
  <h5 *ngIf="size == 5">{{ translator.translate(title) | async }}</h5>

  <div *ngIf="hasHelp" class="help" [ngClass]="{ removePaddingTop: size == 5 }">
    <img class="img" (click)="toggleHelpPopover($event)" src="../assets/images/icons/the-help-file-logo-2.svg" />
    <div class="popover help-popover" [hidden]="!showHelp" [ngClass]="{ addMarginSmallSize: size == 5 }">
      <div class="p-arrow" [hidden]="!showHelp"></div>
      <div class="help-body" [innerHTML]="translator.translate(helpHTML) | async"></div>
    </div>
  </div>
</div>
