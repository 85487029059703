import { Component, OnInit } from '@angular/core';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { AuthIds4Service } from '../_services/authids4.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalEvents } from '../_events/portal.events';
import { SignUpClient } from '../portal-api.g';
import { ConfigService } from '../_services/config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  title = 'Portal';
  readonly loggerFrom: string = 'HomeComponent';
  showSpinner: boolean;

  constructor(
    private authIds4Service: AuthIds4Service,
    private logger: ConsoleLoggerService,
    protected router: Router,
    protected route: ActivatedRoute,
    private portalEvents: PortalEvents,
    private signupClient: SignUpClient,
    private configService: ConfigService
  ) {
    this.showSpinner = true;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((data) => {
      this.authIds4Service.getLoggedUserInfo().then((u) => {
        if (!u || u.expired || data['forceLogin']) {
          this.authIds4Service
            .signInSilent()
            .then(() => {
              const url = this.route.snapshot.url;
              if (url.length > 0 && url[0].path === 'login') {
                const redirectUrl = localStorage.getItem('redirectUrl');
                if (redirectUrl) {
                  this.logger.info('RedirectUrl', 'HomeComponent', redirectUrl);
                  localStorage.removeItem('redirectUrl');
                  this.router.navigateByUrl(redirectUrl);
                }
              }

              this.showSpinner = false;
            })
            .catch(() => {
              this.authIds4Service.loginIds4();
            });
        }
      });
    });

    this.portalEvents.subscriptionServiceId.value = null;
  }
}
