import { UserInformation } from './../_models/user-information';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/_services/config.service';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';
import { Observable } from 'rxjs';
import { ApiServiceBase } from 'src/app/_services/api-service-base';
import { environment } from 'src/environments/environment';
import { UrlService } from 'src/app/_services/url.service';
import { Claim } from '../_models/claims.model';
import { UserDto } from 'src/app/_models/user';
import { ApplicationUser } from '../_models/application-user';
import { AuthIds4Service } from 'src/app/_services/authids4.service';
import { TimeZoneMap } from '../_models/time-zone';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService extends ApiServiceBase {
  constructor(
    protected urlService: UrlService,
    protected http: HttpClient,
    protected configService: ConfigService,
    protected consoleLogger: ConsoleLoggerService,
    protected authIds4: AuthIds4Service) {
    super(urlService, configService, consoleLogger, configService.config.api.modules.core.path);
  }

  getUserInfo(userId: string): Observable<UserDto> {
    const url = this.getUrl(environment.userProfile, 'info', userId);
    return this.http.get<UserDto>(url);
  }

  getApplicationUser(userId: string): Promise<ApplicationUser> {
    const url = this.getUrl(environment.userProfile, environment.applicationUserEndPoint, userId);
    return this.http.get<ApplicationUser>(url).toPromise();
  }

  updateClaims(claims: Claim[]): any {
    const url = this.getUrl(environment.userProfile, 'claims');
    this.http.post(url, claims).toPromise().then(result => {
      this.authIds4.signInSilent().then(user => {
        return result;
      });
    });
  }

  updatePhoneNumber(userInformation: UserInformation): Promise<any> {
    const url = this.getUrl(environment.userProfile, environment.updatePhoneNumberEndPoint);
    return this.http.post(url, userInformation).toPromise();
  }

  deletePhoneNumber(userId: string): Promise<any> {
    const url = this.getUrl(environment.userProfile, environment.deletePhoneNumberEndPoint, userId);
    return this.http.delete(url).toPromise();
  }

  verifyPhoneNumber(userInformation: UserInformation): Promise<any> {
    const url = this.getUrl(environment.userProfile, environment.verifyPhoneNumberEndPoint);
    return this.http.post(url, userInformation).toPromise();
  }

  updateEmail(userInformation: UserInformation): Promise<any> {
    const url = this.getUrl(environment.userProfile, environment.updateEmailEndPoint);
    return this.http.post(url, userInformation).toPromise();
  }

  checkPassword(userInformation: UserInformation): Promise<any> {
    const url = this.getUrl(environment.userProfile, environment.checkPasswordEndPoint);
    return this.http.post(url, userInformation).toPromise();
  }

  confirmEmail(userInformation: UserInformation): Promise<any> {
    const url = this.getUrl(environment.userProfile, environment.confirmEmailEndPoint);
    return this.http.post(url, userInformation).toPromise();
  }

  updatePassword(currentPassword: string, confirmPassword: string, newPassword: string): Promise<any> {
    const accountPassword = {
      currentPassword,
      newPassword,
      confirmPassword
    };

    const url = this.getUrl(environment.userProfile, environment.updatePasswordEndPoint);
    return this.http.post(url, accountPassword).toPromise();
  }

  getTimeZones(culture: string) {
    const url = this.getUrl(environment.userProfile, environment.timeZoneEndPoint, culture);
    return this.http.get<TimeZoneMap>(url).toPromise();
  }
}
