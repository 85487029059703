import { clone } from '../../../../../_helpers/clone';
import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { LineChartWidget } from 'src/app/portal-api.g';
import { HourlyBreakdownWidget } from '../../../_models/dashboard-widget';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-survey-hourly-breakdown-widget',
  templateUrl: './survey-hourly-breakdown-widget.component.html',
  styleUrls: ['./survey-hourly-breakdown-widget.component.scss'],
})
export class SurveyHourlyBreakdownWidgetComponent implements OnInit, OnChanges {
  @Input() config: HourlyBreakdownWidget;

  constructor(public translator: PortalTranslatorService) {}

  public widgetData: any = new LineChartWidget();
  private allSeries: any[];

  ngOnChanges(changes: SimpleChanges): void {
    const configChange: SimpleChange = changes.config;

    if (configChange && configChange.currentValue) {
      const hourlyBreakdown: HourlyBreakdownWidget = configChange.currentValue;

      this.allSeries = hourlyBreakdown.series;
      this.filterSeries(1);
      this.widgetData.groupType = 1;
    }
  }

  ngOnInit() {}

  public filterSeries(option: number) {
    if (option === 1) {
      this.widgetData.series = this.allSeries.slice(0, 2);
    } else {
      this.widgetData.series = this.allSeries.slice(2, 4);
    }
  }

  public changeGroupType(option: number) {
    this.widgetData.groupType = option;
    this.filterSeries(option);
    this.widgetData = clone(this.widgetData);
  }
}
