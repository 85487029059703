import { QuestionLanguageMetadata } from './form-version-metadata';
import { FilterSet } from './results';
import { AlertListItem, AlertListTab } from './alert-list-models';

export class SurveyAnswerDto {
  questionLanguageMetadata: QuestionLanguageMetadata[];
  questionName: string;
  answer: QuestionLanguageMetadata[];

  constructor() {
    this.questionLanguageMetadata = [];
  }
}

export class AlertDto {
  id: string;
  alertType: AlertTypeEnum;
  alertStatus: AlertStatusType;
  title: string;
  surveyAnswerId: string;
  description: string;
  statusHistories: AlertStatusHistoryModel[];
  triggeredItemsJson: string;
  surveyAnswer: SurveyAnswerDto;

  constructor() {
    this.surveyAnswer = new SurveyAnswerDto();
  }
}

export enum AlertTypeEnum {
  ToBeRead = 0,
  ToBeSolved = 1
}

export enum AlertStatusType {
  Unopened = 0,
  Opened = 1,
  Resolved = 2,
  OnHold = 3,
  Cancelled = 4,
  Forwarded = 5,
  Read = 6,
  Closed = 7
}

export enum HistoryOptions {
  None = 0,
  NoAnswer,
  Apology,
  Appointment,
  GiftCard,
  Headquarter,
  Machine,
  Number,
  Shop,
  EmailSent,
  Other
}

export class AlertStatusHistoryModel {
  id: string;
  createdDate: string;
  alertStatusType: AlertStatusType;
  reason: string;
  comment: string;
  historyOption: HistoryOptions;
  authorName: string;
  authorUserId: string;
}

export class AlertClientVisitModel {
  date: string;
  timeOfVisit: string;
  pointOfSale: string;
  alertIssued: string;
  alertIssuedFor: string;
  surveyGlobalScore: string;
}

export class AlertClientInformationLabel {
  clientNumber: string;
  gender: QuestionLanguageMetadata[];
  firstName: string;
  lastName: string;
  ageGroup: QuestionLanguageMetadata[];
  email: string;
  phone: string;
}

export class AlertPage {
  filterSet: FilterSet;
  alertCount: AlertCount;
  alertList: AlertListItem[];
}

export interface AlertCount {
  read: number;
  toRead: number;
  solved: number;
  toSolve: number;
  onHold: number;
  opened: number;
}

export class AlertDetailModel {
  clientVisit: AlertClientVisitModel;
  alert: AlertDto;
  clientInformation: AlertClientInformationLabel;

  constructor() {
    this.alert = new AlertDto();
    this.clientVisit = new AlertClientVisitModel();
    this.clientInformation = new AlertClientInformationLabel();
  }
}
