import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent implements OnInit {
  @Input() openModal: boolean;
  @Input() image: string;
  @Output() closingModal = new EventEmitter<boolean>();
  @Output() croppedImageSrc = new EventEmitter<string>();

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};
  cropImage = new Image();

  public openUnsavedModal = false;
  /*
    Image cropper code taken from links below
    https://www.npmjs.com/package/ngx-image-cropper
    https://stackblitz.com/edit/image-cropper?file=app%2Fapp.component.html
  */

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const openModal: SimpleChange = changes.openModal;

    if (openModal) {
      // Get the modal
      var modal = document.getElementById("modal");

      if (openModal.currentValue) {
        modal.style.display = "block";

        this.cropImage.src = this.image;
        this.canvasRotation = 0;
        // When the user clicks anywhere outside of the modal, close it
        window.addEventListener("click", (event) => {
          if (event.target == modal) {
            this.openUnsavedModal = true;
          }
        });
      } else {
        modal.style.display = "none";
      }
    }
  }

  save() {
    var modal = document.getElementById("modal");
    modal.style.display = "none";
    this.croppedImageSrc.emit(this.croppedImage);
    this.closingModal.emit(true);
    this.openUnsavedModal = false;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  isLeaveConfirmed(leave: boolean) {
    this.openUnsavedModal = false;

    if (leave) {
      var modal = document.getElementById("modal");
      modal.style.display = "none";
      this.closingModal.emit(false);
    }
  }
}
