<div id="modal" class="modal">
  <div class="modal-container">
    <div class="control-container">
      <button (click)="rotateLeft()"><img src="/assets/images/icons/rotate-left.svg" /></button>
      <button (click)="rotateRight()"> <img src="/assets/images/icons/rotate-right.svg" /></button>
      <button (click)="save()"><img src="/assets/images/icons/save.svg" /></button>
    </div>
    <div class="modal-content">
      <div>
        <image-cropper
          [imageBase64]="this.cropImage.src"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
        ></image-cropper>
      </div>
    </div>
  </div>
</div>

<app-unsaved-modal
  [openUnsavedModal]="this.openUnsavedModal"
  (isLeaveConfirmed)="isLeaveConfirmed($event)"
></app-unsaved-modal>
