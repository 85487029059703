<div class="hexa-card-container">
  <div class="hexa-card-header" [class.disabled]="!switchState">
    <div class="header-identity">
      <div class="card-circle">
        <div *ngIf="identifier" class="user-identifier">{{ identifier }}</div>
      </div>

      <div class="card-identity">
        <div *ngIf="title" class="title" (click)="onTitleClick()">{{ title }}</div>

        <div *ngIf="secondaryTitle" (click)="onTitleClick()" class="secondary-title">{{ secondaryTitle }}</div>
      </div>
    </div>

    <div class="header-commands" *ngIf="displayToggleSwtich || displayEditBtn || displayDuplicateBtn || displayDeleteBtn">
      <app-input-switch *ngIf="displayToggleSwtich" [labelOn]="this.translator.translate('switch_activated') | async" [labelOff]="this.translator.translate('switch_deactivated') | async" [(active)]="switchState" (activeChange)="onActiveChange($event)"></app-input-switch>

      <div class="command-container edit-command" *ngIf="displayEditBtn" (click)="onEditClick()"></div>

      <div class="command-container duplicate-command" *ngIf="displayDuplicateBtn" (click)="onDuplicateClick()">
      </div>

      <div class="command-container delete-command" *ngIf="displayDeleteBtn" (click)="onDeleteClick()"></div>
    </div>
  </div>

  <div class="hexa-card-content">
    <app-card-content-user *ngIf="dataTypeName === 'UserInfo'" [userInfo]="data" (impersonateEvent)="onActionClick($event)"></app-card-content-user>
    <app-card-content-pos *ngIf="dataTypeName === 'PosInfo'" [posInfo]="data" (groupEvent)="onActionClick($event)"></app-card-content-pos>
  </div>
</div>