import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { getLocaleNumberFormat, formatNumber } from '@angular/common';

@Component({
  selector: 'app-alert-number',
  templateUrl: './alert-number.component.html',
  styleUrls: ['./alert-number.component.scss']
})
export class AlertNumberComponent implements OnInit {
  @Input() alertNumber: number;
  isGreaterThan1000: boolean;

  public displayNumber: string;
  constructor(public translator: PortalTranslatorService) {
    this.displayNumber = '';
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const newNumber = changes.alertNumber.currentValue;
    if (newNumber >= 1000) {
      this.alertNumber = Math.round(newNumber / 100) / 10;
      this.isGreaterThan1000 = true;
    } else {
      this.isGreaterThan1000 = false;
    }

    this.displayNumber = formatNumber(this.alertNumber, this.translator.activeLanguage.value);
  }
}
