<div class="competitor" *ngFor="let item of config.competitors;let i = index;">
  <div class="header">
    <div class="first">
      <div class="title">{{item.name}}</div>
      <div class="additional-info" *ngIf="item.additionalInfo">{{item.additionalInfo}} {{translator.translate('surveys') | async}}</div>
    </div>
    <div class="second">
      <div class="color" [ngClass]="{green: getMax(item.values).index == 2, yellow: getMax(item.values).index == 1, red: getMax(item.values).index == 0}"></div>
      <div class="value">{{getMax(item.values).value}}<sup>%</sup></div>
    </div>
  </div>
  <div class="chart" [attr.id]="'chart-' + i"></div>
</div>
<div class="competitor">
  <div class="legend">
    <div class="bullet green"></div>{{ translator.translate('competitor_legend_green') | async}}&nbsp;{{this.config.clientName}}
  </div>
  <div class="legend">
    <div class="bullet yellow"></div>{{ translator.translate('competitor_legend_yellow') | async}}&nbsp;{{this.config.clientName}}
  </div>
  <div class="legend">
    <div class="bullet red"></div>{{ translator.translate('competitor_legend_red') | async}}&nbsp;{{this.config.clientName}}
  </div>
</div>