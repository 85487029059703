<div *ngIf="formModel != undefined">
  <div class="card">
    <div [ngClass]="formModel.formName == null ? 'collapse' : ''" class="card-header">
      {{ formModel.formName }}
      <div class="float-right">
        <app-right-panel-commander
          event-name="onRoleAssignmentsRequested"
          button-innerHtml="Add Role Assingnments"
          button-cssClass="btn-primary"
          [params]="roleAssignmentParams"
          *ngIf="!this.formModel.isAdd"
        >
        </app-right-panel-commander>
      </div>
    </div>
    <div class="card-body" *ngIf="formModel.jsonSchema != undefined">
      <json-schema-form loadExternalAssets="true" [schema]="formModel.jsonSchema" [(data)]="formData" framework="bootstrap-4" (onSubmit)="onSubmit($event)"> </json-schema-form>
      <button type="button" class="btn btn-danger" (click)="onDeleteClicked()">Delete</button>
    </div>
  </div>
  <div *ngIf="formModel.objectType == objectType.Subscription && false" class="card mt-3">
    <div class="card-header">
      Branches
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-12">
          <div class="card">
            <div class="card-body limit-height">
              <div class="services pointer" (click)="branchClick($event, null, true)"><i class="fas fa-plus-circle"></i>&nbsp;<span>Branch</span></div>
              <div *ngFor="let branch of this.formModel.data.branches" (click)="branchClick($event, branch)" class="services">
                <span class="pointer">{{ branch.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="branchJsonSchema">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              {{ branchTitle }}
            </div>
            <div class="card-body">
              <json-schema-form loadExternalAssets="true" [schema]="branchJsonSchema" [(data)]="branchData" framework="bootstrap-4" (onSubmit)="onBranchSubmit($event)">
              </json-schema-form>
              <button type="button" class="btn btn-danger" (click)="onBranchDeleteClicked()">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="formModel.objectType == objectType.Organization || formModel.objectType == objectType.Subscription" class="card mt-3 mb-3">
    <div class="card-header">
      Logos by culture
    </div>
    <div class="card-body">
      <div class="row" *ngIf="hasLogo">
        <div class="col-12">
          Current default logo:
          <img [src]="" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ul class="list-group mb-4" *ngIf="entityLogos?.length > 0">
            <li class="list-group-item" *ngFor="let item of entityLogos; let i = index">
              <div class="link">
                <a href="javascript:void(0)" (click)="editCulture(item)">{{ item.isDefault ? 'Default (' + item.cultureCode + ')' : item.cultureCode }}</a>
                <a href="{{ item.logo }}" class="ml-3" target="_blank">View logo</a>
              </div>
              <div class="float-right">
                <div class="buttons" *ngIf="!action || actionItemId != item.cultureCode">
                  <button class="btn btn-danger" (click)="confirmActionPrompt('D', item.cultureCode)">Remove</button>
                </div>
                <div class="confimation" *ngIf="action && actionItemId == item.cultureCode">
                  <h5>Confirm {{ actionDescription }}?</h5>
                  <button class="btn btn-primary mr-3" (click)="confirmAction()">YES</button>
                  <button class="btn btn-primary-outline" (click)="resetAction()">NO</button>
                </div>
              </div>
            </li>
          </ul>
          <h5 *ngIf="!entityLogos || entityLogos.length == 0">No logos defined</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button class="btn-primary btn" (click)="addNewCulture()">Add/Update logo</button>
        </div>
      </div>
      <div class="row" *ngIf="showCultureForm">
        <div class="col-12 p-3">
          <div class="form-group">
            <app-dropdown-input
              class="field"
              [items]="cultureOptions"
              [selectedItem]="cultureOptionSelected"
              (selectionChanged)="cultureOptionSelectionChanged($event)"
              [showBullet]="false"
            >
            </app-dropdown-input>
          </div>
          <div class="form-group box mt-3">
            <app-file-uploader (fileDropped)="uploadFile($event)"></app-file-uploader>
            <img width="250" height="auto" src="{{ imageSource }}" />
          </div>
          <div class="form-row mt-3">
            <button class="btn btn-primary mr-3" (click)="saveForm()">Save</button>
            <button class="btn btn-danger" (click)="clearCulturesForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
