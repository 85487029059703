import { Component, OnInit, Input, NgZone, SimpleChanges, SimpleChange, OnDestroy, OnChanges, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { DistributionGaugeWidget } from '../../../_models/dashboard-widget';
import { ConsoleLoggerService } from '../../../../../_services/console-logger.service';
import { Template } from '../../../../voc/_models/template';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import am4Fr from '@amcharts/amcharts4/lang/fr_FR';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-distribution-gauge-widget',
  templateUrl: './distribution-gauge-widget.component.html',
  styleUrls: ['./distribution-gauge-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DistributionGaugeWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() config: DistributionGaugeWidget;
  @ViewChild('chart', { static: true }) chartElement: ElementRef;

  private readonly loggerFrom: string = 'DistributionGaugeChart';
  private chart: am4charts.PieChart;
  public id: string = Math.floor(Math.random() * Math.floor(10000)).toString();

  // Default colors
  private chartColorClasses: am4core.Color[] = [am4core.color('#0082a4'), am4core.color('#8878b2'), am4core.color('#82cad1'), am4core.color('#97be0d'), am4core.color('#e4368a')];

  constructor(private zone: NgZone, private logger: ConsoleLoggerService, public translator: PortalTranslatorService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const configChange: SimpleChange = changes.config;

    if (configChange.currentValue) {
      if (this.config.colors && configChange.currentValue.colors) {
        this.chartColorClasses = configChange.currentValue.colors.map((c) => am4core.color(c));
      }

      this.zone.runOutsideAngular(() => {
        this.configChart();
      });
    }
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  hideSmall(hidden, target) {
    return target.dataItem.values.value.percent <= 0 ? true : false;
  }

  configChart() {
    this.disposeChart();
    this.chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);

    if (this.translator.activeLanguage.value === 'fr') {
      this.chart.language.locale = am4Fr;
    }

    this.chart.width = am4core.percent(100);

    const topContainer = this.chart.createChild(am4core.Container);
    topContainer.toBack();
    topContainer.padding(15, 15, 15, 15);
    topContainer.width = am4core.percent(100);

    const chartTitle = topContainer.createChild(am4core.Label);
    chartTitle.text = this.config.location;
    chartTitle.fontWeight = 'bold';
    chartTitle.fontFamily = 'PoppinsSemiBold';
    chartTitle.fontSize = 18;
    chartTitle.verticalCenter = 'bottom';
    chartTitle.paddingLeft = 10;

    const chartSubtitle = topContainer.createChild(am4core.Label);
    this.translator.translate('surveys').then((result) => {
      chartSubtitle.text = this.config.surveyCount + ' ' + result;
    });
    chartSubtitle.fontFamily = 'Nunito';
    chartSubtitle.fontSize = 13;
    chartSubtitle.verticalCenter = 'top';
    chartSubtitle.paddingLeft = 10;
    chartSubtitle.marginTop = 20;

    if (this.config.location == null) {
      topContainer.disabled = true;
    }

    const translations: Promise<string>[] = [];
    this.config.series.forEach((dataPoint) => {
      translations.push(this.translator.translate(dataPoint.key).then((result) => (dataPoint.key = result)));
    });

    Promise.all(translations).then(() => (this.chart.data = this.config.series));

    this.chart.radius = am4core.percent(70);
    this.chart.innerRadius = am4core.percent(50);
    this.chart.startAngle = 180;
    this.chart.endAngle = 360;

    this.chart.chartAndLegendContainer.id = 'ChartsAndLegends';

    const series = this.chart.series.push(new am4charts.PieSeries());

    series.dataFields.value = 'value';
    series.dataFields.category = 'key';

    series.labelsContainer.id = 'LabelsContainer';
    series.ticksContainer.id = 'TicksContainer';

    series.slices.template.inert = true;
    series.slices.template.tooltipText = '{value}%';
    series.slices.template.states.getKey('active').properties.shiftRadius = 0;
    series.colors.list = this.chartColorClasses;

    series.labels.template.padding(0, 0, 0, 0);
    series.labels.template.text = '{category}';
    series.labels.template.fontFamily = 'Nunito';
    series.labels.template.fontSize = 14;
    series.labels.template.wrap = true;
    series.labels.template.maxWidth = 90;
    series.labels.template.margin(8, 8, 8, 8);

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.paddingBottom = 0;

    // Hiding the empty slices
    series.ticks.template.adapter.add('hidden', this.hideSmall);
    series.labels.template.adapter.add('hidden', this.hideSmall);

    const midValueContainer = series.createChild(am4core.Container);
    midValueContainer.layout = 'horizontal';
    midValueContainer.width = am4core.percent(100);
    midValueContainer.contentValign = 'middle';
    // midValueContainer.contentAlign = 'center';
    midValueContainer.verticalCenter = 'middle';
    midValueContainer.horizontalCenter = 'middle';
    midValueContainer.paddingBottom = 48;

    const midValue = midValueContainer.createChild(am4core.Label);
    midValue.id = 'midValue';
    midValue.text = this.config.middleValue;
    midValue.verticalCenter = 'bottom';
    midValue.horizontalCenter = 'middle';
    midValue.fontSize = 80;
    midValue.fontFamily = 'PoppinsSemiBold';
    // midValue.fontWeight = 'bold';

    if (this.config.middleValueUnit !== undefined) {
      const midValueUnit = midValueContainer.createChild(am4core.Label);
      midValueUnit.id = 'midValueUnit';
      midValueUnit.text = this.config.middleValueUnit;
      midValueUnit.fontSize = 20;
      midValueUnit.paddingTop = 16;
      midValueUnit.fontFamily = 'Poppins';
    }

    const varianceContainer = series.createChild(am4core.Container);
    varianceContainer.parent = this.chart.chartAndLegendContainer;
    varianceContainer.layout = 'horizontal';
    varianceContainer.width = am4core.percent(100);
    varianceContainer.contentValign = 'top';
    varianceContainer.contentAlign = 'center';
    varianceContainer.marginTop = 8;

    const varianceValue = varianceContainer.createChild(am4core.Label);
    if (this.config.variance) {
      varianceValue.id = 'varianceValue';
      varianceValue.text = this.config.variance;
      varianceValue.fontSize = 30;
      varianceValue.fontFamily = 'PoppinsSemiBold';

      if (this.config.varianceUnit) {
        const varianceUnit = varianceContainer.createChild(am4core.Label);
        varianceUnit.id = 'varianceUnit';
        varianceUnit.textAlign = 'middle';
        varianceUnit.text = this.config.varianceUnit;
        varianceUnit.fontSize = 17;
        varianceUnit.fontFamily = 'Poppins';
        varianceUnit.marginTop = 4;
      }
    }

    const varianceLabel = this.chart.createChild(am4core.Label);
    varianceLabel.parent = this.chart.chartAndLegendContainer;
    varianceLabel.id = 'varianceLabel';
    varianceLabel.align = 'center';
    varianceLabel.horizontalCenter = 'middle';
    varianceLabel.fontSize = 14;
    varianceLabel.fontFamily = 'Nunito';

    this.translator.translate(this.config.varianceLabel).then((result) => {
      varianceLabel.text = result;
    });

    this.chart.legend = new am4charts.Legend();
    this.chart.legend.disabled = false;
    this.chart.legend.position = 'top';

    this.chart.legend.paddingTop = 0;
    this.chart.legend.align = 'center';
    this.chart.legend.valueLabels.template.disabled = false;
    this.chart.legend.markers.template.width = 16;
    this.chart.legend.markers.template.height = 16;
    this.chart.legend.labels.template.fontSize = 11;
    this.chart.legend.labels.template.maxWidth = 125;
    this.chart.legend.labels.template.truncate = false;
    this.chart.legend.labels.template.wrap = true;
    this.chart.legend.labels.template.text = '{category}';

    this.chart.legend.itemContainers.template.paddingLeft = 8;
    this.chart.legend.itemContainers.template.paddingRight = 16;
    this.chart.legend.itemContainers.template.paddingTop = 8;
    this.chart.legend.itemContainers.template.paddingBottom = 8;

    this.chart.responsive.enabled = true;
    this.chart.responsive.useDefault = false;

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelHeight === 350 && target.pixelWidth > 520) {
          // this.logger.warning('Situation 1', this.loggerFrom);
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Label && target.id === 'midValue') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 72;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'midValueUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 14;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 12;
          state.properties.paddingTop = 4;

          return state;
        }
      },
    });

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelHeight === 350 && target.pixelWidth <= 377) {
          // this.logger.warning('Situation 2', this.loggerFrom);
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Label && target.id === 'midValue') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 70;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'midValueUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 14;

          return state;
        }
      },
    });

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelHeight !== 350 && target.pixelWidth <= 520) {
          // this.logger.warning('Situation 3', this.loggerFrom);
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4charts.PieChart) {
          const state = target.states.create(stateId);
          this.chart.radius = am4core.percent(90);
          this.chart.innerRadius = am4core.percent(70);

          state.properties.paddingTop = 0;
          state.properties.paddingLeft = 0;
          state.properties.paddingRight = 0;
          state.properties.paddingBottom = 16;

          return state;
        }

        if (target instanceof am4charts.Legend) {
          const state = target.states.create(stateId);
          state.properties.disabled = false;

          return state;
        }

        if (target instanceof am4core.Container && target.id === 'ChartsAndLegends') {
          const state = target.states.create(stateId);

          state.properties.marginTop = 0;
          state.properties.paddingTop = 0;

          return state;
        }

        if (target instanceof am4core.Container && (target.id === 'LabelsContainer' || target.id === 'TicksContainer')) {
          const state = target.states.create(stateId);
          state.properties.disabled = true;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceLabel') {
          const state = target.states.create(stateId);
          state.properties.paddingBottom = 16;

          return state;
        }
      },
    });

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 343) {
          // this.logger.warning('Situation 4', this.loggerFrom);
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Label && target.id === 'midValue') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 68;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'midValueUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 14;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 12;
          state.properties.paddingTop = 4;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceLabel') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 12;

          return state;
        }
      },
    });

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth < 327) {
          // this.logger.warning('Situation 5', this.loggerFrom);
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Label && target.id === 'midValue') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 64;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'midValueUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 12;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceValue') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 24;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceLabel') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 12;

          return state;
        }
      },
    });

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 288) {
          // this.logger.warning('Situation 6', this.loggerFrom);
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Label && target.id === 'varianceValue') {
          const state = target.states.create(stateId);

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceUnit') {
          const state = target.states.create(stateId);
          state.properties.paddingTop = 0;

          return state;
        }
      },
    });
  }
}
