<div class="container">
  <div class="pagination" [ngClass]="{ small: minimalistVersion }">
    <div class="chevron chevron-left" (click)="!isFirstPage && firstPage()" [ngClass]="{ disabled: isFirstPage }" *ngIf="!minimalistVersion">
      <div class="double-img">
        <img src="/assets/images/icons/iconmonstr-arrow-65.svg" />
        <img src="/assets/images/icons/iconmonstr-arrow-65.svg" />
      </div>
    </div>
    <div class="chevron chevron-left" (click)="!isFirstPage && previousPage()" [ngClass]="{ disabled: isFirstPage, small: minimalistVersion }">
      <img src="/assets/images/icons/iconmonstr-arrow-65.svg" />
    </div>
    <div class="current-page" [ngClass]="{ small: minimalistVersion }">
      <input class="form-control" type="number" id="page-input" name="page-input" [(ngModel)]="currentPage" (keydown)="filterDigits($event)" (change)="setBounderies($event)" />
    </div>
    <div class="max-page" [ngClass]="{ small: minimalistVersion }">
      <span>/</span>
      <span>{{ maxPage }}</span>
    </div>
    <div class="chevron chevron-right" (click)="!isLastPage && nextPage()" [ngClass]="{ disabled: isLastPage, small: minimalistVersion }">
      <img src="/assets/images/icons/iconmonstr-arrow-65.svg" />
    </div>
    <div class="chevron chevron-right" (click)="!isLastPage && lastPage()" [ngClass]="{ disabled: isLastPage }" *ngIf="!minimalistVersion">
      <div class="double-img">
        <img src="/assets/images/icons/iconmonstr-arrow-65.svg" />
        <img src="/assets/images/icons/iconmonstr-arrow-65.svg" />
      </div>
    </div>
  </div>

  <div class="page-size" *ngIf="showPaginationInfo">
    <div class="rows-page">{{ translator.translate('rows_per_page') | async }}</div>
    <div class="ddl">
      <hexa-dropdown-input [items]="pageSizes" [selectedItem]="selectedPageSize" [cancelMinWidth]="true" (selectionChanged)="pageSizeChange($event)"></hexa-dropdown-input>
    </div>
    <div class="page-info" [innerText]="pageInfo"></div>
  </div>

</div>
