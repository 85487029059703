import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.scss']
})
export class SwitchInputComponent implements OnInit {
  @Input() on: boolean;
  @Output() switchChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public translator: PortalTranslatorService) {}

  ngOnInit() {}

  public toggleState(): void {
    this.on = !this.on;

    this.switchChanged.emit(this.on);
  }

  public labelState(): string {
    if (this.on) {
      return 'switch_on';
    } else {
      return 'switch_off';
    }
  }
}
