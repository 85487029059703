import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EnhancedDropdownItem } from '../../../_models/enhanced-dropdown';

@Component({
  selector: 'app-sub-item',
  templateUrl: './sub-item.component.html',
  styleUrls: ['./sub-item.component.scss']
})
export class SubItemComponent implements OnInit {
  @Input() subItemTitle: string;
  @Input() subItems: EnhancedDropdownItem[];
  @Input() selectedItems: EnhancedDropdownItem[];
  @Output() subSelectionChanged: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  selectItem(item: EnhancedDropdownItem, isSelected: boolean, notifyChange: boolean = true) {
    let eventArgs= {
      item: item,
      isSelected: isSelected,
      notifyChange: notifyChange
    }

    this.subSelectionChanged.emit(eventArgs);
  }

  subSelectionChange(event: any)
  {
    this.selectItem(event.item, event.isSelected, event.notifyChange);
  }

  labelClick(event: any){
    event.stopPropagation();
  }
}
