// Helpers to be used by the app
import 'src/app/_helpers/user-helper';
import 'src/app/_helpers/userdto-helper';

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationService } from '../_services/notification.service';
import { AuthIds4Service } from '../_services/authids4.service';
import { UserDto } from '../_models/user';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private consoleLogger: ConsoleLoggerService, private authIds4Service: AuthIds4Service, private router: Router, private notification: NotificationService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const roles = route.data.roles as Array<string>;
    let loggedUser: UserDto;
    let returnVal = false;

    // Picking the user from the right place
    return this.authIds4Service.getLoggedUserInfo().then(user => {
      loggedUser = user.getUserDto();

      // Checking if the user has the role
      if (this.testRole(roles, loggedUser)) {
        returnVal = true;
      } else {
        // tslint:disable-next-line: quotemark
        this.notification.warning("You don't have rights to access this page");

        this.consoleLogger.warning('User missing role', 'RoleGuard', roles);
        this.router.navigate(['/']);
        returnVal = false;
      }

      return returnVal;
    });
  }

  private testRole(roles: string[], loggedUser: UserDto): boolean {
    let returnVal = false;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];

      if (loggedUser.hasRole(role)) {
        this.consoleLogger.success('Role found', 'RoleGuard');
        returnVal = true;
      }
    }

    return returnVal;
  }
}
