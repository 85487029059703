// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  oidcLogLevel: 0,

  roleOwner: 'owner',
  roleDeveloper: 'developer',
  roleContributor: 'contributor',

  slRoot: '/sociallistening',
  slDashboardOverviewFilterSetEndPoint: '/Dashboard/FilterSet',
  slDashboardOverviewWidgetEndPoint: '/Dashboard/Overview',

  vocRoot: '/voc',
  ids4RedirectUrl: '/callback',
  ids4SilentLogintUrl: '/assets/auth/silent-callback.html',
  ids4LogoutRedirectUrl: '/loggedOut',
  ids4Scope: 'openid profile email auth_info additional_info IdentityServerApi',
  organizationEndPoint: '/Organizations',
  culturesHierarchyEndPoint: '/Cultures',
  subscriptionsEndPoint: '/Subscriptions',
  userInformationEndPoint: '/UserInformation',
  addressesEndPoint: '/Addresses',
  subscriptionServicesEndPoint: '/SubscriptionServices',
  branchesEndPoint: '/Branches',
  branchGroupsEndPoint: '/BranchGroups',
  branchGroupsFullEndPoint: '/BranchGroups/Full',
  branchServicesEndPoint: '/BranchServices',
  schemaEndPoint: '/Schema',
  servicesEndPoint: '/Services',
  roleAssignmentsEndPoint: '/RoleAssignments',
  roleAssignmentActivateEndpoint: '/RoleAssignments/Activate',
  usersAlertEmailEndPoint: '/UsersAlert',
  dashboardCompetitorDetailsEndPoint: '/Dashboard/Details/Competitors',
  dashboardDetailsEndPoint: '/Dashboard/Details',
  dashboardOverviewEndPoint: '/Dashboard/Overview',
  dashboardTrendsEndPoint: '/Dashboard/Trends',
  formVersionMetadataEndPoint: '/FormVersionMetadata',
  alertsEndPoint: '/Alerts',
  alertForwardEndPoint: '/ForwardAlert',
  alertSaveHistoryStatusEndPoint: '/AlertStatus',
  singleSignOnEndPoint: '/SingleSignOn',
  projectEndPoint: '/projects',
  projectOptionsEndpoint: '/options',
  projectUrlEndPoint: '/projectUrl',
  regexGroupEndPoint: '/codemapping/groups',
  urlsEndPoint: '/urls',
  templateEndPoint: '/templates',
  layoutEndPoint: '/layouts',
  emailTemplateEndpoint: '/email-templates',
  cultureEndPoint: '/cultures',
  templateLanguageEndPoint: '/templateLanguages',
  layoutLanguageEndPoint: '/layoutLanguages',
  surveyEndPoint: '/surveys',
  surveyAnswerEndPoint: '/surveyanswers',
  regenerateScoresEndPoint: '/regenerate-scores',
  formEndPoint: '/forms',
  categoryEndPoint: '/categories',
  categoryCultureEndPoint: '/category-cultures',
  alertConfigurationEndPoint: '/alertconfiguration',
  versionEndPoint: '/versions',
  publishVersion: 'publish',
  jsonUpload: 'uploadjson',
  predefinedFlowEndPoint: '/predefinedFlows',
  stmAdminClients: '/admin/clients',
  stmAdminMandates: '/admin/mandates',
  stmAdminWaves: '/admin/waves',
  stmAdminSectionDescriptios: '/admin/sectiondescriptions',
  stmAdminIndicators: '/admin/indicators',
  stmAdminNorms: '/admin/norms',
  stmDashboardSections: '/dashboard/sections',
  stmAdminMandateProfiles: '/admin/mandateprofiles',
  mandateEndPoint: '/mandates',
  corporateClientEndPoint: '/corporateclients',
  periodEndPoint: '/periods',
  locationEndPoint: '/locations',
  comparativeanalysisEndPoint: '/comparativeanalysis',
  comparativeanalysisPdfEndPoint: '/comparativeanalysispdf',
  displayTypeEndPoint: '/displayTypes',
  questionEndPoint: '/questions',
  pdfEndpoint: '/pdf',
  webSurveyEndpoint: '/websurvey',
  reportsEndpoint: '/reports',
  questionnaireEndPoint: '/questionnaires',
  pdfzipEndPoint: '/pdfzip',
  csvEndpoint: '/csv',
  adminEndPoint: '/Admin',
  exportEndpoint: '/export',
  importEndpoint: '/import',
  actionLogEndpoint: '/actionlog',
  permissionEndPoint: '/permission',
  questionsEndPoint: '/questions',
  usersEndPoint: '/users',
  userProfile: '/userProfile',
  userAlertNotificationSettingsEndPoint: '/UserAlertNotificationSettings',
  userAssignmentsEndPoint: '/userAssignments',
  userIdEndPoint: '/userId',
  updatePhoneNumberEndPoint: '/updatePhoneNumber',
  verifyPhoneNumberEndPoint: '/verifyPhoneNumber',
  updateEmailEndPoint: '/updateEmail',
  checkPasswordEndPoint: '/checkPassword',
  confirmEmailEndPoint: '/confirmEmail',
  updatePasswordEndPoint: '/updatePassword',
  applicationUserEndPoint: '/applicationUser',
  timeZoneEndPoint: '/timeZones',
  deletePhoneNumberEndPoint: '/deletePhoneNumber',
  customImportConfigurationEndPoint: '/CustomImport',
  customImportProjectConfigurationEndPoint: '/CustomImportProject',
  customExportConfigurationEndPoint: '/CustomExport',
  serviceCustomReportConfigurationEndPoint: '/services/{serviceId}/customreports',
  subscriptionServiceCustomReportEndPoint: '/subscriptionservices/{subscriptionServiceId}/customreports',
  subscriptionServiceReportsEndPoint: '/reports',
  pdfFileNameEndPoint: '/pdfFileName',
  ctProfilesEndPoint: '/profiles',
  assemblyVersionEndPoint:'/AssemblyVersion',
  subscriptionServiceGetCustomImportViewPermissions:"/getCustomImportViewPermissions",
  bookmarkEndPoint: '/bookmarks',
  sectionEndPoint: '/section',
  updateSkipDefaultTemplateUpdatesEndPoint: '/updateSkipDefaultTemplateUpdates'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
