interface Operation {
    op: OperationType;
    path: string;
    value?: any;
    from?: string;
}

enum OperationType {
    add = 'add',
    replace = 'replace',
    test = 'test',
    move = 'move',
    copy = 'copy',
    remove = 'remove'
}

export class PatchFactory {
    private operations: Operation[];

    constructor() {
        this.operations = [];
    }

    add(path: string, value: any): PatchFactory {
        this.operations.push({
            op: OperationType.add,
            path,
            value
        });
        return this;
    }

    remove(path: string): PatchFactory {
        this.operations.push({
            op: OperationType.remove,
            path
        });
        return this;
    }

    replace(path: string, value: any): PatchFactory {
        this.operations.push({
            op: OperationType.replace,
            path,
            value
        });
        return this;
    }

    copy(path: string, from: string): PatchFactory {
        this.operations.push({
            op: OperationType.copy,
            path,
            from
        });
        return this;
    }

    move(path: string, from: string): PatchFactory {
        this.operations.push({
            op: OperationType.move,
            path,
            from
        });
        return this;
    }

    test(path: string, value: any): PatchFactory {
        this.operations.push({
            op: OperationType.test,
            path,
            value
        });
        return this;
    }

    build(): Operation[] {
        return this.operations;
    }
}