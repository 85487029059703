import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { environment } from 'src/environments/environment';

export function getOidcConfig(config: any): UserManagerSettings {

  return {
    authority: config.ids4.authorityUrl,
    client_id: config.ids4.clientId,
    redirect_uri: config.portal.baseUrl.concat(environment.ids4RedirectUrl),
    response_type: 'code',
    scope: config.ids4.scope,
    post_logout_redirect_uri: config.portal.baseUrl.concat('/loggedout'),
    silent_redirect_uri: config.portal.baseUrl.concat(environment.ids4SilentLogintUrl),
    automaticSilentRenew: true
  };
}
