<div class="top-bar">
  <div class="title-section">
    <h1 class="title-container">{{ this.title }}</h1>
    <div class="add-container">
      <div class="add-button">
        <app-input-button (manageClick)="addClick()" [buttonLabel]="this.addLabel"> </app-input-button>
      </div>
    </div>
  </div>
  <div class="search-bar-section">
    <div class="search-bar-child checkbox-filter-container">
      <div class="check-box">
        <input type="checkbox" (change)="toggleCheckBox()" id="checkbox" />
        <label for="checkbox">{{ this.checkBoxFilterLabel }}</label>
      </div>
    </div>
    <div class="search-bar-child search-bar-container">
      <div class="search-box">
        <app-input-text-box [placeholder]="this.searchPlaceholder" [search]="true" [debounceTime]="1000" (textChange)="searchFieldChange($event)"></app-input-text-box>
      </div>
    </div>
    <div class="search-bar-child export-button-container">
      <app-loading-spinner [show-spinner]="showExportSpinner"></app-loading-spinner>
      <div *ngIf="!showExportSpinner" class="export-button">
        <app-input-button
          [secondary]="true"
          [icon]="'assets/images/icons/xlsx-icon_secondary.svg'"
          [buttonLabel]="this.translationTexts.exportXlsx"
          (manageClick)="exportXlsxClick()"
        ></app-input-button>
      </div>
      <div *ngIf="!showExportSpinner" class="export-button">
        <app-input-button
          [secondary]="true"
          [icon]="'assets/images/icons/csv-icon_secondary.svg'"
          [buttonLabel]="this.translationTexts.exportCsv"
          (manageClick)="exportCsvClick()"
        ></app-input-button>
      </div>
    </div>
  </div>
</div>
<app-loading-spinner [show-spinner]="showSpinner"></app-loading-spinner>
<div class="card-list" *ngIf="!showSpinner">
  <div class="user-card" *ngFor="let card of cards">
    <app-card
      [title]="card.title"
      [secondaryTitle]="card.secondaryTitle"
      [identifier]="card.identifier"
      [data]="card.data"
      [tag]="card.tag"
      [displayEditBtn]="card.displayEditBtn"
      [displayDuplicateBtn]="card.displayDuplicateBtn"
      [displayDeleteBtn]="card.displayDeleteBtn"
      (deleteEvent)="onDeleteClick($event)"
      (duplicateEvent)="onDuplicateClick($event)"
      (editEvent)="onEditClick($event)"
      (impersonateEvent)="onImpersonateClick($event)"
      (titleEvent)="onTitleClick($event)"
      (groupEvent)="onClickGroup($event)"
    ></app-card>
  </div>
</div>
