import { Injectable, EventEmitter } from '@angular/core';
import { Item, LocationItem, FilterSet, DashboardOptions, QuestionFilterItem, ResultsState } from '../_models/results';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { QuestionFilterModel } from '../_models/question-filter.model';
import { EnhancedDropdownItem } from '../../portal/_models/enhanced-dropdown';
import { QuestionDetail } from '../_models/question-detail.model';
import { VocEvents } from '../_events/voc.events';
import { ValueObserver } from 'src/app/_base/value.observer';
import { options } from '@amcharts/amcharts4/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MinLengthValidator } from '@angular/forms';

enum localeEnum {
  all = 0,
  country = 1,
  department = 2,
  locality = 3,
  city = 4,
  branch = 5
}

@Injectable({
  providedIn: 'root'
})
export class DashboardFilterService {
  public filterChange = new EventEmitter<string>();
  public filterSet: FilterSet;
  public questionDetails: QuestionDetail[];
  public selectedService: Item;
  public selectedLocale: LocationItem;
  public selectedSubLocale: Item;
  public withAlertsOnly: boolean;
  public ignoreAlertFilterParam: boolean;
  public questionFilter: QuestionFilterModel[];
  public questionFilterDropdownItems: EnhancedDropdownItem[] = [];
  public selectedQuestionFilterDropdownItems: EnhancedDropdownItem[] = [];
  public selectedQuestionFilters: QuestionFilterItem[];
  public reloadedQuestionFilter = false;
  public branchIds: string[];
  public allBranchIdsSelected: boolean;
  public branchCount: number;
  public branchTotalCount: number;

  public trendsStartDate: Date;
  public trendsEndDate: Date;

  public readonly allGlobalKey: string;
  public readonly allDetailKey: string;
  private urlLocationKeys: string[];
  private allLocale: LocationItem;
  private allGlobal: Item;
  private allDetail: Item;
  public currentOptions: DashboardOptions;
  private currentTrendsOptions: DashboardOptions;
  public hasAlertConfiguration: boolean;

  public isLoading: ValueObserver<boolean> = new ValueObserver<boolean>(null);

  constructor(public translator: PortalTranslatorService, public vocEvents: VocEvents) {
    this.filterSet = {
      maxPage: 1,
      earliestAnswer: null,
      latestAnswer: null,
      locations: null,
      services: null,
      questionFilterJson: null
    };

    const referenceDate: Date = new Date();
    this.trendsStartDate = new Date(referenceDate.getFullYear(), referenceDate.getMonth() - 6, 1);
    this.trendsEndDate = new Date(referenceDate.getFullYear(), referenceDate.getMonth(), referenceDate.getDate());

    this.allLocale = {
      key: localeEnum.all,
      value: null
    };

    this.allGlobalKey = '|all-global';
    this.allDetailKey = '|all-detail';

    this.allGlobal = {
      key: `{0}${this.allGlobalKey}`,
      value: null
    };

    this.allDetail = {
      key: `{0}${this.allDetailKey}`,
      value: null
    };

    this.allGlobal.value = 'filter_all_global';
    this.allDetail.value = 'filter_all_detailed';

    this.selectedLocale = this.allLocale;

    this.vocEvents.project.listen(() => {
      this.reloadedQuestionFilter = true;
    });
  }

  public initializeState(state?: ResultsState) {
    this.selectedLocale = this.allLocale;
    this.selectedSubLocale = null;
    this.selectedService = null;

    const referenceDate: Date = new Date();
    this.setTrendsStartDate(new Date(referenceDate.getFullYear(), referenceDate.getMonth() - 6, 1));
    this.setTrendsEndDate(new Date(referenceDate.getFullYear(), referenceDate.getMonth(), referenceDate.getDate()));

    this.currentOptions = null;
    this.withAlertsOnly = false;
    this.ignoreAlertFilterParam = false;

    this.selectedQuestionFilterDropdownItems = [];
    this.selectedQuestionFilters = [];
    this.reloadedQuestionFilter = true;

    if (state) {
      this.currentOptions = state.options;

      this.urlLocationKeys = this.currentOptions.locations;
      this.setService(this.currentOptions.branchServiceId);
      this.selectedQuestionFilters = this.currentOptions.questionFilters;
      this.reloadedQuestionFilter = true;
      this.withAlertsOnly = this.currentOptions.withAlertsOnly;
    }
  }

  public initializeQuestionFilter(filterSet: FilterSet, questionDetails: QuestionDetail[]) {
    this.reloadedQuestionFilter = false;
    this.questionFilterDropdownItems = [];

    this.questionFilter = JSON.parse(filterSet.questionFilterJson);

    let dropdown: EnhancedDropdownItem = {
      key: null,
      selectable: true,
      value: null
    };

    if (this.questionFilter && questionDetails) {
      this.questionFilter.forEach(q => {
        dropdown.key = q.questionName;
        dropdown.value = this.getQuestionShortName(questionDetails, q.questionName) || q.questionName;
        dropdown.tag = q.questionName;

        this.loadChoices(q, questionDetails);

        if (q.subFilters) {
          dropdown.subItems = [];
          q.subFilters.forEach(s => {
            this.loadSubQuestionFilter(dropdown, s, questionDetails);
          });
        }

        this.questionFilterDropdownItems.push(dropdown);
        if (this.selectedQuestionFilters) {
          this.selectedQuestionFilterDropdownItems = [];
          if (this.selectedQuestionFilters.some(selection => selection.key === q.questionName)) {
            const selection = this.selectedQuestionFilters.find(selection => selection.key === q.questionName);
            let count = 0;
            dropdown.subItems.forEach(sub => {
              if (selection.value.some(v => v === sub.answerValue)) {
                this.selectedQuestionFilterDropdownItems.push(sub);
                count++;
              }
            });

            if (dropdown.subItems.length === count) {
              this.selectedQuestionFilterDropdownItems.push(dropdown);
            }
          }
        }

        dropdown = {
          key: null,
          selectable: true,
          value: null
        };
      });
    }
  }

  normalizeDate(date: Date): Date {
    if (date) {
      let normalDate = new Date(date.getTime ? date.getTime() : date);
      return new Date(normalDate.getFullYear(), normalDate.getMonth(), normalDate.getDate());
    }

    return undefined;
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public loadFilterSet(filterSet: FilterSet, questionDetails?: QuestionDetail[], hasAlertConfiguration: boolean = false, setEarliestDates: boolean = true): void {
    this.filterSet = filterSet;
    this.questionDetails = questionDetails;
    this.hasAlertConfiguration = hasAlertConfiguration;

    if (!this.questionFilterDropdownItems || this.questionFilterDropdownItems.length === 0 || this.reloadedQuestionFilter) {
      this.initializeQuestionFilter(filterSet, questionDetails);
    }

    if (this.filterSet) {

      if (!this.selectedService && this.filterSet.services && this.filterSet.services.length) {
        this.selectedService = null;
      }

      if (this.filterSet.locations && this.filterSet.locations.length) {
        if (this.urlLocationKeys) {
          const location = this.urlLocationKeys[0];
          const split = location.split('|');

          let locationType = parseInt(split[0]);
          locationType = locationType ? locationType : localeEnum.country;
          this.selectedLocale = this.filterSet.locations.find(l => l.key === locationType);

          if (!this.selectedLocale) {
            this.selectedLocale = this.allLocale;
          }

          this.setSubLocale(location, false);

          this.urlLocationKeys = null;
        } else if (!this.selectedLocale) {
          this.selectedLocale = this.allLocale;
        }
      } else if (!this.selectedLocale) {
        this.selectedLocale = this.allLocale;
      }
    }
  }

  loadChoices(questionFitlerModel: QuestionFilterModel, questionDetails?: QuestionDetail[]) {
    const questionDetail = questionDetails.find(qd => qd.questionName === questionFitlerModel.questionName);

    if (!questionDetail) {
      return;
    }

    const choiceJson = JSON.parse(questionDetail?.choicesJson);

    const subFilters = [];
    let subFilter: QuestionFilterModel;
    const activeLanguage = this.translator.activeLanguage.value;

    choiceJson.forEach(c => {
      let questionText;
      if (activeLanguage === 'fr') {
        questionText = c.text.fr || c.text;
      } else {
        questionText = c.text.default || c.text;
      }

      if (questionText) {
        const questionSubFilterModel = questionFitlerModel.subFilters.find(s => s.answerValue === c.value);

        subFilter = {
          answerValue: c.value,
          answerName: questionText,
          questionName: questionFitlerModel.questionName,
          subFilters: []
        };

        if (questionSubFilterModel && questionSubFilterModel.subFilters) {
          subFilter.questionName = questionSubFilterModel.questionName;
          subFilter.subFilters = questionSubFilterModel.subFilters;
          this.loadChoices(subFilter, questionDetails);
          subFilter.questionName = questionFitlerModel.questionName;
        }

        subFilters.push(subFilter);
      }
    });

    questionFitlerModel.subFilters = subFilters;
  }

  private loadSubQuestionFilter(dropdown: EnhancedDropdownItem, questionFilterModel: QuestionFilterModel, questionDetails: QuestionDetail[]) {
    let subDropdown: EnhancedDropdownItem = {
      key: null,
      selectable: true,
      value: null
    };

    subDropdown.key = questionFilterModel.questionName.concat('_', questionFilterModel.answerValue);
    subDropdown.value = questionFilterModel.answerName;
    subDropdown.answerValue = questionFilterModel.answerValue;
    subDropdown.questionName = questionFilterModel.questionName;
    subDropdown.tag = questionFilterModel.questionName.concat('_', questionFilterModel.answerValue);

    if (questionFilterModel.subFilters) {
      if (questionFilterModel.subFilters[0]) {
        subDropdown.subItemTitle = this.getQuestionShortName(questionDetails, questionFilterModel.subFilters[0].questionName) || questionFilterModel.subFilters[0].questionName;
      }
      subDropdown.subItems = [];
      questionFilterModel.subFilters.forEach(s => {
        this.loadSubQuestionFilter(subDropdown, s, questionDetails);
      });
    }
    if (!dropdown.subItems.find(s => s.key === subDropdown.key)) {
      dropdown.subItems.push(subDropdown);
    }
  }

  public getQuestionShortName(questionDetails: QuestionDetail[], questionName: string): string {
    let questionShortName = JSON.parse(questionDetails.find(q => q.questionName === questionName)?.questionShortName ?? null);
    let shortNameText;

    if (questionShortName) {
      if (this.translator.activeLanguage.value === 'fr') {
        let shortName = questionShortName.find(sn => sn && sn.lang === 'fr');
        if (shortName) {
          shortNameText = shortName.value.shortText;
        }
      } else {
        let shortName = questionShortName.find(sn => sn && sn.lang === 'en');
        if (shortName) {
          shortNameText = shortName.value.shortText;
        }
      }
    }
    return shortNameText;
  }

  private areDatesEqual(a: Date, b: Date) {
    if (a.getFullYear() !== b.getFullYear()) {
      return false;
    }

    if (a.getMonth() !== b.getMonth()) {
      return false;
    }

    if (a.getDate() !== b.getDate()) {
      return false;
    }

    return true;
  }

  public setQuestionFilterItems(questionFilterItems: QuestionFilterItem[], triggerChange: boolean = false) {
    this.selectedQuestionFilters = questionFilterItems;

    if (triggerChange) {
      this.filterChange.emit('DashboardFilterService.setQuestionFilterItems');
    }
  }

  public setTrendsStartDate(date: Date, triggerChange: boolean = false): void {
    if (!this.areDatesEqual(this.trendsStartDate, date)) {
      this.trendsStartDate = date;
      if (triggerChange) {
        this.filterChange.emit('DashboardFilterService.setTrendsStartDate');
      }
    }
  }

  public setTrendsEndDate(date: Date, triggerChange: boolean = false): void {
    if (!this.areDatesEqual(this.trendsEndDate, date)) {
      this.trendsEndDate = date;
      if (triggerChange) {
        this.filterChange.emit('DashboardFilterService.setTrendsEndDate');
      }
    }
  }

  public setQuestionFilter(selectedQuestionFilters: EnhancedDropdownItem[], triggerChange: boolean = false): void {
    if (selectedQuestionFilters) {
      this.selectedQuestionFilters = [];
      let item: QuestionFilterItem;

      selectedQuestionFilters.forEach(q => {
        if (q.questionName) {
          item = this.selectedQuestionFilters.find(s => s.key === q.questionName);
          if (item) {
            item.value.push(q.answerValue);
          } else {
            item = {
              key: q.questionName,
              value: [q.answerValue]
            };
            this.selectedQuestionFilters.push(item);
          }
        }
      });

      if (triggerChange) {
        this.reloadedQuestionFilter = true;
        this.filterChange.emit('DashboardFilterService.setQuestionFilter');
      }
    }
  }

  public setService(key: string, triggerChange: boolean = false): void {
    if (!this.selectedService || this.selectedService.key !== key) {
      this.selectedService = key ? this.filterSet.services.find(s => s.key === key) : null;

      if (triggerChange) {
        this.filterChange.emit('DashboardFilterService.setService');
      }
    }
  }

  public setLocale(key: number, triggerChange: boolean = false): void {
    if (key > 0) {
      this.updateLocale(
        this.filterSet.locations.find(l => l.key === key),
        triggerChange
      );
      return;
    }

    this.updateLocale(this.allLocale, triggerChange);
  }

  public setSubLocale(key: string, triggerChange: boolean = false): void {
    if (this.isGlobal(key)) {
      this.updateSubLocale(this.buildAllGlobal(), triggerChange);
      return;
    }

    if (this.isDetail(key)) {
      this.updateSubLocale(this.buildAllDetail(), triggerChange);
      return;
    }

    this.updateSubLocale(
      this.selectedLocale.value.find(sub => sub.key === key),
      triggerChange
    );
  }

  public setSubLocaleWithNoLocale(id: string, locationType: number, triggerChange: boolean = false) {
    locationType = locationType ? locationType : localeEnum.country;
    const locale = this.filterSet.locations.find(l => l.key === locationType);
    this.selectedLocale = locale;
    if (locale) {
      this.updateSubLocale(
        locale.value.find(l => l.key === id),
        triggerChange
      );
    }
  }

  public isGlobal(key: string): boolean {
    return key.endsWith(`${this.allGlobalKey}`);
  }

  public isDetail(key: string): boolean {
    return key.endsWith(`${this.allDetailKey}`);
  }

  public buildAllGlobal(): Item {
    return {
      key: this.allGlobal.key.replace('{0}', this.selectedLocale ? this.selectedLocale.key.toString() : '0'),
      value: this.allGlobal.value
    };
  }

  public buildAllDetail(): Item {
    return {
      key: this.allDetail.key.replace('{0}', this.selectedLocale ? this.selectedLocale.key.toString() : '0'),
      value: this.allDetail.value
    };
  }

  private updateLocale(localeItem: LocationItem, triggerChange: boolean = false): void {
    this.selectedLocale = localeItem;

    if (this.selectedLocale !== this.allLocale && this.selectedSubLocale && this.isDetail(this.selectedSubLocale.key)) {
      this.updateSubLocale(this.buildAllDetail(), triggerChange);
      return;
    }

    this.updateSubLocale(this.buildAllGlobal(), triggerChange);
  }

  private updateSubLocale(item: Item, triggerChange: boolean = false): void {
    this.selectedSubLocale = item;
    if (triggerChange) {
      this.filterChange.emit('DashboardFilterService.updateSubLocale');
    }
  }

  public getLocaleTypeStringKey(localeType: number): string {
    let label = '';

    switch (localeType) {
      case localeEnum.all:
        label = 'filter_all';
        break;
      case localeEnum.country:
        label = 'filter_country';
        break;
      case localeEnum.department:
        label = 'filter_province';
        break;
      case localeEnum.locality:
        label = 'filter_region';
        break;
      case localeEnum.city:
        label = 'filter_cities';
        break;
      case localeEnum.branch:
        label = 'filter_branch';
        break;
    }

    return label;
  }

  public getLocaleTypeString(localeType: number): Promise<string> {
    return this.translator.translate(this.getLocaleTypeStringKey(localeType));
  }

  checkOptionsForChange(resultsState: ResultsState): boolean;
  checkOptionsForChange(subscriptionServiceId: string, pageNumber?: number, pageSize?: number, detailed?: boolean): boolean;
  checkOptionsForChange(stateOrId: ResultsState | string, pageNumber?: number, pageSize?: number, detailed?: boolean): boolean {
    const state: ResultsState = stateOrId && (stateOrId as any).projectId ? (stateOrId as ResultsState) : null;
    if (state) {
      if (this.currentOptions) {
        if (this.currentOptions.subscriptionServiceId !== state.options.subscriptionServiceId) {
          return true;
        }
        if (this.currentOptions.branchServiceId !== state.options.branchServiceId) {
          return true;
        }
        if (this.currentOptions.detailed !== state.options.detailed) {
          return true;
        }
        if (this.currentOptions.startDate !== state.options.startDate) {
          return true;
        }
        if (this.currentOptions.endDate !== state.options.endDate) {
          return true;
        }
        if (
          !!this.currentOptions.locations !== !!state.options.locations ||
          (this.currentOptions.locations && this.currentOptions.locations.length !== state.options.locations.length)
        ) {
          return true;
        }
        if (
          !!this.currentOptions.questionFilters !== !!state.options.questionFilters ||
          (this.currentOptions.questionFilters && this.currentOptions.questionFilters.length !== state.options.questionFilters.length)
        ) {
          return true;
        }

        let found = false;
        for (let i = 0; i < this.currentOptions.locations.length; i++) {
          if (this.currentOptions.locations[i] !== state.options.locations[i]) {
            found = true;
            break;
          }
        }

        if (found) {
          return true;
        }

        for (let i = 0; i < this.currentOptions.questionFilters.length; i++) {
          if (
            this.currentOptions.questionFilters[i].key !== state.options.questionFilters[i].key ||
            this.currentOptions.questionFilters[i].value !== state.options.questionFilters[i].value
          ) {
            found = true;
            break;
          }
        }

        if (found) {
          return true;
        }

        return false;
      }
    } else {
      if (this.currentOptions) {
        const options = this.buildOptions(stateOrId as string, pageNumber, pageSize, detailed);
        const propertyNames = Object.keys(options);

        return propertyNames.some(name => (options[name] === undefined ? null : options[name]) !== (this.currentOptions[name] === undefined ? null : this.currentOptions[name]));
      }
    }

    return true;
  }

  checkTrendsOptionsForChange(subscriptionServiceId: string, locations: string[], categories: string[], compareCategories: boolean, langCode: string): boolean {
    if (this.currentTrendsOptions) {
      const options = this.buildTrendsOptions(subscriptionServiceId, locations, categories, compareCategories, langCode);

      if (this.currentTrendsOptions.subscriptionServiceId !== options.subscriptionServiceId) {
        return true;
      }

      if (!!this.currentTrendsOptions.categories !== !!options.categories) {
        return true;
      }

      if (!!this.currentTrendsOptions.locations !== !!options.locations) {
        return true;
      }

      if (options.categories && this.currentTrendsOptions.categories.length !== options.categories.length) {
        return true;
      }

      if (options.locations && this.currentTrendsOptions.locations.length !== options.locations.length) {
        return true;
      }

      if (this.currentTrendsOptions.categories.some(cat => !options.categories.includes(cat))) {
        return true;
      }

      if (this.currentTrendsOptions.locations.some(loc => !options.locations.includes(loc))) {
        return true;
      }

      if (this.currentTrendsOptions.startDate !== options.startDate) {
        return true;
      }

      if (this.currentTrendsOptions.endDate !== options.endDate) {
        return true;
      }

      if (this.currentTrendsOptions.questionFilters !== options.questionFilters) {
        return true;
      }

      return false;
    }

    return true;
  }

  getOptions(subscriptionServiceId: string, pageNumber?: number, pageSize?: number, detailed?: boolean, langCode?: string, demoMode?: boolean): DashboardOptions {
    const options = this.buildOptions(subscriptionServiceId, pageNumber, pageSize, detailed, langCode);
    options.branchIds = this.branchIds;
    options.loadDemoData = demoMode;
    this.currentOptions = options;

    return options;
  }

  getTrendOptions(subscriptionServiceId: string, locations: string[], categories: string[], compareCategories: boolean, langCode?: string): DashboardOptions {
    const options = this.buildTrendsOptions(subscriptionServiceId, locations, categories, compareCategories, langCode);
    this.currentTrendsOptions = options;

    return options;
  }

  private buildTrendsOptions(subscriptionServiceId: string, locations: string[], categories: string[], compareCategories: boolean, langCode: string): DashboardOptions {
    return {
      branchServiceId: this.selectedService ? this.selectedService.key : null,
      subscriptionServiceId,
      locations,
      categories,
      compareCategories,
      startDate: this.toISODateString(this.trendsStartDate),
      endDate: this.toISODateString(this.trendsEndDate),
      langCode,
      questionFilters: this.selectedQuestionFilters
    };
  }

  private buildOptions(subscriptionServiceId: string, pageNumber?: number, pageSize?: number, detailed?: boolean, langCode?: string): DashboardOptions {
    if (
      this.selectedLocale &&
      this.selectedSubLocale &&
      (this.isDetail(this.selectedSubLocale.key) || (!this.isGlobal(this.selectedSubLocale.key) && this.selectedLocale.key === 5))
    ) {
      detailed = true;
    }

    const locations = [];

    if (this.selectedSubLocale) {
      locations.push(this.selectedSubLocale.key);
    }

    locations.push(this.buildAllGlobal().key);

    const options = {
      branchServiceId: this.selectedService ? this.selectedService.key : null,
      subscriptionServiceId,
      detailed,
      locations,
      pageNumber,
      pageSize,
      startDate: this.toISONgbDateString(this.vocEvents.dashboardSelectedFilters.timePeriod.startDate),
      endDate: this.toISONgbDateString(this.vocEvents.dashboardSelectedFilters.timePeriod.endDate),
      langCode,
      questionFilters: this.selectedQuestionFilters,
      withAlertsOnly: this.withAlertsOnly
    };

    return options;
  }

  // Drops the time and covert to iso format.
  private toISODateString(date: Date): string {
    const tmp = new Date(date.toDateString());
    tmp.setUTCHours(0, 0, 0, 0);

    return tmp.toISOString();
  }

  // Drops the time and covert to iso format.
  private toISONgbDateString(date: NgbDate): string {
    if (!date) {
      return '';
    }

    const tmp = new Date(date.year, date.month - 1, date.day);

    return tmp.toISOString();
  }
}
