import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { PortalService } from '../_services/portal.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-activate-assignment',
  template: ''
})
export class ActivateAssignmentComponent implements OnInit {
  private readonly loggerFrom: string = 'ActivateAssignment';
  private id: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logger: ConsoleLoggerService,
    private portalService: PortalService,
    private alertify: AlertifyService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.logger.info('Params', this.loggerFrom, params);
      this.id = params.i;
      this.logger.info('Role Assignment ID', this.loggerFrom, this.id);

      this.activateRoleAssignment();
    });
  }

  activateRoleAssignment() {
    this.portalService.activeRoleAssignmentDeprecate(this.id).subscribe(
      result => {
        this.alertify.success('Permission activated');
      },
      error => {
        this.alertify.error('You cannot activate this permission');
        this.router.navigate(['/'], { skipLocationChange: false });
      },
      () => {
        this.router.navigate(['/'], { skipLocationChange: false });

        this.zone.runOutsideAngular(() => {
          window.location.href = '/';
        });
      }
    );
  }
}
