import { Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Input() openModal: boolean;
  @Input() title: string = '';
  @Input() content: string = '';

  @Output() deleteConfirmed: EventEmitter<boolean>;

  public closeButtonLabel = '';
  public deleteButtonLabel = '';

  constructor(public translator: PortalTranslatorService) {
    this.deleteConfirmed = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges) {
    const openModal: SimpleChange = changes.openModal;

    if (openModal) {
      // Get the modal
      var modal = document.getElementById("deleteModal");

      if (openModal.currentValue) {
        modal.style.display = "block";

        // When the user clicks anywhere outside of the modal, close it
        window.addEventListener("click", (event) => {
          if (event.target == modal) {
            modal.style.display = "none";
            this.deleteConfirmed.emit(false);
          }
        });
      } else {
        modal.style.display = "none";
      }
    }
  }

  ngOnInit() {
    this.translator.translate('delete').then(result => {
      this.deleteButtonLabel = result;
    });

    this.translator.translate('close').then(result => {
      this.closeButtonLabel = result;
    });
  }

  deleteUserGroup() {
    this.deleteConfirmed.emit(true);
  }

  closeModal() {
    var modal = document.getElementById("deleteModal");
    modal.style.display = "none";
    this.deleteConfirmed.emit(false);
  }
}
