<div class="top-bar" (click)="managePopoverClick()">
  <div class="options-info d-flex" *ngIf="false">
    <div class="hit-box-options" (click)="toggleOptionsPopover($event)" [ngClass]="{ disabled: !scrolledToTop }"></div>

    <img src="../assets/images/option-vertical.svg" />

    <div class="info" *ngIf="!adminArea">
      <div class="organization" [innerText]="portalEvents.organization.value?.name"></div>

      <div class="subscription" [innerText]="portalEvents.subscription.value?.name" *ngIf="!isDev; else devLink"></div>

      <ng-template #devLink>
        <div class="subscription" *ngIf="isDev">
          {{ portalEvents.subscription.value?.name }}
          <a *ngIf="portalEvents.subscription.value" [routerLink]="['/dev-admin', portalEvents.subscription.value?.id]" routerLinkActive="router-link-active"><i>D</i></a>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="selected-service" id="popoverMenu-dropdown" [hidden]="!isLogged" (click)="managePopoverClick()" selectedService>
    <div class="navigation-toggle" (click)="toggleNavigationMenu($event)">
      <img src="/assets/images/navigation/navigation-burger-icon.svg" *ngIf="!this.navigationMenu" />
      <img src="/assets/images/navigation/navigation-x-icon.svg" *ngIf="this.navigationMenu" />
    </div>

    <div class="service-button-container" *ngIf="!settingsClicked">
      <div class="service-button">
        <div class="dropdown-button">
          <span class="hidden-xs">
            <div *ngIf="topMenuBreadCrumb && topMenuBreadCrumb.organizationName && topMenuBreadCrumb.organizationName.length > 0">
              <span class="breadcrumb-ellipsis"
                >{{ topMenuBreadCrumb.organizationName }}
                <span *ngIf="topMenuBreadCrumb.subscriptionName.length > 0"> | {{ topMenuBreadCrumb.subscriptionName }}</span>
                <span class="breadcrumb-service" *ngIf="topMenuBreadCrumb.subscriptionServiceType.length > 0"> | {{ topMenuBreadCrumb.subscriptionServiceType }}</span></span
              >
              <br />
              <span class="breadcrumb-projectname breadcrumb-ellipsis" *ngIf="topMenuBreadCrumb.projectName.length > 0"> {{ topMenuBreadCrumb.projectName }}</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="service-button-container" *ngIf="settingsClicked">
      <div class="service-button">
        <div class="dropdown-button" [ngClass]="{ active: true }">
          <span class="hidden-xs">
            {{ translator.translate('settings_your_settings') | async }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="logo-container" (click)="managePopoverClick()">
    <a [routerLink]="['/']" class="logo">
      <img src="{{ getLogoUrl() }}" />
    </a>
  </div>

  <div class="logged-user-container" (click)="managePopoverClick()">
    <div class="logged-user" (click)="toggleAccountPopover($event)" *ngIf="isLogged">
      <div class="connection-info">
        <span class="info-label username">{{ authIds4Service.userName }}</span>
      </div>

      <div class="avatar" [ngClass]="{ disabled: !scrolledToTop }">
        <img src="/assets/images/icons/iconmonstr-user-19.svg" />
      </div>
    </div>
  </div>

  <div *ngIf="!isLogged">
    <button type="button" class="btn btn-outline-primary bg-dark" (click)="login()">
      {{ translator.translate('login') | async }}
    </button>
  </div>
</div>

<div class="navigation-menu" [ngClass]="{ 'move-in': navigationMenu }">
  <app-navigation-menu
    [navigationMenus]="navigationMenus"
    [organizations]="organizations"
    (topMenuBreadCrumbChanged)="topMenuBreadCrumbChanged($event)"
    (toggleNavigationMenu)="toggleNavigationMenu($event)"
  ></app-navigation-menu>
</div>

<div class="popover-overlay" *ngIf="showAccountPopover || navigationMenu" (click)="managePopoverClick()"></div>

<div id="account" class="popover account" [hidden]="!showAccountPopover">
  <div class="hide">
    <app-popover-subject-tree
      [organizations]="organizations"
      [organizationToSelect]="portalEvents.organization.value"
      [subscriptionToSelect]="portalEvents.subscription.value"
      [deselectAll]="adminArea"
      (organizationChanged)="organizationChanged($event)"
      (subscriptionChanged)="subscriptionChanged($event)"
      (managePopoverClick)="managePopoverClick()"
    >
    </app-popover-subject-tree>
  </div>

  <div class="first-row">
    <div class="profile row">
      <div class="col-8 d-flex">
        <div class="avatar">
          <img src="/assets/images/icons/iconmonstr-user-19-dark.svg" />
        </div>
  
        <div class="account-info text-truncate">
          {{ authIds4Service.userName }}
        </div>
      </div>

      <div class="lang-selector-container col-4">
        <div class="m-auto" role="group" aria-label="Basic example">
          <div *ngIf="!settingsClicked" class="btn-group">
            <img src="/assets/images/icons/cogwheel.svg" alt="" />

            <button type="button" class="btn btn-outline-primary settings" (click)="settingsNavigateTo()">
              {{ translator.translate('settings') | async }}
            </button>
          </div>

          <div *ngIf="settingsClicked" class="btn-group">
            <button type="button" class="btn btn-outline-primary settings-desktop" (click)="settingsNavigateBack()">
              {{ translator.translate('settings_back_to_portal') | async }}
            </button>

            <button type="button" class="btn btn-outline-primary settings-mobile" (click)="settingsNavigateBack()">
              {{ translator.translate('settings_portal') | async }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons-row">
      <button type="button" class="btn btn-primary col-6" *ngIf="this.isDev" (click)="goToAdmin()">
        {{ translator.translate('submenu_admin') | async }}
      </button>

      <div class="col-2" *ngIf="this.isDev"></div>

      <button type="button" class="btn btn-outline-primary col-4" (click)="logout()">
        {{ translator.translate('logout') | async }}
      </button>
    </div>
  </div>
</div>

<div
  id="subMenuSticky"
  class="menu-container"
  [ngClass]="{ menuContainerWithProjectSelectionTitle: !hasPopoverMenu(portalEvents.subscriptionService.value) }"
  *ngIf="
    portalEvents.subMenuVisible.value &&
    ((portalEvents.subMenu.value && portalEvents.subMenu.value.length) || (portalEvents.mobileSubMenu.value && portalEvents.mobileSubMenu.value.length))
  "
>
  <div class="menu-bar" *ngIf="!portalEvents.isMobile">
    <a
      class="item"
      href="javascript:void(0)"
      *ngFor="let item of portalEvents.subMenu.value"
      [ngClass]="{ active: item === portalEvents.selectedSubMenu.value }"
      (click)="navigateTo(item)"
    >
      <div class="sub-menu-item-text">
        <span>
          {{ translator.translate(item.text) | async }}
        </span>
      </div>
    </a>
  </div>

  <div class="menu-bar mobile" *ngIf="portalEvents.isMobile">
    <a
      class="item"
      href="javascript:void(0)"
      *ngFor="let item of portalEvents.mobileSubMenu.value"
      [ngClass]="{ active: item === portalEvents.selectedSubMenu.value }"
      (click)="navigateTo(item)"
    >
      <div class="icon">
        <img [attr.src]="getIcon(item)" />
      </div>

      <div class="sub-menu-item-text">
        <span>
          {{ translator.translate(item.text) | async }}
        </span>
      </div>
    </a>
  </div>
</div>
