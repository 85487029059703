<div class="dropdown enhanced-dropdown" [ngClass]="{ disabled: disabled }">
  <a class="dropdown-toggle" data-toggle="dropdown" [ngClass]="{ disabled: disabled }" href="javascript:void(0);" role="button" aria-haspopup="true" aria-expanded="false">
    <div class="dropdown-content">
      <span class="text">{{ selectionLabel }}</span>
      <img class="dropdown-chevron" src="/assets/images/icons/iconmonstr-arrow-65-dark.svg" />
    </div>
  </a>
  <div class="dropdown-menu">
    <div class="enhanced-all">
      <div class="form-check form-check-inline">
        <input class="form-check-input" (change)="allSelected($event)" type="checkbox" [attr.id]="'chk_All' + refNumber" [checked]="chkAllSelected ? 'checked' : ''" />
        <label class="form-check-label" [ngClass]="{ selected: chkAllSelected }" [attr.for]="'chk_All' + refNumber" (click)="labelClick($event)">{{ allTextLabel }}</label>
      </div>
    </div>
    <div id="accordion">
      <div class="card" *ngFor="let item of items">
        <div class="card-header enhanced-accordion" [ngClass]="{ borderBottomNone: !item.subItems, 'keep-open': !item.selectable }" [attr.id]="'heading' + (item.labelOverride || item.tag)">
          <div class="form-check form-check-inline" [attr.data-target]="'#' + (item.labelOverride || item.tag)">
            <input
              class="form-check-input"
              [attr.id]="'chk_' + (item.labelOverride || item.tag)"
              (change)="selectItem(item, $event.target.checked)"
              type="checkbox"
              [(ngModel)]="item.checked"
              *ngIf="item.selectable"
            />
            <label class="form-check-label" [attr.for]="'chk_' + (item.labelOverride || item.tag)" [ngClass]="{ selected: item.checked }" (click)="labelClick($event)">{{ item.value }}</label>
          </div>
        </div>
        <div [attr.id]="(item.labelOverride || item.tag)" data-parent="#accordion">
          <div class="card-body enhanced-sub-card" [ngClass]="{ paddingUnset: !item.subItems }" *ngIf="item.subItems?.length > 0">
            <app-sub-item
              [subItems]="item.subItems"
              [selectedItems]="selectedItems"
              [subItemTitle]="item.subItemTitle"
              (subSelectionChanged)="subSelectionChange($event)"
            ></app-sub-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
