import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DropdownItem } from '../../_models/dropdown';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownInputComponent implements OnInit {
  @Input() items: DropdownItem[];
  @Input() selectedItem: DropdownItem;
  @Input() showBullet: boolean;
  @Input() bulletColor: string;
  @Input() disabled: boolean;
  @Input() cancelMinWidth: boolean;
  @Input() autoWidth: boolean;
  @Output() selectionChanged: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  constructor(public translator: PortalTranslatorService) {}

  ngOnInit() {}

  selectItem(item: DropdownItem) {
    this.selectedItem = item;
    this.selectionChanged.emit(item);
  }
}
