import { Component, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatePickerDirective } from 'ng2-date-picker';
import { DirectionEnum } from 'src/app/modules/voc/_models/direction-enum';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateInputComponent implements OnChanges {
  @Input() dateFormat: string;
  @Input() calendarFormat: string;
  @Input() date: Date;
  @Input() disabled: boolean;
  @Input() min: Date;
  @Input() max: Date;
  @Input() opens: DirectionEnum;
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @ViewChild('dateDirectivePicker') dateDirectivePicker: DatePickerDirective;

  public dateModel: string;
  public validDate: Date;
  public datePickerConfig: any;
  public dateEmpty = false;

  constructor() {
    this.datePickerConfig = {
      weekDayFormat: 'dd',
      drops: 'down'
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && changes.date.currentValue) {
      if (changes.date.previousValue && changes.date.currentValue.getTime() === changes.date.previousValue.getTime()) {
        // Prevent an infinite loop with reports.component
        return;
      }

      if (isNaN(changes.date.currentValue)) {
        this.date = changes.date.previousValue;
      }
      this.updateConfig();
      this.setDateModel(this.date);
    } else if (changes.min && changes.min.currentValue) {
      this.updateConfig();
    } else if (changes.max && changes.max.currentValue) {
      this.updateConfig();
    } else if (changes.dateFormat) {
      this.updateConfig();
      this.setDateModel(this.date);
    }
  }

  updateConfig() {
    this.datePickerConfig = {
      weekDayFormat: this.datePickerConfig.weekDayFormat,
      drops: this.datePickerConfig.drops,
      format: this.dateFormat,
      opens: this.opens,
      min: this.min,
      max: this.max
    };
  }

  setDateModel(date: Date) {
    if (date) {
      switch (this.dateFormat) {
        case 'DD/MM/YYYY':
          this.dateModel = `${this.getFormattedDay(date.getDate())}/${this.dayFormat(date.getMonth() + 1)}/${date.getFullYear()}`;
          break;
        case 'YYYY-MM-DD':
          this.dateModel = `${date.getFullYear()}-${this.dayFormat(date.getMonth() + 1)}-${this.getFormattedDay(date.getDate())}`;
          break;
        case 'YYYY-MM':
          this.dateModel = `${date.getFullYear()}-${this.dayFormat(date.getMonth() + 1)}`;
          break;
        default:
          this.dateModel = `${this.dayFormat(date.getMonth() + 1)}/${date.getFullYear()}`;
          break;
      }
    }

    if (this.calendarFormat === 'day' && this.dateDirectivePicker) {
      this.dateDirectivePicker.api.moveCalendarTo(this.dateModel);
    }
  }

  getFormattedDay(day: any): string {
    const tempDay = `0${day}`;

    return tempDay.substr(tempDay.length - 2, 2);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async verifyDate() {
    if (!this.dateModel || this.dateModel === '') {
      this.dateEmpty = true;
      await this.delay(300);
      this.setDateModel(this.validDate);
      this.emitDateChange(this.dateModel);
      this.dateEmpty = false;
    }
  }

  emitDateChange(value: string) {
    if (!value) {
      this.dateModel = null;
      return;
    }

    let shouldEmit = false;

    switch (this.dateFormat) {
      case 'DD/MM/YYYY':
        if (value.length === 10) {
          this.validDate = new Date(parseInt(value.substr(6), 0), parseInt(value.substr(3, 2), 0) - 1, parseInt(value.substr(0, 2), 0));
          shouldEmit = true;
        }
        break;
      case 'YYYY-MM-DD':
        if (value.length === 10) {
          this.validDate = new Date(parseInt(value.substr(0, 4), 0), parseInt(value.substr(5, 2), 0) - 1, parseInt(value.substr(8), 0));
          shouldEmit = true;
        }
        break;
      case 'YYYY-MM':
        if (value.length === 7) {
          this.validDate = new Date(parseInt(value.substr(0, 4), 0), parseInt(value.substr(5, 2), 0) - 1);
          shouldEmit = true;
        }
        break;
      default:
        if (value.length === 7) {
          this.validDate = new Date(parseInt(value.substr(3), 0), parseInt(value.substr(0, 2), 0) - 1, 1);
          shouldEmit = true;
        }

        break;
    }

    if (shouldEmit) {
      const minDate = this.min ? new Date(this.min) : null;
      const maxDate = this.max ? new Date(this.max) : null;

      if (minDate && this.validDate < minDate) {
        this.date = this.validDate = minDate;
        this.setDateModel(minDate);
      } else if (maxDate && this.validDate > maxDate) {
        this.date = this.validDate = maxDate;
        this.setDateModel(maxDate);
      }

      this.dateChange.emit(this.validDate);
    }
  }

  cleanText(event: any) {
    let shouldClean = false;
    let currentDate: Date;

    switch (this.dateFormat) {
      case 'DD/MM/YYYY':
        if (this.dateModel.length === 10) {
          shouldClean = true;
          currentDate = new Date(parseInt(this.dateModel.substr(6), 0), parseInt(this.dateModel.substr(3, 2), 0) - 1, parseInt(this.dateModel.substr(0, 2), 0));
        }
        break;
      case 'YYYY-MM-DD':
        if (this.dateModel.length === 10) {
          shouldClean = true;
          currentDate = new Date(parseInt(this.dateModel.substr(0, 4), 0), parseInt(this.dateModel.substr(5, 2), 0) - 1, parseInt(this.dateModel.substr(8), 0));
        }
        break;
      case 'YYYY-MM':
        if (this.dateModel.length === 7) {
          currentDate = new Date(parseInt(this.dateModel.substr(0, 4), 0), parseInt(this.dateModel.substr(5, 2), 0) - 1);
          shouldClean = true;
        }
        break;
      default:
        if (this.dateModel.length === 7) {
          currentDate = new Date(parseInt(this.dateModel.substr(3), 0), parseInt(this.dateModel.substr(0, 2), 0) - 1, 1);
          shouldClean = true;
        }
        break;
    }

    if (shouldClean) {
      const minDate = this.min ? new Date(this.min) : null;
      const maxDate = this.max ? new Date(this.max) : null;

      if (minDate && currentDate < minDate) {
        this.date = minDate;
        this.setDateModel(minDate);
      } else if (maxDate && currentDate > maxDate) {
        this.date = maxDate;
        this.setDateModel(maxDate);
      }
    }
  }

  dayFormat(day: number): string {
    if (day.toString().length === 1) {
      const zeroAdded = `0${day.toString()}`;

      return zeroAdded;
    }

    return day.toString();
  }

  pad(num: number, size: number) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
}
