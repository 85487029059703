import { string } from '@amcharts/amcharts4/core';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { DataPoint, Line } from 'src/app/portal-api.g';
import { SocialMedia } from 'src/app/_models/branch';
import { XYChartSerie, PieChartValue, BarValue } from './chart-serie';
import { CompetitorInfo } from './competitors';

export class DashboardWidgetConfig {
  $type?: string;
  title?: string;
  titleHelp?: string;
  additionalInfo?: string;
  showDetailsLink?: boolean;
}

export class LineChartWidget {
  $type?: string;
  labelsAlignment?: LabelsAlignment;
  series: XYChartSerie[];
  colors?: string[];
  totalComments?: any;
  maxValue?: number;
  minValue?: number;
  strictMinMax?: boolean;
  numberFormat?: string;
}

export class DistributionGaugeWidget {
  $type?: string;
  series: PieChartValue[];
  colors?: string[];
  middleValue: string;
  middleValueUnit?: string;
  variance: string;
  varianceUnit: string;
  varianceLabel: string;
  location?: string;
  surveyCount?: number;
}

export class PercentageGaugeWidget {
  $type?: string;
  series?: any;
  serie: PieChartValue;
  color?: string;
  middleValue: string;
  middleValueUnit?: string;
  middleAbsoluteValue: string;
  totalComments?: any;
  variance: string;
  varianceUnit: string;
  varianceLabel: string;
  midValueFontSize?: number;
}

export class CompetitorsWidget {
  $type?: string;
  clientName: string;
  competitors: CompetitorInfo[];
}

export class OversightWidget {
  $type?: string;
  tabs: OversightWidgetTabDto[];
  isEnabled: boolean;
}

export class MultiCardWidget {
  $type?: string;
  description: string;
  cards: CardInfo[];
  abandonnedPercentual: number;
  abandonnedPercentualRange: number;
}

export class SocialMediaPageStatsWidget {
  $type?: string;
}

export class SocialMediaClickRankWidget {
  $type?: string;
  socialMediaClickCounts: SocialMediaClickCount[];
}

export class SocialMediaClickCount {
  socialMedia: SocialMedia;
  count: number;
  rate: number;
}

export class EmailsSummaryWidget {
  $type?: string;
  sendInProgessCard: CardInfo;
  sentEmailsCard: CardInfo;
  deliveredCount: number;
  deliveredShare: number;
  deliveredVariation: number;
  openedCount: number;
  openedShare: number;
  openedVariation: number;
  clickedCount: number;
  clickedShare: number;
  clickedVariation: number;
  bounceCount: number;
  bounceShare: number;
  unsubscribeCount: number;
  unsubscribeShare: number;
  spamCount: number;
  spamShare: number;
  otherCount: number;
  otherShare: number;
}

export class DailyBreakdownWidget {
  $type?: string;
  groupType: GroupType;
  dailyBreakdown: DailyBreakdown;
}

export class DailyBreakdown {
  $type: string;
  completedSurveysCount: number;
  fridayCompletedCount: number;
  fridayCount: number;
  mondayCompletedCount: number;
  mondayCount: number;
  saturdayCompletedCount: number;
  saturdayCount: number;
  sundayCompletedCount: number;
  sundayCount: number;
  thursdayCompletedCount: number;
  thursdayCount: number;
  totalCount: number;
  tuesdayCompletedCount: number;
  tuesdayCount: number;
  wednesdayCompletedCount: number;
  wednesdayCount: number;
}

export class HourlyBreakdownWidget {
  $type?: string;
  groupType: GroupType;
  hourlyBreakdown: HourlyBreakdown[];
  series: Line[];
}

export class HourlyBreakdown {
  $type: string;
  completedSurveysCount: number;
  time: number;
  totalCount: number;
}

export enum GroupType {
  Count,
  Average,
}

export class CardInfo {
  number: number;
  label: string;
  subtitle: string;
  icon: CardIcon;
}

export enum CardIcon {
  EmailsClicked,
  CompletedSurveys,
  AbandonnedSurvey,
  SendingInProgress,
  EmailsSent,
  PageDisplayed,
  SocialLinksClicked,
}

export class OversightWidgetTabDto {
  title: string;
  header?: string;

  footer?: string;
  hasData: boolean;

  content?: OversightWidgetTabContentDto[];
}

export class OversightWidgetTabContentDto {
  icon?: string;
  title?: string;
  text?: string;
  textSuffix?: string;
  value?: string | number;
  valueSuffix?: string;
  footerText?: string;
}

export class HorizontalBarsWidget {
  $type?: string;
  colors: string[];
  series: BarValue[];
  totalComments?: any;
}

export class MultiBarsWidget {
  $type?: string;
  colors: string[];
  series: MultiBarWidgetSerie[];
}

export class MultiBarWidgetSerie {
  serie: BarValue[];
}

export class AlertWidgetWrapper {
  widget: AlertsSummaryWidget;

  toSolve(): number {
    return this.widget.toSolve;
  }

  pendingTotal(): number {
    return this.widget.pendingTotal;
  }

  treatedTotal(): number {
    return this.widget.treatedTotal;
  }

  total(): number {
    return this.pendingTotal() + this.treatedTotal();
  }

  hasData(): boolean {
    if (this.widget.opened === null || this.widget.opened === undefined) {
      if (this.widget.onHold === null || this.widget.onHold === undefined) {
        if (this.widget.toRead === null || this.widget.toRead === undefined) {
          if (this.widget.solved === null || this.widget.solved === undefined) {
            if (this.widget.read === null || this.widget.read === undefined) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }
}

export class AlertsSummaryWidget {
  $type?: string;
  pendingTotal: number;
  treatedTotal: number;
  toSolve: number;
  solved: number;
  opened: number;
  onHold: number;
  toRead: number;
  read: number;

  pendingTimeToOpen: number;
  pendingTimeToContact: number;
  treatedTimeToOpen: number;
  treatedTimeToSolve: number;
}

export enum LabelsAlignment {
  left,
  right,
}

export enum WidgetType {
  LineChart = 0,
  DistributionGauge,
  PercentageGauge,
  HBars,
  AlertsSummary,
  Competitors,
  Oversight,
  Multicard,
  EmailsSummary,
  StatisticsDailyBreakdown,
  StatisticsHourlyBreakdown,
  StatisticsSocialMediaRanking,
  StatisticsSocialMediaPage,
}
