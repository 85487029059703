import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SocialMedia } from 'src/app/_models/branch';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { SocialMediaClickCount, SocialMediaClickRankWidget } from '../../../_models/dashboard-widget';

@Component({
  selector: 'app-social-media-click-ranking-widget',
  templateUrl: './social-media-click-ranking-widget.component.html',
  styleUrls: ['./social-media-click-ranking-widget.component.scss'],
})
export class SocialMediaClickRankWidgetComponent implements OnInit, OnChanges {
  @Input() config: SocialMediaClickRankWidget;

  public socialMediaOptions = SocialMedia;
  public socialMediaResults: SocialMediaClickCount[] = [];

  constructor(public translator: PortalTranslatorService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const configChange = changes.config;

    if (configChange && configChange.currentValue) {
      this.socialMediaResults = configChange.currentValue.socialMediaClickCounts;
    }
  }

  ngOnInit() {}

  public getSocialMediaIcon(type: SocialMedia): string {
    switch (type) {
      case SocialMedia.GoogleReviews:
        return 'google';
      case SocialMedia.TripAdvisor:
        return 'tripadvisor';
      case SocialMedia.BingReviews:
        return 'bing';
      case SocialMedia.Facebook:
        return 'facebook';
      case SocialMedia.FourSquare:
        return 'foursquare';
      case SocialMedia.Yelp:
        return 'yelp';
      default:
        return SocialMedia[type].toString();
    }
  }
}
