import { Injectable } from '@angular/core';
import { PortalEvents } from 'src/app/_events/portal.events';
import { NavigationOption, MobileNavigationOption } from 'src/app/_models/navigationOption';
import { bindNodeCallback, Subject } from 'rxjs';
import { summaryFileName } from '@angular/compiler/src/aot/util';

@Injectable({
  providedIn: 'root',
})
export class PortalEventsBase {
  constructor(protected portalEvents: PortalEvents) {}

  get subscriptionServiceCancellationToken(): Subject<unknown> {
    return this.portalEvents.subscriptionServiceCancellationToken;
  }

  get isMobile(): boolean {
    return this.portalEvents.isMobile;
  }

  set subMenuVisible(value: boolean) {
    this.portalEvents.subMenuVisible.value = value;
  }

  set subMenu(subMenu: NavigationOption[]) {
    this.portalEvents.subMenu.value = subMenu;
  }

  set mobileSubMenu(mobile: MobileNavigationOption[]) {
    this.portalEvents.mobileSubMenu.value = mobile;
  }

  set identifiedPopoverMenu(list: { id: string; menu: NavigationOption; }[]) {
    let nav: NavigationOption[] = [];
    for (var i = 0; i < list.length; i++) {
      nav.push(list[i].menu);
    }

    this.portalEvents.idPopoverMenu.value = list;
    this.portalEvents.popoverMenu.value = nav;
  }

  set popoverMenu(menu: NavigationOption[]) {
    this.portalEvents.popoverMenu.value = menu;
  }

  selectSubMenu(subMenu: NavigationOption | MobileNavigationOption) {
    this.portalEvents.selectedSubMenu.value = subMenu;
  }

  selectPopoverMenu(menu: NavigationOption) {
    this.portalEvents.selectedPopoverMenu.value = menu;
  }

  selectPopoverMenuById(id: string) {
    const list = this.portalEvents.idPopoverMenu.value;
    if (list) {
      const target = list.find(elem => elem.id === id);
      this.selectPopoverMenu(target.menu);
    }
  }
}
