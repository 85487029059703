import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CardIcon, CardInfo } from 'src/app/modules/portal/_models/dashboard-widget';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-card-component',
  templateUrl: './card-component.component.html',
  styleUrls: ['./card-component.component.scss'],
})
export class CardComponentComponent implements OnInit, OnChanges {
  @Input() info: CardInfo;

  public iconSrc = '';

  constructor(public translator: PortalTranslatorService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const infoChange = changes.info;

    if (infoChange && infoChange.currentValue) {
      switch (infoChange.currentValue.icon) {
        case CardIcon.EmailsClicked:
          this.iconSrc = '/assets/images/icons/widgets/emails_clicked.svg';
          break;
        case CardIcon.AbandonnedSurvey:
          this.iconSrc = '/assets/images/icons/widgets/abandonned_surveys.svg';
          break;
        case CardIcon.CompletedSurveys:
          this.iconSrc = '/assets/images/icons/widgets/completed_surveys.svg';
          break;
        case CardIcon.EmailsSent:
          this.iconSrc = '/assets/images/icons/widgets/emails_sent.svg';
          break;
        case CardIcon.PageDisplayed:
          this.iconSrc = '/assets/images/icons/widgets/page_displayed.svg';
          break;
        case CardIcon.SendingInProgress:
          this.iconSrc = '/assets/images/icons/widgets/sending_in_progress.svg';
          break;
        case CardIcon.SocialLinksClicked:
          this.iconSrc = '/assets/images/icons/widgets/social_links_clicked.svg';
          break;
      }
    }
  }

  ngOnInit() {}
}
