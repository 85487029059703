<div class="content">
  <div class="text-content">
    <div class="permissions">
      {{ translator.translate('individual-permissions') | async }}: {{ userInfo.permission ? (translator.translate('yes') | async) : (translator.translate('no') | async) }}

      <div class="group" *ngIf="userInfo?.groups && userInfo?.groups[0]">{{ userInfo.groups[0] }}</div>

      <div class="group" *ngIf="userInfo?.groups && userInfo?.groups?.length > 1">{{ userInfo.groups[1] }}</div>
      
      <div class="group" *ngIf="userInfo?.groups && userInfo?.groups?.length === 3">{{ userInfo.groups[2] }}</div>

      <div class="group thistooltip" *ngIf="userInfo?.groups && userInfo?.groups?.length > 3">
        {{ this.translator.translate('and-more-groups') | async }}
        <div class="tooltiptext">
            <ng-container *ngFor="let group of userInfo?.groups; let i = index">
                <div class="group" *ngIf="i > 1">
                    {{ group }}
                </div>
            </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
