import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { TableColumns, TableValueTypeEnum, TableRow, Alignment } from 'src/app/_models/table.model';
import { Router } from '@angular/router';
import { DashboardFilterService } from '../../voc/_services/dashboard.filter.service';
import { AlertStatusType } from '../../voc/_models/alert-detail-model';
import { AlertTypeEnum } from '../../voc/_models/alert-detail-model';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { PortalEvents } from 'src/app/_events/portal.events';
import { DatePipe } from '@angular/common';
import { SortEvent } from './header/_models/sort-event.model';
import { UrlService } from 'src/app/_services/url.service';
import { VocService } from '../../voc/_services/voc.service';

@Component({
  selector: 'app-hexalib-table',
  templateUrl: './hexalib-table.component.html',
  styleUrls: ['./hexalib-table.component.scss'],
})
export class HexalibTableComponent implements OnInit, AfterViewInit {
  TableValueTypeEnum = TableValueTypeEnum;
  Alignment = Alignment;
  Date = Date;

  @Input() tableColumns: TableColumns[];
  @Input() tableRows: TableRow[];
  @Input() stickyTableHeader = false;
  @Input() headerTop = false;
  @Output() alertClicked = new EventEmitter<string>();
  @Output() pdfClicked = new EventEmitter<any>();
  @Output() buttonClicked = new EventEmitter<any>();
  @Output() pdfCustomClicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter<string>();
  @Output() sort = new EventEmitter<SortEvent>();
  @Output() drillDowned = new EventEmitter<any>();

  public alertStatusType = AlertStatusType;
  public alerttype = AlertTypeEnum;
  public disableReports = false;

  constructor(
    private filtering: DashboardFilterService,
    public translator: PortalTranslatorService,
    protected portalEvents: PortalEvents,
    protected datePipe: DatePipe,
    protected urlService: UrlService,
    protected router: Router,
    protected vocService: VocService
  ) {}

  ngOnInit() {
    this.disableReports = JSON.parse(localStorage.getItem('disableReports'));
    this.portalEvents.onPdfDownloaded.subscribe((id) => {
      this.togglePdfSpinner(id, false);
    });
  }

  ngAfterViewInit() {
    const menuSticky = document.getElementById('subMenuSticky');
    if (menuSticky && this.stickyTableHeader) {
      const tableHeaderSticky = document.querySelectorAll('.sticky-header');
      tableHeaderSticky.forEach((th) => th.setAttribute('style', 'top:' + menuSticky.clientHeight + 'px'));
    }
  }

  private isString(val) {
    return typeof val === 'string';
  }

  alertClick(id: string) {
    this.alertClicked.emit(id);
  }

  pdfClick(surveyAnswerId: string, projectId: string) {
    this.pdfClicked.emit({ surveyAnswerId, projectId });
  }

  buttonClick(object: any) {
    this.buttonClicked.emit({ object });
  }

  deleteClick(id: string) {
    this.deleteClicked.emit(id);
  }

  pdfCustomClick(id: number) {
    this.togglePdfSpinner(id, true);
    this.pdfCustomClicked.emit(id);
  }

  togglePdfSpinner(id: number, showSpinner: boolean) {
    const spinnerContainer = document.getElementById(id.toString());
    const pdfButton = document.getElementById('p-' + id.toString());

    if (showSpinner) {
      spinnerContainer.classList.add('show');
      pdfButton.classList.add('hide');
    } else {
      spinnerContainer.classList.remove('show');
      pdfButton.classList.remove('hide');
    }
  }

  navigateToLocale(params: any) {
    this.drillDowned.emit(params);
  }

  getGrade(grade: number): string {
    if (grade == -1) {
      return '';
    } else if (grade == 0) {
      return 'grade-0';
    } else if (grade == 1) {
      return 'grade-1';
    } else {
      return 'grade-2';
    }
  }

  getStatusText(value: any): string {
    const status = value.state as AlertStatusType;
    const type = value.type as AlertTypeEnum;

    switch (status) {
      case AlertStatusType.Cancelled: {
        return 'alert_cancelled';
      }
      case AlertStatusType.Forwarded: {
        return 'alert_forwarded';
      }
      case AlertStatusType.OnHold: {
        return 'alert_on_hold';
      }
      case AlertStatusType.Opened: {
        return 'alert_opened';
      }
      case AlertStatusType.Resolved: {
        return 'alert_solved';
      }
      case AlertStatusType.Unopened: {
        return type === AlertTypeEnum.ToBeRead ? 'alert_to_read' : 'alert_to_solve';
      }
      case AlertStatusType.Read: {
        return 'alert_was_read';
      }
      case AlertStatusType.Closed: {
        return 'alert_closed';
      }
    }
  }

  expand(id: string) {
    const rows = document.querySelectorAll(`.collapsed-${id}`);
    const img = document.getElementById(id);

    if (rows[0].classList.contains('visible')) {
      img.classList.remove('rotate-accordion');
      rows.forEach((row) => row.classList.remove('visible'));
    } else {
      img.classList.add('rotate-accordion');
      rows.forEach((row) => row.classList.add('visible'));
    }
  }

  sortClicked(event: SortEvent) {
    this.sort.emit(event);
  }

  navigateToAnswer(id: string) {
    this.router.navigate(['/dev-admin', this.portalEvents.subscription.value.id, 'survey-answers', id]);
  }

  public openPdf(surveyAnswerId: string, projectId: string, fileName: string) {
    this.vocService.getSurveyAnswerPdf(surveyAnswerId, projectId).then(
      (pdf) => {
        const blob = new Blob([pdf], { type: 'application/pdf' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = data;
        fileName = fileName.replace(' ', '_');
        link.download = `${fileName}.pdf`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
