import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganizationDto } from '../_models/organization.model';
import { SubscriptionDto } from '../_models/subscription.model';
import { AddressDto } from '../_models/address';
import { SubscriptionServiceDto, SubscriptionService } from '../_models/subscription-services.model';
import { BranchDto, BranchCulturePayload, BranchSocialMedia } from '../_models/branch';
import { ConfigService } from './config.service';
import { ServiceDto } from '../_models/service.model';
import { environment } from 'src/environments/environment';
import { RoleAssignmentSubjectDto } from '../_models/role-assignment-subject.model';
import { RoleAssignment, RoleAssignmentDto, RoleAssignmentCardDto, GroupedRoleAssignment } from '../_models/role-assigment.model';
import { ObjectType } from '../_models/objectType.enum';
import { AutoCompleteItem } from '../_models/autocomplete-item';
import { BranchGroupDto } from '../_models/branch-group-dto';
import { ConsoleLoggerService } from './console-logger.service';
import { ApiServiceBase } from './api-service-base';
import { BranchServiceDto } from '../_models/branch-service-dto';
import { AuthenticationProvider } from '../_models/single-sign-on';
import { EmptyGuid } from '../_helpers/guid';
import { UrlService } from './url.service';
import { UserAlertNotificationSettings } from '../_models/user-alert-notification-settings';
import { EntityLogo } from '../modules/portal/_models/logos.model';

export enum SchemaType {
  address = 'address',
  organization = 'organization',
  subscription = 'subscription',
  service = 'service',
  branch = 'branch',
  subscriptionService = 'subscriptionservice',
}

@Injectable({
  providedIn: 'root',
})
export class PortalService extends ApiServiceBase {
  emptyGuid = '00000000-0000-0000-0000-000000000000';

  constructor(protected urlService: UrlService, protected http: HttpClient, protected configService: ConfigService, protected consoleLogger: ConsoleLoggerService) {
    super(urlService, configService, consoleLogger, configService.config.api.modules.core.path);
  }

  exportBranches(subscriptionId: string): Promise<Blob> {
    const url = this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.exportEndpoint, environment.branchesEndPoint);

    return this.http.post(url, null, { responseType: 'blob' }).toPromise();
  }

  exportSubscription(subscriptionId: string): Promise<Blob> {
    const url = this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.exportEndpoint);

    return this.http.post(url, null, { responseType: 'blob' }).toPromise();
  }

  exportSubscriptionPermissions(subscriptionId: string, type?: string): Promise<Blob> {
    let url = '';
    if (type) {
      url = this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.exportEndpoint, 'permissions', type);
    } else {
      url = this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.exportEndpoint, 'permissions');
    }

    return this.http.post(url, null, { responseType: 'blob' }).toPromise();
  }

  importSubscription(organizationId: string, data: string): Promise<boolean> {
    const url = this.getUrl(environment.organizationEndPoint, organizationId, environment.subscriptionsEndPoint, environment.importEndpoint);

    return this.http.post<boolean>(url, JSON.parse(data)).toPromise();
  }

  public getAssemblyVersion() {
    const url = this.getUrl(environment.assemblyVersionEndPoint);

    return this.http.get(url, { responseType: 'text' });
  }

  public getCulturesHierarchy(): Promise<OrganizationDto[]> {
    return this.http.get<OrganizationDto[]>(this.getUrl(environment.culturesHierarchyEndPoint, 'hierarchy')).toPromise();
  }

  public saveCultureHierarchy(body: OrganizationDto[]): Promise<any> {
    return this.http.post(this.getUrl(environment.culturesHierarchyEndPoint), body).toPromise();
  }

  public getOrganization(id: string): Observable<OrganizationDto> {
    return this.http.get<OrganizationDto>(this.getUrl(environment.organizationEndPoint, id));
  }

  public getOrganizations(lang: string = null): Observable<OrganizationDto[]> {
    if (lang == null) {
      return this.http.get<OrganizationDto[]>(this.getUrl(environment.organizationEndPoint, 'get'));
    }
    return this.http.get<OrganizationDto[]>(this.getUrl(environment.organizationEndPoint, 'get', lang));
  }

  public addOrganization(body: any): Observable<any> {
    return this.http.post(this.getUrl(environment.organizationEndPoint), body);
  }

  public editOrganization(body: any): Observable<any> {
    return this.http.put(this.getUrl(environment.organizationEndPoint), body);
  }

  public deleteOrganization(id: string) {
    return this.http.delete<SubscriptionServiceDto[]>(this.getUrl(environment.organizationEndPoint, id));
  }

  public getOrganizationLogos(id: string): Observable<EntityLogo[]> {
    return this.http.get<EntityLogo[]>(this.getUrl(environment.organizationEndPoint, id, 'logos'));
  }

  public saveOrganizationLogo(id: string, lang: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('logo', file, file.name);

    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json',
      }),
    };

    console.log('File Save - ContentType');

    if (lang && lang !== 'default') {
      return this.http.put(this.getUrl(environment.organizationEndPoint, id, 'logo', lang), formData, httpOptions);
    } else {
      return this.http.put(this.getUrl(environment.organizationEndPoint, id, 'logo'), formData, httpOptions);
    }
  }

  public deleteOrganizationLogo(id: string, lang: string): Promise<any> {
    if (lang && lang !== 'default') {
      return this.http.delete(this.getUrl(environment.organizationEndPoint, id, 'logo', lang)).toPromise();
    } else {
      return this.http.delete(this.getUrl(environment.organizationEndPoint, id, 'logo')).toPromise();
    }
  }

  public getSubscriptions(): Observable<SubscriptionDto[]> {
    return this.http.get<SubscriptionDto[]>(this.getUrl(environment.subscriptionsEndPoint));
  }

  public addSubscription(body: any): Observable<any> {
    return this.http.post(this.getUrl(environment.subscriptionsEndPoint), body);
  }

  public editSubscription(body: any): Observable<any> {
    return this.http.put(this.getUrl(environment.subscriptionsEndPoint), body);
  }

  public deleteSubscription(id: string): Observable<SubscriptionDto[]> {
    return this.http.delete<SubscriptionDto[]>(this.getUrl(environment.subscriptionsEndPoint, id));
  }

  public saveSubscriptionLogo(id: string, lang: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('logo', file, file.name);

    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json',
      }),
    };

    if (lang && lang !== 'default') {
      return this.http.put(this.getUrl(environment.subscriptionsEndPoint, id, 'logo', lang), formData, httpOptions);
    } else {
      return this.http.put(this.getUrl(environment.subscriptionsEndPoint, id, 'logo'), formData, httpOptions);
    }
  }

  public deleteSubscriptionLogo(id: string, lang: string): Promise<any> {
    if (lang && lang !== 'default') {
      return this.http.delete(this.getUrl(environment.subscriptionsEndPoint, id, 'logo', lang)).toPromise();
    } else {
      return this.http.delete(this.getUrl(environment.subscriptionsEndPoint, id, 'logo')).toPromise();
    }
  }

  public addAddresse(body: any): Observable<any> {
    return this.http.post(this.getUrl(environment.addressesEndPoint), body);
  }

  public editAddresse(body: any): Observable<any> {
    return this.http.put(this.getUrl(environment.addressesEndPoint), body);
  }

  public getAddresses(): Observable<AddressDto[]> {
    return this.http.get<AddressDto[]>(this.getUrl(environment.addressesEndPoint));
  }

  public deleteAddresse(id: string): Observable<AddressDto[]> {
    return this.http.delete<AddressDto[]>(this.getUrl(environment.addressesEndPoint, id));
  }

  public getSubscriptionService(id: string): Observable<SubscriptionService> {
    return this.http.get<SubscriptionService>(this.getUrl(environment.subscriptionServicesEndPoint, id));
  }

  public getSubscriptionServices(): Observable<SubscriptionService[]> {
    return this.http.get<SubscriptionService[]>(this.getUrl(environment.subscriptionServicesEndPoint));
  }

  public addSubscriptionService(body: any): Observable<any> {
    return this.http.post(this.getUrl(environment.subscriptionServicesEndPoint), body);
  }

  public editSubscriptionService(body: any): Observable<any> {
    return this.http.put(this.getUrl(environment.subscriptionServicesEndPoint), body);
  }

  public deleteSubscriptionService(id: string): Observable<SubscriptionServiceDto[]> {
    return this.http.delete<SubscriptionServiceDto[]>(this.getUrl(environment.subscriptionServicesEndPoint, id));
  }

  public getBranches(): Observable<BranchDto[]> {
    return this.http.get<BranchDto[]>(this.getUrl(environment.branchesEndPoint));
  }

  public addBranch(body: BranchDto): Promise<any> {
    return this.http
      .post(this.getUrl(environment.branchesEndPoint), {
        name: body.name,
        subscriptionId: body.subscriptionId,
        addressId: body.addressId,
        code: body.code,
        enabled: body.enabled,
        address: body.address
          ? {
              address: body.address.address,
              country: body.address.country,
              department: body.address.department,
              locality: body.address.locality,
              city: body.address.city,
              postalCode: body.address.postalCode,
            }
          : null,
      })
      .toPromise();
  }

  public editBranch(body: any): Promise<any> {
    // No easy fix to serialize geocoordinate. Value is re-computed on Branch edit.
    delete body.geocoordinate;

    return this.http.put(this.getUrl(environment.branchesEndPoint), body).toPromise();
  }

  public deleteBranchLogo(branchId: string): Promise<any> {
    return this.http.delete(this.getUrl(environment.branchesEndPoint, branchId, 'logo')).toPromise();
  }

  public upsertBranchLogoToBranchGroup(branchId: string, file: File): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('logo', file, file.name);

    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json',
      }),
    };

    return this.http.put(this.getUrl(environment.branchesEndPoint, branchId, 'logo', 'branch-group'), formData, httpOptions).toPromise();
  }

  public upsertBranchLogo(branchId: string, file: File): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('logo', file, file.name);

    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json',
      }),
    };

    return this.http.put(this.getUrl(environment.branchesEndPoint, branchId, 'logo'), formData, httpOptions).toPromise();
  }

  public deleteBranchCulture(branchId: string, cultureCode: string): Promise<any> {
    return this.http.delete(this.getUrl(environment.branchesEndPoint, branchId, environment.cultureEndPoint, cultureCode)).toPromise();
  }

  public upsertBranchCulture(branchId: string, payload: BranchCulturePayload): Promise<any> {
    const formData: FormData = new FormData();

    if (payload.logo) {
      formData.append('logo', payload.logo, payload.logo.name);
    }

    if (payload.cultureCode) {
      formData.append('cultureCode', payload.cultureCode);
    }

    if (payload.name) {
      formData.append('name', payload.name);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json',
      }),
    };

    return this.http.put(this.getUrl(environment.branchesEndPoint, branchId, environment.cultureEndPoint), formData, httpOptions).toPromise();
  }

  public upsertBranchCultureToBranchGroup(branchId: string, payload: BranchCulturePayload): Promise<any> {
    const formData: FormData = new FormData();

    if (payload.logo) {
      formData.append('logo', payload.logo, payload.logo.name);
    }

    if (payload.cultureCode) {
      formData.append('cultureCode', payload.cultureCode);
    }

    if (payload.name) {
      formData.append('name', payload.name);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json',
      }),
    };

    return this.http.put(this.getUrl(environment.branchesEndPoint, branchId, environment.cultureEndPoint, 'branch-group'), formData, httpOptions).toPromise();
  }

  public getBranchLogos(id: string): Promise<EntityLogo[]> {
    return this.http.get<EntityLogo[]>(this.getUrl(environment.branchesEndPoint, id, 'logos')).toPromise();
  }

  public deleteBranch(id: string): Promise<BranchDto[]> {
    return this.http.delete<BranchDto[]>(this.getUrl(environment.branchesEndPoint, id)).toPromise();
  }

  public getBranchGroups(): Promise<BranchGroupDto[]> {
    return this.http.get<BranchGroupDto[]>(this.getUrl(environment.branchGroupsEndPoint)).toPromise();
  }

  public getBranch(id: string): Promise<BranchDto> {
    return this.http.get<BranchDto>(this.getUrl(environment.branchesEndPoint, id)).toPromise();
  }

  public getBranchSocialMedia(id: string): Promise<BranchSocialMedia[]> {
    return this.http.get<BranchSocialMedia[]>(this.getUrl(environment.branchesEndPoint, id, '/socialmedia')).toPromise();
  }

  public getBranchGroup(id: string): Promise<BranchGroupDto> {
    return this.http.get<BranchGroupDto>(this.getUrl(environment.branchGroupsEndPoint, id)).toPromise();
  }

  public getBranchService(id: string): Promise<BranchServiceDto> {
    return this.http.get<BranchServiceDto>(this.getUrl(environment.branchServicesEndPoint, id)).toPromise();
  }

  public getSubAddresses(subscriptionId: string): Promise<AddressDto[]> {
    return this.http.get<AddressDto[]>(this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.addressesEndPoint)).toPromise();
  }

  public getSubBranchesFiltered(subscriptionId: string): Promise<BranchDto[]> {
    return this.http.get<BranchDto[]>(this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.usersEndPoint, environment.branchesEndPoint)).toPromise();
  }

  public getSubBranches(subscriptionId: string): Promise<BranchDto[]> {
    return this.http.get<BranchDto[]>(this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.branchesEndPoint)).toPromise();
  }

  public getSubBranchGroups(subscriptionId: string): Promise<BranchGroupDto[]> {
    return this.http.get<BranchGroupDto[]>(this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.branchGroupsEndPoint)).toPromise();
  }

  public getSubBranchGroupsFull(subscriptionId: string): Promise<BranchGroupDto[]> {
    return this.http.get<BranchGroupDto[]>(this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.branchGroupsFullEndPoint)).toPromise();
  }

  public getSubBranchServices(subscriptionId: string): Promise<BranchServiceDto[]> {
    return this.http.get<BranchServiceDto[]>(this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.branchServicesEndPoint)).toPromise();
  }

  public addBranchGroup(body: BranchGroupDto): Promise<any> {
    return this.http
      .post(this.getUrl(environment.branchGroupsEndPoint), {
        name: body.name,
        subscriptionId: body.subscriptionId,
      })
      .toPromise();
  }

  public editBranchGroup(body: any): Promise<any> {
    return this.http.put(this.getUrl(environment.branchGroupsEndPoint), body).toPromise();
  }

  public deleteBranchGroup(id: string): Promise<BranchGroupDto[]> {
    return this.http.delete<BranchGroupDto[]>(this.getUrl(environment.branchGroupsEndPoint, id)).toPromise();
  }

  public linkBranchToBranchGroup(branchId: string, branchGroupId: string): Promise<boolean> {
    return this.http.post<boolean>(this.getUrl(environment.branchGroupsEndPoint, branchGroupId, environment.branchesEndPoint, branchId), null).toPromise();
  }

  public unlinkBranchToBranchGroup(branchId: string, branchGroupId: string): Promise<boolean> {
    return this.http.delete<boolean>(this.getUrl(environment.branchGroupsEndPoint, branchGroupId, environment.branchesEndPoint, branchId)).toPromise();
  }

  public addBranchService(body: BranchServiceDto): Promise<any> {
    return this.http
      .post(this.getUrl(environment.branchServicesEndPoint), {
        name: body.name,
        shortName: body.shortName,
        code: body.code,
        subscriptionId: body.subscriptionId,
        cultures: body.cultures,
      })
      .toPromise();
  }

  public editBranchService(body: any): Promise<any> {
    return this.http.put(this.getUrl(environment.branchServicesEndPoint), body).toPromise();
  }

  public deleteBranchService(id: string): Promise<BranchServiceDto[]> {
    return this.http.delete<BranchServiceDto[]>(this.getUrl(environment.branchServicesEndPoint, id)).toPromise();
  }

  public linkBranchToBranchService(branchId: string, branchServiceId: string): Promise<boolean> {
    return this.http.post<boolean>(this.getUrl(environment.branchServicesEndPoint, branchServiceId, environment.branchesEndPoint, branchId), null).toPromise();
  }

  public unlinkBranchToBranchService(branchId: string, branchServiceId: string): Promise<boolean> {
    return this.http.delete<boolean>(this.getUrl(environment.branchServicesEndPoint, branchServiceId, environment.branchesEndPoint, branchId)).toPromise();
  }

  public getSchema(type: SchemaType) {
    return this.http.get(this.getUrl(environment.schemaEndPoint.concat('?type=', type)));
  }

  public activateRoleAssignment(): Promise<any> {
    return this.http.post(this.getUrl(environment.roleAssignmentActivateEndpoint), null).toPromise();
  }

  public activeRoleAssignmentDeprecate(id: string): Observable<any> {
    return this.http.get(this.getUrl(environment.roleAssignmentsEndPoint.concat('/Activate/', id)));
  }

  public getRoleAssignments(subject: RoleAssignmentSubjectDto): Observable<RoleAssignment[]> {
    return this.http.get<RoleAssignment[]>(this.getUrl(environment.roleAssignmentsEndPoint.concat('/', ObjectType[subject.type.toString()].toString(), '/', subject.id)));
  }

  public getRoleAssignmentsBySubjectId(organizationId: string, subject?: RoleAssignmentSubjectDto): Observable<GroupedRoleAssignment[]> {
    if (subject) {
      return this.http.get<GroupedRoleAssignment[]>(this.getUrl(environment.roleAssignmentsEndPoint, 'SubjectPath', organizationId, subject.subjectPath));
    } else {
      return this.http.get<GroupedRoleAssignment[]>(this.getUrl(environment.roleAssignmentsEndPoint, 'SubjectPath', organizationId));
    }
  }

  public getFullRelatedRoleAssignments(roleId: string): Promise<RoleAssignment[]> {
    return this.http.get<RoleAssignment[]>(this.getUrl(environment.roleAssignmentsEndPoint.concat('/RelatedRoles/', roleId, '/full'))).toPromise();
  }

  public getRelatedRoleAssignments(roleId: string): Promise<GroupedRoleAssignment[]> {
    return this.http.get<GroupedRoleAssignment[]>(this.getUrl(environment.roleAssignmentsEndPoint.concat('/RelatedRoles/', roleId))).toPromise();
  }

  public deleteRelatedRoleAssignment(referencePath: string, userEmail: string, role: string): Observable<any> {
    if (referencePath) {
      return this.http.delete<any>(this.getUrl(environment.roleAssignmentsEndPoint, 'RelatedRoles', userEmail, role, referencePath));
    } else {
      return this.http.delete<any>(this.getUrl(environment.roleAssignmentsEndPoint, 'RelatedRoles', userEmail, role));
    }
  }

  public replaceRoleAssignments(items: RoleAssignment[], communicationLanguage: string): Promise<any> {
    return this.http.post(this.getUrl(environment.roleAssignmentsEndPoint).concat('?communicationLanguage=', communicationLanguage), items).toPromise();
  }

  public updateRoleAssignments(items: RoleAssignment[], communicationLanguage: string): Promise<any> {
    return this.http.patch(this.getUrl(environment.roleAssignmentsEndPoint).concat('?communicationLanguage='), items).toPromise();
  }

  public updateBranchesSocialMedia(id: string, items: BranchSocialMedia[]): Promise<void> {
    return this.http.put<void>(this.getUrl(environment.branchesEndPoint, id, '/socialmedia'), items).toPromise();
  }

  public getRoleAssignment(id: string): Observable<any> {
    return this.http.get<RoleAssignment>(this.getUrl(environment.roleAssignmentsEndPoint, id));
  }

  public getRoleAssignmentsAutocomplete(value: string, subject: RoleAssignmentSubjectDto): Observable<AutoCompleteItem[]> {
    return this.http.get<AutoCompleteItem[]>(this.getUrl(environment.roleAssignmentsEndPoint.concat('/PossibleUsers/', subject.subjectPath, '/', subject.id, '/', value)));
  }

  public deleteRoleAssignment(id: string): Observable<RoleAssignmentDto[]> {
    return this.http.delete<RoleAssignmentDto[]>(this.getUrl(environment.roleAssignmentsEndPoint, id));
  }

  public editService(body: any): Observable<any> {
    return this.http.put(this.getUrl(environment.servicesEndPoint), body);
  }

  public deleteService(id: string): Observable<ServiceDto[]> {
    return this.http.delete<ServiceDto[]>(this.getUrl(environment.servicesEndPoint, id));
  }

  public getSingleSignOns(organizationId: string): Promise<AuthenticationProvider[]> {
    const url = this.getUrl(environment.singleSignOnEndPoint, organizationId);
    return this.http.get<AuthenticationProvider[]>(url).toPromise();
  }

  public getSingleSignOn(singleSignOnId: string): Promise<AuthenticationProvider> {
    const url = this.getUrl(environment.singleSignOnEndPoint, 'details', singleSignOnId);
    return this.http.get<AuthenticationProvider>(url).toPromise();
  }

  public saveSingleSignOn(singleSignOn: AuthenticationProvider): Promise<boolean> {
    const url = this.getUrl(environment.singleSignOnEndPoint);

    if (singleSignOn.id === 'new') {
      singleSignOn.id = this.emptyGuid;
    }

    return this.http.post<boolean>(url, singleSignOn).toPromise();
  }

  public deleteSingleSignOn(id: string): Promise<any> {
    return this.http.delete<any>(this.getUrl(environment.singleSignOnEndPoint, id)).toPromise();
  }

  public getUserAlertNotificationSettings(userAlertNotificationSetttingId: string): Promise<UserAlertNotificationSettings[]> {
    const url = this.getUrl(environment.userAlertNotificationSettingsEndPoint, environment.userIdEndPoint, userAlertNotificationSetttingId);
    return this.http.get<UserAlertNotificationSettings[]>(url).toPromise();
  }

  public addNotificationSubscription(userAlertNotificationSettings: UserAlertNotificationSettings): Promise<any> {
    return this.http.post(this.getUrl(environment.userAlertNotificationSettingsEndPoint), userAlertNotificationSettings).toPromise();
  }

  public updateNotificationSubscription(userAlertNotificationSettings: UserAlertNotificationSettings): Promise<any> {
    return this.http.put(this.getUrl(environment.userAlertNotificationSettingsEndPoint), userAlertNotificationSettings).toPromise();
  }

  public deleteNotificationSubscription(userAlertNotificationSetttingId: string) {
    return this.http.delete<UserAlertNotificationSettings>(this.getUrl(environment.userAlertNotificationSettingsEndPoint, userAlertNotificationSetttingId));
  }

  public importBranches(subscriptionId: string, result: string | ArrayBuffer) {
    const url = this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.csvEndpoint, environment.branchesEndPoint);

    const text = typeof result === 'string' ? result : result.toString();
    return this.http.post<boolean>(url, text).toPromise();
  }

  public importUsers(subscriptionId: string, result: string | ArrayBuffer) {
    const url = this.getUrl(environment.subscriptionsEndPoint, subscriptionId, environment.csvEndpoint, environment.usersEndPoint);

    const text = typeof result === 'string' ? result : String.fromCharCode.apply(null, new Uint16Array(result));
    return this.http.post<boolean>(url, text).toPromise();
  }
}
