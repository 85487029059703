import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';
import { PortalEvents } from 'src/app/_events/portal.events';
import { ProjectOptionModel } from '../_models/project-option-model';
import { Project } from '../_models/project';
import { ValueObserver } from 'src/app/_base/value.observer';
import { ResultsState } from '../_models/results';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalEventsBase } from 'src/app/_events/portal.events.base';
import { environment } from 'src/environments/environment';
import { AuthIds4Service } from 'src/app/_services/authids4.service';
import { FilterModelState } from 'src/app/modules/voc/_models/filter.model';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UserBookmarkSection } from '../_models/bookmark';

@Injectable({
  providedIn: 'root',
})
export class VocEvents extends PortalEventsBase {
  private initializeFilter: boolean;
  private replaceUrl = false;
  private loadEmptyState = false;
  public projectCancellationToken = new Subject<boolean>();

  public language = this.portalEvents.language;
  public organization = this.portalEvents.organization;
  public subscription = this.portalEvents.subscription;

  public get subscriptionServiceId(): ValueObserver<string> {
    return this.portalEvents.subscriptionServiceId;
  }

  public languageChangeToHandle = false;

  public projects: ValueObserver<Project[]> = new ValueObserver<Project[]>();
  public project: ValueObserver<Project> = new ValueObserver<Project>();
  public projectOptions: ValueObserver<ProjectOptionModel[]> = new ValueObserver<ProjectOptionModel[]>();
  public resultsState: ValueObserver<ResultsState> = new ValueObserver<ResultsState>();

  public isDemoMode: boolean;
  public showDemoModeBanner: boolean;

  public projectLoaded: ValueObserver<boolean> = new ValueObserver();

  public useDashboardFilters = false;
  public loadedFilterStateDashboard = false;
  public loadedFilterStateDetail = false;

  private def: { start: NgbDate; end: NgbDate } = this.defaultTimePeriod();
  public dashboardSelectedFilters: FilterModelState = {
    branches: [],
    customFilters: [],
    questionFilters: [],
    timePeriod: { startDate: this.def.start, endDate: this.def.end },
    alertsOnly: false,
    timePeriodSelectedDropDownIndex: null,
  };

  public detailsSelectedFilters: FilterModelState = {
    branches: [],
    customFilters: [],
    questionFilters: [],
    timePeriod: { startDate: this.def.start, endDate: this.def.end },
    alertsOnly: false,
    timePeriodSelectedDropDownIndex: null,
    advancedFilter: null,
  };

  constructor(
    protected portalEvents: PortalEvents,
    private logger: ConsoleLoggerService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public calendar: NgbCalendar,
    private authService: AuthIds4Service
  ) {
    super(portalEvents);

    this.subscriptionServiceId.listen(() => {
      this.project.value = null;
      this.loadEmptyState = true;
    });

    this.language.listen(() => {
      this.languageChangeToHandle = true;
    });

    this.project.listen((p) => {
      this.renewProjectCancellationToken();
      this.loadedFilterStateDashboard = false;
      this.loadedFilterStateDetail = false;
    });
  }

  public replaceUrlOnNextChange() {
    this.replaceUrl = true;
  }

  private defaultTimePeriod(): { start: NgbDate; end: NgbDate } {
    var start = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
    var end = this.calendar.getToday();

    return {
      start: start,
      end: end,
    };
  }

  getLastChild(route: ActivatedRoute): ActivatedRoute {
    return route.firstChild ? this.getLastChild(route.firstChild) : route;
  }

  adjustBrowserHistory(state: ResultsState) {
    const options64 = encodeURIComponent(btoa(JSON.stringify(state)));
    if (options64 !== this.route.snapshot.queryParamMap.get('filterOptions')) {
      const url = this.router.createUrlTree([], { relativeTo: this.getLastChild(this.route), queryParams: { filterOptions: state ? options64 : undefined } }).toString();

      if (this.router.url !== url) {
        if (this.initializeFilter || this.replaceUrl) {
          this.location.replaceState(`${url}`);
          this.replaceUrl = false;
        } else {
          // There is a bug with location.go. See: https://stackoverflow.com/questions/58880962/after-a-location-go-history-back-does-not-trigger-activatedroute-parammap
          this.location.go(`${url}`);
          // this.router.navigateByUrl(url);
        }
      }
    }
  }

  public renewProjectCancellationToken() {
    this.logger.info('Renew project cancellation token', 'Voc Events');
    this.projectCancellationToken.next(true);
    this.projectCancellationToken.complete();
    this.projectCancellationToken = new Subject();
  }

  public get isAdmin(): Promise<boolean> {
    return this.authService.getLoggedUserFromCache().then((user) => {
      let isDev = false;
      if (user !== null) {
        const loggedUserDto = user.getUserDto();
        isDev = loggedUserDto.hasRole(environment.roleDeveloper);
      }

      return Promise.resolve(isDev);
    });
  }

  public saveDashboardFilterState(projectId: string, userBookmarkSection: UserBookmarkSection): void {
    if (this.subscriptionServiceId.value) {
      sessionStorage.setItem(`dashboardSelectedFilters-${userBookmarkSection}-${projectId}`, JSON.stringify(this.dashboardSelectedFilters));
      this.loadedFilterStateDashboard = true;
    }
  }
  public loadDashboardFilterState(projectId: string, userBookmarkSection: UserBookmarkSection): void {
    this.loadedFilterStateDashboard = true;
    let savedFilter: FilterModelState;
    try {
      savedFilter = JSON.parse(sessionStorage.getItem(`dashboardSelectedFilters-${userBookmarkSection}-${projectId}`)) as FilterModelState;
      if (!savedFilter) {
        this.loadedFilterStateDashboard = false;
      }
    } catch {
      savedFilter = null;
      this.loadedFilterStateDashboard = false;
    }

    this.dashboardSelectedFilters = this.prepareFilterModelState(savedFilter);
  }

  public saveDetailFilterState(projectId: string): void {
    if (this.subscriptionServiceId.value) {
      sessionStorage.setItem(`detailSelectedFilters-${projectId}`, JSON.stringify(this.detailsSelectedFilters));
      this.loadedFilterStateDetail = true;
    }
  }

  public loadDetailFilterState(projectId: string): void {
    this.loadedFilterStateDetail = true;
    let savedFilter: FilterModelState;
    try {
      savedFilter = JSON.parse(sessionStorage.getItem(`detailSelectedFilters-${projectId}`)) as FilterModelState;
      if (!savedFilter) {
        this.loadedFilterStateDetail = false;
      }
    } catch {
      savedFilter = null;
      this.loadedFilterStateDetail = false;
    }

    this.detailsSelectedFilters = this.prepareFilterModelState(savedFilter);
  }

  private prepareFilterModelState(model: any): FilterModelState {
    const newModel = {
      branches: [],
      customFilters: [],
      questionFilters: [],
      timePeriod: { startDate: this.def.start, endDate: this.def.end },
      selectedTimePeriodItem: { key: null, value: null },
      alertsOnly: false,
      timePeriodSelectedDropDownIndex: null,
      advancedFilter: null,
      selectedLocationType: null,
    };

    let keys = Object.keys(newModel);

    for (let i = 0; i < keys.length; i++) {
      let property = keys[i];
      try {
        let savedValue = model[property];
        switch (property) {
          case 'timePeriod':
            if (model.timePeriod.startDate) {
              newModel.timePeriod.startDate = new NgbDate(model.timePeriod.startDate.year, model.timePeriod.startDate.month, model.timePeriod.startDate.day);
            }

            if (model.timePeriod.endDate) {
              newModel.timePeriod.endDate = new NgbDate(model.timePeriod.endDate.year, model.timePeriod.endDate.month, model.timePeriod.endDate.day);
            }
            break;
          case 'branches':
          case 'customFilters':
          case 'questionFilters':
            if (Array.isArray(savedValue)) {
              newModel[property] = savedValue;
            }
            break;
          case 'alertsOnly':
            if (typeof savedValue === 'boolean' && !!savedValue) {
              newModel[property] = savedValue;
            }
            break;
          case 'selectedTimePeriodItem':
          case 'advancedFilter':
            if (typeof savedValue === 'object' && !!savedValue) {
              newModel[property] = savedValue;
            }
            break;
          case 'timePeriodSelectedDropDownIndex':
            if (typeof savedValue === 'number') {
              newModel[property] = savedValue;
            }
            break;
          default:
            if (savedValue) {
              newModel[property] = savedValue;
            }
            break;
        }
      } catch {}
    }

    return newModel;
  }
}
