import 'src/app/_helpers/user-helper';
import 'src/app/_helpers/userdto-helper';
import { Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthIds4Service } from '../_services/authids4.service';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { User } from 'oidc-client';
import { TopMenuBreadCrumb } from '../modules/voc/_models/top-menu-bread-crumb';
import { NavigationMenu, SubscriptionMenu, SubscriptionServiceMenu } from '../modules/voc/_models/navigation-menu';
import { PortalService } from '../_services/portal.service';
import { ConfigService } from '../_services/config.service';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { PortalEvents } from '../_events/portal.events';
import { NavigationOption, MobileNavigationOption } from '../_models/navigationOption';
import { environment } from 'src/environments/environment';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ContactTracingService } from '../modules/contact-tracing/_services/contact-tracing.service';
import { OrganizationDto, SignUpClient, SubscriptionDto, SubscriptionServiceDto, UserClient } from '../portal-api.g';
import { VocService } from '../modules/voc/_services/voc.service';
import { UserSettingsService } from '../modules/admin/_services/user-settings.service';

declare var $: JQueryStatic;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class TopBarComponent implements OnInit, OnDestroy {
  @Output() displayNavigationMenu = new EventEmitter<boolean>();

  $: any;
  private readonly loggerFrom: string = 'TopBar';
  public isLogged: boolean;
  public isDev: boolean;
  public user: User;
  public autoSelectService: boolean;
  public organizations: OrganizationDto[];
  public topMenuBreadCrumb: TopMenuBreadCrumb = new TopMenuBreadCrumb();
  public navigationMenus: NavigationMenu[] = [];
  public navigationMenu = false;

  public scrolledToTop: boolean;
  public showAccountPopover: boolean;
  public showOptionsPopover: boolean;

  public showingPopup: boolean;

  public authorized: boolean;

  public adminArea: boolean;
  public settingsClicked = false;

  private langChangeSubscription: Subscription;
  private subscriptionIdChangeSubscription: Subscription;

  private unsubscribeList: Subscription[] = [];
  protected userName: '';
  constructor(
    private signupClient: SignUpClient,
    private userClient: UserClient,
    public authIds4Service: AuthIds4Service,
    private logger: ConsoleLoggerService,
    private portalService: PortalService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    public portalEvents: PortalEvents,
    private zone: NgZone,
    public translator: PortalTranslatorService,
    private changeDetect: ChangeDetectorRef,
    private location: Location,
    private contactTracing: ContactTracingService,
    private vocService: VocService,
    private eRef: ElementRef,
    private userSettings: UserSettingsService
  ) {
    this.adminArea = false;
    this.scrolledToTop = true;
    this.autoSelectService = false;
    this.isLogged = false;

    this.showAccountPopover = false;
    this.showOptionsPopover = false;

    this.langChangeSubscription = null;
    this.organizations = [];

    this.showingPopup = false;

    // Looking the route to decide if loads the first service or not
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.logger.warning('Route:', this.loggerFrom, event);

        // Looking for the CT registration

        if (event.url === '/' || event.url === '/home' || event.urlAfterRedirects === '/') {
          this.autoSelectService = true;

          if (this.user) {
            this.logger.info('User loaded:', this.loggerFrom, this.user);

            this.loadUserService();
          }
        }

        if (event.url.startsWith('/admin')) {
          this.adminArea = true;
        } else {
          this.adminArea = false;
        }

        if (event.url.startsWith('/admin/userSettings')) {
          this.settingsClicked = true;
        } else {
          this.settingsClicked = false;
        }
      }
    });

    this.portalEvents.setShowSpinner(true);

    this.authIds4Service.userLoadedEvent.subscribe(
      (user) => {
        if (user && !user.expired) {
          this.initUser(user);
        }
      },
      (error) => {
        this.logger.error(error, this.loggerFrom);
      },
      () => {
        this.portalEvents.setShowSpinner(false);
      }
    );

    // Listening to subscription change from the components
    this.unsubscribeList.push(
      this.portalEvents.subscriptionServiceId.listen((subscriptionServiceId) => {
        this.selectServiceTree(subscriptionServiceId);
      })
    );
  }

  ngOnInit() {
    this.portalEvents.onOrganizationTreeChanged.subscribe(() => {
      this.loadOrganizationTree(true);
    });
    // Leaving this comment, because maybe we'll roll back this change
    // this.zone.runOutsideAngular(() => {
    //   window.addEventListener('scroll', this.scrollHandler);
    // });
  }

  onClick(event) {
    const account = document.getElementById('account');
    if (!account.contains(event.target)) {
      this.showAccountPopover = false;
    }
  }

  loadOrganizationTree(override?: boolean) {
    if (!this.langChangeSubscription || override) {
      if (this.langChangeSubscription) {
        this.langChangeSubscription.unsubscribe();
        this.subscriptionIdChangeSubscription && this.subscriptionIdChangeSubscription.unsubscribe();
      }

      this.langChangeSubscription = this.portalEvents.language.listen((lang) => {
        this.portalEvents.setShowSpinner(true);

        this.userClient
          .getUserMenu()
          .subscribe((vm) => {
            this.navigationMenus = [];
            this.organizations = vm.organizations;

            if (this.organizations) {
              this.organizations.forEach(async (organization) => {
                const subscriptions: SubscriptionMenu[] = [];

                this.navigationMenus.push({
                  organizationId: organization.id,
                  organizationName: organization.displayName,
                  subscriptions,
                  adminPermission: false || this.isDev,
                });

                for (const subscription of organization.subscriptions) {
                  const subscriptionServiceMenu: SubscriptionServiceMenu[] = [];

                  for (const subscriptionService of subscription.subscriptionServices) {
                    subscriptionServiceMenu.push({
                      id: subscriptionService.id,
                      name: subscriptionService.displayName,
                      serviceType: this.portalEvents.getSubscriptionServiceType(subscriptionService.serviceId),
                      projects: [],
                    });
                  }

                  const subscriptionMenu = {
                    id: subscription.id,
                    name: subscription.name,
                    subscriptionServices: subscriptionServiceMenu,
                    adminPermission: false || this.isDev,
                  };

                  subscriptions.push(subscriptionMenu);
                }
              });
            }

            if (this.subscriptionIdChangeSubscription) {
              this.subscriptionIdChangeSubscription.unsubscribe();
            }

            this.subscriptionIdChangeSubscription = this.portalEvents.subscriptionServiceId.listen((subscriptionServiceId) => {
              if (subscriptionServiceId) {
                this.selectServiceTree(subscriptionServiceId);
              } else if (this.portalEvents.organization.value) {
                const orgId = this.portalEvents.organization.value.id;
                this.portalEvents.organization.value = this.organizations.find((org) => org.id === orgId);

                if (this.portalEvents.subscription.value) {
                  const subId = this.portalEvents.subscription.value.id;
                  this.portalEvents.subscription.value = this.portalEvents.organization.value.subscriptions.find((sub) => sub.id === subId);

                  if (this.portalEvents.subscriptionService.value) {
                    const serviceId = this.portalEvents.subscriptionService.value.id;
                    this.portalEvents.subscriptionService.value = this.portalEvents.subscription.value.subscriptionServices.find((serv) => serv.id === serviceId);
                    this.portalEvents.serviceId.value = this.portalEvents.subscriptionService.value.serviceId;
                  }
                }
              } else {
                this.loadUserService();
              }
            });

            if (!this.changeDetect['destroyed']) {
              this.changeDetect.detectChanges();
            }
          }, null)
          .add(() => {
            this.portalEvents.setShowSpinner(false);
          });
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeList.forEach((item) => {
      item.unsubscribe();
    });

    // window.removeEventListener('scroll', this.scrollHandler);
    $('#popoverMenu-dropdown').remove('show.bs.dropdown');
    $('#popoverMenu-dropdown').remove('hide.bs.dropdown');
  }

  loadUserService(): void {
    if (this.organizations.length === 0) {
    } else {
      if (!this.portalEvents.organization.value) {
        this.autoSelectService = true;
      }

      this.loadDefaultService();
    }
  }

  private loadDefaultService(): void {
    if (this.autoSelectService) {
      this.portalEvents.organization.value = this.organizations[0];

      if (this.portalEvents.organization.value && this.portalEvents.organization.value.subscriptions && this.portalEvents.organization.value.subscriptions.length) {
        this.portalEvents.subscription.value = this.portalEvents.organization.value.subscriptions[0];
        this.selectFirstService();
      }

      this.autoSelectService = false;
    }
  }

  public getLogoUrl(): string {
    switch (this.configService.config.Environment) {
      case 'SIT':
        return '/assets/images/logos/logo_sit.svg';
      case 'UAT':
        return '/assets/images/logos/logo_uat.svg';
      case 'Production':
        return '/assets/images/logos/logo_prod.svg';
      default:
        return '/assets/images/logos/logo_dev.svg';
    }
  }

  selectServiceTree(subscriptionServiceId: string) {
    for (const org of this.organizations) {
      if (org.subscriptions && org.subscriptions.length) {
        const subscription = org.subscriptions.find((s) => s.subscriptionServices.some((ss) => ss.id === subscriptionServiceId));
        if (subscription) {
          const service = subscription.subscriptionServices.find((ss) => ss.id === subscriptionServiceId);
          subscription.subscriptionServices = subscription.subscriptionServices;
          this.portalEvents.organization.value = org;
          this.portalEvents.subscription.value = subscription;
          this.portalEvents.subscriptionService.value = service;
          break;
        }
      }
    }
  }

  selectFirstService() {
    if (this.portalEvents.subscription.value && this.portalEvents.subscription.value.subscriptionServices && this.portalEvents.subscription.value.subscriptionServices.length) {
      this.navigateToService(this.portalEvents.subscription.value.subscriptionServices[0]);
    } else {
      this.settingsClicked = false;
      this.router.navigate(['home']);
    }
  }

  navigateToService(service: SubscriptionServiceDto) {
    let chain = Promise.resolve();

    this.portalEvents.subscriptionService.value = service;

    let navigationOption: NavigationOption = {
      text: 'home',
      params: ['home'],
      options: { relativeTo: this.route },
    };

    switch (this.portalEvents.subscriptionService.value.serviceId) {
      case this.configService.serviceIds.stmDashboard:
        navigationOption = {
          text: 'stm',
          params: ['stm'],
        };

        break;
      case this.configService.serviceIds.voiceOfCustomer:
        chain = chain.then(() => {
          return this.vocService.getProjects(this.portalEvents.subscriptionService.value.id, this.portalEvents.language.value).then((projects) => {
            if (projects && projects.length) {
              navigationOption = {
                text: 'voc',
                params: ['voc', this.portalEvents.subscriptionService.value.id, 'project', projects[0].id, 'results'],
              };
            } else {
              navigationOption = {
                text: 'voc',
                params: ['voc', this.portalEvents.subscriptionService.value.id],
              };
            }

            return Promise.resolve();
          });
        });

        break;

      case this.configService.serviceIds.mysteryShopper:
        navigationOption = {
          text: 'mysteryshopper',
          params: ['mysteryshopper', this.portalEvents.subscriptionService.value.id, 'report'],
        };

        break;

      case this.configService.serviceIds.socialListening:
        this.portalEvents.subscriptionServiceId.value = service.id; // TODO: Temporary Fix

        navigationOption = {
          text: 'sociallistening',
          params: ['sociallistening', this.portalEvents.subscriptionService.value.id, 'results'],
        };

        break;

      case this.configService.serviceIds.contactTracing:
        navigationOption = {
          text: 'contacttracing',
          params: ['contacttracing', this.portalEvents.subscriptionService.value.id, 'patrons'],
        };

        break;

      default: throw `The service type ${this.portalEvents.subscriptionService.value.serviceId} is not supported.`;
    }

    if (this.portalEvents.subMenu.value && this.portalEvents.subMenu.value.length) {
      this.portalEvents.selectedSubMenu.value = this.portalEvents.subMenu.value[0];
    }

    this.settingsClicked = false;
    chain.then(() => {
      this.router.navigate(navigationOption.prependParams ? navigationOption.prependParams.concat(navigationOption.params) : navigationOption.params, navigationOption.options);
    });
  }

  hasPopoverMenu(subscriptionService: SubscriptionServiceDto): boolean {
    if (this.portalEvents.subscriptionService.value) {
      let isCurrent = subscriptionService === this.portalEvents.subscriptionService.value;
      let hasPopover = this.portalEvents.popoverMenu.value && this.portalEvents.popoverMenu.value.length > 0;

      if (subscriptionService) {
        switch (subscriptionService.serviceId) {
          case this.configService.serviceIds.voiceOfCustomer:
            return isCurrent && hasPopover;

          case this.configService.serviceIds.stmDashboard:
          case this.configService.serviceIds.mysteryShopper:
          case this.configService.serviceIds.contactTracing:
            return false;
        }
      }
    }
    return false;
  }

  login() {
    this.authIds4Service.loginIds4();
  }

  logout() {
    // localStorage.removeItem(`detailSelectedFilters-${this.portalEvents.subscriptionService.value.id}`);
    // localStorage.removeItem(`dashboardSelectedFilters-${this.portalEvents.subscriptionService.value.id}`);

    localStorage.clear();

    this.authIds4Service.logoutIds4();
  }

  goToAdmin() {
    this.settingsClicked = false;
    this.managePopoverClick();
    this.router.navigate(['admin', 'roles']);
  }

  toggleAccountPopover(e: any) {
    // Preventing to trigger the window.click
    e.stopPropagation();
    e.preventDefault();

    if (!this.scrolledToTop) {
      return;
    }

    this.managePopoverClick();
  }

  toggleNavigationMenu(e: any): void {
    // Preventing to trigger the window.click
    e.stopPropagation();
    e.preventDefault();

    this.navigationMenu = !this.navigationMenu;
    this.displayNavigationMenu.emit(this.navigationMenu);
  }

  managePopoverClick(): void {
    this.navigationMenu = false;

    this.showAccountPopover = !this.showAccountPopover;
    if (this.showAccountPopover) {
      this.showOptionsPopover = false;
    }

    this.displayNavigationMenu.emit(this.navigationMenu);
  }

  toggleOptionsPopover(e: any) {
    // Preventing to trigger the window.click
    e.stopPropagation();
    e.preventDefault();

    if (!this.scrolledToTop) {
      return;
    }

    this.showOptionsPopover = !this.showOptionsPopover;
    if (this.showOptionsPopover) {
      this.showAccountPopover = false;
    }
  }

  organizationChanged(item: OrganizationDto) {
    this.portalEvents.organization.value = item;
    this.showOptionsPopover = false;
  }

  subscriptionChanged(item: SubscriptionDto) {
    this.portalEvents.subscription.value = item;
    this.selectFirstService();
    this.showOptionsPopover = false;
  }

  topMenuBreadCrumbChanged(topMenuBreadCrumb: TopMenuBreadCrumb) {
    this.topMenuBreadCrumb = topMenuBreadCrumb;
  }

  navigateTo(subMenu: NavigationOption | MobileNavigationOption) {
    this.settingsClicked = false;
    this.portalEvents.selectedSubMenu.value = subMenu;
    this.router.navigate(subMenu.prependParams ? subMenu.prependParams.concat(subMenu.params) : subMenu.params, subMenu.options);
  }

  popoverNavigateTo(menu: NavigationOption) {
    this.settingsClicked = false;
    this.portalEvents.selectedPopoverMenu.value = menu;
    this.router.navigate(menu.prependParams ? menu.prependParams.concat(menu.params) : menu.params, menu.options);
  }

  settingsNavigateTo() {
    this.settingsClicked = true;
    this.managePopoverClick();
    this.router.navigate(['/admin/userSettings']);
  }

  settingsNavigateBack() {
    this.settingsClicked = false;
    if (window.history.length > 1) {
      this.location.back();
      //setTimeout(() => window.location.reload(), 50);
    } else {
      this.router.navigate(['/']);
    }
  }

  getIcon(item: MobileNavigationOption) {
    if (item === this.portalEvents.selectedSubMenu.value) {
      return item.icon.replace('{0}', '_active');
    }
    return item.icon.replace('{0}', '');
  }

  public getDisplayName(name: string, isShortName: boolean): string {
    if (name && name.indexOf('[guid]') > -1) {
      if (isShortName) {
        return '[Incomplet]';
      } else {
        return '[Enregistrement incomplet]';
      }
    }

    return name;
  }

  private initUser(user: User) {
    this.user = user;
    this.isDev = this.user.getUserDto().hasRole(environment.roleDeveloper);
    this.isLogged = true;

    const userDto = this.user.getUserDto();

    // If the username is null or empty, needs to load the default language
    if (!this.authIds4Service.userName) {
      this.translator.changeLanguage(userDto.culture);
    }

    this.authIds4Service.userName = userDto.name;

    let ctEnabled = false;

    if (typeof this.user.profile.ct_enabled === 'boolean') {
      ctEnabled = this.user.profile.ct_enabled;
    } else {
      var str = '' + this.user.profile.ct_enabled;
      ctEnabled = str.trim().toLowerCase() === 'true';
    }

    if (this.user && ctEnabled) {
      this.logger.info('Create a branch for user:', this.loggerFrom, this.user);
      this.location.replaceState('/');

      this.contactTracing
        .addProfile()
        .then((id) => {
          // Show spinner
          this.portalEvents.setShowSpinner(true);

          this.loadOrganizationTree(true);
          this.router.navigate(['/contacttracing', id, 'info']);
        })
        .finally(() => this.portalEvents.setShowSpinner(false));
    } else {
      this.signupClient.getUserOrganizationOwnerHasSurveys().subscribe((vm) => {
        if (vm.requiresRedirectToSignup) {
          window.location.href = this.configService.config.signup.baseUrl;
        } else {
          this.loadOrganizationTree(false);
        }
      });
    }

    this.userClient.getUserSubscriptionServiceType().subscribe((vm) => {
      this.portalEvents.userType.value = vm;
    });
  }

  dropDownShow = (): void => {
    this.zone.run(() => {
      $('.top-bar').addClass('bring-to-top');
    });
  };

  dropDownHide = (): void => {
    this.zone.run(() => {
      $('.top-bar').removeClass('bring-to-top');
    });
  };
}
