<div class="upgrade-widget-container">
  <div class="saas-to-pro" *ngIf="this.showSaasToPro">
    <div>{{ translator.translate('need_more_power') | async }}</div>
    <ul>
      <li>{{ translator.translate('You have several point of service? you_have_several_point_of_service') | async }}</li>
      <li>{{ translator.translate('you_need_more_customization') | async }}</li>
      <li>{{ translator.translate('you_want_to_deploy_a_multichannel_strategy_to_get_closer_to_your_customers_everywhere_they_are') | async }}</li>
    </ul>
    <a>{{ translator.translate('find_out_how_hexa_pro_can_help_you') | async }}</a>
  </div>

  <div class="ct-to-saas" *ngIf="this.showCtToSaas">
    <h4>{{ translator.translate('implement_a_satisfaction_survey') | async }}</h4>
    <p>{{ translator.translate('start_gathering_high_quality') | async }}</p>
    <div class="btn btn-primary ct-to-saas-button" (click)="this.convertCtToSaas()">{{ translator.translate('start_free_trial_now') | async }}</div>
  </div>

  <div class="saas-to-ct" *ngIf="this.showSaasToCt">
    <div class="title"> {{ translator.translate('register_your_bar_or_restaurant') | async }} <span class="bold"> {{ translator.translate('for_free') | async }} </span> {{ translator.translate('and') | async }}</div>
    <button #saasToCt [disabled]="(this.isLoading | async)" class="btn btn-primary saas-to-ct-button" (click)="this.convertSaasToCt()">{{ translator.translate('just_stay_open') | async }}</button>

    <div class="facilitate-tracing-in-the-event-of-a-covid19-outbreak"><span class="bold">{{ translator.translate('facilitate_tracing') | async }}</span> {{ translator.translate('in-the-event-of-a-COVID-19-outbreak') | async }}</div>
    <a target="_blank" href="{{this.ctUrl}}">{{ translator.translate('click_here_for_more_info') | async}}</a>
  </div>

  <div class="saasfree-to-saaspro" *ngIf="this.showSaasFreeToPro">
    <div class="trial-expiration">
      <h4>{{ translator.translate('your_free_trial') | async }}</h4>
      <h5>{{ translator.translate('is_valid_until') | async }}</h5>
    </div>

    <div class="price-info">
      <h4>{{ translator.translate('price_per_month') | async }}</h4>
      <span>{{ translator.translate('unlimited_surveys_data_visualization_and_notifcations') | async }}</span>
      <span>{{ translator.translate('no_other_costs_no_commitment_cancel_anytime') | async }}</span>
      <div class="btn btn-primary">{{ translator.translate('upgrade_now') | async }}</div>
    </div>
  </div>
</div>
