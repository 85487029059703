<ul class="nav">
  <li class="nav-item" *ngFor="let tab of config.tabs; let i = index">
    <a class="nav-link" [ngClass]="{ active: currentTab === i }" (click)="selectFilter(i, $event)" href="#">
      <span>{{ tab.title }}</span>
    </a>
  </li>
</ul>

<div class="contents">
  <div *ngFor="let tab of config.tabs; let i = index">
    <div class="content" *ngIf="currentTab === i">
      <div class="not-enough-data-overlay" *ngIf="displayMockData(tab)">
        <div class="not-enough-data-overlay-inner">
          <div class="not-enough-data-overlay-text">{{ this.getOverlayText(tab) | async }}</div>
        </div>
      </div>

      <div class="content-inner" [ngClass]="{ 'blurry-text': displayMockData(tab) }">
        <h2 *ngIf="tab.header">{{ tab.header }}</h2>
        <div class="entry" *ngFor="let item of getTabContentOrMockData(tab)">
          <div class="icon" [ngStyle]="{ backgroundImage: 'url(./assets/images/voc/oversight-widget/' + item.icon + '.svg)' }"></div>
          <div class="info">
            <span class="title">{{ item.title }}</span>
            <div>
            <div class="description">
              <span class="prominent">{{ item.value }}</span>
              <span class="super">{{ item.valueSuffix }}&nbsp;</span>
              <span class="text">
                <span [innerHTML]="item.text"></span>
                <br *ngIf="item.textSuffix">
                <div [innerHTML]="item.textSuffix"></div>
              </span>
            </div>
            </div>
          </div>
        </div>
        <div class="footer" *ngIf="tab.footer && !displayMockData(tab)" [innerHTML]="tab.footer">
        </div>
      </div>
    </div>
  </div>
</div>
