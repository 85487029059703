import { Component, OnInit, Input, NgZone, SimpleChanges, SimpleChange, OnDestroy, OnChanges, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Color, FontWeight } from '@amcharts/amcharts4/core';
import { PieChartValue } from '../../../_models/chart-serie';
import { PercentageGaugeWidget } from '../../../_models/dashboard-widget';
import { ConsoleLoggerService } from '../../../../../_services/console-logger.service';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-percentage-gauge-widget',
  templateUrl: './percentage-gauge-widget.component.html',
  styleUrls: ['./percentage-gauge-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PercentageGaugeWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() config: PercentageGaugeWidget;
  @ViewChild('chart', { static: true }) chartElement: ElementRef;

  private readonly loggerFrom: string = 'PercentageGaugeWidget';
  private chart: am4charts.PieChart;
  public id: string = Math.floor(Math.random() * Math.floor(10000)).toString();

  // Default colors
  private chartColorClasses: am4core.Color[] = [am4core.color('#0082a4'), am4core.color('#8878b2'), am4core.color('#82cad1'), am4core.color('#97be0d'), am4core.color('#e4368a')];

  constructor(private zone: NgZone, private logger: ConsoleLoggerService, public translator: PortalTranslatorService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const configChange: SimpleChange = changes.config;

    if (configChange.currentValue) {
      if (this.config.color && configChange.currentValue.color) {
        this.chartColorClasses = [am4core.color(configChange.currentValue.color), am4core.color(am4core.colors.rgbaToRgb('#00000000'))];
      } else {
        this.chartColorClasses = [am4core.color('#63ddb0'), am4core.color(am4core.colors.rgbaToRgb('#00000000'))];
      }

      this.zone.runOutsideAngular(() => {
        this.configChart();
      });
    }
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  configChart() {
    this.disposeChart();
    this.chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);
    this.chart.numberFormatter.numberFormat = '#,##0.';

    this.chart.data = [
      this.config.serie,
      {
        key: 'none',
        value: 100 - this.config.serie.value,
      },
    ];

    this.chart.tooltip.hide();

    this.chart.radius = am4core.percent(75);
    this.chart.innerRadius = am4core.percent(70);
    this.chart.contentValign = 'top';

    const series = this.chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = 'value';
    series.dataFields.category = 'key';

    const sliceTemplate = series.slices.template;

    sliceTemplate.states.getKey('hover').properties.scale = 1;
    sliceTemplate.states.getKey('active').properties.shiftRadius = 0;
    sliceTemplate.tooltipText = '{value.value}';
    sliceTemplate.clickable = false;
    sliceTemplate.inert = true;
    sliceTemplate.alwaysShowTooltip = false;
    sliceTemplate.hideTooltip();

    series.hideTooltip();
    series.colors.list = this.chartColorClasses;
    series.labels.template.hide();
    series.ticks.template.hide();
    series.paddingBottom = 0;
    series.marginBottom = 0;

    series.hiddenState.properties.startAngle = 0;
    series.hiddenState.properties.endAngle = -90;

    this.addMiddleValueLabel(series);
    this.addMiddleAbsoluteValue(series);
    this.addVarianceLabel();

    this.chart.responsive.enabled = true;
    this.chart.responsive.useDefault = false;

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 230 && target.pixelHeight > 250) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Label && target.id === 'midValue') {
          const state = target.states.create(stateId);
          state.properties.fontSize = this.config.midValueFontSize ?? 70;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'midValueUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = this.config.midValueFontSize ? 14 : 24;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'varianceLabel') {
          const state = target.states.create(stateId);
          state.properties.fontSize = 12;

          return state;
        }
      },
    });

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 182 || target.pixelHeight <= 250) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target.pixelHeight <= 250) {
          if (target instanceof am4core.Label && target.id === 'midValue') {
            const state = target.states.create(stateId);
            state.properties.fontSize = this.config.midValueFontSize ?? 64;

            return state;
          }

          if (target instanceof am4core.Label && target.id === 'midValueUnit') {
            const state = target.states.create(stateId);
            state.properties.fontSize = this.config.midValueFontSize ? 14 : 20;

            return state;
          }
        } else {
          if (target instanceof am4core.Label && target.id === 'midValue') {
            const state = target.states.create(stateId);
            state.properties.fontSize = 64;

            return state;
          }
        }

        if (target.pixelHeight > 250) {
          if (target instanceof am4core.Label && target.id === 'varianceLabel') {
            const state = target.states.create(stateId);
            state.properties.fontSize = 12;
            state.properties.wrap = true;
            state.properties.textAlign = 'middle';
            state.properties.dy = -60;

            return state;
          }
        }
      },
    });

    this.chart.responsive.rules.push({
      relevant: (target) => {
        if (target.pixelWidth <= 135) {
          return true;
        }

        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Label && target.id === 'midValue') {
          const state = target.states.create(stateId);
          state.properties.fontSize = this.config.midValueFontSize ?? 48;

          return state;
        }

        if (target instanceof am4core.Label && target.id === 'midValueUnit') {
          const state = target.states.create(stateId);
          state.properties.fontSize = this.config.midValueFontSize ? 14 : 16;

          return state;
        }
      },
    });
  }

  private addVarianceLabel() {
    const varianceContainer = this.chart.createChild(am4core.Container);
    varianceContainer.parent = this.chart.chartAndLegendContainer;
    varianceContainer.layout = 'horizontal';
    varianceContainer.width = am4core.percent(100);
    varianceContainer.contentValign = 'top';
    varianceContainer.contentAlign = 'center';
    varianceContainer.marginBottom = 16;

    // Pulling the variance label closer to the gauge (Social listening)
    if (this.config.midValueFontSize) {
      varianceContainer.dy = -24;
    }
    const varianceValue = varianceContainer.createChild(am4core.Label);
    varianceValue.id = 'varianceValue';
    varianceValue.text = this.config.variance == 'NaN' ? 'N/A' : this.config.variance;
    varianceValue.fontSize = this.config.midValueFontSize ? 24 : 30;
    varianceValue.fontFamily = 'PoppinsSemiBold';

    const varianceUnit = varianceContainer.createChild(am4core.Label);
    varianceUnit.id = 'varianceUnit';
    varianceUnit.text = this.config.variance == 'NaN' ? '' : this.config.varianceUnit;
    varianceUnit.align = 'center';
    varianceUnit.fontSize = this.config.midValueFontSize ? 11 : 17;
    varianceUnit.fontFamily = 'Poppins';

    const varianceLabel = this.chart.createChild(am4core.Label);
    varianceLabel.id = 'varianceLabel';
    varianceLabel.align = 'center';
    varianceLabel.valign = 'bottom';
    varianceLabel.horizontalCenter = 'middle';
    varianceLabel.fontSize = 14;
    varianceLabel.fontWeight = 'normal';

    // Pulling the variance label closer to the gauge (Social listening)
    if (this.config.midValueFontSize) {
      varianceLabel.dy = -34;
    }

    this.translator.translate(this.config.varianceLabel).then((result) => {
      varianceLabel.text = result;
    });
  }

  private addMiddleValueLabel(series: am4charts.PieSeries) {
    const midValueContainer = series.createChild(am4core.Container);
    midValueContainer.layout = 'horizontal';
    midValueContainer.width = am4core.percent(100);
    midValueContainer.contentValign = 'middle';
    midValueContainer.verticalCenter = 'middle';
    midValueContainer.horizontalCenter = 'middle';
    midValueContainer.valign = 'middle';
    midValueContainer.paddingTop = 10;

    const midValue = midValueContainer.createChild(am4core.Label);
    midValue.id = 'midValue';
    midValue.text = this.config.middleValue == 'NaN' ? 'N/A' : this.config.middleValue;

    if (midValue.text === 'N/A') {
      midValue.fontSize = 55;
    } else {
      midValue.fontSize = this.config.midValueFontSize ?? 80;
    }

    midValue.fontFamily = 'PoppinsSemiBold';
    midValue.align = 'center';

    if (this.config.middleValueUnit !== undefined) {
      const midValueUnit = midValueContainer.createChild(am4core.Label);
      midValueUnit.id = 'midValueUnit';
      midValueUnit.text = this.config.middleValue == 'NaN' ? '' : this.config.middleValueUnit;
      midValueUnit.fontSize = this.config.midValueFontSize ? 14 : 30;
      midValueUnit.fontWeight = 'normal';
      midValueUnit.fontFamily = 'Poppins';
      midValueUnit.paddingTop = this.config.midValueFontSize ? 8 : 18;
    }
  }

  private addMiddleAbsoluteValue(series: am4charts.PieSeries) {
    const midAbsoluteValueContainer = series.createChild(am4core.Container);
    midAbsoluteValueContainer.layout = 'horizontal';
    midAbsoluteValueContainer.width = am4core.percent(100);
    midAbsoluteValueContainer.contentValign = 'middle';
    midAbsoluteValueContainer.verticalCenter = 'middle';
    midAbsoluteValueContainer.horizontalCenter = 'middle';
    midAbsoluteValueContainer.valign = 'middle';
    midAbsoluteValueContainer.paddingTop = 100;

    const midAbsoluteValue = midAbsoluteValueContainer.createChild(am4core.Label);
    midAbsoluteValue.id = 'midAbsoluteValue';
    midAbsoluteValue.text = this.config.middleAbsoluteValue;
    midAbsoluteValue.fontSize = 24;
    midAbsoluteValue.fontFamily = 'PoppinsSemiBold';
    midAbsoluteValue.align = 'center';
  }
}
