import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public status: string;
  public errorCode: string;

  constructor(private route: ActivatedRoute, protected location: Location, public translator: PortalTranslatorService) {}

  ngOnInit() {
    this.route.params.subscribe((observer) => {
      this.status = observer.status;

      this.translator.translate('error_404').then((msg) => {
        this.errorCode = msg.replace('404', this.status);
      });
    });
  }

  reload() {
    this.location.back();
  }
}
