import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PortalComponent } from './portal.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { RightPanelCommanderComponent } from './right-panel-commander/right-panel-commander.component';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileUploadModule } from 'node_modules/ng2-file-upload';
import { DashboardWidgetComponent } from './ui/dashboard-widget/dashboard-widget.component';
import { LinechartWidgetComponent } from './ui/dashboard-widget/linechart-widget/linechart-widget.component';
import { BreakdownLinechartWidgetComponent } from './ui/dashboard-widget/breakdown-linechart-widget/breakdown-linechart-widget.component';
import { BreakdownBarchartWidgetComponent } from './ui/dashboard-widget/breakdown-barchart-widget/breakdown-barchart-widget.component';
import { DistributionGaugeWidgetComponent } from './ui/dashboard-widget/distribution-gauge-widget/distribution-gauge-widget.component';
import { PercentageGaugeWidgetComponent } from './ui/dashboard-widget/percentage-gauge-widget/percentage-gauge-widget.component';
import { HBarsChartWidgetComponent } from './ui/dashboard-widget/hbarschart-widget/hbarschart-widget.component';
import { SocialMediaClickRankWidgetComponent } from './ui/dashboard-widget/social-media-click-ranking-widget/social-media-click-ranking-widget.component';
import { AlertsSummaryWidgetComponent } from './ui/dashboard-widget/alerts-summary-widget/alerts-summary-widget.component';
import { DateInputComponent } from './ui/date-input/date-input.component';
import { DropdownInputComponent } from './ui/dropdown-input/dropdown-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CompetitorsWidgetComponent } from './ui/dashboard-widget/competitors-widget/competitors-widget.component';
import { OversightWidgetComponent } from './ui/dashboard-widget/oversight-widget/oversight-widget.component';
import { MulticardWidgetComponent } from './ui/dashboard-widget/multicard-widget/multicard-widget.component';
import { EmailsSummaryWidgetComponent } from './ui/dashboard-widget/emails-summary-widget/emails-summary-widget.component';
import { SurveyDailyBreakdownWidgetComponent } from './ui/dashboard-widget/survey-daily-breakdown-widget/survey-daily-breakdown-widget.component';
import { SurveyHourlyBreakdownWidgetComponent } from './ui/dashboard-widget/survey-hourly-breakdown-widget/survey-hourly-breakdown-widget.component';
import { CardComponentComponent } from './ui/dashboard-widget/_components/card-component/card-component.component';
import { EnhancedDropdownComponent } from './ui/enhanced-dropdown/enhanced-dropdown.component';
import { TableComponent } from './table/table.component';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { SubItemComponent } from './ui/enhanced-dropdown/sub-item/sub-item.component';
import { SwitchInputComponent } from './ui/switch-input/switch-input.component';
import { OverlayComponent } from './ui/overlay/overlay.component';
import { TextBoxComponent } from './ui/text-box/text-box.component';
import { AlertNumberComponent } from './ui/alert-number/alert-number.component';
import { QuickFormComponent } from './quick-form/quick-form.component';
import { Bootstrap4FrameworkModule } from 'angular6-json-schema-form';
import { ErrorComponent } from './error/error.component';
import { PaginationComponent } from '../portal/pagination/pagination.component';
import { HeaderComponent } from './table/header/header.component';
import { HexaLibModule } from 'hexa-lib';
import { HexalibTableComponent } from './hexalib-table/hexalib-table.component';
import { HexaLibHeaderComponent } from './hexalib-table/header/hexalib-table-tableheader.component';
import { InfoPopoverComponent } from './ui/popover-widget/info-popover/info-popover.component';
import { InputSwitchComponent } from './ui/input-switch/input-switch.component';
import { InputTextBoxComponent } from './ui/input-text-box/input-text-box.component';
import { InputButtonComponent } from './ui/input-button/input-button.component';
import { CardComponent } from './ui/card/card.component';
import { CardContentUserComponent } from './ui/card/card-content-user/card-content-user.component';
import { CardContentPosComponent } from './ui/card/card-content-pos/card-content-pos.component';
import { DeleteModalComponent } from './ui/modal/delete-modal/delete-modal.component';
import { CardListComponent } from './card-list/card-list.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperModalComponent } from './ui/modal/image-cropper-modal/image-cropper-modal.component';
import { DragAndDropDirective } from './ui/drag-and-drop/drag-and-drop.directive';
import { FacebookConfigurationSaveModalComponent } from './ui/modal/facebook-configuration-save-modal/facebook-configuration-save-modal.component';
import { UnsavedModalComponent } from './ui/modal/unsaved-modal/unsaved-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    Bootstrap4FrameworkModule,
    CommonModule,
    FileUploadModule,
    MomentModule,
    FormsModule,
    DpDatePickerModule,
    HttpClientModule,
    RouterModule,
    HexaLibModule,
    ImageCropperModule,
    NgSelectModule
  ],
  declarations: [
    PortalComponent,
    LoadingSpinnerComponent,
    RightPanelCommanderComponent,
    FileUploaderComponent,
    DashboardWidgetComponent,
    LinechartWidgetComponent,
    BreakdownLinechartWidgetComponent,
    BreakdownBarchartWidgetComponent,
    DistributionGaugeWidgetComponent,
    PercentageGaugeWidgetComponent,
    HBarsChartWidgetComponent,
    AlertsSummaryWidgetComponent,
    CompetitorsWidgetComponent,
    OversightWidgetComponent,
    MulticardWidgetComponent,
    EmailsSummaryWidgetComponent,
    SurveyDailyBreakdownWidgetComponent,
    SurveyHourlyBreakdownWidgetComponent,
    SocialMediaClickRankWidgetComponent,
    CardComponentComponent,
    DateInputComponent,
    DropdownInputComponent,
    EnhancedDropdownComponent,
    SubItemComponent,
    TableComponent,
    PaginationComponent,
    SwitchInputComponent,
    OverlayComponent,
    TextBoxComponent,
    AlertNumberComponent,
    QuickFormComponent,
    ErrorComponent,
    HeaderComponent,
    HexalibTableComponent,
    HexaLibHeaderComponent,
    InfoPopoverComponent,
    InputSwitchComponent,
    InputTextBoxComponent,
    InputButtonComponent,
    CardComponent,
    CardContentUserComponent,
    CardContentPosComponent,
    CardListComponent,
    DeleteModalComponent,
    ImageCropperModalComponent,
    UnsavedModalComponent,
    DragAndDropDirective,
    FacebookConfigurationSaveModalComponent
  ],
  exports: [
    LoadingSpinnerComponent,
    RightPanelCommanderComponent,
    TranslateModule,
    FileUploaderComponent,
    DashboardWidgetComponent,
    DateInputComponent,
    DropdownInputComponent,
    LinechartWidgetComponent,
    EnhancedDropdownComponent,
    TableComponent,
    PaginationComponent,
    TextBoxComponent,
    SwitchInputComponent,
    OverlayComponent,
    ErrorComponent,
    QuickFormComponent,
    PercentageGaugeWidgetComponent,
    HBarsChartWidgetComponent,
    HexalibTableComponent,
    HexaLibModule,
    InfoPopoverComponent,
    InputSwitchComponent,
    InputTextBoxComponent,
    InputButtonComponent,
    CardComponent,
    CardListComponent,
    DeleteModalComponent,
    ImageCropperModalComponent,
    DragAndDropDirective,
    FacebookConfigurationSaveModalComponent,
    UnsavedModalComponent,
    NgSelectModule
  ],
  providers: [DatePipe],
})
export class PortalModule {}

