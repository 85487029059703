import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ObjectType } from '../../../_models/objectType.enum';
import { ConsoleLoggerService } from '../../../_services/console-logger.service';
import { FormModel } from '../../../_models/form.model';
import { QuickFormService } from '../../../_services/quickForm.service';
import { AddressDto } from '../../../_models/address';
import { BranchDto } from '../../../_models/branch';
import { PortalService, SchemaType } from '../../../_services/portal.service';
import { NotificationService } from '../../../_services/notification.service';
import { Router } from '@angular/router';
import { DropdownItem } from '../_models/dropdown';
import { EntityLogo } from '../_models/logos.model';
import { SubscriptionClient } from 'src/app/portal-api.g';

@Component({
  selector: 'app-quick-form',
  templateUrl: './quick-form.component.html',
  styleUrls: ['./quick-form.component.scss']
})
export class QuickFormComponent implements OnInit {
  private loggerFrom = 'Quickform';
  formModel: FormModel;
  addresses: AddressDto[];
  objectType = ObjectType;
  branchJsonSchema: any;
  branchData: BranchDto;
  branchTitle: string;
  branchAdd: boolean;
  formData: any;

  readonly formFrom: string = 'QuickFormComponent';

  public roleAssignmentParams: any;

  protected imageSource: any;
  protected file: File;

  protected cultureOptions: DropdownItem[] = [];
  protected cultureOptionSelected: DropdownItem;

  protected entityLogos: EntityLogo[] = [];
  protected selectedEntityLogo: EntityLogo;

  protected hasLogo: boolean;

  // Related to Cultures for Organization and Subscription
  protected showCultureForm: boolean;

  protected action: string;
  protected actionDescription: string;
  protected actionItemId: string;

  constructor(
    private http: HttpClient,
    private logger: ConsoleLoggerService,
    private quickFormService: QuickFormService,
    private portalService: PortalService,
    private subscriptionClient : SubscriptionClient,
    private notification: NotificationService,
    private router: Router
  ) {
    this.quickFormService.formModel.subscribe((formModel: FormModel) => {
      this.logger.info('QuickForm', this.formFrom, formModel);

      if (formModel) {
        this.formModel = formModel;
        this.formData = formModel.data;

        switch (this.formModel.objectType) {
          case this.objectType.Organization:
          case this.objectType.Subscription:
            this.cultureOptions = [];
            this.cultureOptions.push({
              key: this.formData.cultureCode,
              value: `Default (${this.formData.cultureCode})`
            });

            if (this.formData.cultures) {
              this.cultureOptions.push(
                ...this.formData.cultures.map(c => {
                  return {
                    key: c.cultureCode,
                    value: c.cultureCode
                  };
                })
              );
            }

            break;
          case this.objectType.SubscriptionService:
            break;
          case this.objectType.Address:
            break;
        }

        // Cleaning the cultures form
        this.clearCulturesForm();

        if (this.formData != null) {
          this.roleAssignmentParams = {
            id: this.formData.id,
            name: this.formData.name !== undefined ? this.formData.name : this.formData.displayName,
            type: this.formModel.objectType,
            subjectPath: this.formData.subjectPath
          };
        }
      }
    });

    this.quickFormService.addresses.subscribe((addresses: AddressDto[]) => {
      if (addresses) {
        this.addresses = addresses;
      }
    });
  }

  ngOnInit() {}

  protected getBranchSchema(): any {
    return this.portalService.getSchema(SchemaType.branch);
  }

  protected onDeleteClicked() {
    let request: any;

    switch (this.formModel.objectType) {
      case this.objectType.Organization:
        request = this.portalService.deleteOrganization(this.formModel.data.id);
        break;
      case this.objectType.Subscription:
        request = this.portalService.deleteSubscription(this.formModel.data.id);
        break;
      case this.objectType.SubscriptionService:
        request = this.portalService.deleteSubscriptionService(this.formModel.data.id);
        break;
      case this.objectType.Address:
        request = this.portalService.deleteAddresse(this.formModel.data.id);
        break;
    }

    return request.subscribe(s => {
      this.notification.success('Deletion successful!');
      this.formModel = undefined;
      this.formData = undefined;

      this.quickFormService.reloadData();
    });
  }

  protected onSubmit(event) {
    let request: any;

    if (this.formModel.isAdd) {
      switch (this.formModel.objectType) {
        case this.objectType.Organization:
          request = this.portalService.addOrganization(event);
          break;
        case this.objectType.Subscription:
          request = this.portalService.addSubscription(event);
          break;
        case this.objectType.SubscriptionService:
          request = this.portalService.addSubscriptionService(event);
          break;
        case this.objectType.Address:
          request = this.portalService.addAddresse(event);
          break;
      }

      this.notification.success('Model added');
    } else {
      switch (this.formModel.objectType) {
        case this.objectType.Organization:
          request = this.portalService.editOrganization(event);
          break;
        case this.objectType.Subscription:
          request = this.portalService.editSubscription(event);
          break;
        case this.objectType.SubscriptionService:
          request = this.portalService.editSubscriptionService(event);
          break;
        case this.objectType.Address:
          request = this.portalService.editAddresse(event);
          break;
      }

      this.notification.success('Model changed');
    }

    return request.subscribe(data => {
      if (this.formModel.isAdd) {
        if (this.formModel.objectType === this.objectType.Address) {
          event.id = data;
          this.addresses.push(event);
          this.quickFormService.setAddresses(this.addresses);
        }
      } else {
        if (this.formModel.objectType === this.objectType.Address) {
          const addressIndex = this.addresses.findIndex(x => x.id === event.id);
          this.addresses[addressIndex] = event;
          this.quickFormService.setAddresses(this.addresses);
        }
      }
      this.quickFormService.reloadData();
    });
  }

  protected branchClick(event, model: BranchDto, isAdd: boolean) {
    this.getBranchSchema().subscribe(schema => {
      this.branchJsonSchema = schema;
    });

    if (isAdd) {
      this.branchData = new BranchDto();
      this.branchData.subscriptionId = this.formModel.data.id;
      this.branchTitle = 'Add Branch';
      this.branchAdd = true;
    } else {
      this.branchTitle = 'Edit Branch';
      this.branchData = model;
      this.branchAdd = false;
    }
  }

  protected onBranchSubmit(event) {
    let request: Promise<any>;

    if (this.branchAdd) {
      request = this.portalService.addBranch(event);
    } else {
      request = this.portalService.editBranch(event);
    }

    return request.then(s => {
      this.quickFormService.reloadData();
    });
  }

  protected onBranchDeleteClicked() {
    const request = this.portalService.deleteBranch(this.branchData.id);
    return request.then(s => {
      this.quickFormService.reloadData();
    });
  }

  protected addNewCulture(): void {
    this.clearCulturesForm();
    this.showCultureForm = true;
  }

  protected uploadFile(files: any) {
    const file: File = files[0];
    const fileReader = new FileReader();

    fileReader.onload = () => {
      this.logger.info('Loaded File', this.loggerFrom, file);
      this.imageSource = fileReader.result;
      this.file = file;
    };

    fileReader.readAsDataURL(file);
  }

  protected editCulture(logo: EntityLogo) {
    this.selectedEntityLogo = logo;

    this.logger.info('Change logo: ', this.loggerFrom, logo);

    this.cultureOptionSelected = this.cultureOptions.find(c => c.key === this.selectedEntityLogo.cultureCode);
    this.logger.info('Culture Options: ', this.loggerFrom, this.cultureOptionSelected);

    this.file = null;
    this.imageSource = logo.logo;

    this.showCultureForm = true;
  }

  protected cultureOptionSelectionChanged(item: DropdownItem): void {
    this.cultureOptionSelected = item;
  }

  protected saveForm(): void {
    if (this.file) {
      // Submit new file to Azure Storage
      switch (this.formModel.objectType) {
        case this.objectType.Organization:
          this.portalService.saveOrganizationLogo(this.formModel.data.id, this.cultureOptionSelected.key, this.file).subscribe(r => this.clearCulturesForm());
          break;
        case this.objectType.Subscription:
          this.portalService.saveSubscriptionLogo(this.formModel.data.id, this.cultureOptionSelected.key, this.file).subscribe(r => this.clearCulturesForm());
          break;
        case this.objectType.SubscriptionService:
          break;
        case this.objectType.Address:
          break;
      }
    } else {
      // Clear the form after
      this.clearCulturesForm();
    }
  }

  protected clearCulturesForm(): void {
    this.showCultureForm = false;
    this.selectedEntityLogo = null;
    this.imageSource = null;
    this.file = null;
    this.cultureOptionSelected = this.cultureOptions[0];
    this.entityLogos = [];

    this.reloadCulturesList();
  }

  private reloadCulturesList(): void {
    switch (this.formModel.objectType) {
      case this.objectType.Organization:
        this.portalService.getOrganizationLogos(this.formData.id).subscribe(logos => {
          this.logger.info(`Organization logos`, this.loggerFrom, logos);
          this.entityLogos = logos;
        });
        break;
      case this.objectType.Subscription:
        this.subscriptionClient.getLogos(this.formData.organizationId, this.formData.id).subscribe(viewModel => {
          this.logger.info(`Subscription logos`, this.loggerFrom, viewModel);

          // Compatibility with old EntityLogo type
          let logos = viewModel.logos.map(x => {
            return {
              entityId: x.entityId,
              cultureCode: x.culture,
              isDefault: x.isDefault,
              logo: x.logo
            }
          });

          this.entityLogos = logos;
        });
        break;
      case this.objectType.SubscriptionService:
        break;
      case this.objectType.Address:
        break;
    }
  }

  protected confirmActionPrompt(action: string, cultureId: string): void {
    switch (action) {
      case 'D':
        this.actionDescription = 'DELETION';
        break;
    }

    this.action = action;
    this.actionItemId = cultureId;
  }

  protected resetAction() {
    this.actionDescription = null;
    this.actionItemId = null;
    this.action = null;
  }

  protected confirmAction(): void {
    switch (this.action) {
      case 'D':
        this.logger.warning(`Delete logo (${this.actionItemId})`, this.loggerFrom, this.actionItemId);

        // Submit new file to Azure Storage
        switch (this.formModel.objectType) {
          case this.objectType.Organization:
            this.portalService.deleteOrganizationLogo(this.formModel.data.id, this.actionItemId).then(_ => this.clearCulturesForm()).then(_ => this.reloadCulturesList());
            break;
          case this.objectType.Subscription:
            this.portalService.deleteSubscriptionLogo(this.formModel.data.id, this.actionItemId).then(_ => this.clearCulturesForm()).then(_ => this.reloadCulturesList());
            break;
          case this.objectType.SubscriptionService:
            break;
          case this.objectType.Address:
            break;
        }
    }

    this.resetAction();
  }
}
