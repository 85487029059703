import { ConfigService, SubscriptionServiceShortNameEnum } from 'src/app/_services/config.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { NavigationOption, MobileNavigationOption } from '../_models/navigationOption';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { ValueObserver } from '../_base/value.observer';
import { GetUserSubscriptionServiceTypeViewModel, OrganizationDto, SubscriptionDto, SubscriptionServiceDto } from '../portal-api.g';

@Injectable({
  providedIn: 'root',
})
export class PortalEvents {
  public subscriptionServiceCancellationToken = new Subject();
  public readonly EventRoleAssignmentsClosed: string = 'onRoleAssignmentsClose';

  public isMobile: boolean;

  private showSpinnerBehavior = new BehaviorSubject<boolean>(false);
  public showSpinner: Observable<boolean> = this.showSpinnerBehavior.asObservable();

  public onAuthorizationFailed = new EventEmitter<any>();
  public onRoleAssignmentsRequested = new EventEmitter<any>();
  public onRoleAssignmentsClose = new EventEmitter<any>();
  public onRoleAssignmentsChangeParams = new EventEmitter<any>();
  public onAlertEmailAssignmentRequested = new EventEmitter<any>();
  public onAlertEmailAssignmentClose = new EventEmitter<any>();
  public onAlertEmailAssignmentChangeParams = new EventEmitter<any>();
  public onStmDashboardLoaded = new EventEmitter<any>();
  public onStmDashboardUnloaded = new EventEmitter<any>();
  public onPdfDownloaded = new EventEmitter<any>();

  public onOrganizationTreeChanged = new EventEmitter<any>();

  // ValueObservers
  public organization = new ValueObserver<OrganizationDto>();
  public subscription = new ValueObserver<SubscriptionDto>();
  public subscriptionService = new ValueObserver<SubscriptionServiceDto>();
  public serviceId = new ValueObserver<string>();
  public subscriptionServiceId = new ValueObserver<string>();
  public language = new ValueObserver<string>();
  public subMenu = new ValueObserver<NavigationOption[]>();
  public popoverMenu = new ValueObserver<NavigationOption[]>();
  public idPopoverMenu = new ValueObserver<{ id: string; menu: NavigationOption }[]>();
  public mobileSubMenu = new ValueObserver<MobileNavigationOption[]>();
  public userType = new ValueObserver<GetUserSubscriptionServiceTypeViewModel>();

  public selectedPopoverMenu = new ValueObserver<NavigationOption>();
  public selectedSubMenu = new ValueObserver<NavigationOption>();
  public subMenuVisible = new ValueObserver<boolean>();

  constructor(private logger: ConsoleLoggerService, protected configService: ConfigService) {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent) || /iPhone|iPod/.test(userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.subscription.listen((sub) => {
      this.renewSubscriptionServiceCancellationToken();
    });

    this.subscriptionService.listen((subscriptionService) => {
      this.serviceId.value = subscriptionService.serviceId;
    });

    this.mobileSubMenu.listen((v) => {
      this.subMenuVisible.value = v && v.length > 0;
    });

    this.subMenu.listen((v) => {
      this.subMenuVisible.value = v && v.length > 0;
    });
  }

  refreshSubscriptionService() {
    var oldValue = this.subscriptionServiceId;
    this.subscriptionServiceId = new ValueObserver<string>();
    this.subscriptionServiceId.value = oldValue.value;
  }

  renewSubscriptionServiceCancellationToken() {
    this.subscriptionServiceCancellationToken.next();
    this.subscriptionServiceCancellationToken.complete();
    this.subscriptionServiceCancellationToken = new Subject();
  }

  public setShowSpinner(show: boolean) {
    this.showSpinnerBehavior.next(show);
  }

  set identifiedPopoverMenu(list: { id: string; menu: NavigationOption }[]) {
    let nav: NavigationOption[] = [];
    for (var i = 0; i < list.length; i++) {
      nav.push(list[i].menu);
    }

    this.idPopoverMenu.value = list;
    this.popoverMenu.value = nav;
  }

  public getSubscriptionServiceType(serviceId: string): string {
    switch (serviceId) {
      case this.configService.serviceIds.mysteryShopper:
        return SubscriptionServiceShortNameEnum.ms;
      case this.configService.serviceIds.socialListening:
        return SubscriptionServiceShortNameEnum.sl;
      case this.configService.serviceIds.contactTracing:
        return SubscriptionServiceShortNameEnum.ct;
      case this.configService.serviceIds.stmDashboard:
        return SubscriptionServiceShortNameEnum.stm;
      case this.configService.serviceIds.mysteryShopping:
        return SubscriptionServiceShortNameEnum.ms;
      default:
        return SubscriptionServiceShortNameEnum.voc;
    }
  }
}
