import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LoadingSpinnerComponent implements OnChanges, OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('show-spinner') showSpinnerInput: boolean;
  @Input() widthInput: number;
  @Input() hostWidth: string;

  public showSpinner: boolean;
  public defaultWidth = 10;

  constructor(private logger: ConsoleLoggerService, private host: ElementRef, private renderer: Renderer2) {
    this.showSpinner = this.showSpinnerInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.logger.info('Changes', 'SpinnerComponent', changes);

    this.showSpinner = changes.showSpinnerInput.currentValue;
  }

  ngOnInit() {
    if (this.widthInput !== undefined && this.widthInput !== null) {
      this.defaultWidth = this.widthInput;
    }

    if (this.hostWidth !== undefined && this.hostWidth !== null) {
      this.renderer.setStyle(this.host.nativeElement, 'width', this.hostWidth + 'px');
      this.renderer.setStyle(this.host.nativeElement, 'margin-left', 'auto');
      this.renderer.setStyle(this.host.nativeElement, 'margin-right', 'auto');
    }
  }
}
