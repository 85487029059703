import { Injectable } from '@angular/core';
import { Config } from '../_models/config.model';
import { HttpClient } from '@angular/common/http';

class ServiceIdEnum {
  public voiceOfCustomer = '00000000-0000-0000-0000-100000000001';
  public mysteryShopper = '00000000-0000-0000-0000-100000000002';
  public stmDashboard = '00000000-0000-0000-0000-100000000010';
  public socialListening = '00000000-0000-0000-0000-100000000003';
  public mysteryShopping = '00000000-0000-0000-0000-100000000004';
  public contactTracing = '00000000-0000-0000-0000-100000000020';
}

export enum SubscriptionServiceShortNameEnum {
  voc = 'voc',
  ms = 'ms',
  stm = 'stm',
  sl = 'sl',
  ct = 'ct',
}

class SubscriptionServiceIdEnum {
  public stmDashboard = '00000000-0000-0000-0001-100000000001';
}

class ProviderEnum {
  public facebook = '00000000-1000-0000-0000-000000000003';
}

export class TaxonomyEnum {
  public firstName = 'firstName';
  public lastName = 'lastName';
  public gender = 'gender';
  public ageGroup = 'ageGroup';
  public dateOfBirth = 'dateOfBirth';
  public email = 'email';
  public phone = 'phone';
  public dateOfVisit = 'dateOfVisit';
  public clientNumber = 'clientNumber';
  public nps = 'nps';
  public ces = 'ces';
  public motivation = 'motivation';
  public contest = 'contest';
  public competitorName = 'competitorName';
  public competitorRating = 'competitorRating';
  public timeOfVisit = 'timeOfVisit';
  public allowNewsletter = 'allowNewsletter';
  public receipt = 'receipt';

  public list = [
    this.firstName,
    this.lastName,
    this.gender,
    this.ageGroup,
    this.dateOfBirth,
    this.email,
    this.phone,
    this.dateOfVisit,
    this.clientNumber,
    this.nps,
    this.ces,
    this.motivation,
    this.contest,
    this.competitorRating,
    this.competitorName,
    this.timeOfVisit,
    this.allowNewsletter,
    this.receipt,
  ];
}

export class SocialListeningWidgetEnum {
  public general = 'general';
  public trends = 'trends';
  public generalByCategory = 'generalByCategory';
  public popularKeywords = 'popularKeywords';
  public comments = 'comments';
  public commentsByCategory = 'commentsByCategory';
  public accuracy = 'accuracy';
  public starRating = 'starRating';

  public list = [this.general, this.accuracy, this.popularKeywords, this.commentsByCategory, this.comments, this.trends, this.starRating];
}

export enum DashboardWidgetEnum {
  general,
  generalByCategory,
  nps,
  trends,
  alerts,
  ces,
  competitors,
  oversight,
  statisticsTrendCompletedSurveys,
  statisticsCompletedSurveys,
  statisticsEmailsSummary,
  statisticsDailyBreakdown,
  statisticsHourlyBreakdown,
  statisticsSocialMediaPage,
  statisticsSocialMediaRanking
}

// export class DashboardWidgetEnum {
//   public general = 'general';
//   public generalByCategory = 'generalByCategory';
//   public nps = 'nps';
//   public trends = 'trends';
//   public alerts = 'alerts';
//   public ces = 'ces';
//   public competitors = 'competitors';
//   public oversight = 'oversight';
//   public statisticsTrendCompletedSurveys = 'statisticsTrendCompletedSurveys';
//   public statisticsCompletedSurveys = 'statisticsCompletedSurveys';
//   public statisticsEmailsSummary = 'statisticsEmailsSummary';
//   public statisticsDailyBreakdown = 'statisticsDailyBreakdown';
//   public statisticsHourlyBreakdown = 'statisticsHourlyBreakdown';
//   public statisticsSocialMediaPage = 'statisticsSocialMediaPage';
//   public statisticsSocialMediaRanking = 'statisticsSocialMediaRanking';

//   public list = [
//     this.general,
//     this.generalByCategory,
//     this.nps,
//     this.trends,
//     this.alerts,
//     this.ces,
//     this.competitors,
//     this.oversight,
//     this.statisticsTrendCompletedSurveys,
//     this.statisticsCompletedSurveys,
//     this.statisticsEmailsSummary,
//     this.statisticsDailyBreakdown,
//     this.statisticsHourlyBreakdown,
//     this.statisticsSocialMediaPage,
//     this.statisticsSocialMediaRanking
//   ];
// }

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private innerConfig: Config;
  private initPromise: Promise<Config>;
  public dashboardWidgets: DashboardWidgetEnum;
  public socialListeningDashboardWidgets: SocialListeningWidgetEnum;
  public serviceIds: ServiceIdEnum;
  public taxonomies: TaxonomyEnum;
  public subscriptionServiceIds: SubscriptionServiceIdEnum;
  public providerIds: ProviderEnum;

  constructor(private http: HttpClient) {
    this.serviceIds = new ServiceIdEnum();
    this.taxonomies = new TaxonomyEnum();
    // this.dashboardWidgets = DashboardWidgetEnum;
    this.socialListeningDashboardWidgets = new SocialListeningWidgetEnum();
    this.subscriptionServiceIds = new SubscriptionServiceIdEnum();
    this.providerIds = new ProviderEnum();
  }

  public get config(): Config {
    return this.innerConfig;
  }

  public get init(): Promise<Config> {
    if (this.initPromise === undefined) {
      this.initPromise = this.http
        .get<Config>('../config.json')
        .toPromise()
        .then((config) => (this.innerConfig = config));
    }

    return this.initPromise;
  }
}
