import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Subject } from 'rxjs';
import { DropdownItem } from 'hexa-lib/lib/_models/models';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() currentPage: number;
  @Input() maxPage: number;
  @Input() pageSize: number;
  @Input() totalRecords: number;
  @Input() showPaginationInfo: boolean;
  @Input() multipleOf3Pages: boolean;
  @Input() minimalistVersion: boolean;
  @Output() pageChanged: EventEmitter<number>;
  @Output() pageSizeChanged: EventEmitter<number>;

  public isFirstPage: boolean;
  public isLastPage: boolean;

  public pageSizes: DropdownItem[];
  public selectedPageSize: DropdownItem;
  public pageInfo: string;

  constructor(public translator: PortalTranslatorService) {
    this.currentPage = 1;
    this.maxPage = 1;
    this.totalRecords = 0;
    this.isFirstPage = true;
    this.isLastPage = true;

    this.pageChanged = new EventEmitter<number>();
    this.pageSizeChanged = new EventEmitter<number>();

    this.initPageInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const maxPageChange: SimpleChange = changes.maxPage;
    const currentPageChange: SimpleChange = changes.currentPage;
    const totalRecordsChange: SimpleChange = changes.totalRecords;
    const pageSizeChange: SimpleChange = changes.pageSize;
    const showPaginationInfoChange: SimpleChange = changes.showPaginationInfo;
    const multipleOf3PagesChange: SimpleChange = changes.multipleOf3Pages;

    if (multipleOf3PagesChange && multipleOf3PagesChange.currentValue) {
      if (multipleOf3PagesChange.currentValue) {
        this.pageSizes = [
          {
            key: 9,
            value: '9',
          },
          {
            key: 18,
            value: '18',
          },
          {
            key: 27,
            value: '27',
          },
          {
            key: 39,
            value: '39',
          },
          {
            key: 48,
            value: '48',
          },
        ];
      } else {
        this.pageSizes = [
          {
            key: 10,
            value: '10',
          },
          {
            key: 20,
            value: '20',
          },
          {
            key: 30,
            value: '30',
          },
          {
            key: 40,
            value: '40',
          },
          {
            key: 50,
            value: '50',
          },
        ];
      }

      this.selectedPageSize = this.pageSizes[0];
    }

    if (maxPageChange && maxPageChange.currentValue) {
      this.isLastPage = true;

      if (maxPageChange.currentValue > this.currentPage) {
        this.isLastPage = false;
      }

      this.updateCurrentPageInfo();
    } else if (changes.currentPage && changes.currentPage.currentValue === 1) {
      this.firstPage();
    }

    if (currentPageChange && currentPageChange.currentValue) {
      if (currentPageChange.currentValue > 1) {
        this.isFirstPage = false;
      }

      this.updateCurrentPageInfo();
    }

    if (pageSizeChange && pageSizeChange.currentValue) {
      if (this.pageSizes.findIndex((p) => p.key === pageSizeChange.currentValue) > -1) {
        this.selectedPageSize = this.pageSizes.find((p) => p.key === pageSizeChange.currentValue);
      }
    }

    if (totalRecordsChange && totalRecordsChange.currentValue) {
      this.updateCurrentPageInfo();
    }

    if (showPaginationInfoChange && showPaginationInfoChange.currentValue) {
      this.showPaginationInfo = showPaginationInfoChange.currentValue;
    }
  }

  private initPageInfo() {
    this.updateCurrentPageInfo();

    this.pageSizes = [
      {
        key: 10,
        value: '10',
      },
      {
        key: 20,
        value: '20',
      },
      {
        key: 30,
        value: '30',
      },
      {
        key: 40,
        value: '40',
      },
      {
        key: 50,
        value: '50',
      },
    ];

    this.selectedPageSize = this.pageSizes[0];
  }

  private updateCurrentPageInfo() {
    this.translator.translate('page_info').then((pageInfo) => {
      this.pageInfo = pageInfo.replace('{0}', this.currentPage.toString()).replace('{1}', this.maxPage.toString()).replace('{2}', this.totalRecords.toString());
    });
  }

  public nextPage() {
    if (this.currentPage < this.maxPage) {
      this.currentPage++;
      this.pageChange();
    }
  }

  public lastPage() {
    this.currentPage = this.maxPage;
    this.pageChange();
  }

  public previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pageChange();
    }
  }

  public firstPage() {
    this.currentPage = 1;
    this.pageChange();
  }

  public setBounderies(e: any) {
    if (this.currentPage >= this.maxPage) {
      this.currentPage = this.maxPage;
    }

    if (this.currentPage <= 1) {
      this.currentPage = 1;
    }

    this.pageChange();
  }

  public filterDigits(e: any) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
    ) {
      return; // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  private pageChange() {
    if (this.currentPage === 1) {
      this.isFirstPage = true;
    } else {
      this.isFirstPage = false;
    }

    if (this.currentPage === this.maxPage) {
      this.isLastPage = true;
    } else {
      this.isLastPage = false;
    }

    if (this.maxPage !== 0) {
      this.pageChanged.next(this.currentPage);
    }
  }

  public pageSizeChange(selected: any) {
    if (this.maxPage !== 0) {
      this.pageSizeChanged.next(selected.key);
    }
  }
}
