<div class="alerts-summary">
  <div class="body">
    <div class="column first">
      <div class="value big">
        <app-alert-number [alertNumber]="wrapper.total()"></app-alert-number>
        <div class="label normal">{{ translator.translate('alert_total') | async }}</div>
      </div>
      <div class="value">
        <app-alert-number [alertNumber]="wrapper.pendingTotal()"></app-alert-number>
        <div class="label critical"><img src="/assets/images/icons/flash.svg" />{{ translator.translate('pending_alerts') | async }}<span class="asterisk">*</span></div>
      </div>
      <div class="value">
        <app-alert-number [alertNumber]="wrapper.treatedTotal()"></app-alert-number>
        <div class="label warning"><img src="/assets/images/icons/sea-foam-ok.svg" />{{ translator.translate('treated_alerts') | async }}</div>
      </div>
    </div>
    <div class="column second">
      <div class="title"><img src="/assets/images/icons/flash.svg" />{{ translator.translate('pending_alerts') | async }}<span class="asterisk">*</span></div>
      <div class="d-flex">
        <div class="value">
          <app-alert-number [alertNumber]="wrapper.toSolve()"></app-alert-number>
          <div class="label">{{ translator.translate('alert_to_solve') | async }}</div>
        </div>
        <div class="value">
          <app-alert-number [alertNumber]="config.toRead"></app-alert-number>
          <div class="label">{{ translator.translate('alert_to_read') | async }}</div>
        </div>
        <div class="value hidden-xs">
          <app-alert-number [alertNumber]="config.opened"></app-alert-number>
          <div class="label">{{ translator.translate('alert_open') | async }}</div>
        </div>
        <div class="value hidden-xs">
          <app-alert-number [alertNumber]="config.onHold"></app-alert-number>
          <div class="label">{{ translator.translate('alert_on_hold') | async }}</div>
        </div>
      </div>
      <div class="time-summary">
        <div class="value mt-0">
          <div class="col-9 col-md-10">
            {{ translator.translate('alert_average_time_to_open') | async }}
          </div>
          <div class="col-3 col-md-2">
            <h1>{{ config.pendingTimeToOpen }}<sup>H</sup></h1>
          </div>
        </div>
        <div class="value">
          <div class="col-9 col-md-10">
            {{ translator.translate('alert_average_time_to_contact') | async }}
          </div>
          <div class="col-3 col-md-2">
            <h1>{{ config.pendingTimeToContact }}<sup>H</sup></h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column third">
      <div class="title"><img src="/assets/images/icons/sea-foam-ok.svg" />{{ translator.translate('treated_alerts') | async }}</div>
      <div class="d-flex">
        <div class="value">
          <app-alert-number [alertNumber]="config.solved"></app-alert-number>
          <div class="label">{{ translator.translate('alert_solved_closed') | async }}</div>
        </div>
        <div class="value">
          <app-alert-number [alertNumber]="config.read"></app-alert-number>
          <div class="label">{{ translator.translate('alert_was_read') | async }}</div>
        </div>
      </div>
      <div class="time-summary">
        <div class="value mt-0">
          <div class="col-9 col-md-10">
            {{ translator.translate('alert_average_time_to_open') | async }}
          </div>
          <div class="col-3 col-md-2">
            <h1>{{ config.treatedTimeToOpen }}<sup>H</sup></h1>
          </div>
        </div>
        <div class="value">
          <div class="col-9 col-md-10">
            {{ translator.translate('alert_average_time_to_solve') | async }}
          </div>
          <div class="col-3 col-md-2">
            <h1>{{ config.treatedTimeToSolve }}<sup>H</sup></h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="footer-message">
      * {{translator.translate('the_number_of') | async}} <span class="pending-alert-text">{{translator.translate('pending_alerts') | async}}</span>
      {{translator.translate('is_not_impacted_by_the') | async}}
    </div>
  </div>
</div>
