<div class="button-container" [style]="setButtonStyle()" (click)="handleClick()" (mouseover)="hover = true" (mouseleave)="hover = false">
  <div class="button-icon-container" *ngIf="icon !== ''">
    <img class="button-icon" [src]="icon" />
  </div>

  
  <div class="button-label" *ngIf="!this.isProcessing;else loading">{{ buttonLabel === '' ? 'button' : translator.translate(buttonLabel) | async }}</div>
  <ng-template #loading>
    <div class="button-label">
      <app-loading-spinner [show-spinner]="true" [widthInput]="100"></app-loading-spinner>
    </div>
  </ng-template>
</div>
