<div class="error">
  <div>
    <img src="/assets/images/generic-error-illustration.svg" />
  </div>

  <div class="error-content" *ngIf="status !== '500' && status !== '403'">
    <div class="title">
      <strong>{{ translator.translate('error_title') | async }}</strong>
      <div class="error-label-section">
        <span class="error-label"> {{ errorCode }}</span>
      </div>
    </div>
    <div class="description">
      <p>{{ translator.translate('error_404_description') | async }}</p>
      <p>{{ translator.translate('error_persists') | async }}</p>
    </div>
  </div>

  <div class="error-content" *ngIf="status === '500'">
    <div class="title">
      <strong>{{ translator.translate('error_title') | async }}</strong>
      <div class="error-label-section">
        <span class="error-label"> {{ translator.translate('error_500') | async }}</span>
      </div>
    </div>
    <div class="description">
      <p>{{ translator.translate('internet_access') | async }}</p>
      <p>{{ translator.translate('contact_admin') | async }}</p>
    </div>
  </div>

  <div class="error-content" *ngIf="status === '403'">
    <div class="title">
      <strong>{{ translator.translate('error_title') | async }}</strong>
      <div class="error-label-section">
        <span class="error-label"> {{ errorCode }}</span>
      </div>
    </div>
    <div class="description">
      <p>{{ translator.translate('error_403_description') | async }}</p>
      <p>{{ translator.translate('error_persists') | async }}</p>
    </div>
  </div>
</div>
