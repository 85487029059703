<div class="container">
  <div class="social-media" *ngFor="let item of socialMediaResults; let i = index">
    <div class="position">{{ i + 1 }}.</div>
    <div class="icon"><img src="/assets/images/icons/socialmedia/{{ getSocialMediaIcon(item.socialMedia) }}.svg" alt="Facebook" /></div>
    <div class="name">{{ translator.translate(socialMediaOptions[item.socialMedia].toString()) | async }}</div>
    <div class="line"></div>
    <div class="share">{{ item.rate }}%</div>
    <div class="count">({{ item.count }})</div>
  </div>
</div>
