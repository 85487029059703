import { Injectable } from '@angular/core';
import { UrlService } from 'src/app/_services/url.service';
import { ConfigService } from 'src/app/_services/config.service';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';
import { ApiServiceBase } from 'src/app/_services/api-service-base';
import { environment } from 'src/environments/environment';
import { FormVersionMetadata } from '../_models/form-version-metadata';
import { Form } from '../_models/form';
import { FormVersion } from '../_models/form-version';
import { Layout } from '../_models/layout';
import { Template } from '../_models/template';
import { LayoutLanguage } from '../_models/layout-language';
import { TemplateLanguage } from '../_models/template-language';
import { TemplateLanguageValues } from '../_models/template-language-values';
import { LayoutLanguageValues } from '../_models/layout-language-values';
import { Survey } from '../_models/survey';
import { ModelBase } from '../_models/model-base';
import { TemplateBase } from '../_models/template-base';
import { PatchFactory } from '../_models/patch-factory';
import { ProfileDto } from '../_models/profile';
import { Observable } from 'rxjs';
import { DetailPage } from '../_models/results';

@Injectable({
  providedIn: 'root',
})
export class ContactTracingService extends ApiServiceBase {
  constructor(protected urlService: UrlService, private http: HttpClient, protected configService: ConfigService, protected consoleLogger: ConsoleLoggerService) {
    super(urlService, configService, consoleLogger, configService.config.api.modules.contactTracing.path);
  }

  prepForInsert(base: ModelBase) {
    delete base.id;
    delete base.lastModifiedDate;
    delete base.createdDate;
  }

  getDetails(subscriptionServiceId: string, options: any): Observable<DetailPage> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.dashboardDetailsEndPoint);
    return this.http.post<DetailPage>(url, options);
  }
  getDetailsCsv(subscriptionServiceId: string, options: any): Observable<HttpResponse<Blob>> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.reportsEndpoint);
    return this.http.post(url, options, { observe: 'response', responseType: 'blob' });
  }
  getSurveys(subscriptionServiceId: string): Promise<Survey[]> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.surveyEndPoint);
    return this.http.get<Survey[]>(url).toPromise();
  }
  getLayouts(subscriptionServiceId: string): Promise<Layout[]> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.layoutEndPoint);
    return this.http.get<Layout[]>(url).toPromise();
  }
  getTemplates(subscriptionServiceId: string): Promise<Template[]> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.templateEndPoint);
    return this.http.get<Template[]>(url).toPromise();
  }
  getForms(subscriptionServiceId: string): Promise<Form[]> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.formEndPoint);
    return this.http.get<Form[]>(url).toPromise();
  }
  getBar(subscriptionServiceId: string): Promise<any> {
    const url = this.getUrl(environment.ctProfilesEndPoint, subscriptionServiceId);
    return this.http.get(url).toPromise();
  }

  getPoster(storeUrl: string, qrOnly: boolean): Observable<Blob>{
    var body = {
      'storeUrl': storeUrl,
      'qrOnly': qrOnly
    };

    const url = this.getUrl(environment.ctProfilesEndPoint,'/poster');
    return this.http.post(url, body, { responseType: 'blob' });
  }

  searchByName(search: string): Promise<any> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, '/searchByName', search);
    return this.http.get(url).toPromise();
  }

  searchByEmail(search: string): Promise<any> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, 'searchByEmail', search);
    return this.http.get(url).toPromise();
  }

  getSurvey(id: any): Promise<Survey> {
    const url = this.getUrl(environment.surveyEndPoint, id);
    return this.http.get<Survey>(url).toPromise();
  }
  getLayout(layoutId: any): Promise<Layout> {
    const url = this.getUrl(environment.layoutEndPoint, layoutId);
    return this.http.get<Layout>(url).toPromise();
  }
  getTemplate(templateId: string): Promise<Template> {
    const url = this.getUrl(environment.templateEndPoint, templateId);
    return this.http.get<Template>(url).toPromise();
  }
  getForm(formId: string): Promise<Form> {
    const url = this.getUrl(environment.formEndPoint, formId);
    return this.http
      .get<Form>(url)
      .toPromise()
      .then((result) => {
        let latest: FormVersion;
        const form = result;

        if (form.formVersions && form.formVersions.length) {
          form.formVersions.forEach((f) => {
            if (!latest || (f.majorVersion >= latest.majorVersion && f.minorVersion >= latest.minorVersion)) {
              latest = f;
            }
          });

          result.latestVersionJson = latest.formJson;
          result.latestVersion = latest;
        }

        return Promise.resolve(result);
      });
  }
  getVersion(formId: string, major: number, minor: number): Promise<FormVersion> {
    const url = this.getUrl(environment.formEndPoint, formId, environment.versionEndPoint, major.toString(), minor.toString());
    return this.http.get<FormVersion>(url).toPromise();
  }
  getLayoutLanguage(layoutLanguageId: string): Promise<LayoutLanguage> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, layoutLanguageId);
    return this.http.get<LayoutLanguage>(url).toPromise();
  }
  getTemplateLanguage(templateLanguageId: string): Promise<TemplateLanguage> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId);
    return this.http.get<TemplateLanguage>(url).toPromise();
  }
  getTemplateLanguageWithValue(templateLanguageId: string, surveyId: string): Promise<TemplateLanguage> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId);
    return this.http.get<TemplateLanguage>(url).toPromise();
  }
  getLayoutLanguageWithValue(templateLanguageId: string, surveyId: string): Promise<LayoutLanguage> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId, environment.layoutEndPoint);
    return this.http.get<LayoutLanguage>(url).toPromise();
  }

  addSurvey(survey: Survey): Promise<string> {
    delete survey.flowId;
    this.prepForInsert(survey);
    this.prepForInsert(survey.flow);

    const url = this.getUrl(environment.surveyEndPoint);
    return this.http.post<string>(url, survey).toPromise();
  }
  addTemplateLanguage(templateLanguage: TemplateLanguage): Promise<string> {
    this.prepForInsert(templateLanguage);
    const url = this.getUrl(environment.templateLanguageEndPoint);
    return this.http.post<string>(url, templateLanguage).toPromise();
  }
  addLayoutLanguage(layoutLanguage: LayoutLanguage): Promise<string> {
    this.prepForInsert(layoutLanguage);
    const url = this.getUrl(environment.layoutLanguageEndPoint);
    return this.http.post<string>(url, layoutLanguage).toPromise();
  }
  addTemplate(template: Template): Promise<string> {
    this.prepForInsert(template);
    const url = this.getUrl(environment.templateEndPoint);
    return this.http.post<string>(url, template).toPromise();
  }
  addLayout(layout: TemplateBase): Promise<string> {
    this.prepForInsert(layout);
    const url = this.getUrl(environment.layoutEndPoint);
    return this.http.post<string>(url, layout).toPromise();
  }
  addForm(form: Form): Promise<string> {
    this.prepForInsert(form);
    const url = this.getUrl(environment.formEndPoint);
    return this.http.post<string>(url, form).toPromise();
  }
  addLayoutLanguageValues(layoutLanguageId: string, surveyId: string, valuesJson: string): Promise<string> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, layoutLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .post<string>(url, {
        layoutLanguageId,
        surveyId,
        valuesJson,
      })
      .toPromise();
  }
  addTemplateLanguageValues(templateLanguageId: string, surveyId: string, valuesJson: string, headTitle: string): Promise<string> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .post<string>(url, {
        templateLanguageId,
        surveyId,
        valuesJson,
        headTitle,
      })
      .toPromise();
  }

  updateSurvey(survey: Survey): Promise<boolean> {
    const url = this.getUrl(environment.surveyEndPoint);
    this.prepForInsert;
    return this.http
      .put<boolean>(url, {
        id: survey.id,
        createdDate: survey.createdDate,
        lastModifiedDate: survey.lastModifiedDate,
        subscriptionServiceId: survey.subscriptionServiceId,
        flowId: survey.flowId,
        name: survey.name,
        defaultLanguage: survey.defaultLanguage,
        supportedLanguages: survey.supportedLanguages,
        flow: survey.flow,
      })
      .toPromise();
  }

  updateTemplate(template: Template): Promise<boolean> {
    const url = this.getUrl(environment.templateEndPoint, template.id);

    return this.http
      .put<boolean>(url, {
        id: template.id,
        layoutId: template.layoutId,
        templateLanguages: template.templateLanguages,
        lastModifiedDate: template.lastModifiedDate,
        createdDate: template.createdDate,
        subscriptionServiceId: template.subscriptionServiceId,
        sourceId: template.sourceId,
        name: template.name,
        data: template.data,
      })
      .toPromise();
  }

  updateLayout(layout: Layout): Promise<boolean> {
    const url = this.getUrl(environment.layoutEndPoint, layout.id);

    return this.http
      .put<boolean>(url, {
        id: layout.id,
        layoutLanguages: layout.layoutLanguages,
        lastModifiedDate: layout.lastModifiedDate,
        createdDate: layout.createdDate,
        subscriptionServiceId: layout.subscriptionServiceId,
        sourceId: layout.sourceId,
        name: layout.name,
        data: layout.data,
      })
      .toPromise();
  }

  updateLayoutLanguageValues(layoutLanguageId: string, surveyId: string, values: LayoutLanguageValues): Promise<boolean> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, layoutLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .put<boolean>(url, {
        id: values.id,
        createdDate: values.createdDate,
        lastModifiedDate: values.lastModifiedDate,
        surveyId: values.surveyId,
        layoutLanguageId: values.layoutLanguageId,
        valuesJson: values.valuesJson,
      })
      .toPromise();
  }
  updateTemplateLanguageValues(templateLanguageId: string, surveyId: string, values: TemplateLanguageValues): Promise<boolean> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .put<boolean>(url, {
        id: values.id,
        createdDate: values.createdDate,
        lastModifiedDate: values.lastModifiedDate,
        surveyId: values.surveyId,
        templateLanguageId: values.templateLanguageId,
        valuesJson: values.valuesJson,
        headTitle: values.headTitle,
      })
      .toPromise();
  }
  updateTemplateLanguage(templateLanguage: TemplateLanguage): Promise<boolean> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguage.id);

    return this.http
      .put<boolean>(url, {
        id: templateLanguage.id,
        createdDate: templateLanguage.createdDate,
        lastModifiedDate: templateLanguage.lastModifiedDate,
        templateId: templateLanguage.templateId,
        templateLanguageValues: templateLanguage.templateLanguageValues,
        sourceId: templateLanguage.sourceId,
        name: templateLanguage.name,
        data: templateLanguage.data,
        formJson: templateLanguage.formJson,
      })
      .toPromise();
  }
  updateLayoutLanguage(layoutLanguage: LayoutLanguage): Promise<boolean> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, layoutLanguage.id);

    return this.http
      .put<boolean>(url, {
        id: layoutLanguage.id,
        name: layoutLanguage.name,
        lastModifiedDate: layoutLanguage.lastModifiedDate,
        createdDate: layoutLanguage.createdDate,
        data: layoutLanguage.data,
        formJson: layoutLanguage.formJson,
        sourceId: layoutLanguage.sourceId,
        layoutId: layoutLanguage.layoutId,
      })
      .toPromise();
  }

  uploadTemplateLanguageJson(id: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();
    const url = this.getUrl(environment.templateLanguageEndPoint, id, environment.jsonUpload);
    return this.http.patch<boolean>(url, payload).toPromise();
  }

  uploadLayoutLanguageJson(id: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();
    const url = this.getUrl(environment.layoutLanguageEndPoint, id, environment.jsonUpload);
    return this.http.patch<boolean>(url, payload).toPromise();
  }

  updateForm(form: Form): Promise<boolean> {
    const url = this.getUrl(environment.formEndPoint, form.id);
    return this.http
      .put<boolean>(url, {
        id: form.id,
        createdDate: form.createdDate,
        lastModifiedDate: form.lastModifiedDate,
        name: form.name,
        subscriptionServiceId: form.subscriptionServiceId,
        sourceId: form.sourceId,
        formVersions: form.formVersions,
        latestVersionJson: form.latestVersionJson,
        latestVersion: form.latestVersion,
      })
      .toPromise();
  }
  updateSurveyWithDefault(surveyId: string): Promise<boolean> {
    const url = this.getUrl(environment.surveyEndPoint, surveyId, 'default');
    return this.http.post<boolean>(url, {}).toPromise();
  }
  updateSurveysWithDefault(): Promise<void> {
    const url = this.getUrl(environment.surveyEndPoint, 'default');
    return this.http.post<void>(url, {}).toPromise();
  }

  addProfile(): Promise<string> {
    const url = this.getUrl(environment.ctProfilesEndPoint);
    return this.http.post<string>(url, null).toPromise();
  }

  addBarForUser(userInfo: any): Promise<any> {
    const url = this.getUrl(environment.ctProfilesEndPoint, '/createBarFor');
    return this.http.post(url, userInfo).toPromise();
  }

  addBranchForBar(subscriptionServiceId: string): Promise<string> {
    const url = this.getUrl(environment.ctProfilesEndPoint, '/createBranchForBar', subscriptionServiceId);
    return this.http.post<string>(url, null).toPromise();
  }

  canAddBranchForBar(subscriptionServiceId: string): Promise<boolean> {
    const url = this.getUrl(environment.ctProfilesEndPoint, '/canCreateBranch', subscriptionServiceId);
    return this.http.get<boolean>(url).toPromise();
  }

  hasProfiles(subscriptionServiceId: string): Promise<any> {
    const url = this.getUrl(environment.ctProfilesEndPoint, '/hasProfiles', subscriptionServiceId);

    return this.http.get(url).toPromise();
  }

  getProfiles(subscriptionServiceId: string): Promise<ProfileDto[]> {
    const url = this.getUrl(environment.ctProfilesEndPoint, subscriptionServiceId);

    return this.http.get<ProfileDto[]>(url).toPromise();
  }

  getProfileByBranch(subscriptionId: string, branchId: string): Promise<ProfileDto> {
    const url = this.getUrl(environment.ctProfilesEndPoint, subscriptionId, 'branch', branchId);

    return this.http.get<ProfileDto>(url).toPromise();
  }

  getProfileReport(startDate: string, endDate: string): Promise<any> {
    const url = this.getUrl(environment.ctProfilesEndPoint, '/report', startDate, endDate);

    return this.http.get<any>(url).toPromise();
  }

  getUncompletedReport(startDate: string, endDate: string): Promise<any> {
    const url = this.getUrl(environment.ctProfilesEndPoint, '/uncompleted', startDate, endDate);

    return this.http.get<any>(url).toPromise();
  }

  importProfiles(organizationId: string, subscriptionName: string, subscriptionOwnerEmail: string, culture: string, result: string | ArrayBuffer) {
    const url = this.getUrl(environment.ctProfilesEndPoint, 'importCsv', organizationId, subscriptionName, subscriptionOwnerEmail, culture);

    const text = typeof result === 'string' ? result : String.fromCharCode.apply(null, new Uint16Array(result));
    return this.http.post<boolean>(url, text).toPromise();
  }

  updateProfile(profile: ProfileDto, emailLanguage: string): Promise<any> {
    delete (profile as any).$type;
    delete (profile.branch as any).$type;
    delete (profile.branch.address as any).$type;
    delete (profile.branch as any).geocoordinate;

    const url = this.getUrl(environment.ctProfilesEndPoint);
    return this.http.put(url, profile, { params: { emailLanguage } }).toPromise();
  }

  checkEndpointAvailability(endpoint: string): Promise<any> {
    const url = this.getUrl(environment.ctProfilesEndPoint, '/endpointAvailable', endpoint);
    return this.http.get(url).toPromise();
  }

  saveNewVersion(formId: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();
    const url = this.getUrl(environment.formEndPoint, formId, environment.versionEndPoint, environment.jsonUpload);
    return this.http.patch<boolean>(url, payload).toPromise();
  }
  patchVersion(formVersionId: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();
    const url = this.getUrl(environment.versionEndPoint, formVersionId);

    return this.http.patch<boolean>(url, payload).toPromise();
  }
  publishVersion(formId: string): Promise<boolean> {
    const url = this.getUrl(environment.formEndPoint, formId, environment.versionEndPoint, environment.publishVersion);

    return this.http.patch<boolean>(url, null).toPromise();
  }
  saveFormVersionMetadata(formVersionMetadata: FormVersionMetadata[]): Promise<boolean> {
    const url = this.getUrl(environment.formVersionMetadataEndPoint);
    return this.http.post<boolean>(url, formVersionMetadata).toPromise();
  }

  deleteForm(id: string): Promise<boolean> {
    const url = this.getUrl(environment.formEndPoint, id);
    return this.http.delete<boolean>(url).toPromise();
  }

  deleteSurvey(id: string): Promise<boolean> {
    const url = this.getUrl(environment.surveyEndPoint, id);
    return this.http.delete<boolean>(url).toPromise();
  }
}
