import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ɵangular_packages_platform_browser_animations_animations_e } from '@angular/platform-browser/animations';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
})
export class InputSwitchComponent implements OnInit, OnChanges {
  @Input() active: boolean;
  @Input() labelOn: string;
  @Input() labelOff: string;
  @Input() labelPosition = 'left';
  @Input() noLabel = false;
  @Input() culture: string;

  @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public defaultCulture: string = '';
  public labelTranslationKey: string;

  constructor(public translator: PortalTranslatorService) {
    this.setCultures();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const activeChange: SimpleChange = changes.active;

    if (this.defaultCulture != this.culture && this.defaultCulture) {
      this.defaultCulture = this.culture;
      this.setCultures();
    }

    if (activeChange && activeChange.currentValue) {
      this.changeLabelState(activeChange.currentValue);
    }
  }

  ngOnInit() {
    this.defaultCulture = this.translator.activeLanguage.value;
    this.changeLabelState(this.active);
  }

  public toggleState(): void {
    this.active = !this.active;

    // Because the change tracker isn't fired when modifying the value directly.
    this.changeLabelState(this.active);
    this.activeChange.emit(this.active);
  }

  public changeLabelState(state: boolean): void {
    this.labelTranslationKey = this.active ? this.labelOn : this.labelOff;
  }

  setSwitchStyle(): any {
    if (!this.noLabel) {
      return {
        margin: this.labelPosition === 'left' ? '0 0 0 1rem' : '0 1rem 0 0',
      };
    } else {
      return;
    }
  }

  setCultures() {
    Promise.all([this.translator.translate('switch_activated'), this.translator.translate('switch_deactivated')]).then((r) => {
      this.labelOn = r[0];
      this.labelOff = r[1];

      this.changeLabelState(this.active);
    });
  }
}
