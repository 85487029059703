import { Injectable } from '@angular/core';
import { Survey } from '../_models/survey';
import { PatchFactory } from '../_models/patch-factory';
import { Project } from '../_models/project';
import { Template } from '../_models/template';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { TemplateLanguage } from '../_models/template-language';
import { Form } from '../_models/form';
import { TemplateLanguageValues } from '../_models/template-language-values';
import { Layout } from '../_models/layout';
import { LayoutLanguage } from '../_models/layout-language';
import { LayoutLanguageValues } from '../_models/layout-language-values';
import { Flow } from '../_models/flow';
import { TemplateBase } from '../_models/template-base';
import { AlertConfiguration } from '../_models/alert-configuration';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/_services/config.service';
import { ConsoleLoggerService } from 'src/app/_services/console-logger.service';
import { UserAlertAssignmentModel } from 'src/app/_models/user-alert-assignment.model';
import { ApiServiceBase } from 'src/app/_services/api-service-base';
import { DashboardOptions, DetailPage } from '../_models/results';
import { Category, CategoryCulture } from '../_models/category';
import { FormVersionMetadata } from '../_models/form-version-metadata';
import { AlertDetailModel, AlertStatusHistoryModel, AlertPage } from '../_models/alert-detail-model';
import { AlertForward } from '../_models/alert-forward';
import { DashboardFilterService } from './dashboard.filter.service';
import { ProjectOptionModel } from '../_models/project-option-model';
import { isElementInViewport } from '@amcharts/amcharts4/.internal/core/utils/DOM';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';
import { ProjectUrl } from '../_models/project-url';
import { FormVersion } from '../_models/form-version';
import { EmptyGuid } from '../../../_helpers/guid';
import { AlertListItem, AlertListTab } from '../_models/alert-list-models';
import { QuestionDetail } from '../_models/question-detail.model';
import { UrlService } from 'src/app/_services/url.service';
import { EmailLayout } from '../_models/email-layout';
import { EmailTemplateBase } from '../_models/email-template-base';
import { EmailTemplate } from '../_models/email-template';
import { EmailTemplateCulture } from '../_models/email-template-culture';
import { EmailLayoutCulture } from '../_models/email-layout-culture';
import { CodeGroupModel } from '../_models/code-mapping.model';
import { AvailableReport } from '../_models/available-report';
import { BookmarkDto, BookmarkViewModel, UserBookmarkSection } from '../_models/bookmark';

@Injectable({
  providedIn: 'root',
})
export class VocService extends ApiServiceBase {
  emptyGuid = '00000000-0000-0000-0000-000000000000';

  constructor(
    protected urlService: UrlService,
    private http: HttpClient,
    protected configService: ConfigService,
    protected consoleLogger: ConsoleLoggerService,
    protected translatorService: PortalTranslatorService
  ) {
    super(urlService, configService, consoleLogger, configService.config.api.modules.voc.path);
  }

  exportFormToWord(formId: string, version: FormVersion, wordExportIntro: any): Promise<Blob> {
    const url = this.getUrl(
      environment.formEndPoint,
      formId,
      environment.versionEndPoint,
      version.majorVersion.toString(),
      version.minorVersion.toString(),
      environment.exportEndpoint
    );

    return this.http.post(url, wordExportIntro, { responseType: 'blob' }).toPromise();
  }

  exportProject(projectId: string): Promise<Blob> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.exportEndpoint);

    return this.http.post(url, null, { responseType: 'blob' }).toPromise();
  }

  importProject(subscriptionServiceId: string, data: string): Promise<boolean> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.projectEndPoint, environment.importEndpoint);

    return this.http.post<boolean>(url, JSON.parse(data)).toPromise();
  }

  getReport(projectId: string, reportOption: any): Observable<HttpResponse<Blob>> {
    const url = this.getUrl(environment.projectEndPoint, projectId, this.translatorService.activeLanguage.value, environment.reportsEndpoint);

    return this.http.post(url, reportOption, { observe: 'response', responseType: 'blob' });
  }

  getAlertPdfUrl(projectId: string, surveyAnswerId: string, filename: string) {
    return this.getUrl(environment.pdfEndpoint, environment.webSurveyEndpoint, surveyAnswerId, projectId, filename) + '?culture=' + this.translatorService.activeLanguage.value;
  }

  forwardAlert(alertForwardModel: AlertForward): Promise<AlertStatusHistoryModel> {
    const url = this.getUrl(environment.alertForwardEndPoint);
    return this.http.post<AlertStatusHistoryModel>(url, alertForwardModel).toPromise();
  }

  // Get

  getBookMarks(subscriptionServiceId: string, projectId: string, userBookmarkSection: UserBookmarkSection): Promise<BookmarkViewModel[]> {
    const url = this.getUrl(
      subscriptionServiceId,
      environment.projectEndPoint,
      projectId,
      environment.bookmarkEndPoint,
      environment.sectionEndPoint,
      userBookmarkSection.toString()
    );

    return this.http.get<BookmarkViewModel[]>(url).toPromise();
  }

  public getPermission(id: string): Promise<boolean> {
    return this.http.get<boolean>(this.getUrl(environment.subscriptionServicesEndPoint, environment.permissionEndPoint, id)).toPromise();
  }

  getAvailableCustomImportPermissions(subscriptionServiceId: string, projectId: string): Promise<boolean> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, projectId, environment.subscriptionServiceGetCustomImportViewPermissions);

    return this.http.get<boolean>(url).toPromise();
  }

  getAvailableReports(subscriptionServiceId: string, projectId: string): Promise<AvailableReport[]> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, projectId, environment.subscriptionServiceReportsEndPoint);
    return this.http.get<AvailableReport[]>(url).toPromise();
  }

  getAlerts(subscriptionServiceId: string, dashboardOptions: DashboardOptions, alertListType: AlertListTab, projectId?: string): Observable<AlertPage> {
    const url = projectId
      ? this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.projectEndPoint, projectId, environment.alertsEndPoint, alertListType.toString())
      : this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.alertsEndPoint, alertListType.toString());

    return this.http.post<AlertPage>(url, dashboardOptions);
  }

  getAlertDetails(surveyAnswerId: string, subscriptionServiceId: string, lang: string, projectId?: string): Promise<AlertDetailModel> {
    const url = projectId
      ? this.getUrl(
          environment.subscriptionServicesEndPoint,
          subscriptionServiceId,
          environment.projectEndPoint,
          projectId,
          environment.surveyAnswerEndPoint,
          surveyAnswerId,
          environment.alertsEndPoint,
          lang
        )
      : this.getUrl(environment.subscriptionServicesEndPoint, subscriptionServiceId, environment.surveyAnswerEndPoint, surveyAnswerId, environment.alertsEndPoint, lang);
    return this.http.get<AlertDetailModel>(url).toPromise();
  }

  getProjectOptions(projectId: string): Promise<ProjectOptionModel[]> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.projectOptionsEndpoint);
    return this.http.get<ProjectOptionModel[]>(url).toPromise();
  }

  getCodeMappingGroups(regex: string): Promise<CodeGroupModel[]> {
    const url = this.getUrl(environment.projectEndPoint, environment.regexGroupEndPoint);
    return this.http.get<CodeGroupModel[]>(url, { headers: new HttpHeaders({ 'hexa-voc-regex': regex }) }).toPromise();
  }

  getQuestionDetail(projectId: string, loadDemoData: boolean = false): Observable<QuestionDetail[]> {
    var url = this.getUrl(environment.projectEndPoint, projectId, environment.questionsEndPoint);
    if (loadDemoData) {
      console.log('inside demo data');
      url += '?LoadDemoData=true';
      console.log(url);
    }
    return this.http.get<QuestionDetail[]>(url);
  }

  getProject(id: string): Promise<Project> {
    const url = this.getUrl(environment.projectEndPoint, id);
    return this.http.get<Project>(url).toPromise();
  }

  getProjects(vocSubscriptionServiceId: string, lang: string): Promise<Project[]> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, vocSubscriptionServiceId, environment.projectEndPoint, lang, 'lang');
    return this.http.get<Project[]>(url).toPromise();
  }

  getSurvey(id: string): Promise<Survey> {
    const url = this.getUrl(environment.surveyEndPoint, id);
    return this.http.get<Survey>(url).toPromise();
  }

  regenerateScores(surveyId: string, startDate?: string, endDate?: string) {
    const url = this.getUrl(environment.surveyEndPoint, surveyId, environment.regenerateScoresEndPoint);

    let params = new HttpParams();
    if (startDate && endDate) {
      params = params.set('startDate', startDate);
      params = params.set('endDate', endDate);
    }

    return this.http.get<Survey>(url, { params: params }).toPromise();
  }

  regenerateScoresForVersion(formId: string, majorVersion: number, minorVersion: number, startDate?: string, endDate?: string) {
    const url = this.getUrl(environment.formEndPoint, formId, environment.versionEndPoint, majorVersion.toString(), minorVersion.toString(), environment.regenerateScoresEndPoint);

    let params = new HttpParams();
    if (startDate && endDate) {
      params = params.set('startDate', startDate);
      params = params.set('endDate', endDate);
    }

    return this.http.get<Survey>(url, { params: params }).toPromise();
  }

  regenerateScoresForList(projectId: string, list: string) {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.regenerateScoresEndPoint);
    let params = new HttpParams();
    params = params.set('answerList', list);
    return this.http.get<Survey>(url, { params }).toPromise();
  }

  getSurveys(): Promise<Survey[]> {
    const url = this.getUrl(environment.surveyEndPoint);
    return this.http.get<Survey[]>(url).toPromise();
  }

  getTemplates(id: string): Promise<Template[]> {
    const url = this.getUrl(environment.projectEndPoint, id, environment.templateEndPoint);

    return this.http.get<Template[]>(url).toPromise();
  }

  getEmailTemplates(projectId: string): Promise<EmailTemplate[]> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.templateEndPoint);
    return this.http.get<EmailTemplate[]>(url).toPromise();
  }

  getLayouts(id: string): Promise<Layout[]> {
    const url = this.getUrl(environment.projectEndPoint, id, environment.layoutEndPoint);

    return this.http.get<Layout[]>(url).toPromise();
  }

  getTemplate(id: string): Promise<Template> {
    const url = this.getUrl(environment.templateEndPoint, id);
    return this.http.get<Template>(url).toPromise();
  }

  getEmailTemplate(projectId: string, templateId: string): Promise<EmailTemplate> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.templateEndPoint, templateId);
    return this.http.get<EmailTemplate>(url).toPromise();
  }

  getLayout(id: string): Promise<Layout> {
    const url = this.getUrl(environment.layoutEndPoint, id);
    return this.http.get<Layout>(url).toPromise();
  }

  getEmailLayouts(projectId: string): Promise<EmailLayout[]> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.layoutEndPoint);
    return this.http.get<EmailLayout[]>(url).toPromise();
  }

  getEmailLayout(projectId: string, layoutId: string): Promise<EmailLayout> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.layoutEndPoint, layoutId);
    return this.http.get<EmailLayout>(url).toPromise();
  }

  getTemplateLanguageWithValue(templateLanguageId: string, surveyId: string): Promise<TemplateLanguage> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId);

    return this.http.get<TemplateLanguage>(url).toPromise();
  }

  getLayoutLanguageWithValue(templateLanguageId: string, surveyId: string): Promise<LayoutLanguage> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId, environment.layoutEndPoint);

    return this.http.get<LayoutLanguage>(url).toPromise();
  }

  getTemplateLanguage(id: string): Promise<TemplateLanguage> {
    const url = this.getUrl(environment.templateLanguageEndPoint, id);
    return this.http.get<TemplateLanguage>(url).toPromise();
  }

  getEmailTemplateCulture(projectId: string, templateId: string, cultureId: string): Promise<EmailTemplateCulture> {
    const url = this.getUrl(
      environment.projectEndPoint,
      projectId,
      environment.emailTemplateEndpoint,
      environment.templateEndPoint,
      templateId,
      environment.cultureEndPoint,
      cultureId
    );

    return this.http.get<EmailTemplateCulture>(url).toPromise();
  }

  getLayoutLanguage(id: string): Promise<LayoutLanguage> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, id);
    return this.http.get<LayoutLanguage>(url).toPromise();
  }

  getEmailLayoutCulture(projectId: string, layoutId: string, cultureId: string): Promise<EmailLayoutCulture> {
    const url = this.getUrl(
      environment.projectEndPoint,
      projectId,
      environment.emailTemplateEndpoint,
      environment.layoutEndPoint,
      layoutId,
      environment.cultureEndPoint,
      cultureId
    );

    return this.http.get<EmailLayoutCulture>(url).toPromise();
  }

  getPredefinedFlows(): Promise<Flow[]> {
    const url = this.getUrl(environment.predefinedFlowEndPoint);
    return this.http.get<Flow[]>(url).toPromise();
  }

  getForms(id: string): Promise<Form[]> {
    const url = this.getUrl(environment.projectEndPoint, id, environment.formEndPoint);
    return this.http.get<Form[]>(url).toPromise();
  }

  getForm(id: string): Promise<Form> {
    const url = this.getUrl(environment.formEndPoint, id);
    return this.http.get<Form>(url).toPromise();
  }

  getCategories(id: string): Promise<Category[]> {
    const url = this.getUrl(environment.projectEndPoint, id, environment.categoryEndPoint);
    return this.http.get<Category[]>(url).toPromise();
  }

  getCategory(id: string): Promise<Category> {
    const url = this.getUrl(environment.categoryEndPoint, id);
    return this.http.get<Category>(url).toPromise();
  }

  getCategoryCulture(id: string): Promise<CategoryCulture> {
    const url = this.getUrl(environment.categoryEndPoint, id, environment.categoryCultureEndPoint, id);
    return this.http.get<CategoryCulture>(url).toPromise();
  }

  getProjectUrls(id: string): Promise<ProjectUrl[]> {
    const url = this.getUrl(environment.projectEndPoint, id, environment.urlsEndPoint);
    return this.http.get<ProjectUrl[]>(url).toPromise();
  }

  getProjectUrl(id: string): Promise<ProjectUrl> {
    const url = this.getUrl(environment.projectUrlEndPoint, id);
    return this.http.get<ProjectUrl>(url).toPromise();
  }

  getVersion(formId: string, major: number, minor: number): Promise<FormVersion> {
    const url = this.getUrl(environment.formEndPoint, formId, environment.versionEndPoint, major.toString(), minor.toString());
    return this.http.get<FormVersion>(url).toPromise();
  }

  getFormVersionMetadata(formVersionId: string): Promise<FormVersionMetadata[]> {
    const url = this.getUrl(environment.formVersionMetadataEndPoint, formVersionId);
    return this.http.get<FormVersionMetadata[]>(url).toPromise();
  }

  getCustomImportCount(projectId: string, startDate?: string, endDate?: string): Promise<number[]> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, projectId);

    let params = new HttpParams();
    if (startDate && endDate) {
      params = params.set('startDate', startDate);
      params = params.set('endDate', endDate);
    }
    return this.http.get<number[]>(url, { params: params }).toPromise();
  }

  // Add

  addBookMark(subscriptionServiceId: string, projectId: string, bookmarkDto: BookmarkDto): Promise<BookmarkViewModel> {
    const url = this.getUrl(subscriptionServiceId, environment.projectEndPoint, projectId, environment.bookmarkEndPoint);

    return this.http.post<BookmarkViewModel>(url, bookmarkDto).toPromise();
  }

  addProject(project: Project): Promise<string> {
    const url = this.getUrl(environment.subscriptionServicesEndPoint, project.voiceOfCustomerServiceId, environment.projectEndPoint);

    return this.http
      .post<string>(url, {
        name: project.name,
        voiceOfCustomerServiceId: project.voiceOfCustomerServiceId,
      })
      .toPromise();
  }

  addVoc(voc: Survey): Promise<string> {
    const url = this.getUrl(environment.surveyEndPoint);

    return this.http
      .post<string>(url, {
        projectId: voc.projectId,
        name: voc.name,
        defaultLanguage: voc.defaultLanguage,
        supportedLanguages: voc.supportedLanguages,
        flow: {
          flowJson: voc.flow.flowJson,
          sourceId: voc.flow.sourceId,
          name: null,
        },
      })
      .toPromise();
  }

  addTemplate(template: Template): Promise<string> {
    const url = this.getUrl(environment.templateEndPoint);

    return this.http
      .post<string>(url, {
        projectId: template.projectId,
        name: template.name,
        sourceId: template.sourceId,
        layoutId: template.layoutId,
        data: template.data,
      })
      .toPromise();
  }

  addEmailTemplate(projectId: string, template: EmailTemplate): Promise<string> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.templateEndPoint);

    return this.http
      .post<string>(url, {
        projectId,
        name: template.name,
        emailLayoutId: template.emailLayoutId,
        data: template.data,
      })
      .toPromise();
  }

  addLayout(layout: TemplateBase): Promise<string> {
    const url = this.getUrl(environment.layoutEndPoint);

    return this.http
      .post<string>(url, {
        projectId: layout.projectId,
        name: layout.name,
        sourceId: layout.sourceId,
        data: layout.data,
      })
      .toPromise();
  }

  addEmailLayout(projectId: string, layout: EmailTemplateBase): Promise<string> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.layoutEndPoint);

    return this.http
      .post<string>(url, {
        projectId,
        name: layout.name,
        data: layout.data,
      })
      .toPromise();
  }

  addForm(form: Form): Promise<string> {
    const url = this.getUrl(environment.formEndPoint);

    return this.http
      .post<string>(url, {
        projectId: form.projectId,
        name: form.name,
        sourceId: form.sourceId,
      })
      .toPromise();
  }

  addProjectUrl(projectUrl: ProjectUrl): Promise<string> {
    const url = this.getUrl(environment.projectUrlEndPoint);

    const payload = {
      projectId: projectUrl.projectId,
      type: projectUrl.type,
      custom: projectUrl.custom,
      subDomain: projectUrl.subdomain,
      domain: projectUrl.domain,      
      secured: projectUrl.secured,
      path: projectUrl.path,
      ttlSeconds: 3600,
    };

    return this.http.post<string>(url, payload).toPromise();
  }

  addCategory(category: Category): Promise<string> {
    const url = this.getUrl(environment.categoryEndPoint);

    const payload = {
      projectId: category.projectId,
      name: category.name,
      shortName: category.shortName,
      code: category.code,
      order: category.order,
      cultures: [],
    };

    if (category.cultures) {
      category.cultures.forEach((cat) => {
        payload.cultures.push({
          name: cat.name,
          shortName: cat.shortName,
          cultureCode: cat.cultureCode,
        });
      });
    }

    return this.http.post<string>(url, payload).toPromise();
  }

  addCategoryCulture(categoryCulture: CategoryCulture): Promise<string> {
    const url = this.getUrl(environment.categoryEndPoint, categoryCulture.categoryId, environment.categoryCultureEndPoint);
    const payload = {
      name: categoryCulture.name,
      shortName: categoryCulture.shortName,
      cultureCode: categoryCulture.cultureCode,
    };

    return this.http.post<string>(url, payload).toPromise();
  }

  addTemplateLanguage(templateLanguage: TemplateLanguage): Promise<string> {
    const url = this.getUrl(environment.templateLanguageEndPoint);

    return this.http
      .post<string>(url, {
        sourceId: templateLanguage.sourceId,
        templateId: templateLanguage.templateId,
        name: templateLanguage.name,
        data: templateLanguage.data,
        formJson: templateLanguage.formJson,
      })
      .toPromise();
  }

  addEmailTemplateCulture(projectId: string, templateId: string, emailTemplateCulture: EmailTemplateCulture): Promise<string> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.templateEndPoint, templateId, environment.cultureEndPoint);

    return this.http
      .post<string>(url, {
        templateId,
        cultureCode: emailTemplateCulture.cultureCode,
        data: emailTemplateCulture.data,
        formJson: emailTemplateCulture.formJson,
      })
      .toPromise();
  }

  addLayoutLanguage(layoutLanguage: LayoutLanguage): Promise<string> {
    const url = this.getUrl(environment.layoutLanguageEndPoint);

    return this.http
      .post<string>(url, {
        sourceId: layoutLanguage.sourceId,
        layoutId: layoutLanguage.layoutId,
        name: layoutLanguage.name,
        data: layoutLanguage.data,
        formJson: layoutLanguage.formJson,
      })
      .toPromise();
  }

  addEmailLayoutCulture(projectId: string, layoutId: string, emailLayoutCulture: EmailLayoutCulture): Promise<string> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.layoutEndPoint, layoutId, environment.cultureEndPoint);

    return this.http
      .post<string>(url, {
        layoutId,
        cultureCode: emailLayoutCulture.cultureCode,
        data: emailLayoutCulture.data,
        formJson: emailLayoutCulture.formJson,
      })
      .toPromise();
  }

  addTemplateValues(versionId: string, templateId: string, values: any): Promise<string> {
    const url = this.getUrl(environment.templateEndPoint, templateId, environment.versionEndPoint, versionId);

    return this.http.post<string>(url, values).toPromise();
  }

  addTemplateLanguageValues(templateLanguageId: string, surveyId: string, valuesJson: string, headTitle: string): Promise<string> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .post<string>(url, {
        templateLanguageId,
        surveyId,
        valuesJson,
        headTitle,
      })
      .toPromise();
  }

  addLayoutLanguageValues(layoutLanguageId: string, surveyId: string, valuesJson: string): Promise<string> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, layoutLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .post<string>(url, {
        layoutLanguageId,
        surveyId,
        valuesJson,
      })
      .toPromise();
  }

  saveAlertConfiguration(formVersionId: string, alertConfiguration: AlertConfiguration): Promise<string> {
    const url = this.getUrl(environment.alertConfigurationEndPoint);

    return this.http
      .post<string>(url, {
        formVersionId,
        alertConfiguration,
      })
      .toPromise();
  }

  saveAlertHistoryStatus(statusModel: AlertStatusHistoryModel, alertId: string): Promise<any> {
    const url = this.getUrl(environment.alertSaveHistoryStatusEndPoint);
    return this.http.post<AlertStatusHistoryModel>(url, { alertId, status: statusModel }).toPromise();
  }

  // Updates

  updateProject(project: Project): Promise<boolean> {
    const url = this.getUrl(environment.projectEndPoint, project.id);
    return this.http
      .put<boolean>(url, {
        id: project.id,
        name: project.name,
        metadata: project.metadata,
        cultures: project.cultures,
        order: project.order,
      })
      .toPromise();
  }

  updateProjectMetadata(id: string, operation: any): Promise<boolean> {
    const url = this.getUrl(environment.projectEndPoint, id);
    return this.http.patch<boolean>(url, operation).toPromise();
  }

  updateSurvey(survey: Survey): Promise<boolean> {
    const url = this.getUrl(environment.surveyEndPoint);
    return this.http
      .put<boolean>(url, {
        id: survey.id,
        createdDate: survey.createdDate,
        lastModifiedDate: survey.lastModifiedDate,
        projectId: survey.projectId,
        flowId: survey.flowId,
        name: survey.name,
        defaultLanguage: survey.defaultLanguage,
        supportedLanguages: survey.supportedLanguages,
        flow: survey.flow,
      })
      .toPromise();
  }

  updateTemplate(template: Template): Promise<boolean> {
    const url = this.getUrl(environment.templateEndPoint, template.id);

    return this.http
      .put<boolean>(url, {
        id: template.id,
        layoutId: template.layoutId,
        templateLanguages: template.templateLanguages,
        lastModifiedDate: template.lastModifiedDate,
        createdDate: template.createdDate,
        projectId: template.projectId,
        sourceId: template.sourceId,
        name: template.name,
        data: template.data,
      })
      .toPromise();
  }

  updateLayout(layout: Layout): Promise<boolean> {
    const url = this.getUrl(environment.layoutEndPoint, layout.id);

    return this.http
      .put<boolean>(url, {
        id: layout.id,
        layoutLanguages: layout.layoutLanguages,
        lastModifiedDate: layout.lastModifiedDate,
        createdDate: layout.createdDate,
        projectId: layout.projectId,
        sourceId: layout.sourceId,
        name: layout.name,
        data: layout.data,
      })
      .toPromise();
  }

  updateEmailTemplate(projectId: string, templateId: string, emailTemplate: EmailTemplate): Promise<boolean> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.templateEndPoint, templateId);

    return this.http
      .put<boolean>(url, {
        id: templateId,
        name: emailTemplate.name,
        projectId,
        lastModifiedDate: emailTemplate.lastModifiedDate,
        createdDate: emailTemplate.createdDate,
        data: emailTemplate.data,
        emailLayoutId: emailTemplate.emailLayoutId,
      })
      .toPromise();
  }

  updateEmailLayout(projectId: string, layoutId: string, emailLayout: EmailLayout): Promise<boolean> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.emailTemplateEndpoint, environment.layoutEndPoint, layoutId);

    return this.http
      .put<boolean>(url, {
        id: layoutId,
        lastModifiedDate: emailLayout.lastModifiedDate,
        createdDate: emailLayout.createdDate,
        projectId,
        name: emailLayout.name,
        data: emailLayout.data,
      })
      .toPromise();
  }

  updateEmailTemplateCulture(projectId: string, templateId: string, cultureId: string, emailTemplateCulture: EmailTemplateCulture): Promise<boolean> {
    const url = this.getUrl(
      environment.projectEndPoint,
      projectId,
      environment.emailTemplateEndpoint,
      environment.templateEndPoint,
      templateId,
      environment.cultureEndPoint,
      cultureId
    );

    return this.http
      .put<boolean>(url, {
        id: cultureId,
        lastModifiedDate: emailTemplateCulture.lastModifiedDate,
        createdDate: emailTemplateCulture.createdDate,
        templateId,
        cultureCode: emailTemplateCulture.cultureCode,
        data: emailTemplateCulture.data,
        formJson: emailTemplateCulture.formJson,
      })
      .toPromise();
  }

  updateForm(form: Form): Promise<boolean> {
    const url = this.getUrl(environment.formEndPoint, form.id);
    return this.http
      .put<boolean>(url, {
        id: form.id,
        createdDate: form.createdDate,
        lastModifiedDate: form.lastModifiedDate,
        name: form.name,
        projectId: form.projectId,
        sourceId: form.sourceId,
        formVersions: form.formVersions,
        latestVersionJson: form.latestVersionJson,
        latestVersion: form.latestVersion,
      })
      .toPromise();
  }

  updateUrl(projectUrl: ProjectUrl) {
    const url = this.getUrl(environment.projectUrlEndPoint, projectUrl.id);
    return this.http
      .put<boolean>(url, {
        id: projectUrl.id,
        createdDate: projectUrl.createdDate,
        lastModifiedDate: projectUrl.lastModifiedDate,
        projectId: projectUrl.projectId,
        type: projectUrl.type,
        domain: projectUrl.domain,
        subdomain: projectUrl.subdomain,
        custom: projectUrl.custom,
        secured: projectUrl.secured,
        ttlSeconds: projectUrl.ttlSeconds,
        path: projectUrl.path,
      })
      .toPromise();
  }

  updateCategory(category: Category): Promise<boolean> {
    const url = this.getUrl(environment.categoryEndPoint, category.id);
    return this.http
      .put<boolean>(url, {
        id: category.id,
        createdDate: category.createdDate,
        lastModifiedDate: category.lastModifiedDate,
        projectId: category.projectId,
        name: category.name,
        shortName: category.shortName,
        code: category.code,
        cultures: category.cultures,
        order: category.order,
      })
      .toPromise();
  }

  updateCategoryCulture(categoryCulture: CategoryCulture): Promise<boolean> {
    const url = this.getUrl(environment.categoryEndPoint, categoryCulture.categoryId, environment.categoryCultureEndPoint, categoryCulture.id);
    return this.http
      .put<boolean>(url, {
        id: categoryCulture.id,
        createdDate: categoryCulture.createdDate,
        lastModifiedDate: categoryCulture.lastModifiedDate,
        categoryId: categoryCulture.categoryId,
        category: categoryCulture.category,
        name: categoryCulture.name,
        shortName: categoryCulture.shortName,
        cultureCode: categoryCulture.cultureCode,
      })
      .toPromise();
  }

  updateTemplateLanguage(templateLanguage: TemplateLanguage): Promise<boolean> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguage.id);

    return this.http
      .put<boolean>(url, {
        id: templateLanguage.id,
        createdDate: templateLanguage.createdDate,
        lastModifiedDate: templateLanguage.lastModifiedDate,
        templateId: templateLanguage.templateId,
        templateLanguageValues: templateLanguage.templateLanguageValues,
        sourceId: templateLanguage.sourceId,
        name: templateLanguage.name,
        data: templateLanguage.data,
        formJson: templateLanguage.formJson,
      })
      .toPromise();
  }

  updateLayoutLanguage(layoutLanguage: LayoutLanguage): Promise<boolean> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, layoutLanguage.id);

    return this.http
      .put<boolean>(url, {
        id: layoutLanguage.id,
        name: layoutLanguage.name,
        lastModifiedDate: layoutLanguage.lastModifiedDate,
        createdDate: layoutLanguage.createdDate,
        data: layoutLanguage.data,
        formJson: layoutLanguage.formJson,
        sourceId: layoutLanguage.sourceId,
        layoutId: layoutLanguage.layoutId,
      })
      .toPromise();
  }

  updateEmailLayoutCulture(projectId: string, layoutId: string, cultureId: string, emailLayoutCulture: EmailLayoutCulture): Promise<boolean> {
    const url = this.getUrl(
      environment.projectEndPoint,
      projectId,
      environment.emailTemplateEndpoint,
      environment.layoutEndPoint,
      layoutId,
      environment.cultureEndPoint,
      cultureId
    );

    return this.http
      .put<boolean>(url, {
        id: cultureId,
        lastModifiedDate: emailLayoutCulture.lastModifiedDate,
        createdDate: emailLayoutCulture.createdDate,
        layoutId,
        cultureCode: emailLayoutCulture.cultureCode,
        data: emailLayoutCulture.data,
        formJson: emailLayoutCulture.formJson,
      })
      .toPromise();
  }

  updateTemplateLanguageValues(templateLanguageId: string, surveyId: string, values: TemplateLanguageValues): Promise<boolean> {
    const url = this.getUrl(environment.templateLanguageEndPoint, templateLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .put<boolean>(url, {
        id: values.id,
        createdDate: values.createdDate,
        lastModifiedDate: values.lastModifiedDate,
        surveyId: values.surveyId,
        templateLanguageId: values.templateLanguageId,
        valuesJson: values.valuesJson,
        headTitle: values.headTitle,
      })
      .toPromise();
  }

  updateLayoutLanguageValues(layoutLanguageId: string, surveyId: string, values: LayoutLanguageValues): Promise<boolean> {
    const url = this.getUrl(environment.layoutLanguageEndPoint, layoutLanguageId, environment.surveyEndPoint, surveyId);

    return this.http
      .put<boolean>(url, {
        id: values.id,
        createdDate: values.createdDate,
        lastModifiedDate: values.lastModifiedDate,
        surveyId: values.surveyId,
        layoutLanguageId: values.layoutLanguageId,
        valuesJson: values.valuesJson,
      })
      .toPromise();
  }

  patchVersion(formVersionId: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();
    const url = this.getUrl(environment.versionEndPoint, formVersionId);

    return this.http.patch<boolean>(url, payload).toPromise();
  }

  saveNewVersion(formId: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();

    const url = this.getUrl(environment.formEndPoint, formId, environment.versionEndPoint, environment.jsonUpload);

    return this.http.patch<boolean>(url, payload).toPromise();
  }

  saveProjectOptions(projectId: string, projectOptionModels: ProjectOptionModel[]): Promise<boolean> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.projectOptionsEndpoint);
    return this.http.post<boolean>(url, projectOptionModels).toPromise();
  }

  publishVersion(formId: string): Promise<boolean> {
    const url = this.getUrl(environment.formEndPoint, formId, environment.versionEndPoint, environment.publishVersion);

    return this.http.patch<boolean>(url, null).toPromise();
  }

  uploadTemplateLanguageJson(id: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();

    const url = this.getUrl(environment.templateLanguageEndPoint, id, environment.jsonUpload);

    return this.http.patch<boolean>(url, payload).toPromise();
  }

  uploadLayoutLanguageJson(id: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();

    const url = this.getUrl(environment.layoutLanguageEndPoint, id, environment.jsonUpload);

    return this.http.patch<boolean>(url, payload).toPromise();
  }

  uploadEmailTemplateCultureJson(projectId: string, templateId: string, cultureId: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();

    const url = this.getUrl(
      environment.projectEndPoint,
      projectId,
      environment.emailTemplateEndpoint,
      environment.templateEndPoint,
      templateId,
      environment.cultureEndPoint,
      cultureId,
      environment.jsonUpload
    );

    return this.http.patch<boolean>(url, payload).toPromise();
  }

  uploadEmailLayoutCultureJson(projectId: string, layoutId: string, cultureId: string, json: string): Promise<boolean> {
    const payload = new PatchFactory().replace('/formJson', json).build();

    const url = this.getUrl(
      environment.projectEndPoint,
      projectId,
      environment.emailTemplateEndpoint,
      environment.layoutEndPoint,
      layoutId,
      environment.cultureEndPoint,
      cultureId,
      environment.jsonUpload
    );

    return this.http.patch<boolean>(url, payload).toPromise();
  }

  saveFormVersionMetadata(formVersionMetadata: FormVersionMetadata[]): Promise<boolean> {
    const url = this.getUrl(environment.formVersionMetadataEndPoint);

    return this.http.post<boolean>(url, formVersionMetadata).toPromise();
  }
  // Deletes

  deleteBookMark(subscriptionServiceId: string, projectId: string, bookmarkId: string): Promise<boolean> {
    const url = this.getUrl(subscriptionServiceId, environment.projectEndPoint, projectId, environment.bookmarkEndPoint, bookmarkId);

    return this.http.delete<boolean>(url).toPromise();
  }

  deleteProject(id: string): Promise<object> {
    const url = this.getUrl(environment.projectEndPoint, id);
    return this.http.delete(url).toPromise();
  }

  deleteVoc(id: string): Promise<object> {
    const url = this.getUrl(environment.surveyEndPoint, id);
    return this.http.delete(url).toPromise();
  }

  deleteVersion(id: string): Promise<object> {
    const url = this.getUrl(environment.versionEndPoint, id);
    return this.http.delete(url).toPromise();
  }

  deleteForm(id: string): Promise<object> {
    const url = this.getUrl(environment.formEndPoint, id);
    return this.http.delete(url).toPromise();
  }

  deleteTemplate(id: string): Promise<object> {
    const url = this.getUrl(environment.templateEndPoint, id);
    return this.http.delete(url).toPromise();
  }

  deleteCategoryCulture(categoryCulture: CategoryCulture): Promise<object> {
    const url = this.getUrl(environment.categoryEndPoint, categoryCulture.categoryId, environment.categoryCultureEndPoint, categoryCulture.id);
    return this.http.delete(url).toPromise();
  }

  deleteProjectUrl(id: string) {
    const url = this.getUrl(environment.projectUrlEndPoint, id);
    return this.http.delete<boolean>(url).toPromise();
  }

  getResultsDetails(projectId: string, dashboardOptions: DashboardOptions): Promise<DetailPage> {
    const url = this.getUrl(environment.projectEndPoint, projectId, environment.dashboardDetailsEndPoint);
    return this.http.post<DetailPage>(url, dashboardOptions).toPromise();
  }

  getAnswer(id: string): Promise<any> {
    const url = this.getUrl('/surveyanswer', id);
    return this.http.get<any>(url).toPromise();
  }

  updateAnswerState(id: string, state: string): Promise<boolean> {
    const url = this.getUrl('/surveyanswer', id, state);
    return this.http.put<boolean>(url, {}).toPromise();
  }

  public getSurveyAnswerPdf(surveyAnswerId: string, projectId: string) {
    const url = this.getUrl(environment.pdfEndpoint, environment.projectEndPoint, projectId, environment.surveyAnswerEndPoint, surveyAnswerId);

    return this.http.post(url, null, { responseType: 'blob' }).toPromise();
  }
}
