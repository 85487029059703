<div class="pos-content">
    <div class="groups-title">{{ translator.translate('group') | async }} ({{ posInfo.groups.length }})</div>

    <div class="groups-list">
      <div class="group" *ngFor="let group of posInfo.groups | slice:0:2; let i = index;"
        (click)="onClickGroup(group.id)">
        <div class="group-name" *ngIf="i < 2">{{ group.name }}</div>
      </div>

      <div class="group-plus-more" *ngIf="posInfo.groups.length > 2" [ngbPopover]="popContent"
        triggers="mouseenter:mouseleave" popoverTitle="{{ translator.translate('group') | async }}"
        [autoClose]="'outside'"
        [openDelay]="300">
        <ng-template #popContent>
          <p *ngFor="let group of posInfo.groups | slice:2;">
            {{group.name}}<br />
          </p>
        </ng-template>
        + {{ posInfo.groups.length - 2 }}
      </div>
    </div>
  </div>