<div
  class="dashboard-widget"
  [ngClass]="{ 'alerts-board': isAlertsWidget(), 'competitors-board': isCompetitorsWidget(), 'emails-board': isEmailsWidget() }"
  *ngIf="!(elementConfigs[0]?.type == WidgetType.StatisticsSocialMediaRanking && elementConfigs[0]?.config.socialMediaClickCounts.length <= 1)"
>
  <div class="header">
    <div class="left-container">
      <div class="title flex-wrap d-flex">
        <app-info-popover [size]="1" [helpHTML]="widgetConfig.titleHelp" [hasHelp]="widgetConfig.titleHelp" [title]="widgetConfig.title"></app-info-popover>
      </div>
      <div class="additional-info" *ngIf="hasData">{{ widgetConfig.additionalInfo }}</div>
    </div>
    <div class="right-container" *ngIf="hasData && showDetailsLink()">
      <a href="javascript:void(0)" *ngIf="!isCompetitorsWidget() && !isTrendsWidget() && !isAlertsWidget()" (click)="goToDetails()" class="footer-link">{{
        translator.translate('dashboard_view_details') | async
      }}</a>
      <a href="javascript:void(0)" *ngIf="isCompetitorsWidget()" (click)="goToCompetitors()" class="footer-link">{{
        translator.translate('dashboard_view_complete_list') | async
      }}</a>
      <a href="javascript:void(0)" *ngIf="isTrendsWidget()" (click)="goToTrends()" class="footer-link">{{ translator.translate('dashboard_view_all_trends') | async }}</a>
      <a href="javascript:void(0)" *ngIf="isAlertsWidget()" (click)="goToResultAlerts()" class="footer-link">{{ translator.translate('dashboard_view_all_alerts') | async }}</a>
    </div>
  </div>

  <div class="body" *ngIf="hasData">
    <ng-container *ngFor="let element of elementConfigs">
      <div class="element-container set-min-height" [ngClass]="{ 'line-chart': element.type == WidgetType.LineChart, 'hbarchart-widget': element.type == WidgetType.HBars }">
        <app-linechart-widget *ngIf="element.type == WidgetType.LineChart" [config]="element.config"></app-linechart-widget>
        <app-distribution-gauge-widget *ngIf="element.type == WidgetType.DistributionGauge" [config]="element.config"></app-distribution-gauge-widget>
        <app-percentage-gauge-widget *ngIf="element.type == WidgetType.PercentageGauge" [config]="element.config"></app-percentage-gauge-widget>
        <app-hbarschart-widget *ngIf="element.type == WidgetType.HBars" [config]="element.config"></app-hbarschart-widget>
        <app-alerts-summary-widget *ngIf="element.type == WidgetType.AlertsSummary" [config]="element.config"></app-alerts-summary-widget>
        <app-competitors-widget *ngIf="element.type == WidgetType.Competitors" [config]="element.config"></app-competitors-widget>
        <app-oversight-widget *ngIf="element.type == WidgetType.Oversight" [config]="element.config"></app-oversight-widget>
        <app-multicard-widget *ngIf="element.type == WidgetType.Multicard" [config]="element.config"></app-multicard-widget>
        <app-emails-summary-widget *ngIf="element.type == WidgetType.EmailsSummary" [config]="element.config"></app-emails-summary-widget>
        <app-survey-daily-breakdown-widget *ngIf="element.type == WidgetType.StatisticsDailyBreakdown" [config]="element.config"></app-survey-daily-breakdown-widget>
        <app-survey-hourly-breakdown-widget *ngIf="element.type == WidgetType.StatisticsHourlyBreakdown" [config]="element.config"></app-survey-hourly-breakdown-widget>
        <app-social-media-click-ranking-widget *ngIf="element.type == WidgetType.StatisticsSocialMediaRanking" [config]="element.config"></app-social-media-click-ranking-widget>
        <app-social-media-page-stats-widget *ngIf="element.type == WidgetType.StatisticsSocialMediaPage" [config]="element.config"></app-social-media-page-stats-widget>
      </div>
    </ng-container>
  </div>

  <div class="empty-body" *ngIf="!loading && !hasData">
    <img src="/assets/images/icons/graph-nodata.svg" alt="data-empty" />

    <h1>{{ translator.translate('dashboard_no_data') | async }}</h1>
  </div>

  <div class="loading-state" *ngIf="loading">
    <app-loading-spinner [show-spinner]="true"></app-loading-spinner>
  </div>

  <div class="footer" *ngIf="hasData">
    <a href="javascript:void(0)" *ngIf="!isAlertsWidget() && !isTrendsWidget() && !isCompetitorsWidget()" (click)="goToDetails()" class="footer-link">{{
      translator.translate('dashboard_view_details') | async
    }}</a>
    <a href="javascript:void(0)" *ngIf="isAlertsWidget()" (click)="goToAlerts()" class="footer-link">{{ translator.translate('dashboard_view_all_alerts') | async }}</a>
    <a href="javascript:void(0)" *ngIf="isTrendsWidget()" (click)="goToTrends()" class="footer-link">{{ translator.translate('dashboard_view_all_trends') | async }}</a>
    <a href="javascript:void(0)" *ngIf="isCompetitorsWidget()" (click)="goToCompetitors()" class="footer-link">{{
      translator.translate('dashboard_view_complete_list') | async
    }}</a>
  </div>
</div>
